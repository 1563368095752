import { INTERN_TRANSLATION_KEY } from '@/views/LearnDashboard/layouts/b2b/StartSection/sections/CoursesThatDontBelongToTracks/constants';

const es = {
  [INTERN_TRANSLATION_KEY]: {
    NEW_TAG: 'Nuevo',
    SEE_ALL_LINK: 'Ver todos',
    SEE_ALL_LINK_SESSIONS: 'Ver todas',
    COURSES_WITHOUT_TRACKS_TITLE: 'Cursos asignados para ti',
    CTA_UNDERSTOOD_COURSE: 'Entendido',
    EMPTY_TITLE: 'Todavía no tienes cursos asignados',
    EMPTY_SUBTITLE:
      'Pídele al administrador de tu cuenta que te asigne uno para arrancar con tu aprendizaje',
    START_LEARNING_COURSE: 'Tus cursos pendientes a desarrollar',
    START_LEARNING_TRACKS: 'Tus rutas pendientes a desarrollar',
    ROUTES_NAME: 'Rutas de aprendizaje',
    COURSE_NAME: 'Cursos',
    LIVE_SESSIONS_NAME: 'Próximas sesiones',
    START_LEARNING_MORE_CONTENT: 'Más contenido de tu interés',
    VIEW_CATALOG: 'Ver catálogo',
    SHOW_MORE_TRACK: 'Accede a todas tus rutas de aprendizaje',
    SHOW_MORE_COURSE: 'Accede a todos tus cursos asignados',
    SHOW_MORE_LIVE_SESSIONS: 'Accede a todas tus próximas sesiones',
    EXPIRE_DATE: 'Vence {{date}}',
  },
};
export default es;
