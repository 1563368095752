import { useQuery } from '@apollo/client';

import GET_LIST_REWARD_COURSES_QUERY from '../../graphql/GetListCoursesClaimedByRewardQuery.v5.graphql';
import {
  GetListCoursesClaimedByRewardQuery,
  GetListCoursesClaimedByRewardQueryVariables,
} from '../../graphql/types/GetListCoursesClaimedByRewardQuery';
import { getParseListCoursesRewards } from '../../utils/getParseListCoursesRewards';

const useGetListRewardsClaimeds = ({
  organizationID,
  teamID,
}: {
  organizationID: number | null;
  teamID: number | null;
}) => {
  const { data, loading, error } = useQuery<
    GetListCoursesClaimedByRewardQuery,
    GetListCoursesClaimedByRewardQueryVariables
  >(GET_LIST_REWARD_COURSES_QUERY, {
    skip: !(organizationID && teamID),
    fetchPolicy: 'cache-and-network',
    context: {
      clientName: 'v5',
    },
    variables: {
      input: {
        organization_team_id: Number(teamID),
        organization_id: organizationID,
      },
    },
  });

  return {
    listCourses: getParseListCoursesRewards(data),
    loading,
    error,
  };
};

export default useGetListRewardsClaimeds;
