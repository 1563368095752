export var MAIN_FOOTER_PAGE_KEY = 'main-footer';
export var COMPANY_LINKS = [{
  label: 'COMPANY_ABOUT',
  href: 'https://www.crehana.com/sobre-nosotros/'
}, {
  label: 'COMPANY_SIGN_UP',
  href: 'https://www.crehana.com/entrar/'
}];
export var PRODUCT_LINKS = [{
  label: 'PRODUCT_DEVELOPMENT',
  href: 'https://www.crehana.com/desarrollo-equipos/'
}, {
  label: 'PRODUCT_WORK_ENVIRONMENT',
  href: 'https://www.crehana.com/clima-laboral/'
}, {
  label: 'PRODUCT_PERFORMANCE',
  href: 'https://www.crehana.com/evaluaciones-de-desempeno/'
}];
export var RESOURCES_LINKS = [{
  label: 'RESOURCES_BLOG',
  href: 'https://www.crehana.com/blog/'
}, {
  label: 'RESOURCES_PODCAST',
  href: 'https://open.spotify.com/show/4e4HZuphxpKot9xfaqXt9k'
}, {
  label: 'RESOURCES_DOWNLOABLES',
  href: 'https://www.crehana.com/business/descargables/'
}, {
  label: 'RESOURCES_COURSES',
  href: 'https://www.crehana.com/cursos-online/'
}, {
  label: 'RESOURCES_YOUTUBE',
  href: 'https://www.youtube.com/watch?v=dtcQyOsuL3g'
}];
export var HELP_LINKS = [{
  label: 'HELP_PRIVACY',
  href: 'https://www.crehana.com/legal/politicas-de-privacidad/'
}, {
  label: 'HELP_COOKIES',
  href: 'https://www.crehana.com/legal/politica-de-cookies/'
}, {
  label: 'HELP_ARCO',
  href: 'https://www.crehana.com/legal/solicitud-arco/'
}, {
  label: 'HELP_TERMS',
  href: 'https://www.crehana.com/legal/terminos-y-condiciones/'
}, {
  label: 'HELP_CLAIMS_BOOK',
  href: 'https://www.crehana.com/libro-reclamaciones/'
}];