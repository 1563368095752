import { useCallback } from 'react';

import { createUrlWithSourceQueryParams } from '@crehana/analytics';
import {
  CourseCard,
  type TInteractiveCourseCardProps,
} from '@crehana/compass.knowledgehub/components';
import type { TSession } from '@crehana/compass.live-sessions';
import { formatDate, isDateValid } from '@crehana/date';
import type { UserTeamItem } from '@crehana/web';

import { useLearnDashboardContext } from '@/views/LearnDashboard/context';
import type { UserTeamItemParsed } from '@/views/LearnDashboard/hooks/useTeamTracks/utils/useParsedTeamCourses';

import { DEFAULT_IMG_CONTENT } from '../../../constants';
import {
  ITEMS_CUSTOM,
  LOCATION_SESSION_PLACEHOLDER_IMAGE,
  VIRTUAL_SESSION_PLACEHOLDER_IMAGE,
} from '../constants';
import type { GetHomeUserCoursesWithoutTrack_me_enrollmentSet_edges_node_course as TCoursesWithoutTrack } from '../graphql/types/GetHomeUserCoursesWithoutTrack';
import useTranslations from '../hooks/useTranslations';

type TReturnParse = Pick<
  TInteractiveCourseCardProps,
  | 'title'
  | 'duration'
  | 'courseId'
  | 'coverImage'
  | 'level'
  | 'onClick'
  | 'expiresDate'
  | 'isLiveSessionCourse'
  | 'typeLiveSession'
  | 'dateSession'
  | 'hourSession'
> & { coins?: number };

const useCardHandler = (courseCoins?: number, tracksCoins?: number) => {
  const { t, languageCode } = useTranslations();
  const {
    baseUrlB2B = '',
    redirectTo,
    organizationSlug,
  } = useLearnDashboardContext();
  const redirectToTrack = useCallback(
    (team: UserTeamItem) => {
      const path = `${baseUrlB2B}/my-courses/tracks/${team.teamId}/`;
      redirectTo(path);
    },
    [baseUrlB2B, redirectTo],
  );
  const redirectToCourse = (courseOriginalId: number) => {
    const path = `/clases/v2/${courseOriginalId}/detalle`;

    window.location.href = path;
  };

  const goToLiveSession = (sessionId: number) => {
    const baseUrl = window.location.origin;
    window.open(
      `${baseUrl}/org/${organizationSlug}/live-sessions/${sessionId}/`,
      '_blank',
    );
  };

  const redirectToCatalog = () => {
    const url = createUrlWithSourceQueryParams({
      url: baseUrlB2B + '/content/',
      source: 'Home',
      sourceDetail: 'Text Link Tracks',
    });

    redirectTo(url);
  };

  const generateCardButtons = (
    items?: TReturnParse[],
    titleContent?: string,
    onActionShowMore?: () => void,
  ) => {
    const titleSessions = titleContent === t('SHOW_MORE_LIVE_SESSIONS');
    if (!items) return null;
    const contentWithGreeting: React.ReactNode[] = items.map(
      (
        {
          title,
          duration,
          courseId,
          coverImage,
          level,
          onClick,
          coins,
          expiresDate,
          isLiveSessionCourse,
          typeLiveSession,
          dateSession,
          hourSession,
        },
        index,
      ) => {
        const EXPIRESDAYS = isDateValid(expiresDate)
          ? formatDate(new Date(expiresDate!), {
              languageCode: languageCode,
              outputFormat: 'D [de] MMMM',
            })
          : undefined;
        return (
          <div
            key={`slides_card${index * 1}`}
            className="md:tw-h-[34.4rem] tw-object-cover tw-rounded-2 tw-min-w-24 md:tw-min-w-full tw-h-[35.2rem] tw-cursor-pointer"
          >
            <CourseCard
              title={title}
              className="tw-h-full tw-w-full md:tw-min-w-full"
              duration={duration}
              courseId={courseId}
              coverImage={coverImage}
              level={level}
              sizeImage="md:tw-h-[24.6rem] tw-h-[25.4rem]"
              onClick={onClick}
              coinsNumber={coins}
              isLiveSessionCourse={isLiveSessionCourse}
              typeLiveSession={typeLiveSession}
              hourSession={hourSession}
              dateSession={dateSession}
              expiresDate={
                EXPIRESDAYS &&
                t('EXPIRE_DATE', {
                  interpolation: {
                    date: EXPIRESDAYS,
                  },
                })
              }
            />
          </div>
        );
      },
    );
    contentWithGreeting.push(
      <div
        key={`slides_card${contentWithGreeting.length}`}
        className="md:tw-h-[34.4rem] md:tw-max-w-[32.4rem] md:tw-w-full tw-object-cover tw-rounded-2 tw-h-[35.2] tw-flex tw-justify-end tw-flex-col tw-bg-primary-light-600 dark:tw-bg-primary-dark-700 tw-p-2.4 tw-min-w-24"
      >
        <p className="tw-font-subtitle1 md:tw-font-subtitle3 tw-text-neutral-light-100 tw-mb-1.2">
          {titleContent}
        </p>
        <button
          className="md:tw-btn-primary-xs tw-btn-primary-s tw-w-fit tw-btn-white"
          type="button"
          onClick={onActionShowMore}
        >
          {titleSessions ? t('SEE_ALL_LINK_SESSIONS') : t('SEE_ALL_LINK')}
        </button>
      </div>,
    );
    return contentWithGreeting;
  };
  const parseItems = useCallback(
    ({
      itemsParsedCourse,
      itemsParsedTeams,
      itemsParsedLiveSessions,
    }: {
      itemsParsedCourse?: TCoursesWithoutTrack[];
      itemsParsedTeams?: UserTeamItemParsed[];
      itemsParsedLiveSessions?: TSession[];
    }): TReturnParse[] | undefined => {
      if (itemsParsedTeams) {
        return itemsParsedTeams.slice(0, ITEMS_CUSTOM).map(items => {
          return {
            title: items.team?.name || '',
            courseId: items.originalId?.toString() || '',
            coverImage:
              items?.team?.teamImageV2?.url ||
              items?.team?.teamImageV2?.placeholder ||
              DEFAULT_IMG_CONTENT,
            onClick: () => redirectToTrack(items),
            coins: tracksCoins,
            expiresDate: items.expirationDate || undefined,
          };
        });
      }

      if (itemsParsedCourse) {
        return itemsParsedCourse.slice(0, ITEMS_CUSTOM).map(items => {
          return {
            title: items.title,
            courseId: items.originalId?.toString() || '',
            coverImage: items?.promoImage || items?.imagePlaceholder || '',
            duration: items.duration || 0,
            level: items.level?.name,
            onClick: () => redirectToCourse(items.originalId || 0),
            coins: courseCoins,
          };
        });
      }

      //TODO: Improve Type for LiveSession when Data
      if (itemsParsedLiveSessions) {
        const itemsCustom = itemsParsedLiveSessions;

        return itemsCustom.slice(0, ITEMS_CUSTOM).map(items => {
          const coverImageEmpty =
            items.type === 'virtual'
              ? VIRTUAL_SESSION_PLACEHOLDER_IMAGE
              : LOCATION_SESSION_PLACEHOLDER_IMAGE;

          const startHour = items.startDate.toLocaleTimeString(languageCode, {
            hour12: true,
            hour: 'numeric',
            minute: '2-digit',
          });

          const endHour = items.endDate.toLocaleTimeString(languageCode, {
            hour12: true,
            hour: 'numeric',
            minute: '2-digit',
          });

          const dateSession = formatDate(items.startDate, {
            languageCode,
            outputFormat: 'DD/MM/YYYY',
          });

          return {
            title: items.title,
            courseId: items.id?.toString() || '',
            coverImage: items?.imageUrl || coverImageEmpty,
            duration: 0,
            level: '',
            onClick: () => goToLiveSession(Number(items.id) || 0),
            isLiveSessionCourse: true,
            typeLiveSession: items.type,
            dateSession,
            hourSession: `${startHour} - ${endHour}`,
          };
        });
      }
    },
    [courseCoins, redirectToTrack, tracksCoins],
  );

  return { generateCardButtons, parseItems, redirectToCatalog };
};

export default useCardHandler;
