import { useQuery } from '@apollo/client';

import GET_ORG_LOGO from '../graphql/GetHomeOrganizationInductionLogo.b2b.graphql';
import {
  GetHomeOrganizationInductionLogo,
  GetHomeOrganizationInductionLogoVariables,
} from '../graphql/types/GetHomeOrganizationInductionLogo';

const useGetOrgLogo = ({ slug }: { slug: string }) => {
  const { data } = useQuery<
    GetHomeOrganizationInductionLogo,
    GetHomeOrganizationInductionLogoVariables
  >(GET_ORG_LOGO, {
    context: { clientName: 'b2b' },
    variables: {
      slug,
    },
  });

  const organizationLogo = data?.organization?.logo;

  return { organizationLogo };
};

export default useGetOrgLogo;
