import { useCallback, useEffect } from 'react';

import { PlayroomRewardBanner } from '@crehana/compass.playroom';
import { useStickyState } from '@crehana/react-hooks';
import { createUrlWithSourceQueryParams } from '@crehana/utils';

import { useLearnDashboardContext } from '@/views/LearnDashboard/context';
import { useCrehanaTranslation } from '@/views/LearnDashboard/hooks';

import { TRewardsBanner } from './types';

const RewardsBanner: React.FC<React.PropsWithChildren<TRewardsBanner>> = ({
  isDark,
  isPlayroomEnabled,
  hasCurrency,
  amplitudeSource,
  amplitudeSourceDetail,
}) => {
  const { baseUrlB2B, redirectTo } = useLearnDashboardContext();
  const { t } = useCrehanaTranslation();

  const [bannerStatus, setBannerStatus] = useStickyState<
    'hidden' | 'opened' | 'closed'
  >('hidden', 'PLAYROOM_REWARDS_BANNER');

  const redirectToGamificationAchievements = useCallback(() => {
    let url = `${baseUrlB2B}/playroom/achievements/`;

    if (amplitudeSource) {
      url = createUrlWithSourceQueryParams({
        url,
        source: amplitudeSource,
        sourceDetail: amplitudeSourceDetail,
      });
    }

    redirectTo(url);
  }, [baseUrlB2B, amplitudeSource, amplitudeSourceDetail, redirectTo]);

  const handleBannerClose = useCallback(() => {
    setBannerStatus('closed');
  }, [setBannerStatus]);

  const handleBannerRedirect = useCallback(() => {
    redirectToGamificationAchievements();
    setTimeout(() => {
      setBannerStatus('closed');
    }, 100);
  }, [redirectToGamificationAchievements]);

  useEffect(() => {
    if (isPlayroomEnabled && hasCurrency) {
      if (bannerStatus === 'hidden') {
        setBannerStatus('opened');
      }
    }
  }, [isPlayroomEnabled, hasCurrency, bannerStatus, setBannerStatus]);

  return (
    <PlayroomRewardBanner
      isDark={isDark}
      withRiveCoin
      open={bannerStatus === 'opened'}
      animated
      text={t('REWARD_BANNER_TEXT')}
      button={{
        label: t('REWARD_BANNER_CAT'),
        onClick: handleBannerRedirect,
      }}
      onClose={handleBannerClose}
    />
  );
};

export default RewardsBanner;
