export default {
  en: {
    COMPANY_TITLE: 'Company',
    COMPANY_HELP_CENTER: 'Help Center',
    COMPANY_ABOUT: 'About Crehana',
    COMPANY_SIGN_UP: 'Sign up',
    COMPANY_HIRING: 'We are hiring!',
    CONTACT_PHONE: 'Contact us at <br /> (+51) 963-761-736',
    COMPANY_CREHANA_FOR_BUSINESS: 'Crehana for Business',
    PRODUCT_TITLE: 'Products',
    PRODUCT_DEVELOPMENT: 'Development',
    PRODUCT_WORK_ENVIRONMENT: 'Work Environment',
    PRODUCT_PERFORMANCE: 'Performance',
    RESOURCES_TITLE: 'Resources',
    RESOURCES_BLOG: 'Blog',
    RESOURCES_PODCAST: 'Podcast',
    RESOURCES_DOWNLOABLES: 'Downloads',
    RESOURCES_COURSES: 'Courses',
    RESOURCES_YOUTUBE: 'Youtube',
    COMMUNITY_TITLE: 'Protection Policies',
    COMMUNITY_STORIES: 'Success Stories',
    COMMUNITY_RESOURCES: 'Resources',
    COMMUNITY_BLOG: 'Blog',
    HELP_TITLE: 'Helpful Links',
    HELP_PRIVACY: 'Privacy Policies',
    HELP_COOKIES: 'Cookie Policy',
    HELP_ARCO: 'ARCO Request',
    HELP_CANCELLATION: 'Cancellation Policy',
    HELP_TERMS: 'Terms and Conditions',
    HELP_CLAIMS_BOOK: 'Claims Book',
    RIGHTS_RESERVED: 'All rights reserved'
  }
};