import { MainMenu } from '@crehana/web';

import { TCustomMainMenu } from '../types';

const NewMainMenu = ({
  alternativeMainMenuProps,
}: {
  alternativeMainMenuProps: TCustomMainMenu;
}) => {
  return (
    <nav className="tw-z-dialog tw-border-solid tw-border-b-[1px] tw-border-b-neutral-light-300 dark:tw-border-b-elevation-dark-04 tw-bg-neutral-light-100 dark:tw-bg-elevation-dark-01">
      <MainMenu {...alternativeMainMenuProps} />
    </nav>
  );
};

export default NewMainMenu;
