import { FC } from 'react';
import * as React from 'react';

import { clsx } from 'clsx';

import { useTranslation } from '@crehana/i18n';
import { Dialog, PrimaryButton, TextLink } from '@crehana/ui';
import { useUITheme } from '@crehana/web';

interface SkillXPEvolutionReminderProps {
  open: boolean;
  total: number;
  onRedirect: () => void;
}

const SkillXPEvolutionReminder: FC<
  React.PropsWithChildren<SkillXPEvolutionReminderProps>
> = ({ open, total, onRedirect }) => {
  const { isDark } = useUITheme();
  const [isOpen, setIsOpen] = React.useState<boolean>(open);
  const { t } = useTranslation();

  return (
    <Dialog
      open={isOpen}
      onDismiss={() => {
        setIsOpen(false);
      }}
      datatestid="skill-xp-dialog"
      className="flex flex-col justify-center p-24 sm:p-56 md:p-76 rounded-10"
      ignoreBackdropClick
      ignoreEscape
      size="m"
      isDark={isDark}
    >
      <p className="font-h4 text-center flex justify-center mb-24">
        {t('SKILLXP_EVOLUTION_REMINDER_PENDINGS_TITLE', {
          interpolation: { count: total },
        })}
      </p>
      <p className="mb-24 font-body1 text-center whitespace-pre-line ">
        {t('SKILLXP_EVOLUTION_REMINDER_PENDINGS_SUBTITLE')}
      </p>
      <div className="flex justify-around">
        <TextLink
          className={clsx(isDark ? 'text-white' : 'text-gray-main')}
          label={t('SKILLXP_EVOLUTION_REMINDER_PENDINGS_DISMISS')}
          onClick={() => {
            setIsOpen(false);
          }}
        />
        <PrimaryButton
          isDark={isDark}
          label={t('SKILLXP_EVOLUTION_REMINDER_PENDINGS_CONFIRM')}
          data-testid="take-skill-iq-btn"
          onClick={onRedirect}
        />
      </div>
    </Dialog>
  );
};

export default SkillXPEvolutionReminder;
