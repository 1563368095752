import { FC } from 'react';

import ArrowRight from '@crehana/compass.icons.outline/ArrowRight';
import { LazyImage } from '@crehana/compass.ui';

import { useCrehanaTranslation } from '@/views/LearnDashboard/hooks';

import type { TProgressState } from '..';
import { redirectToEditStudyPlan } from '../../../utils/redirectToEditStudyPlan';
import Slider from '../../Slider';
import Header from './Header';
import ProgressState from './ProgressState';

const EmptyWithoutCourseMobile: FC<Omit<TProgressState, 'state'>> = ({
  progress,
  minProgress,
  minTarget,
}) => {
  const { t } = useCrehanaTranslation();
  return (
    <Slider groupSize={1} className="tw-gap-1.6" isPagination={false}>
      <div className="dark:tw-bg-neutral-dark-200 tw-bg-neutral-light-300 tw-left-0 tw-w-[24rem] md:tw-h-[44.4rem] tw-h-[35.2rem] tw-p-2.4 tw-rounded-2">
        <Header className="tw-text-neutral-light-800" />
        <ProgressState
          state={'empty'}
          progress={progress}
          minProgress={minProgress}
          minTarget={minTarget}
        />
        <button
          onClick={redirectToEditStudyPlan}
          type="button"
          className="tw-text-link-m dark:tw-text-primary-light-500"
        >
          {t('START_LEARNING_EDIT')}
          <ArrowRight size={20} className="tw-text-current" />
        </button>
      </div>
      <div className="dark:tw-bg-elevation-dark-04 tw-bg-neutral-light-100 tw-flex tw-flex-col tw-justify-between tw-left-0 tw-w-[24rem] md:tw-h-[44.4rem] tw-h-[35.2rem] tw-p-2.4 tw-rounded-2">
        <div className="tw-w-full tw-h-12 tw-mb-1.6">
          <LazyImage
            imgClassName="object-cover"
            className="tw-w-full tw-h-full"
            src="https://crehana-public-catalog.imgix.net/images/app/gallery/2023/12/04/3dd58f07/27c7976e.png"
            alt="CONTENT_EMPTY"
          />
        </div>
        <p className="dark:tw-text-neutral-light-100 tw-font-h3 tw-mb-0.4 tw-mt-1.6 tw-text-neutral-light-800">
          {t('START_LEARNING_EMPTY_ASSIGNED')}
        </p>
        <p className="tw-font-body4 tw-text-neutral-light-800 dark:tw-text-neutral-light-100">
          {t('START_LEARNING_EMPTY_MESSAGE')}
        </p>
      </div>
    </Slider>
  );
};

export default EmptyWithoutCourseMobile;
