import { useQuery } from '@apollo/client';

import GET_COURSE_ENROLLMENT_PROGRESS from '../graphql/GetCourseEnrollmentProgressQuery.graphql';
import type {
  GetCourseEnrollmentProgressQuery,
  GetCourseEnrollmentProgressQueryVariables,
} from '../graphql/types/GetCourseEnrollmentProgressQuery';
import type { TDataSlice } from '../types';

const useGetCourseEnrollment = () => {
  const { data, loading, refetch } = useQuery<
    GetCourseEnrollmentProgressQuery,
    GetCourseEnrollmentProgressQueryVariables
  >(GET_COURSE_ENROLLMENT_PROGRESS, {
    variables: {
      first: 1,
      lastActions: true,
      isHidden: false,
      isActive: true,
      assignedCourses: null,
      progressState: 'progress',
    },
  });

  if (data?.me?.enrollmentSet?.totalCount === 0) {
    refetch({ progressState: 'incomplete' });
  }

  const courseSlide: TDataSlice[] | undefined =
    data?.me?.enrollmentSet?.edges.map(elem => {
      return {
        imagePlaceholder: elem?.node?.course?.imagePlaceholder,
        isEnrollment: true,
        promoImage: elem?.node?.course?.promoImage,
        lastVideoLectureUrl: elem?.node?.course?.lastVideoLectureUrl,
        title: elem?.node?.course?.title || '',
        duration: elem?.node?.course?.duration,
        progress: elem?.node?.course?.progress,
        teacher: elem?.node?.course?.professor?.fullName,
        dashboardUrl: elem?.node?.course?.dashboardUrl,
        url: elem?.node?.course?.url,
      } satisfies TDataSlice;
    });

  return { courseSlide, loadingCourseEnrollmentProgress: loading };
};

export default useGetCourseEnrollment;
