import { useMediaQuery } from '@crehana/react-hooks';
import { Container } from '@crehana/ui';

import { type THeaderProps } from '../../types';
import DetailDesktop from './DetailDesktop';
import DetailMobile from './DetailMobile';

const DetailLearnTrackHeader: React.FC<THeaderProps> = ({
  detailHeaderTrack,
}) => {
  const { matches: isSm } = useMediaQuery('sm');

  return (
    <Container type="dashboard" data-testid="detail-track">
      {!isSm ? (
        <DetailMobile {...detailHeaderTrack} />
      ) : (
        <DetailDesktop {...detailHeaderTrack} />
      )}
    </Container>
  );
};

export default DetailLearnTrackHeader;
