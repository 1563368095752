import { FC } from 'react';

import ArrowIosLeft from '@crehana/compass.icons.outline/ArrowIosLeft';

import { useCrehanaTranslation } from '@/views/LearnDashboard/hooks/useCrehanaTranslation';

import type {
  BreadcrumbItem,
  BreadcrumbProps,
  TBreadcrumbPropsMobile,
} from './type';

const BreadcrumbMobile: FC<
  TBreadcrumbPropsMobile & Pick<BreadcrumbProps, 'items'>
> = ({ goToPath, homeUrl, itemClass, items }) => {
  const { t } = useCrehanaTranslation();
  const selectItem: BreadcrumbItem | undefined =
    items && items[items.length - 1];

  return (
    <li className="breadcrumb-item tw-font-body5" key="home">
      <a
        className={itemClass}
        href={homeUrl}
        onClick={ev => goToPath(ev, selectItem?.href || homeUrl)}
      >
        <div className="tw-w-2 tw-h-2 tw-mr-0.4">
          <ArrowIosLeft size={20} className="tw-fill-current " />
        </div>
        <span>{selectItem?.label ?? t('LABEL_SIDEBAR_ITEM_START')}</span>
      </a>
    </li>
  );
};

export default BreadcrumbMobile;
