import { differenceBetweenDates, toDate } from "@crehana/date";
import { LANGUAGES } from "@crehana/ts-types";
import { customLocalStorage } from "@crehana/utils";

/**
 * "If the user has already seen the NPS survey in the last 24 hours, don't show it again."
 *
 * The function is a bit more complicated than that, but that's the gist of it
 * @returns A boolean
 */
var canVisibleNps = function canVisibleNps(userName) {
  var localDateStorage = customLocalStorage.getItem('CAN_VISIBLE_NPS');
  if (localDateStorage) {
    var parseDateuserNps = JSON.parse(localDateStorage);
    if (parseDateuserNps.user === userName) {
      var now = new Date();
      var dateOld = toDate(parseDateuserNps.date, {
        languageCode: LANGUAGES.ES
      });
      var _differenceBetweenDat = differenceBetweenDates(dateOld, now, {
          languageCode: LANGUAGES.ES
        }),
        hours = _differenceBetweenDat.hours;
      if (hours > 24) customLocalStorage.removeItem('CAN_VISIBLE_NPS');
      return !(hours < 24);
    }
    return true;
  }
  customLocalStorage.setItem('CAN_VISIBLE_NPS', JSON.stringify({
    date: new Date().toISOString(),
    user: userName
  }));
  return true;
};
export default canVisibleNps;