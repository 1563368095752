import { DetailTrackType, TStateTrack } from '../types';

const useToggleType = (
  detailTrack: DetailTrackType | null,
): TStateTrack | undefined => {
  if (detailTrack?.trackDisabled) {
    return 'disabled';
  }
  if (!detailTrack?.trackDisabled && !detailTrack?.isCompletedTrack) {
    return 'active';
  }
  return 'success';
};

export default useToggleType;
