import { useMediaQuery } from '@crehana/react-hooks';
import { useUITheme } from '@crehana/web';
import DynamicSkeleton from '@crehana/web/components/LearnAcademyPanel/skeleton/DynamicSkeleton';

const AcademiesSkeleton = () => {
  const { isDark } = useUITheme();
  const { matches: isMd } = useMediaQuery('md');

  return (
    // @ts-expect-error Property containerFullWidth is missing in type
    <DynamicSkeleton
      isDark={isDark}
      isMobile={!isMd}
      containerType="dashboard"
    />
  );
};

export default AcademiesSkeleton;
