import cn from 'clsx';

import useTeamCoursesTranslation from '../../../hooks/useTeamCoursesTranslation/useTeamCoursesTranslation';
import { SkillPathCoursesPanelPropsNew } from '../../../types';

const SkillPathCoursesPanelNew: React.FC<
  React.PropsWithChildren<SkillPathCoursesPanelPropsNew>
> = ({ courseCount, className, children }) => {
  const { t } = useTeamCoursesTranslation();
  return (
    <section
      className={cn(
        'tw-w-full tw-rounded-1 dark:tw-bg-elevation-dark-04 tw-bg-neutral-light-100',
        className,
      )}
    >
      <section className="tw-border-b tw-border-neutral-light-300 dark:tw-border-neutral-dark-600 tw-border-solid tw-mx-1.6 tw-pb-1.6 tw-pt-2.4 tw-px-0.8">
        <div className="tw-flex tw-flex-wrap tw-items-center">
          <span className="tw-bg-primary-light-100 dark:tw-bg-primary-dark-100 tw-font-link1 tw-mr-0.8 tw-px-0.4 tw-py-0.2 tw-rounded-0.4 tw-text-primary-light-600">
            {courseCount}
          </span>
          <p className="tw-font-subtitle5 tw-text-neutral-light-800 dark:tw-text-neutral-light-100">
            {t('LEARNING_TEAM_TRACK_CONTENT_TRACK')}
          </p>
        </div>
      </section>

      <section className="md:tw-px-2 tw-pt-2.4 md:tw-pt-2 tw-pb-1.2 tw-p-1.2 sm:tw-p-1.6">
        {children}
      </section>
    </section>
  );
};

export default SkillPathCoursesPanelNew;
