import { FC } from 'react';
import * as React from 'react';

import LevelCompleteVertical from '@crehana/icons/LevelCompleteVertical';
import PlayCircle from '@crehana/icons/outline/PlayCircle';
import { Clamp, colors, LazyImage } from '@crehana/ui';

import type { GetHomeOrganizationInductionClassrooms_courses as ClassroomType } from '../../../../graphql/types/GetHomeOrganizationInductionClassrooms';

interface ClassroomCourseCardProps {
  course: ClassroomType;
  isDark?: boolean;
}

const ClassroomCourseCard: FC<
  React.PropsWithChildren<ClassroomCourseCardProps>
> = ({ course, isDark }) => {
  const { primaryMain, secondaryMain, white } = colors;

  return (
    <button
      type="button"
      className={`flex w-full items-center p-8 ${
        isDark ? 'bg-base-light-dark-mode' : 'bg-gray-lighter'
      }  rounded-5`}
      onClick={() => {
        window.location.href = `/clases/v2/${course.originalId}/detalle/`;
      }}
    >
      <div className="relative" style={{ minHeight: 85, minWidth: 85 }}>
        {course.promoImage ? (
          <LazyImage
            alt={`${course.title}-image-thumbnail`}
            src={course.promoImage.url || ''}
            className="rounded-5 w-full h-full"
            imgClassName="object-cover"
            isDark={isDark}
          />
        ) : (
          <div
            className={`${
              isDark ? 'bg-base-main' : 'bg-gray-dark'
            } h-full w-full`}
          />
        )}
        <div className="absolute" style={{ top: 20, left: 20 }}>
          <PlayCircle size={40} color={white} />
        </div>
      </div>
      <div
        className="px-8 h-full flex flex-col justify-around items-start"
        style={{ height: 85 }}
      >
        <Clamp clamp={2} className="font-subtitle4 text-left">
          {course.title}
        </Clamp>
        <div className="flex items-center">
          <LevelCompleteVertical
            color={isDark ? secondaryMain : primaryMain}
            size={20}
          />
          <p className="font-subtitle4 text-base-lighter ml-12">
            {course.level?.name}
          </p>
        </div>
      </div>
    </button>
  );
};

export default ClassroomCourseCard;
