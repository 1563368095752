import { useEffect } from 'react';

import { getSourceEventParams, useAmplitude } from '@crehana/analytics';

import { LEARN_DASHBOARD_PAGE_NAME } from '../constants';

/**
 * The section being rendered.
 *
 * Add any other section as a union:
 * 'section1' | 'section2'
 *
 * useTrackRender('section2');
 */

function useTrackRender(section: string) {
  const { logEvent } = useAmplitude();

  useEffect(() => {
    if (section) {
      logEvent({
        event: 'Render Courses Panel',
        data: {
          ...getSourceEventParams(),
          'Page Name': LEARN_DASHBOARD_PAGE_NAME,
          'Course Section': section,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [section]);
}

export default useTrackRender;
