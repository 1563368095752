import { useEffect, useState } from 'react';

import { differenceInMinutes } from 'date-fns';

import { TSession } from '@crehana/compass.live-sessions';

type TUseNextLiveSessionProps = {
  sessions: TSession[];
  loading: boolean;
};

export const useNextLiveSession = ({
  sessions,
  loading,
}: TUseNextLiveSessionProps) => {
  const [nextLiveSession, setNextLiveSession] = useState<TSession>();

  useEffect(() => {
    if (!sessions || !sessions[0]) return;

    const firstSession = sessions[0];
    const minutesToSession = differenceInMinutes(
      firstSession.startDate,
      new Date(),
    );

    // The session is live
    if (minutesToSession < 0 && firstSession.endDate > new Date())
      return setNextLiveSession(firstSession);

    // The session will start in less than 30 minutes
    if (minutesToSession <= 30) return setNextLiveSession(firstSession);
  }, [loading]);

  return nextLiveSession;
};
