import { Subject } from 'rxjs';

import type { TLearnDashboardContextPlayroomReactiveEvents as TPlayroomReactiveEvents } from '../types';

/**
 * @description This Typescript function, named createSubjectForRedeemCatalog,
 * exports an object containing two properties: emitRedeemCatalogEvent and subscribeRedeemCatalogEvent.
 *
 * The function creates a new instance of the RxJS Subject class, and assigns it to a variable
 * named _redeemCatalogSubject.
 *
 * "emitRedeemCatalogEvent" is a function that, when called, will call the "next()" method on
 * the _redeemCatalogSubject instance, effectively emitting a new value from the subject.
 *
 * "subscribeRedeemCatalogEvent" is a function that takes in a callback function as an argument.
 * When called, it calls the "subscribe()" method on the "_redeemCatalogSubject" instance and passes
 * the callback function as an argument, effectively subscribing to the subject.
 *
 * Overall, this function is used to create a subject that can be used to emit and subscribe to
 * events related to redeeming a catalog.
 */
export const createSubjectForRedeemCatalog = (): TPlayroomReactiveEvents => {
  const _redeemCatalogSubject = new Subject<void>();

  const emitRedeemCatalogEvent: TPlayroomReactiveEvents['emitRedeemCatalogEvent'] =
    () => _redeemCatalogSubject.next();

  const subscribeRedeemCatalogEvent: TPlayroomReactiveEvents['subscribeRedeemCatalogEvent'] =
    callback => _redeemCatalogSubject.subscribe(callback);

  return {
    emitRedeemCatalogEvent,
    subscribeRedeemCatalogEvent,
  };
};
