import AlertCircle from '@crehana/icons/outline/AlertCircle';
import CheckmarkCircle from '@crehana/icons/outline/CheckmarkCircle';
import CloseSmall from '@crehana/icons/outline/CloseSmall';

import type { StatusType } from './types';

export const STATUS: StatusType = {
  PENDING: {
    label: 'TASK_ITEM_PENDING',
    type: 'orange',
    leftIcon: AlertCircle,
  },
  COMPLETED: {
    label: 'TASK_ITEM_COMPLETED',
    type: 'green',
    leftIcon: CheckmarkCircle,
  },
  INACTIVE: {
    label: 'TASK_ITEM_INACTIVE',
    type: 'red',
    leftIcon: AlertCircle,
  },
  ACTIVE: {
    label: 'TASK_ITEM_ACTIVE',
    type: 'orange',
    leftIcon: AlertCircle,
  },
  DELETED: {
    label: 'TASK_ITEM_DELETED',
    type: 'red',
    leftIcon: CloseSmall,
  },
  DELAYED: {
    label: 'TASK_ITEM_DELAYED',
    type: 'red',
    leftIcon: AlertCircle,
  },
};
