import { memo } from 'react';

import cn from 'clsx';

import { parseClassnames } from '@crehana/ui';

interface DividerProps {
  isDark?: boolean;
  /**
   * @default 'vertical'
   */
  orientation?: 'horizontal' | 'vertical';
  className?: string;
}

const Divider: React.FC<DividerProps> = ({
  orientation = 'vertical',
  className,
}) => {
  return orientation === 'horizontal' ? (
    <div
      className={cn(
        'tw-border tw-border-solid tw-border-b tw-w-full tw-h-[1px] tw-border-neutral-light-700',
        className,
      )}
      role="separator"
      aria-orientation="horizontal"
    />
  ) : (
    <span
      className={cn(
        parseClassnames(
          className || '',
          'tw-h-1.2 tw-mx-0.8 tw-border-r tw-border-solid tw-border-neutral-light-700',
        ),
        {
          'tw-inline-block': className?.includes('hidden') === false,
        },
      )}
      role="separator"
      aria-orientation="vertical"
    />
  );
};

export default memo(Divider);
