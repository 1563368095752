import { PAYGNotificationKey, PremiumNotificationKey } from './types';

/* IMPORTANT: Priority is defined on the order of this elements,
  do not rearrange them without a reason */
export const PREMIUM_NOTIFICATIONS: PremiumNotificationKey[] = [
  // NPS active survey
  'nps-reminder',
];

/* IMPORTANT: Priority is defined on the order of this elements,
  do not rearrange them without a reason */
export const PAYG_NOTIFICATIONS: PAYGNotificationKey[] = [
  // NPS active survey
  'nps-reminder',
];
