import { Children, useState } from 'react';

import ArrowIosLeft from '@crehana/compass.icons.outline/ArrowIosLeft';
import ArrowIosRight from '@crehana/compass.icons.outline/ArrowIosRight';
import { cn } from '@crehana/compass.ui/v2';

import type { GridColsVariant, TSlider } from './type';

/* The `templateGrid` variable is an object that maps the number of columns in a
 grid layout to a corresponding CSS class. It is used in the `className` prop of
 the `Slider` component to dynamically set the number of columns in the grid
 based on the `groupSize` prop. */
const templateGrid: Readonly<Record<GridColsVariant, string>> = {
  0: 'md:tw-grid-cols-none',
  1: 'md:tw-grid-cols-1',
  2: 'md:tw-grid-cols-2',
  3: 'md:tw-grid-cols-3',
  4: 'md:tw-grid-cols-4',
};

const Slider: React.FC<React.PropsWithChildren<TSlider>> = ({
  children,
  className,
  groupSize = 1,
  isPagination = true,
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const slides = Children.toArray(children) as React.ReactElement[];

  const groupedSlides: React.ReactElement[][] = Array.from(
    { length: Math.ceil(slides.length / groupSize) },
    (_, index) => slides.slice(index * groupSize, (index + 1) * groupSize),
  );

  const isVisibleActions: boolean = groupedSlides.length > 1;

  const prevSlide = () => {
    setCurrentStep(
      currentStep === 0 ? groupedSlides.length - 1 : currentStep - 1,
    );
  };

  const nextSlide = () => {
    setCurrentStep(
      currentStep < groupedSlides.length - 1 ? currentStep + 1 : 0,
    );
  };

  if (slides.length === 0) return null;

  const visibleGroupSlide = () => {
    const currentGroup = groupedSlides[currentStep] || groupedSlides[0];
    return currentGroup.map((slideGroup, groupIndex) => (
      <article
        key={`slideGroup_${groupIndex * 1}`}
        className="tw-w-full tw-h-full tw-duration-500 after:tw-z-0 tw-z-0"
      >
        {slideGroup}
      </article>
    ));
  };

  return (
    <>
      <div
        className={cn(
          'tw-overflow-auto [&::-webkit-scrollbar]:tw-hidden md:tw-hidden tw-w-full',
          className,
        )}
      >
        <div className="tw-inline-flex tw-gap-1.6 tw-mr-3.2 md:tw-mr-auto tw-overflow-x-auto [&::-webkit-scrollbar]:tw-hidden">
          {children}
        </div>
      </div>
      <div
        className={cn(
          `tw-hidden md:tw-grid md:tw-h-full tw-h-[44rem] tw-m-auto tw-w-full tw-relative`,
          templateGrid[groupSize],
          className,
        )}
      >
        {visibleGroupSlide()}

        <div
          className={cn(
            'tw-bg-primary-light-300 tw-z-7',
            isVisibleActions ? 'tw-contents' : 'tw-hidden',
          )}
        >
          <button
            className={cn(
              'tw-flex tw-items-center tw-justify-center tw-w-4 tw-h-4 tw-absolute tw-top-[50%] -tw-translate-x-0 tw-translate-y-[-50%] tw-left-1.2 tw-font-body5 tw-rounded-2 tw-bg-neutral-light-100 tw-text-neutral-light-800 tw-cursor-pointer',
              { 'tw-hidden': currentStep === 0 },
            )}
            onClick={prevSlide}
            type="button"
          >
            <ArrowIosLeft size={24} className="tw-fill-current" />
          </button>
          <button
            className={cn(
              'tw-flex tw-items-center tw-justify-center tw-w-4 tw-h-4 tw-absolute tw-top-[50%] -tw-translate-x-0 tw-translate-y-[-50%] tw-right-1.2 tw-font-body5 tw-rounded-2 tw-bg-neutral-light-100 tw-text-neutral-light-800 tw-cursor-pointer',
              { 'tw-hidden': currentStep === groupedSlides.length - 1 },
            )}
            onClick={nextSlide}
            type="button"
          >
            <ArrowIosRight size={24} className="tw-fill-current" />
          </button>
        </div>

        {isPagination && (
          <div
            className={cn(
              'tw-absolute tw-bottom-2.4 tw-justify-center tw-py-0.2 tw-right-[50%] tw-left-[50%] ',
              isVisibleActions ? 'tw-flex' : 'tw-hidden',
            )}
          >
            {groupedSlides.map((_, index) => (
              <button
                key={`sliderDot_${index * 1}`}
                className="tw-w-0.8 tw-h-0.8 tw-mr-2"
                onClick={() => setCurrentStep(index)}
                type="button"
              >
                <span
                  className={cn(
                    'tw-block tw-w-0.8 tw-h-0.8 tw-border tw-border-solid  tw-rounded-full',
                    index === currentStep
                      ? 'tw-bg-secondary-light-500 tw-border-none'
                      : 'tw-border-neutral-dark-500',
                  )}
                />
              </button>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default Slider;
