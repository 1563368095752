import { FC } from 'react';

import ArrowRight from '@crehana/compass.icons.outline/ArrowRight';

import useTeamCoursesTranslation from '../../hooks/useTeamCoursesTranslation/useTeamCoursesTranslation';
import { TCardProject } from '../../types';

const ContinueButton: FC<
  Pick<TCardProject, 'handleClick'> & { className: string }
> = ({ handleClick, className }) => {
  const { t } = useTeamCoursesTranslation();
  return (
    <button type="button" className={className} onClick={handleClick}>
      {t('LEARN_TEAM_TRACK_CONTINUE_PROJECT')}{' '}
      <ArrowRight size={20} className="tw-text-current tw-ml-0.4" />
    </button>
  );
};

export default ContinueButton;
