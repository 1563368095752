export default {
  es: {
    COMPANY_TITLE: 'Compañía',
    COMPANY_HELP_CENTER: 'Centro de ayuda',
    COMPANY_ABOUT: 'Sobre Crehana',
    COMPANY_SIGN_UP: 'Sign up',
    COMPANY_HIRING: '¡Estamos contratando!',
    CONTACT_PHONE: 'Contáctanos al <br /> (+51) 963-761-736',
    COMPANY_CREHANA_FOR_BUSINESS: 'Crehana para empresas',
    PRODUCT_TITLE: 'Productos',
    PRODUCT_DEVELOPMENT: 'Desarrollo',
    PRODUCT_WORK_ENVIRONMENT: 'Clima laboral',
    PRODUCT_PERFORMANCE: 'Desempeño',
    RESOURCES_TITLE: 'Recursos',
    RESOURCES_BLOG: 'Blog',
    RESOURCES_PODCAST: 'Podcast',
    RESOURCES_DOWNLOABLES: 'Descargables',
    RESOURCES_COURSES: 'Cursos',
    RESOURCES_YOUTUBE: 'Youtube',
    COMMUNITY_TITLE: 'Políticas de protección',
    COMMUNITY_STORIES: 'Historias de éxito',
    COMMUNITY_RESOURCES: 'Recursos',
    COMMUNITY_BLOG: 'Blog',
    HELP_TITLE: 'Enlaces de ayuda',
    HELP_PRIVACY: 'Políticas de privacidad',
    HELP_COOKIES: 'Política de cookies',
    HELP_ARCO: 'Solicitud ARCO',
    HELP_CANCELLATION: 'Política de cancelación',
    HELP_TERMS: 'Términos y condiciones',
    HELP_CLAIMS_BOOK: 'Libro de reclamaciones',
    RIGHTS_RESERVED: 'Todos los derechos reservados'
  }
};