import Calendar5Filled from '@crehana/compass.icons.fill/Calendar';
import Archive from '@crehana/compass.icons.outline/Archive';
import BookOpen from '@crehana/compass.icons.outline/BookOpen';
import Calendar5 from '@crehana/compass.icons.outline/Calendar';
import Diploma from '@crehana/compass.icons.outline/Diploma';
import ExternalLink from '@crehana/compass.icons.outline/ExternalLink';
import Globe2 from '@crehana/compass.icons.outline/Globe2';
import Home from '@crehana/compass.icons.outline/Home';
import Mentorship from '@crehana/compass.icons.outline/Mentorship';
import PencilOutline from '@crehana/compass.icons.outline/Pencil';
import Percent from '@crehana/compass.icons.outline/Percent';
import SkillXp from '@crehana/compass.icons.outline/SkillXp';
import Stats from '@crehana/compass.icons.outline/Stats';
import { TExtraDataRoutes } from '@crehana/compass.layouts';
import { useTranslation } from '@crehana/i18n';

import { HelperTooltip } from '../components';
import AcademiesSection from '../layouts/b2b/AcademiesSection';
import ContentForYou from '../layouts/b2b/ContentForYou';
import LazyLiveSessionsSection from '../layouts/b2b/LiveSessionSection/LazyLiveSessionsSection';
import PlayroomAchievementSection from '../layouts/b2b/PlayroomAchievementSection';
import PlayroomRewardsView from '../layouts/b2b/PlayroomRewardsView';
import SkillXP from '../layouts/b2b/SkillXP';
import { PendingSkills, SolvedSkills } from '../layouts/b2b/SkillXP-Evolution';
import StartSection from '../layouts/b2b/StartSection';
import ClassNotesSection from '../layouts/shared/ClassNotesSection';
import CoursesSection from '../layouts/shared/CoursesSection';
import DiplomasSection from '../layouts/shared/DiplomasSection';
import LazyKnowledgeHubHomeSection from '../layouts/shared/KnowledgeHubHomeSection';
import MentorsSection from '../layouts/shared/MentorsSection';
import ProjectsSection from '../layouts/shared/ProjectsSection';
import StudyPlanSection from '../layouts/shared/StudyPlanSection';

interface IUseRoutesExtraDataArgs {
  isSidebarV2Enabled: boolean;
}

interface IUseRoutesExtraDataReturnType {
  routesExtraData: TExtraDataRoutes;
}

const useRoutesExtraData = ({
  isSidebarV2Enabled,
}: IUseRoutesExtraDataArgs): IUseRoutesExtraDataReturnType => {
  const { t } = useTranslation();
  const shouldRenderAdditionalData = !isSidebarV2Enabled;

  const routesExtraData: TExtraDataRoutes = {
    LEARN_HOME_START: {
      key: 'LEARN_HOME_START',
      exact: true,
      component: StartSection,
      tourSelector: 'home-start',
      ...(shouldRenderAdditionalData
        ? {
            icon: isSidebarV2Enabled ? Home : Stats,
            activeIcon: isSidebarV2Enabled ? Home : Stats,
          }
        : {}),
    },
    LEARN_KNOWLEDGE_HUB: {
      key: 'LEARN_KNOWLEDGE_HUB',
      exact: false,
      component: LazyKnowledgeHubHomeSection,
      tourSelector: 'knowledge-hub-start',
      ...(shouldRenderAdditionalData
        ? {
            icon: Globe2,
            activeIcon: Globe2,
          }
        : {}),
    },
    LEARN_FOR_YOU: {
      key: 'LEARN_FOR_YOU',
      exact: true,
      component: ContentForYou,
      tourSelector: 'content-for-you',
      ...(shouldRenderAdditionalData
        ? {
            tooltip: isSidebarV2Enabled ? (
              <HelperTooltip
                title="Más contenido"
                description="Descubre los nuevos contenidos de aprendizaje"
              />
            ) : (
              t('LABEL_SIDEBAR_ITEM_CONTENT_FOR_YOU')
            ),

            icon: BookOpen,
            activeIcon: BookOpen,
          }
        : {}),
    },
    LEARN_ENROLLED_COURSES: {
      key: 'LEARN_ENROLLED_COURSES',
      exact: false,
      component: CoursesSection,
      tourSelector: 'enrolled-courses-tab',
      ...(shouldRenderAdditionalData
        ? {
            activeIcon: PencilOutline,
            tourSelector: 'enrolled-courses-tab',
            contentTitle: t('LABEL_SIDEBAR_ITEM_MY_COURSES_ENROLLED'),
          }
        : {}),
    },
    LEARN_TRACKS: {
      key: 'LEARN_TRACKS',
      exact: true,
      component: AcademiesSection,
      tourSelector: 'tracks-tab',
      ...(shouldRenderAdditionalData
        ? {
            contentTitle: t('LABEL_SIDEBAR_ITEM_MY_COURSES_TRACKS'),
            icon: PencilOutline,
            activeIcon: PencilOutline,
          }
        : {}),
    },
    LEARN_LIVE_SESSIONS: {
      key: 'LEARN_LIVE_SESSIONS',
      exact: false,
      component: LazyLiveSessionsSection,
      tourSelector: 'live-sessions-tab',
      ...(shouldRenderAdditionalData
        ? {
            contentTitle: t('LABEL_SIDEBAR_ITEM_MY_COURSES_LIVE_SESSIONS'),
            icon: PencilOutline,
            activeIcon: PencilOutline,
          }
        : {}),
    },

    LEARN_NOTES: {
      key: 'LEARN_NOTES',
      exact: false,
      component: ClassNotesSection,
      tourSelector: 'classnotes-tab',
      ...(shouldRenderAdditionalData
        ? {
            contentTitle: t('LABEL_SIDEBAR_ITEM_MY_COURSES_NOTES'),
            icon: Archive,
            activeIcon: Archive,
          }
        : {}),
    },
    LEARN_STUDY_PLAN: {
      key: 'LEARN_STUDY_PLAN',
      tourSelector: 'study-plan-tab',
      exact: true,
      component: StudyPlanSection,
      ...(shouldRenderAdditionalData
        ? {
            icon: Calendar5,
            activeIcon: Calendar5Filled,
            tooltip: isSidebarV2Enabled ? (
              <HelperTooltip
                title="Plan de estudios"
                description="Planifica tu meta de aprendizaje"
              />
            ) : (
              t('LABEL_SIDEBAR_ITEM_MY_STUDY_PLAN')
            ),
            contentTitle: t('LABEL_SIDEBAR_ITEM_MY_COURSES_LIVES'),
          }
        : {}),
    },
    LEARN_MENTORS: {
      key: 'LEARN_MENTORS',
      tourSelector: 'mentors-tab',
      exact: true,
      component: MentorsSection,
      ...(shouldRenderAdditionalData
        ? {
            icon: Mentorship,
            activeIcon: Mentorship,
            contentTitle: t('LABEL_SIDEBAR_ITEM_MENTORS'),
            tooltip: isSidebarV2Enabled ? (
              <HelperTooltip
                title="Mentorías"
                description="Solicita asesorías con especialistas"
              />
            ) : (
              t('LABEL_SIDEBAR_ITEM_MENTORS')
            ),
          }
        : {}),
    },
    LEARN_RESOURCES_BLOG: {
      key: 'LEARN_RESOURCES_BLOG',
      component: () => null,
      exact: true,
      rightIcon: ExternalLink,
      tourSelector: 'resources-blog-tab',
      ...(shouldRenderAdditionalData
        ? {
            icon: undefined,
            activeIcon: undefined,
            openInNewTab: true,
          }
        : {}),
    },
    LEARN_RESOURCES_LIVES: {
      key: 'LEARN_RESOURCES_LIVES',
      component: () => null,
      exact: true,
      rightIcon: ExternalLink,
      tourSelector: 'resources-blog-tab',
      ...(shouldRenderAdditionalData
        ? {
            icon: undefined,
            activeIcon: undefined,
            openInNewTab: true,
          }
        : {}),
    },
    LEARN_RESOURCES_TOOLS: {
      key: 'LEARN_RESOURCES_TOOLS',
      rightIcon: ExternalLink,
      openInNewTab: true,
      component: () => null,
      tourSelector: 'resources-tools-tab',
      ...(shouldRenderAdditionalData
        ? {
            icon: undefined,
            activeIcon: undefined,
            openInNewTab: true,
          }
        : {}),
    },
    LEARN_SKILLXP: {
      key: 'LEARN_SKILLXP',
      tourSelector: 'skillXp-tab',
      exact: true,
      component: SkillXP,
      ...(shouldRenderAdditionalData
        ? {
            icon: Percent,
            activeIcon: Percent,
            contentTitle: t('LABEL_SIDEBAR_ITEM_SKILL_XP'),
            decorations: {
              subTitle: t('LABEL_SIDEBAR_ITEM_SKILL_XP_SEPARATOR'),
            },
          }
        : {}),
    },
    LEARN_PROJECTS: {
      key: 'LEARN_PROJECTS',
      tourSelector: 'projects-tab',
      exact: true,
      component: ProjectsSection,
      ...(shouldRenderAdditionalData
        ? {
            tooltip: isSidebarV2Enabled ? (
              <HelperTooltip
                title="Proyectos"
                description="Lleva a cabo los proyectos de tus cursos"
              />
            ) : (
              t('LABEL_SIDEBAR_ITEM_MY_PROJECTS')
            ),
            contentTitle: t('LABEL_SIDEBAR_ITEM_MY_PROJECTS'),
            icon: Archive,
            activeIcon: Archive,
          }
        : {}),
    },
    LEARN_CERTIFICATES: {
      key: 'LEARN_CERTIFICATES',
      tourSelector: 'diplomas-tab',
      exact: true,
      component: DiplomasSection,
      ...(shouldRenderAdditionalData
        ? {
            icon: Diploma,
            activeIcon: Diploma,
            tooltip: isSidebarV2Enabled ? (
              <HelperTooltip
                title="Diplomas"
                description="Consulta los diplomas obtenidos por el cumplimiento de tu aprendizaje"
              />
            ) : (
              t('LABEL_SIDEBAR_ITEM_MY_COURSES_CERTIFICATIONS')
            ),
            contentTitle: t('LABEL_SIDEBAR_ITEM_MY_COURSES_CERTIFICATIONS'),
          }
        : {}),
    },
    LEARN_SKILL_XP_EVOLUTION_PENDING: {
      key: 'LEARN_SKILL_XP_EVOLUTION_PENDING',
      exact: false,
      component: PendingSkills,
      ...(shouldRenderAdditionalData
        ? {
            icon: SkillXp,
            activeIcon: SkillXp,
          }
        : {}),
    },
    LEARN_SKILL_XP_EVOLUTION_SOLVED: {
      key: 'LEARN_SKILL_XP_EVOLUTION_SOLVED',
      exact: false,
      component: SolvedSkills,
      ...(shouldRenderAdditionalData
        ? { icon: SkillXp, activeIcon: SkillXp }
        : {}),
    },
    LEARN_DASHBOARD_PLAYROOM_REWARDS: {
      key: 'LEARN_DASHBOARD_PLAYROOM_REWARDS',
      tourSelector: 'playroom-achievements-tab',
      exact: false,
      component: PlayroomRewardsView,
      ...(shouldRenderAdditionalData
        ? {
            icon: Percent,
            activeIcon: Percent,
          }
        : {}),
    },
    LEARN_DASHBOARD_PLAYROOM_ACHIEVEMENTS: {
      key: 'LEARN_DASHBOARD_PLAYROOM_ACHIEVEMENTS',
      tourSelector: 'playroom-achievements-tab',
      exact: false,
      component: PlayroomAchievementSection,
      ...(shouldRenderAdditionalData
        ? {
            icon: Percent,
            activeIcon: Percent,
          }
        : {}),
    },
  };

  return {
    routesExtraData,
  };
};

export default useRoutesExtraData;
