import { FC, PropsWithChildren, ReactNode } from 'react';

import { clsx } from 'clsx';

import CloseSmall from '@crehana/compass.icons.outline/CloseSmall';
import { DialogRequest, Modal } from '@crehana/compass.ui';

import useTeamCoursesTranslation from '../../../hooks/useTeamCoursesTranslation';

type TCardModal = {
  onDismiss: () => void;
  isOpen?: boolean;
  title?: string;
  contentAction?: ReactNode;
};

const LayoutCardModal: FC<PropsWithChildren<TCardModal>> = ({
  onDismiss,
  isOpen,
  title,
  contentAction,
  children,
}) => {
  const { t } = useTeamCoursesTranslation();
  return (
    <Modal
      onDismiss={onDismiss}
      open={isOpen}
      role="dialog"
      className="tw-flex tw-justify-center tw-place-items-center"
    >
      <div className="tw-rounded tw-bg-neutral-light-100 dark:tw-bg-elevation-dark-00 tw-shadow-01 dark:tw-shadow-none sm:tw-min-w-[56.2rem] md:tw-max-w-[76rem] tw-max-w-[28.8rem] tw-max-h-[75vh] tw-overflow-y-auto">
        <div
          className={clsx(
            'tw-mb-1.2 tw-border-b tw-border-neutral-light-400 dark:tw-border-neutral-light-700 tw-border-solid tw-flex tw-items-center tw-justify-between tw-pb-1.2 tw-pt-1.6 tw-px-2.4',
            { 'tw-border-none': !title },
          )}
        >
          {title && (
            <h2 className="tw-text-neutral-light-800 dark:tw-text-neutral-light-100 tw-font-subtitle4 tw-font-semibold">
              {title}
            </h2>
          )}

          <button type="button" onClick={onDismiss} className="tw-ml-auto">
            <CloseSmall
              size={24}
              className="tw-fill-current tw-text-neutral-light-700 dark:tw-text-neutral-dark-700"
            />
          </button>
        </div>
        {children}
        <DialogRequest.Actions className="tw-rounded-b tw-bg-neutral-light-300 dark:tw-bg-neutral-dark-600 dark:tw-border-neutral-dark-600 tw-border-neutral-light-400 tw-border-solid tw-border-t tw-flex tw-gap-0.8 tw-justify-between tw-px-2.4 tw-py-2">
          {contentAction ? (
            contentAction
          ) : (
            <button
              className="sm:tw-ml-auto sm:tw-w-auto tw-btn-primary-s tw-inline tw-w-full"
              onClick={onDismiss}
              type="button"
            >
              {t('COURSE_CARD_REQUIREMENTS_POPUP_CTA')}
            </button>
          )}
        </DialogRequest.Actions>
      </div>
    </Modal>
  );
};

export default LayoutCardModal;
