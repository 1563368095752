import { useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { usePlayroomCoinEventValues } from '@crehana/compass.playroom';

import { useLearnDashboardContext } from '@/views/LearnDashboard/context';

import GET_DETAIL_TRACK_BY_ID from '../../graphql/GetDetailTrackByIdQuery.v5.graphql';
import {
  GetDetailTrackByIdQuery,
  GetDetailTrackByIdQueryVariables,
} from '../../graphql/types/GetDetailTrackByIdQuery';
import parseDataDetailTrack from '../../utils/parseDataDetailTrack';

const useGetDetailTrack = () => {
  const { id } = useParams<{ id: string }>();
  const { organizationSlug, me } = useLearnDashboardContext();
  const organizationId = me.organization?.originalId?.toString() as string;

  const { data, loading, error } = useQuery<
    GetDetailTrackByIdQuery,
    GetDetailTrackByIdQueryVariables
  >(GET_DETAIL_TRACK_BY_ID, {
    fetchPolicy: 'network-only',
    context: {
      clientName: 'v5',
    },
    variables: {
      input: {
        organization_team_id: Number(id),
        organization_id: Number(organizationId),
      },
    },
  });

  const { playroomLearningPathCoins } = usePlayroomCoinEventValues(
    organizationSlug as string,
    organizationId,
  );

  return {
    data: parseDataDetailTrack(
      data?.content_organization_team?.user_organization_team,
      playroomLearningPathCoins,
    ),
    loading: loading,
    error,
  };
};

export default useGetDetailTrack;
