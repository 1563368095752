import { FC, useCallback, useMemo } from 'react';

import cn from 'clsx';

import Plus from '@crehana/compass.icons.outline/Plus';
import { ImageCard } from '@crehana/compass.knowledgehub/components';
import { NextLiveSession } from '@crehana/compass.live-sessions';
import { formatDuration } from '@crehana/date';
import { useMediaQuery } from '@crehana/react-hooks';
import { Col, Container, Row } from '@crehana/ui';

import { TrackSectionScroll } from '@/views/LearnDashboard/components';
import { useLearnDashboardContext } from '@/views/LearnDashboard/context';
import { useCrehanaTranslation } from '@/views/LearnDashboard/hooks';

import { AMPLITUDE_SCROLL_EVENT_NAME, DEFAULT_IMG_CONTENT } from '../constants';
import Slider from '../sections/Slider';
import type { TDataSlice, TLastActivity } from '../types';
import LearningPlanLayout from './LearningPlanLayout';

const LastActivity: FC<TLastActivity> = ({
  isDark,
  isMarketplaceUserAndUIFF,
  onActionClickLearningPlan,
  newCourses,
  plans,
  actionMetrics,
  isCourseAssigned,
  coinsCourse,
  isActiveFF,
  nextLiveSession,
  courseProgress,
}) => {
  const { isB2BOnlyPanel, organizationSlug } = useLearnDashboardContext();
  const { t } = useCrehanaTranslation();
  const { matches: isMd } = useMediaQuery('md');
  const { matches: isSm } = useMediaQuery('sm');
  const isXs: boolean = !isSm;
  const shouldRenderLearningPlan = !isMarketplaceUserAndUIFF || isMd;
  const dataSlice: TDataSlice[] | null = useMemo(() => {
    if (courseProgress && courseProgress?.length > 0) {
      return courseProgress;
    }

    return newCourses.slice(0, 5).map(items => ({
      imagePlaceholder: items.imagePlaceholder,
      title: items.title,
      promoImage: items.promoImage,
      url: items.url,
      dashboardUrl: items.dashboardUrl,
      lastVideoLectureUrl: items.lastVideoLectureUrl,
      teacher: items.professor?.fullName,
      isEnrollment: items.isEnrollment,
    }));
  }, [courseProgress, newCourses]);

  const getUrl = useCallback(
    (
      url?: string,
      urlDashboard?: string,
      playerUrl?: string,
      isEnrollment?: boolean,
    ) => {
      return (
        <div className="tw-flex tw-items-center md:tw-items-start tw-flex-col tw-mt-1.6 tw-mb-1.2">
          {courseProgress ? (
            <div className="tw-flex">
              <a
                className="tw-btn-primary-s tw-w-full md:tw-w-auto tw-flex tw-items-center tw-justify-center"
                href={playerUrl || ''}
              >
                {t(
                  isEnrollment
                    ? 'UD_CONTINUE_COURSE'
                    : 'START_LEARNING_READY_COURSE',
                )}
              </a>
              <span className="tw-flex tw-items-center tw-ml-1.6">
                <Plus className="tw-text-current tw-mr-0.4" size={20} />
                <a
                  className="tw-w-full md:tw-w-auto tw-flex tw-items-center tw-justify-center tw-font-subtitle5"
                  href={urlDashboard || ''}
                >
                  {t('START_LEARNING_SHOW_MORE')}
                </a>
              </span>
            </div>
          ) : (
            <a
              className="tw-btn-primary-s tw-w-full md:tw-w-auto tw-flex tw-items-center tw-justify-center"
              href={url || ''}
            >
              {t('SHOW_MORE')}
            </a>
          )}
        </div>
      );
    },
    [courseProgress, t],
  );

  const returnSubtitle = useCallback(
    ({
      nameTeacher,
      duration,
      progress,
      isEnrollment,
    }: {
      nameTeacher?: string;
      duration?: number;
      progress?: number;
      isEnrollment: boolean | null;
    }) => {
      if (progress != undefined && isEnrollment && duration) {
        return t('UD_DURATION_LEFT', {
          interpolation: {
            duration: formatDuration(duration - duration * (progress / 100)),
          },
        });
      }
      if (duration)
        return t('UD_DURATION', {
          interpolation: { duration: formatDuration(duration) },
        });
      return !isXs && `Por: ${nameTeacher}`;
    },
    [isXs, t],
  );

  const returnContent = () => {
    if (isB2BOnlyPanel && courseProgress?.length === 0)
      return (
        <div className="tw-h-[35.2rem] tw-bg-primary-light-600 tw-p-2.4 tw-text-neutral-light-100 tw-rounded-2 md:tw-h-full tw-w-full tw-flex tw-flex-col tw-justify-end">
          <p className="tw-font-h4 tw-mb-0.8 tw-font-semibold">
            {t('START_LEARNING_ONLYPANEL_MESSAGE_TITLE')}
          </p>
          <p className="tw-font-body3">
            {t('START_LEARNING_ONLYPANEL_MESSAGE_SUBTITLE')}
          </p>
        </div>
      );

    return (
      <Slider>
        {dataSlice?.map(
          (
            {
              title,
              teacher,
              promoImage,
              url,
              dashboardUrl,
              lastVideoLectureUrl,
              duration,
              progress,
              isEnrollment,
            },
            index,
          ) => (
            <div
              key={`slides_card${index * 1}`}
              className="tw-h-[35.2rem] sm:tw-min-w-full tw-min-w-[25rem] tw-rounded-2 tw-object-cover md:tw-h-min-[44.4rem] md:tw-h-full"
            >
              <ImageCard
                backgroundImg={promoImage || DEFAULT_IMG_CONTENT}
                title={title}
                subtitle={returnSubtitle({
                  nameTeacher: teacher || '',
                  duration,
                  progress: progress ?? undefined,
                  isEnrollment,
                })}
                bottomElement={getUrl(
                  url!,
                  dashboardUrl!,
                  lastVideoLectureUrl!,
                  isEnrollment!,
                )}
                labelState={t(
                  isEnrollment ? 'START_LAST_PLAY' : 'UD_ENROLLMENTS_NEW_TAG',
                )}
                progress={isEnrollment ? progress : undefined}
                coins={isEnrollment ? coinsCourse : undefined}
                className="tw-h-full tw-w-full"
              />
            </div>
          ),
        )}
      </Slider>
    );
  };

  return (
    <>
      <TrackSectionScroll
        amplitudeEventName={AMPLITUDE_SCROLL_EVENT_NAME}
        amplitudeSource="Last Activity Section"
      />
      <Container
        type="dashboard"
        className="tw-pt-2.4 tw-rounded-0.4 tw-mt-2.4"
      >
        <Row>
          <Col
            md={shouldRenderLearningPlan ? 8 : 12}
            xs={12}
            className={cn({
              '!tw-max-w-[90%] md:tw-max-w-full tw-pl-0': isB2BOnlyPanel,
            })}
          >
            {isActiveFF ? (
              <div
                className={cn(
                  'tw-h-full tw-grid tw-gap-1 tw-grid-cols-1',
                  nextLiveSession && ' md:tw-grid-cols-2',
                )}
              >
                {nextLiveSession && (
                  <NextLiveSession
                    session={nextLiveSession}
                    isHome={true}
                    isAdmin={false}
                    organizationSlug={organizationSlug ?? ''}
                  />
                )}
                <div className="tw-w-screen md:tw-w-full tw-h-full">
                  {returnContent()}
                </div>
              </div>
            ) : (
              <div className="tw-w-screen md:tw-w-full tw-h-full">
                {returnContent()}
              </div>
            )}
          </Col>
          {shouldRenderLearningPlan && (
            <Col md={4}>
              <LearningPlanLayout
                onActionClickLearningPlan={onActionClickLearningPlan}
                isDark={isDark}
                plans={plans}
                actionMetrics={actionMetrics}
                isCourseAssigned={isCourseAssigned}
              />
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
};

export default LastActivity;
