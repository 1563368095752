import trackUserActivity from "./trackUserActivity";
import { UserActivityEvent } from "./types";
var trackPageView = function trackPageView(_ref) {
  var type = _ref.type,
    id = _ref.id,
    page = _ref.page,
    user = _ref.user,
    pageData = _ref.pageData;
  trackUserActivity({
    event: UserActivityEvent.PAGE_VIEW,
    data: {
      item_id: id,
      item_type: type,
      path: window.location.href.split('?')[0],
      origin_path: window.document.referrer.split('?')[0],
      page_identifier: page,
      page_data: pageData
    },
    user: user ? {
      segment: user.segmentationKey,
      username: user.username
    } : undefined
  });
};
export default trackPageView;