import { useMe } from '@crehana/auth';
import {
  useAvailableSurvey,
  useReminderEnabled,
} from '@crehana/web/components/Survey/hooks';

import {
  isCurrentPAYGNotificationPreviousToKey,
  isCurrentPremiumNotificationPreviousToKey,
} from '../utils/index';

const useNPSSurveyValidation = ({
  currentNotificationIndex,
  notificationType,
}: {
  currentNotificationIndex?: number;
  notificationType: 'premium' | 'payg';
}) => {
  const { me } = useMe();
  const isAPreviousNotificationBeingEvaluated =
    notificationType === 'premium'
      ? isCurrentPremiumNotificationPreviousToKey({
          currentNotificationIndex,
          notificationKey: 'nps-reminder',
        })
      : isCurrentPAYGNotificationPreviousToKey({
          currentNotificationIndex,
          notificationKey: 'nps-reminder',
        });
  const activeSurveyQuery = useAvailableSurvey({
    skip: isAPreviousNotificationBeingEvaluated,
  });

  const npsReminderIsEnabledToShow = useReminderEnabled({
    hasAvailableSurveyToAnswer: activeSurveyQuery.hasAvailableSurveyToAnswer,
    surveyToAnswerId: Boolean(activeSurveyQuery.surveyToAnswerId),
    skip: activeSurveyQuery.loading,
    userName: me?.username!,
  });

  return {
    isEnabledToShow: npsReminderIsEnabledToShow,
    isActiveOrBeingEvaluated:
      isAPreviousNotificationBeingEvaluated ||
      activeSurveyQuery.loading ||
      npsReminderIsEnabledToShow,
    surveyId: activeSurveyQuery.surveyToAnswerId || '',
  };
};

export default useNPSSurveyValidation;
