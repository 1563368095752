export var redirectToProductDashboard = function redirectToProductDashboard(_ref) {
  var product = _ref.product,
    isProduction = _ref.isProduction;
  if (product === 'talent') {
    var PRODUCTION_URL = 'https://crehana.acsendo.com/rest/saml3/login?next=https://crehana.acsendo.com/company/home?source_page=Switch%20Panel';
    var QA_URL = 'https://pruebas.acsendo.com/rest/saml3/login?next=https://pruebas.acsendo.com/company/home?source_page=Switch%20Panel';
    window.location.href = isProduction ? PRODUCTION_URL : QA_URL;
  } else if (product === 'learning') {
    // Temporary case until we share this component with acsendo. Then we need to redirect to Crehana
    // url like the case with 'talent'
    return false;
  } else {
    throw new Error("There's no url to redirect for this product: ".concat(product));
  }
};
export var getByLabelText = function getByLabelText(product) {
  if (product === 'learning') {
    return 'Gestión de Aprendizaje';
  }
  if (product === 'talent') {
    return 'Gestión de Talento';
  }
  throw new Error('Product label not found');
};