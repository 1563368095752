import { useCallback, useMemo } from 'react';

import { clsx } from 'clsx';

import Playsquare from '@crehana/compass.icons.fill/Playsquare';
import Routes from '@crehana/compass.icons.fill/Routes';
import ArrowRight from '@crehana/compass.icons.outline/ArrowRight';
import { useMediaQuery } from '@crehana/react-hooks';
import { useLearnLastActivityContext } from '@crehana/web';

import { useCrehanaTranslation } from '@/views/LearnDashboard/hooks';

import { TLastActivity } from '../../types';
import { redirectToEditStudyPlan } from '../../utils/redirectToEditStudyPlan';
import { COLOR_STATE } from '../CoursesThatDontBelongToTracks/constants';
import Slider from '../Slider';
import Abstract from './components/Abstract';
import Empty from './components/Empty';
import EmptyWithoutCourseDesktop from './components/EmptyWithoutCourseDesktop';
import EmptyWithoutCourseMobile from './components/EmptyWithoutCourseMobile';
import Header from './components/Header';
import ProgressState from './components/ProgressState';
import type { TAbstract, TLearningPlanNewProps } from './types';

export const LearningPlanNew: React.FC<
  TLearningPlanNewProps & Pick<TLastActivity, 'plans'>
> = ({ hasEmpty, summaryCount, plans }) => {
  const { stateLearning } = useLearnLastActivityContext();
  const { t } = useCrehanaTranslation();
  const { matches: isXs } = useMediaQuery({ until: 'sm' });
  const transformProgress = (countTarget: number, countProgress: number) => {
    if (countTarget <= 0) return 0;
    const progressCustom = (countProgress * 100) / countTarget;
    if (progressCustom > 100) return 100;
    return progressCustom;
  };

  const _totalCompletedMetrics = (target: number, completed: number) => {
    const total = target - completed;
    if (total < 0) return 0;
    return total;
  };

  const summaryPlan: TAbstract[] = useMemo(
    () => [
      {
        course: {
          title: t('START_LEARNING_COURSE_TITLE', {
            interpolation: {
              count: _totalCompletedMetrics(
                summaryCount.course.target,
                summaryCount.course.progress,
              ),
            },
          }),
          subtitle: t('START_LEARNING_COURSE_COMPLETED', {
            interpolation: {
              count: summaryCount.course.progress,
              countTarget: summaryCount.course.target,
            },
          }),
        },
        ctaButton: t('GO_TO_MY_COURSES'),
        icon: Playsquare,
        progress: transformProgress(
          summaryCount.course.target,
          summaryCount.course.progress,
        ),
        onAction: () => summaryCount.course.onClick(),
      },
      {
        course: {
          title: t('START_LEARNING_ROUTE_TITLE', {
            interpolation: {
              count: _totalCompletedMetrics(
                summaryCount.route.target,
                summaryCount.route.progress,
              ),
            },
          }),
          subtitle: t('START_LEARNING_ROUTE_COMPLETED', {
            interpolation: {
              count: summaryCount.route.progress,
              countTarget: summaryCount.route.target,
            },
          }),
        },
        ctaButton: t('B2B_START_SECTION_GOTO_ROUTES_MOBILE'),
        icon: Routes,
        progress: transformProgress(
          summaryCount.route.target,
          summaryCount.route.progress,
        ),
        onAction: () => summaryCount.route.onClick(),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [summaryCount.course, summaryCount.route],
  );

  const backgroundPlan = useMemo(
    () => COLOR_STATE[stateLearning ?? 'empty'],
    [stateLearning],
  );
  const returnContent = useCallback(() => {
    if (hasEmpty?.empty) return <Empty />;
    if (hasEmpty?.emptyWithoutCourse)
      return isXs ? (
        <EmptyWithoutCourseMobile
          minProgress={plans?.progress.minutes}
          minTarget={plans?.target.minutes}
          progress={plans?.progress.percent || 0}
        />
      ) : (
        <EmptyWithoutCourseDesktop
          minProgress={plans?.progress.minutes}
          minTarget={plans?.target.minutes}
          progress={plans?.progress.percent || 0}
        />
      );
    return (
      <>
        {/* section mobile */}
        <div className="tw-block md:tw-hidden">
          <Slider isPagination={false} groupSize={1}>
            <div
              className={clsx(
                'tw-min-w-[25rem] sm:tw-min-w-[30rem] tw-h-auto tw-flex  tw-flex-col tw-justify-between tw-p-2.4 tw-rounded-2',
                backgroundPlan.background,
              )}
            >
              <Header className="tw-text-neutral-light-800" />
              <ProgressState
                state={stateLearning ?? 'empty'}
                minProgress={plans?.progress.minutes}
                minTarget={plans?.target.minutes}
                progress={plans?.progress.percent || 0}
              />
              <button
                onClick={redirectToEditStudyPlan}
                type="button"
                className="tw-text-link-m dark:tw-text-primary-light-500 tw-font-subtitle5 tw-mt-1.6"
              >
                {t('START_LEARNING_EDIT')}
                <ArrowRight size={20} className="tw-text-current" />
              </button>
            </div>
            {summaryPlan.map((item, index) => (
              <Abstract key={`abstracs_${index * 1}`} {...item} />
            ))}
          </Slider>
        </div>
        {/* section desktop */}
        <div
          className="tw-hidden md:tw-block tw-bg-neutral-light-100 dark:tw-bg-elevation-dark-04 tw-min-w-fit tw-p-2.4 tw-rounded-2"
          data-testid="plan-study"
        >
          <div className="tw-flex tw-items-center tw-justify-between tw-mb-2">
            <Header />
            <button
              onClick={redirectToEditStudyPlan}
              type="button"
              className="tw-text-link-m tw-font-subtitle5"
              data-testid="plan-button-edit"
            >
              {t('START_LEARNING_EDIT')}
              <ArrowRight size={20} className="tw-text-current" />
            </button>
          </div>
          <ProgressState
            state={stateLearning ?? 'empty'}
            minProgress={plans?.progress.minutes}
            minTarget={plans?.target.minutes}
            progress={plans?.progress.percent || 0}
          />
          <div className="tw-mt-2.4">
            {summaryPlan.map((item, index) => (
              <Abstract key={`abstracs_${index * 1}`} {...item} />
            ))}
          </div>
        </div>
      </>
    );
  }, [
    backgroundPlan,
    hasEmpty?.empty,
    hasEmpty?.emptyWithoutCourse,
    isXs,
    plans,
    stateLearning,
    summaryPlan,
    t,
  ]);
  return returnContent();
};

export default LearningPlanNew;
