import { useQuery } from '@apollo/client';

import { COMPANY_ID, REDIRECT } from './constants';
import GET_CANNY_TOKEN_QUERY from './graphql/GetCannyTokenQuery.v4.b2b.graphql';
import type { GetCannyTokenQuery } from './graphql/types/GetCannyTokenQuery';

const useCannyUrl = (params?: { skip?: boolean }): string => {
  const { loading, error, data } = useQuery<GetCannyTokenQuery>(
    GET_CANNY_TOKEN_QUERY,
    {
      skip: params?.skip,
      context: { clientName: 'v4.b2b' },
    },
  );

  const ssoToken = data?.cannyToken || null;

  if (loading || error || !ssoToken) return '';

  return `https://canny.io/api/redirects/sso?companyID=${COMPANY_ID}&ssoToken=${ssoToken}&redirect=${REDIRECT}`;
};

export default useCannyUrl;
