/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum CourseTypeContentEnum {
  COURSE_TYPE_CREHANA = "COURSE_TYPE_CREHANA",
  COURSE_TYPE_ELEVATE = "COURSE_TYPE_ELEVATE",
  COURSE_TYPE_EXTERNAL = "COURSE_TYPE_EXTERNAL",
}

export enum CourseTypeEnum {
  B2B = "B2B",
  B2B_CLASSROOMS = "B2B_CLASSROOMS",
  DEGREE = "DEGREE",
  FREE = "FREE",
  FREEMIUM = "FREEMIUM",
  HIDDEN = "HIDDEN",
  MEMBERSHIP = "MEMBERSHIP",
  PAID = "PAID",
  PARTNER = "PARTNER",
  ROCKSTAR = "ROCKSTAR",
  TEMPORAL_ACCESS = "TEMPORAL_ACCESS",
}

export enum EnrollmentCourseProgressState {
  CERTIFIED = "CERTIFIED",
  COMPLETED = "COMPLETED",
  INITIAL = "INITIAL",
  IN_PROGRESS = "IN_PROGRESS",
}

export enum EnrollmentOrderByEnum {
  COURSE_TITLE = "COURSE_TITLE",
  ENROLLMENT_AT = "ENROLLMENT_AT",
}

export enum OrderDirectionEnum {
  ASC = "ASC",
  DESC = "DESC",
}

export enum QuizRetryTimeEnum {
  RETRY_TIME_120_MIN = "RETRY_TIME_120_MIN",
  RETRY_TIME_180_MIN = "RETRY_TIME_180_MIN",
  RETRY_TIME_240_MIN = "RETRY_TIME_240_MIN",
  RETRY_TIME_60_MIN = "RETRY_TIME_60_MIN",
  RETRY_WITHOUT_TIME = "RETRY_WITHOUT_TIME",
}

export enum QuizTimeLimitEnum {
  TIME_120_MIN = "TIME_120_MIN",
  TIME_30_MIN = "TIME_30_MIN",
  TIME_45_MIN = "TIME_45_MIN",
  TIME_60_MIN = "TIME_60_MIN",
  TIME_WITHOUT = "TIME_WITHOUT",
}

export enum QuizTypeEnum {
  TYPE_CUMULATIVE = "TYPE_CUMULATIVE",
  TYPE_FORMATIVE = "TYPE_FORMATIVE",
}

export enum RoleEnum {
  ADMIN = "ADMIN",
  EMPLOYEE = "EMPLOYEE",
  MANAGER = "MANAGER",
  MULT_ADMIN = "MULT_ADMIN",
  READER = "READER",
  ROOT = "ROOT",
}

export enum StatusProjectEnum {
  CERTIFIED = "CERTIFIED",
  DRAFT = "DRAFT",
  FINISHED = "FINISHED",
  OBSERVED = "OBSERVED",
  PUBLISHED = "PUBLISHED",
  RESOLVED = "RESOLVED",
}

export enum UserTeamOrderByEnum {
  ORGANIZATION_TEAM_END_DATE = "ORGANIZATION_TEAM_END_DATE",
  USER_TEAM_EXPIRATION_DATE = "USER_TEAM_EXPIRATION_DATE",
}

export enum UserTeamSearchFilterEnum {
  TEAM_SEARCH_FILTER_ALL = "TEAM_SEARCH_FILTER_ALL",
  TEAM_SEARCH_FILTER_COMPLETED = "TEAM_SEARCH_FILTER_COMPLETED",
  TEAM_SEARCH_FILTER_CURRENT = "TEAM_SEARCH_FILTER_CURRENT",
  TEAM_SEARCH_FILTER_DISABLED = "TEAM_SEARCH_FILTER_DISABLED",
  TEAM_SEARCH_FILTER_EXPIRED = "TEAM_SEARCH_FILTER_EXPIRED",
}

export interface EnrollmentFiltersInput {
  progress_state?: EnrollmentCourseProgressState | null;
  is_hidden?: boolean | null;
  assigned_courses?: boolean | null;
  course_title?: string | null;
}

export interface EnrollmentInputOrderBy {
  field: EnrollmentOrderByEnum;
  direction: OrderDirectionEnum;
}

export interface GetQuizByClassInput {
  organization_id?: number | null;
  video_lecture_id: string;
}

export interface TalentDashboardCollaboratorTimelineInput {
  user_id?: number | null;
  user_email?: string | null;
  month_number: number;
  year_number?: number | null;
  company_id?: number | null;
}

export interface TalentDashboardCollaboratorTodoListInput {
  user_id?: number | null;
  user_email?: string | null;
  company_id?: number | null;
}

export interface UserOrganizationTeamInput {
  organization_id?: number | null;
  organization_team_id: number;
}

export interface UserTeamInputOrderBy {
  field: UserTeamOrderByEnum;
  direction: OrderDirectionEnum;
}

export interface UserTeamRewardInput {
  organization_id?: number | null;
  organization_team_id: number;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
