import { FC } from 'react';

import { useMediaQuery } from '@crehana/react-hooks';

import { TCardProject } from '../../types';
import CardProject from './CardProject';
import CardProjectTable from './CardProjectTable';

export const LayoutCardProject: FC<TCardProject> = props => {
  const { matches: isSM } = useMediaQuery({ only: 'sm' });

  return isSM ? <CardProjectTable {...props} /> : <CardProject {...props} />;
};
