import type { CSSProperties } from 'react';

import type { MeObject } from '@crehana/auth';
import { type IconProps } from '@crehana/compass.icons.fill/types';
import type { TTypeProject } from '@crehana/compass.ui';
import type {
  TPortugueseTranslations,
  TSetTranslationKeys,
} from '@crehana/ts-types';
import type { ContainerProps } from '@crehana/ui';

import { StatusProjectEnum } from '@/shared/types/graphql/globals.v5';

import { B2B_TEAM_COURSES } from './constants';
import {
  GetUserTracksQuery_userTeams_edges_node_team_reward,
  GetUserTracksQuery_userTeams_edges_node_team_userReward,
} from './graphql/types/GetUserTracksQuery';
import type localeEs from './i18n/localeEs';

export type TValueFilters = 'all' | 'defeated' | 'expired' | 'disabled';

export type LearnUser = Required<
  Pick<MeObject, 'isB2BAllCatalog' | 'username' | 'originalId' | 'userTagV2'>
> & {
  organization: Pick<NonNullable<MeObject['organization']>, 'slug'>;
};

export type TypeCard = 'article' | 'video' | 'course';

export enum STATUS {
  REWARD_STATUS_NOT_AVAILABLE = 1,
  REWARD_STATUS_AVAILABLE = 2,
  REWARD_STATUS_TAKEN = 3,
  REWARD_STATUS_INACTIVE = 4,
}

export type B2bTeamCoursesLocaleKeys = TSetTranslationKeys<typeof localeEs>;

export type SkillPathCoursesPanelProps = {
  title: string;
  finalDate?: string;
  overdueDays?: number;
  completedCoursesCount: number;
  mandatoryCoursesCount: number;
  mandatoryProjectsCount: number;
  assignedCoursesCount: number;
  isCompletedSkillPath: boolean;
  rewardsCount: number;
  isAcademyCard: boolean;
  academyName?: string;
  logo: Partial<{
    academyLogoUrl: string | null;
  }>;
  isDark?: boolean;
  className?: string;
  onContinuePath: () => void;
};

export type SkillPathCoursesPanelPropsNew = {
  courseCount: number;
  className?: string;
};

export type LearnTeamVideoTrailer = {
  url: string;
  sourceType: string;
};

export type TStateTrack = 'active' | 'disabled' | 'success';

export type THeaderDetail = {
  trackName: string;
  isTrackReward?: boolean;
  description?: string | null;
  assigned?: string;
  coinPlayroomNumber?: number;
  date?: string;
  typeTrack?: TStateTrack;
  teamExpired?: boolean;
};

export type TSkillPathCardProperties = {
  icon: React.FC<IconProps>;
  assigned: number;
  accessed: number;
  title: string;
};

export type TCardProject = {
  type?: TTypeProject;
  typeTitle?: string;
  title: string;
  logo: string;
  handleClick: () => void;
};

export type SkillPathsPanelProps = {
  count: number;
  title: string;
  className?: string;
};

export type TBaseComponentProps = {
  className?: string;
  style?: CSSProperties;
  'data-testid'?: string;
  'data-cy'?: string;
};

export type TCertificacionCriteria = TBaseComponentProps & {
  state: string;
  typeCertification: string;
  description: string;
  numberCertifications: { completed: number; expected: number };
  isBorder?: boolean;
};

export type StateOptions = 'success' | 'disabled' | 'default';

export type TRequirements = {
  title: string;
  state?: StateOptions;
};

export type TInformation = {
  title: string;
  teacherName?: string | null;
  duration?: string;
  labelTrack?: string;
  isVisibleTeacherName?: boolean;
  isRequired: boolean;
};

export type TLayoutLearnTeam = {
  order: number;
  isMediumT?: boolean;
  isMedium?: boolean;
  isSuccess?: boolean;
  isCompletedCourse?: boolean;
  isFirst?: boolean;
  isLast?: boolean;
  porcentage: number;
};

export type LoadingCardsProps = {
  className?: string;
  containerType?: ContainerProps['type'];
};

export type TMachineContextCourseTrailerType = {
  sources: {
    type: string;
    src: string;
  }[];
};

export type TSummary = {
  required: number;
  progress: number;
};

export type TDataCertificate = {
  image: string | null;
  attachmentUrl: string | null;
};

export type TLayout = {
  summaryCourse: TSummary;
  summaryProject: TSummary;
  summaryQuiz: TSummary;
  teamDisabled?: boolean;
  dataCertificate: TDataCertificate | null;
  isCompletedTrack: boolean;
  trackProjects: TProjectCourse | null;
  hasCertificate: boolean;
};

export type THeaderProps = {
  detailHeaderTrack: THeaderDetail;
};

export type StatusItem = {
  label: string;
  count: number | string;
  action?: () => void;
};

export type TranslationsBundle = TPortugueseTranslations<
  B2bTeamCoursesLocaleKeys,
  typeof B2B_TEAM_COURSES
>;

export type TMenuOptionCoursesOwn = {
  label: B2bTeamCoursesLocaleKeys;
  onClick: () => void;
};

export type TSkillTrackUser = {
  teamId: number;
  trackName: string;
  trackImage: string;
  trackDescription: string;
  learnPathCoin: number;
  endDate: string;
  isCompletedTrack: boolean;
  rewardData: GetUserTracksQuery_userTeams_edges_node_team_reward | null;
  userRewardData: GetUserTracksQuery_userTeams_edges_node_team_userReward | null;
  isLocked: boolean;
};

export const customType: { [key in StatusProjectEnum]: TTypeProject } = {
  [StatusProjectEnum.CERTIFIED]: 'certified',
  [StatusProjectEnum.DRAFT]: 'pending',
  [StatusProjectEnum.FINISHED]: 'underReview',
  [StatusProjectEnum.OBSERVED]: 'remarks',
  [StatusProjectEnum.PUBLISHED]: 'underReview',
  [StatusProjectEnum.RESOLVED]: 'underReview',
};

export type TDetailRewards = {
  originalId: number | null;
  status: STATUS;
  numberCourses: number | null;
};

export type TDetailTrackBody = {
  teamId: number;
  nameTrack: string;
  mandatoryProjectsCount: number;
  mandatoryCoursesCount: number;
  mandatoryQuizzesCount: number;
  shouldRenderRewardCard: boolean;
  trackDisabled: boolean;
  isCompletedTrack: boolean;
  inProgressMandatoryProjectsCount: number;
  inProgressCourses: number;
  inProgressQuizzesCount: number;
  dataCertificate: TDataCertificate | null;
  trackProjects: TProjectCourse | null;
  listCourses: TCoursesTrack[];
  coinPlayroomNumber?: number;
  isCertificateGenerated: boolean;
  trackVersion: string | null;
};

export type TDetailReward = {
  status: STATUS;
  numberCourses: number;
  rewardId: number;
};

export type Dependency = {
  courseId: number;
  isBlocked: boolean;
  dependsOn: number[];
};

export type TTrackSummary = {
  mandatoryProjectsCount: number;
  mandatoryCoursesCount: number;
  mandatoryQuizzesCount: number;
  inProgressCourses: number;
  inProgressMandatoryProjectsCount: number;
  inProgressQuizzesCount: number;
  totalCoursesCount: number;
};

export type TTrackReward = {
  rewardId: number;
  userRewardId: number;
  numberCourses: number;
  status: STATUS;
};

export type TTrailerCourse = {
  videoUrl: string;
  videoSourceType: string;
};

export type TProjectCourse = {
  originalId: number;
  title: string | null;
  url: {
    relativeUrl: string;
  };
  currentActionState: StatusProjectEnum | null;
  projectImageThumbnailUrl: string | null;
};

export type TCoursesTrack = {
  courseId: number;
  courseName: string;
  courseImage: string;
  profesorFullName: string;
  duration: number;
  progress: number;
  courseTypeEnum: string;
  lastVideoLectureUrl: string;
  trailerSource: TTrailerCourse[];
  order: number;
  hasQuiz: boolean;
  hasProject: boolean;
  isRequired: boolean;
  iconExternal?: string;
  typeCourse?: TypeCard;
  language?: string;
  url?: string;
  integrationItemId?: string | null;
  integrationObjectId?: number | null;
  wasOpened?: boolean;
};

export type DetailTrackType = {
  trackId: number;
  trackName: string;
  trackDescription: string;
  trackImage: string;
  trackEndDate: string;
  isCompletedTrack: boolean;
  trackDisabled: boolean;
  trackReward: TTrackReward;
  trackSummary: TTrackSummary;
  trackPlayroomCoin: number;
  listCourses: TCoursesTrack[];
  dataCertificate: TDataCertificate | null;
  lastProject: TProjectCourse | null;
  hasCertificate: boolean;
  trackVersion: string | null;
};
