export default {
  pt: {
    COMPANY_TITLE: 'Conheça a Crehana',
    COMPANY_HELP_CENTER: 'Central de ajuda',
    COMPANY_ABOUT: 'Sobre a Crehana',
    COMPANY_SIGN_UP: 'Sign up',
    COMPANY_HIRING: 'Estamos contratando!',
    CONTACT_PHONE: 'Contacte-nos em <br /> (+51) 963-761-736',
    COMPANY_CREHANA_FOR_BUSINESS: 'Crehana para empresas',
    PRODUCT_TITLE: 'Produtos',
    PRODUCT_DEVELOPMENT: 'Desenvolvimento',
    PRODUCT_WORK_ENVIRONMENT: 'Clima de trabalho',
    PRODUCT_PERFORMANCE: 'Desempenho',
    RESOURCES_TITLE: 'Recursos',
    RESOURCES_BLOG: 'Blogue',
    RESOURCES_PODCAST: 'Podcast',
    RESOURCES_DOWNLOABLES: 'Descarregáveis',
    RESOURCES_COURSES: 'Cursos',
    RESOURCES_YOUTUBE: 'Youtube',
    COMMUNITY_TITLE: 'Políticas de proteção',
    COMMUNITY_STORIES: 'Historias de sucesso',
    COMMUNITY_RESOURCES: 'Recursos',
    COMMUNITY_BLOG: 'Blog',
    HELP_TITLE: 'Links de ajuda',
    HELP_PRIVACY: 'Políticas de privacidade',
    HELP_COOKIES: 'Política de cookies',
    HELP_ARCO: 'Solicitação ARCO',
    HELP_CANCELLATION: 'Política de cancelación',
    HELP_TERMS: 'Termos e condições',
    HELP_CLAIMS_BOOK: 'Livro de reclamações',
    RIGHTS_RESERVED: 'Todos os direitos reservados'
  }
};