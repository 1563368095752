import { StatusProjectEnum } from '@/shared/types/graphql/globals.v5';

import {
  GetDetailTrackByIdQuery_content_organization_team_user_organization_team,
  GetDetailTrackByIdQuery_content_organization_team_user_organization_team_team_courses,
} from '../graphql/types/GetDetailTrackByIdQuery';
import type { DetailTrackType, STATUS, TCoursesTrack } from '../types';

const parseDataListCourses = (
  listDataCourses: GetDetailTrackByIdQuery_content_organization_team_user_organization_team_team_courses[],
): TCoursesTrack[] => {
  return listDataCourses
    .sort((a, b) => (a?.order || 0) - (b?.order || 0))
    .map(item => {
      return {
        courseId: Number(item?.course_id),
        courseImage: item?.course?.image || '',
        courseName: item?.course?.title || '',
        profesorFullName: item?.course?.professor?.full_name || '',
        duration: Number(item?.course?.duration),
        progress: Number(item?.course?.progress) || 0,
        courseTypeEnum: item?.course?.course_type || '',
        lastVideoLectureUrl:
          item?.course?.first_video_lecture_url?.relative_url || '',
        hasQuiz: Boolean(item?.course_user_summary?.has_quiz),
        hasProject: Boolean(item?.course_user_summary?.has_project),
        isRequired: Boolean(item?.is_required) || false,
        trailerSource: item?.course?.trailer_source
          ? [
              {
                videoUrl: item.course.trailer_source?.video_url || '',
                videoSourceType:
                  item?.course.trailer_source?.video_source_type || '',
              },
            ]
          : [],
        order: Number(item?.order),
        typeCourse: 'course',
      };
    });
};

const parseDataDetailTrack = (
  data:
    | GetDetailTrackByIdQuery_content_organization_team_user_organization_team
    | null
    | undefined,
  numberCoinPlayroom: number,
): DetailTrackType | null => {
  if (data) {
    return {
      trackId: data?.team_id,
      trackName: data?.team_name || '',
      trackDescription: data?.description || '',
      trackImage: '',
      trackEndDate: data?.expiration_date || '',
      isCompletedTrack: data?.user_organization_team_summary?.is_completed,
      trackDisabled: data?.organization_team?.is_hidden,
      trackPlayroomCoin: numberCoinPlayroom,
      trackReward: {
        rewardId: Number(data?.organization_team?.reward?.id),
        userRewardId: Number(data?.user_reward?.id),
        numberCourses: data?.organization_team?.reward?.number_courses || 0,
        status: data?.user_reward?.status as STATUS,
      },
      trackSummary: {
        mandatoryProjectsCount:
          data?.user_organization_team_summary?.required_projects || 0,
        mandatoryCoursesCount:
          data?.user_organization_team_summary?.required_courses || 0,
        mandatoryQuizzesCount:
          data?.user_organization_team_summary?.required_quiz_courses || 0,
        inProgressCourses:
          data?.user_organization_team_summary?.completed_courses || 0,
        inProgressMandatoryProjectsCount:
          data?.user_organization_team_summary?.created_projects || 0,
        inProgressQuizzesCount:
          data?.user_organization_team_summary?.completed_quiz_courses || 0,
        totalCoursesCount:
          data?.user_organization_team_summary?.total_courses || 0,
      },
      lastProject: data?.in_progress_project
        ? {
            originalId: Number(data?.in_progress_project?.id),
            title: data?.in_progress_project?.title || '',
            url: {
              relativeUrl: data?.in_progress_project?.url?.relative_url || '',
            },
            currentActionState: data?.in_progress_project
              ?.current_action_state as StatusProjectEnum,
            projectImageThumbnailUrl:
              data?.in_progress_project?.project_image || '',
          }
        : null,
      listCourses: parseDataListCourses(data?.team_courses),
      dataCertificate: {
        attachmentUrl: data?.certificate?.attachment_url || '',
        image: data?.certificate?.image || '',
      },
      hasCertificate: data?.organization_team?.generate_certificate || false,
      trackVersion: data?.organization_team?.version || null,
    };
  }

  return null;
};

export default parseDataDetailTrack;
