import type { TPageTranslations } from '@crehana/i18n';

import { LEARN_DASHBOARD_PAGE_KEY } from '@/views/LearnDashboard/constants';

import type { TKeys } from './types';

const pt: TPageTranslations<TKeys, typeof LEARN_DASHBOARD_PAGE_KEY> = {
  [LEARN_DASHBOARD_PAGE_KEY]: {
    // Titles
    UD_ENROLLMENTS_TITLE: 'Meus cursos',
    UD_ENROLLMENTS_COURSES: 'Todos os meus cursos',
    UD_ENROLLMENTS_TRACKS: 'Minhas especializações',
    UD_ENROLLMENTS_CAREERS: 'Minhas carreiras',
    UD_ENROLLMENTS_BUNDLES: 'Meus certificados',
    UD_ENROLLMENTS_DEGREES: 'MicroDegree',
    UD_ENROLLMENTS_ACADEMIES: 'Academias',
    UD_ENROLLMENTS_NEW_TAG: 'Novo',
    UD_ENROLLMENTS_BOOTCAMPS: 'Bootcamp',
    UD_CLASSNOTES_TITLE: 'Minhas anotações',
    UD_PROJECTS_TITLE: 'Meus projetos',
    UD_DIPLOMAS_TITLE: 'Meus diplomas',
    UD_STUDY_PLAN_TITLE: 'Meu plano de estudos',
    // Onboarding
    UD_ONBOARDING_WELCOME_TITLE: 'Bem-vindo ao seu novo painel do aluno!',
    UD_ONBOARDING_WELCOME_DESC:
      'Vamos mostrar as melhorias que temos para você.',
    UD_ONBOARDING_MOBILE_MENU_TITLE: 'Nova barra de menu',
    UD_ONBOARDING_MOBILE_MENU_DESC:
      'Implementamos uma nova barra de menu para melhorar a sua navegação entre as seções Meus Cursos, Minhas Anotações, Meus Projetos, Meus Certificados e Meu Plano de Estudos.',
    UD_ONBOARDING_ENROLLMENTS_TITLE: 'Meus cursos',
    UD_ONBOARDING_ENROLLMENTS_DESC:
      'Aqui, você não só irá encontrar todos os cursos inscritos, como também o progresso nas suas especializações.',
    UD_ONBOARDING_CLASSNOTES_TITLE: 'Minhas anotações',
    UD_ONBOARDING_CLASSNOTES_DESC:
      'Todas as anotações que você fizer dentro dos seus cursos poderão ser vistas aqui.',
    UD_ONBOARDING_PROJECTS_TITLE: 'Meus projetos',
    UD_ONBOARDING_PROJECTS_DESC:
      'Nesta seção, encontre todos os projetos nos quais você está trabalhando. Também vai poder visualizar o estado da revisão de cada um, até os que já foram certificados.',
    UD_ONBOARDING_DIPLOMAS_TITLE: 'Meus certificados',
    UD_ONBOARDING_DIPLOMAS_DESC:
      'Encontre os seus certificados Crehana aqui, uma vez que seu projeto final tiver sido aprovado.',
    UD_ONBOARDING_STUDY_PLAN_TITLE: 'Meu plano de estudos',
    UD_ONBOARDING_STUDY_PLAN_DESC:
      'Sabia que as pessoas com mais sucesso possuem hábitos? Nesta seção vamos ajudá-lo a alcançar as suas metas.',
    UD_ONBOARDING_NIGHT_SWITCH_TITLE: 'Junte-se ao lado escuro',
    UD_ONBOARDING_NIGHT_SWITCH_DESC:
      'Personalize seu painel em modo noturno, ativando este switch.',
    UD_ONBOARDING_CONFIG_TITLE: 'Ajustes dentro da conta',
    UD_ONBOARDING_CONFIG_DESC:
      'Tenha um acesso mais direto à configuração da sua conta como um todo neste link.',
    UD_ONBOARDING_FAQ_TITLE: 'Respondendo as suas perguntas',
    UD_ONBOARDING_FAQ_DESC:
      'Caso ainda tenha dúvidas sobre o funcionamento da plataforma, será um prazer ajudá-lo através da Central de Ajuda Crehana.',
    UD_ONBOARDING_SEARCH_TITLE: 'O que está procurando no seu painel?',
    UD_ONBOARDING_SEARCH_DESC:
      'Faça uma busca mais precisa sobre o que está precisando em seu painel nesta seção.',
    // Filters & FAQ
    UD_CONFIGURATION: 'Configuração',
    UD_SEARCH_HINT: 'Procurar seus cursos, projetos...',
    UD_SEARCH_PROJECT_SUBTITLE: 'Projeto de {{course}}',
    UD_SEARCH_ERROR: 'Houve um erro ao obter a informação do seu painel.',
    UD_SEARCH_NO_RESULTS: 'Não encontramos resultados para a sua busca',
    UD_GO_TO_COURSES: 'Ver todos os meus cursos',
    UD_FILTER_LABEL: 'Filtre por',
    UD_FAQ_COURSE: 'Não encontro meu curso',
    UD_FAQ_PROJECT_UPLOAD: 'Subir projetos',
    UD_FAQ_START_COURSES: 'Como começo meus cursos?',
    UD_FAQ_DIPLOMA: 'Como obtenho meus certificado?',
    UD_FAQ_ONBOARDING: 'Como meu painel funciona?',
    UD_FAQ_GENERAL: 'Ainda está com dúvidas? Entre em nossa',
    UD_FAQ_GENERAL_LINK: 'Central de Ajuda Crehana',
    // Enrollments components and actions
    UD_TRACK_TITLE: 'Especialização em {{title}}',
    UD_DURATION: 'Duração: {{duration}}',
    UD_DOWNLOAD_SYLLABUS: 'Baixar o programa do curso',
    UD_DOWNLOAD_DIPLOMA: 'Baixar seu diploma',
    UD_START_COURSE: 'Começar o curso',
    UD_CONTINUE_COURSE: 'Continuar o curso',
    UD_REWATCH_COURSE: 'Ver de novo',
    UD_CREATE_PROJECT: 'Começar projeto',
    UD_EDIT_PROJECT: 'Editar projeto',
    UD_GO_TO_PROJECT: 'Ver projeto',
    UD_MODULE_LIST_PROGRESS: '{{completed}}/{{total}} módulos finalizados',
    UD_COURSE_LIST_PROGRESS: '{{completed}}/{{total}} cursos finalizados',
    UD_PROJECT_LIST_PROGRESS: '{{completed}}/{{total}} projetos certificados',
    UD_SAVED_COURSES_TITLE: 'Meus salvos',
    UD_SAVED_COURSES_TOUR_TITLE: 'Salve para ver depois',
    UD_SAVED_COURSES_TITLE_DESC:
      'Agora você pode salvar cursos. Não perca a sua aprendizagem de vista!',
    // FAQ'S
    UD_UPLOAD_PROJECTS: 'Como subir meus projetos?',
    UD_START_COURSES: 'Como começo os curso atribuídos a mim?',
    UD_CHAT_WITH_MENTORS: 'Como falo com os tutores?',
    UD_UPLOAD_PROJECT: 'Como subo meu projeto?',
    UD_CANT_FIND_COURSES: 'Não encontro meus cursos',
    UD_UPLOAD_PROJECTS_TO_PLATFORM: 'Como subo meu projeto à plataforma?',
    UD_PROJECT_DESCRIPTION: 'Onde encontro a descrição do meu projeto?',
    UDE_ACADEMIES_ERROR: 'Houve um erro ao obter as suas academias',
    REWARD_BANNER_TEXT:
      'Ganhe enquanto aprende! Descubra como obter pontos, insígnias e recompensas.',
    REWARD_BANNER_CAT: 'Saber mais',
    REWARDS_TITLE: 'Você quer adicionar a função Rewards?',
    REWARDS_SUBTITLE: 'Saiba mais',
    REWARDS_COPY1:
      'Ao concluir as trilhas de \n aprendizagem, os alunos \n podem escolher cursos livres.',
    REWARDS_COPY2: 'Terão acesso a todo o \n catálogo de cursos da \n Crehana.',
    REWARDS_COPY3: 'Incentive os alunos \n a continuarem \n aprendendo.',
    REWARDS_ACCEPT: 'Sim, quero',
    REWARDS_CANCEL: 'Agora não',
    MAIN_SECTION_TITLE_WELCOME_BACK: 'Que bom ver você!',
    MAIN_SECTION_TITLE_GREETING: 'Oi, **{{name}}**.',
    MAIN_SECTION_TITLE_WELCOME: 'Bem-vindo à Crehana!',
    LABEL_SIDEBAR_ITEM_COMEBACK: 'Voltar para meus produtos',
    LABEL_SIDEBAR_ITEM_COMEBACK_SHORT: 'Voltar',
    LABEL_SIDEBAR_ITEM_TITLE: 'E-Learning',
    LABEL_SIDEBAR_ITEM_START: 'Início',
    LABEL_SIDEBAR_ITEM_KNOWLEDGE_HUB: 'Catálogo',
    LABEL_SIDEBAR_ITEM_CONTENT_FOR_YOU: 'Mais conteúdo',
    LABEL_SIDEBAR_ITEM_MY_COURSES_SUBTITLE:
      'Explore suas trilhas designadas para aprendizado personalizado.',
    LABEL_SIDEBAR_ITEM_MY_COURSES: 'Meus cursos',
    LABEL_SIDEBAR_ITEM_MY_COURSES_SEPARATOR: 'APRENDIZAGEM E PROGRESSO',
    LABEL_SIDEBAR_ITEM_MY_COURSES_BREADCRUMB: 'Cursos',
    LABEL_SIDEBAR_ITEM_MY_COURSES_TITLE: 'Meus cursos',
    LABEL_SIDEBAR_ITEM_MY_COURSES_ENROLLED_MOBILE: 'Cursos',
    LABEL_SIDEBAR_ITEM_MY_COURSES_SAVED: 'Cursos salvos',
    LABEL_SIDEBAR_ITEM_MY_COURSES_TRACKS: 'Trilhas de aprendizagem',
    LABEL_SIDEBAR_ITEM_MY_COURSES_TRACKS_DETAIL:
      'Trajetórias de aprendizado de',
    LABEL_SIDEBAR_ITEM_MY_COURSES_LIVE_SESSIONS: 'Sessões ao vivo',
    LABEL_SIDEBAR_ITEM_MY_COURSES_NOTES: 'Anotações',
    LABEL_SIDEBAR_ITEM_MY_COURSES_CERTIFICATIONS: 'Certificados',
    LABEL_SIDEBAR_ITEM_MY_PROJECTS: 'Projetos',
    LABEL_SIDEBAR_ITEM_MY_STUDY_PLAN: 'Plano de estudos',
    LABEL_SIDEBAR_ITEM_SKILL_XP: 'Skill XP',
    LABEL_SIDEBAR_ITEM_SKILL_XP_EVOLUTION: 'Skill XP Evolution',
    LABEL_SIDEBAR_ITEM_SKILL_XP_EVOLUTION_PENDING: 'Testes pendentes',
    LABEL_SIDEBAR_ITEM_SKILL_XP_EVOLUTION_SOLVED: 'Testes resolvidos',
    LABEL_SIDEBAR_ITEM_SKILL_XP_SEPARATOR: 'AVALIAÇÃO E REALIZAÇÕES',
    LABEL_SIDEBAR_ITEM_MENTORS: 'Mentorias',
    LABEL_SIDEBAR_ITEM_BLOG: 'Blog',
    LABEL_SIDEBAR_ITEM_LIVES: 'Lives',
    LABEL_SIDEBAR_ITEM_TOOLS: 'Ferramentas',
    LABEL_SIDEBAR_ITEM_PLAYROOM_ACHIEVEMENTS: 'Logros',
    LABEL_SIDEBAR_ITEM_PLAYROOM_REWARDS: 'Recompensas',
    LABEL_SIDEBAR_ITEM_PLAYROOM_TAB: 'Gamificação',
    SHOW_MORE: 'Ver mais',
    B2B_CONTINUE_WITH_LAST_ACTION_TITLE: 'Continue de onde parou',
    GO_TO_MY_COURSES: 'Ir aos meus cursos',
    B2B_START_SECTION_MANDATORY_COURSES: 'Cursos obrigatórios',
    B2B_START_SECTION_MANDATORY_PROJECTS: 'Projetos obrigatórios',
    B2B_START_SECTION_GOTO_ROUTES: 'Ir às trilhas de aprendizagem',
    B2B_START_SECTION_GOTO_ROUTES_MOBILE: 'Ir a meu rotas',
    B2B_START_SECTION_YOUR_LEARNING_PATH: 'Suas trilhas de aprendizagem',
    CONTENT_FOR_YOU_SECTION_BREADCRUMB: 'Conteúdo para você',
    CONTENT_FOR_YOU_SECTION_DESCRIPTION:
      'Descubra novos cursos, rotas, projetos, artigos do blog e mais, segundo os seus interesses.',
    HITS_SECTION_MOBILE_TITLE: 'Crehana Hits',
    HITS_SECTION_TITLE:
      'Crehana Hits, aprenda com as suas celebridades favoritas',
    BLOG_SECTION_TITLE: 'Últimos artigos do blog',

    DOWNLOAD_FILTER: 'Tipo de certificação',
    DOWNLOAD_FILTER_ALL: 'Todos ({{count}})',
    DOWNLOAD_FILTER_DIPLOMA: 'Certificados ({{count}})',
    DOWNLOAD_FILTER_PROOF: 'Constâncias ({{count}})',
    DOWNLOAD_DIPLOMA: 'Certificado',
    DOWNLOAD_PROOF: 'PROVA',
    DOWNLOAD_DESCRIPTION_GENERAL:
      'No total, obteve {{diplomasCount}} certificados e {{certificateCount}} certificados de participação',

    SKILLXP_EVOLUTION_REMINDER_PENDINGS_TITLE:
      'Você tem {{count}} teste de habilidade para resolver',
    SKILLXP_EVOLUTION_REMINDER_PENDINGS_SUBTITLE:
      'Seu administrador está esperando que você resolva seus testes para continuar atribuindo seus caminhos de aprendizado.',
    SKILLXP_EVOLUTION_REMINDER_PENDINGS_DISMISS: 'Pular teste de habilidade',
    SKILLXP_EVOLUTION_REMINDER_PENDINGS_CONFIRM: 'Iniciar SkillXP',

    START_LEARNING_PATH: 'Iniciar a sua aprendizagem',
    START_LEARNING_UNASSIGNED_TITLE: 'Não crie seu currículo ainda',
    START_LEARNING_UNASSIGNED_SUBTITLE:
      'Organize sua semana para atingir seus objetivos de aprendizado',
    START_LEARNING_UNASSIGNED_CTA: 'Criar currículo',
    START_LEARNING_EDIT: 'Editar',
    START_LEARNING_LABEL_COMPLETED: '**Você conseguiu!** Continue assim!',
    START_LEARNING_LABEL_SUCCESS: 'O último esforço!',
    START_LEARNING_LABEL_WARNING: 'Não pare!',
    START_LEARNING_EMPTY_ASSIGNED:
      'Tudo o que resta é que os cursos sejam atribuídos a você!',
    START_LEARNING_EMPTY_MESSAGE:
      'Quando seu administrador fizer isso, você poderá ver seu progresso aqui',
    START_LEARNING_HEADER: 'Seu progresso',
    START_LEARNING_EMPTY_TITLE: 'É hora de começar!',
    START_LEARNING_CAN: 'Você pode fazer isso!',
    START_LEARNING_MESSAGE_OBJECTIVE: 'para concluir o objetivo semanal',
    START_LEARNING_GOTO_PROJECT: 'Ir para meus projetos',
    START_LEARNING_COURSE_TITLE: '{{count}} Curso a ser concluído',
    START_LEARNING_COURSE_TITLE_plural: '{{count}} Cursos a serem concluídos',
    START_LEARNING_ROUTE_TITLE: '{{count}} curso a ser concluído',
    START_LEARNING_ROUTE_TITLE_plural: '{{count}} Rotas a serem concluídas',
    START_LEARNING_PROJECT_TITLE: '{{count}} Projeto a ser concluído',
    START_LEARNING_PROJECT_TITLE_plural:
      '{{count}} Projetos a serem concluídos',
    START_LEARNING_COURSE_COMPLETED:
      'Você concluiu {{count}} de {{countTarget}} cursos',
    START_LEARNING_ROUTE_COMPLETED:
      'Você concluiu {{count}} de {{countTarget}} Routes',
    START_LEARNING_PROJECT_COMPLETED:
      'Você concluiu {{count}} de {{countTarget}} Projetos',
    START_LEARNING_TRACK: 'Seus cursos pendentes a serem desenvolvidos',
    START_LEARNING_SHOW_CONTENT: 'Mais conteúdo de seu interesse',
    START_LEARNING_SHOW_CATALOG: 'Veja o catálogo',
    START_LEARNING_ONLYPANEL_MESSAGE_TITLE:
      'Mergulhe em novas experiências de aprendizado!',
    START_LEARNING_ONLYPANEL_MESSAGE_SUBTITLE:
      'Aqui você descobrirá o conteúdo visto recentemente quando lhe for atribuído o seu primeiro curso',
    START_LAST_PLAY: 'Última reprodução',
    START_LEARNING_READY_COURSE: 'Iniciar curso',
    START_LEARNING_SHOW_MORE: 'Mais informações',
    UD_DURATION_LEFT: 'Tempo restante: {{duration}}',
    LABEL_SIDEBAR_BACKOUT: 'Retornar',

    LIVE_SESSION_SUBTITLE:
      'Aqui você pode ver todas as sessões que foram atribuídas a você',
  },
};

export default pt;
