import dynamic from 'next/dynamic';

const RevampListTracks = dynamic(() => import('../RevampListTrack'), {
  ssr: false,
});

const AcademiesLayout = () => {
  return <RevampListTracks />;
};

export default AcademiesLayout;
