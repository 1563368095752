import dynamic from 'next/dynamic';

import { LiveSessionsList } from '@crehana/compass.live-sessions';
import { Container } from '@crehana/ui';

const LazyLiveSessionsSection = dynamic(() => import('./LiveSessionsSection'), {
  loading: () => (
    <div className="tw-w-full tw-mb-8">
      <Container type={'dashboard'}>
        <LiveSessionsList
          sessions={[]}
          variant="past"
          error={false}
          onRefresh={() => {}}
          searchFilter={''}
          organizationSlug=""
          isAdmin={false}
          loading={true}
        />
      </Container>
    </div>
  ),
});

export default LazyLiveSessionsSection;
