import { FC } from 'react';

import ArrowRight from '@crehana/compass.icons.outline/ArrowRight';

import { useCrehanaTranslation } from '@/views/LearnDashboard/hooks';

import type { TProgressState } from '..';
import { redirectToEditStudyPlan } from '../../../utils/redirectToEditStudyPlan';
import Header from './Header';
import ProgressState from './ProgressState';

const EmptyWithoutCourseDesktop: FC<Omit<TProgressState, 'state'>> = ({
  progress,
  minProgress,
  minTarget,
}) => {
  const { t } = useCrehanaTranslation();
  return (
    <div
      className="tw-bg-neutral-light-100  tw-min-w-fit tw-p-2.4 tw-rounded-2"
      data-testid="plan_empty_without_tracks"
    >
      <div className="tw-flex tw-items-center tw-justify-between tw-mb-2">
        <Header className="tw-text-neutral-light-800" />
        <button
          onClick={redirectToEditStudyPlan}
          type="button"
          className="tw-text-link-m dark:tw-text-primary-light-500 tw-font-subtitle5"
        >
          {t('START_LEARNING_EDIT')}
          <ArrowRight size={20} className="tw-text-current" />
        </button>
      </div>
      <ProgressState
        state="empty"
        progress={progress}
        minProgress={minProgress}
        minTarget={minTarget}
      />
      <p className="tw-mt-2.4 tw-text-neutral-light-800 tw-font-subtitle1">
        {t('START_LEARNING_EMPTY_ASSIGNED')}
      </p>
      <p className="tw-mt-0.8 tw-text-neutral-light-800 tw-font-body3">
        {t('START_LEARNING_EMPTY_MESSAGE')}
      </p>
    </div>
  );
};

export default EmptyWithoutCourseDesktop;
