import { useFlagOrganization } from '@/shared/hooks';

export default function useHigherEducationHomeUserUI(slug?: string) {
  const { isActive, isLoading } = useFlagOrganization({
    flag: 'NEW_HIGHER_EDUCATION_HOME_USER',
    slug,
  });

  return isLoading || isActive;
}
