import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["addResourceBundle"];
import { useTranslation } from '@crehana/i18n';
import { COLLABORATOR_I18N_KEY, locales } from "../i18n/collaborator";
var useCollaboratorTranslation = function useCollaboratorTranslation() {
  var _useTranslation = useTranslation(COLLABORATOR_I18N_KEY),
    addResourceBundle = _useTranslation.addResourceBundle,
    translationContextValue = _objectWithoutProperties(_useTranslation, _excluded);
  addResourceBundle(locales);
  return translationContextValue;
};
export default useCollaboratorTranslation;