import { FC } from 'react';

import useTeamCoursesTranslation from '../../../hooks/useTeamCoursesTranslation';
import { TInformation } from '../../../types';
import Divider from './Divider';

const InformationTablet: FC<TInformation> = ({
  title,
  duration,
  labelTrack,
  isRequired,
}) => {
  const { t } = useTeamCoursesTranslation();

  return (
    <div className="tw-w-full sm:tw-my-auto">
      <div className="tw-flex tw-items-center tw-w-full">
        <p className="tw-line-clamp-1 tw-font-subtitle5 tw-mb-0.4 tw-text-neutral-light-800 dark:tw-text-neutral-light-100">
          {title}
        </p>
        <p className="tw-ml-0.8 tw-font-label1 tw-text-neutral-light-600 tw-uppercase tw-mr-0.8 tw-shrink-0 ">
          {labelTrack}
        </p>
      </div>

      <div className=" tw-mb-1 sm:tw-mb-0 tw-flex tw-items-center tw-w-full tw-text-neutral-light-700 dark:tw-text-neutral-dark-400 tw-font-body5">
        {isRequired && (
          <>
            <span className="tw-flex-shrink-0 tw-text-primary-light-500">
              {t('TAG_REQUIRED')}
            </span>
            {duration && <Divider />}
          </>
        )}
        {duration && (
          <span className="tw-flex-shrink-0 tw-mr-0.8">{duration}</span>
        )}
      </div>
    </div>
  );
};

export default InformationTablet;
