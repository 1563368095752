import dynamic from 'next/dynamic';

import SkillXPSkeleton from './components/SkillXPSkeleton';

const LazySkillXP = dynamic(() => import('./SkillXP'), {
  ssr: false,
  loading: () => <SkillXPSkeleton />,
});

export default LazySkillXP;
