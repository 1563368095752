/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { FC, RefObject, useMemo } from 'react';

import cn from 'clsx';

import { Drawer, Popper } from '@crehana/compass.ui';
import { useMediaQuery } from '@crehana/react-hooks';
import {
  addQueryParamToUrl,
  createUrlWithSourceQueryParams,
} from '@crehana/utils';

import useTeamCoursesTranslation from '../../../hooks/useTeamCoursesTranslation';
import { TCoursesTrack, TMenuOptionCoursesOwn } from '../../../types';

type TOption = {
  isOpen: boolean;
  setIsOpen: () => void;
  onOpenTrailer?: () => void;
  refPopper: RefObject<HTMLButtonElement>;
  dataCourse: TCoursesTrack;
};

const Option: FC<TOption> = ({
  isOpen: isOpenDrawer,
  setIsOpen,
  refPopper,
  dataCourse,
  onOpenTrailer,
}) => {
  const { t } = useTeamCoursesTranslation();
  const { matches: isMd } = useMediaQuery({ from: 'md' });

  const OPTION_COURSES_OWN_DEFAULT: TMenuOptionCoursesOwn[] = [
    {
      label: 'LEARN_TEAM_TRACK_OPTIONAL_MENU_START_CURSO',
      onClick: () => {
        window.location.href = addQueryParamToUrl({
          url: createUrlWithSourceQueryParams({
            url: dataCourse.lastVideoLectureUrl || '',
            source: 'Learn Dashboard',
            sourceDetail: 'B2B Tracks',
          }),
          key: 'Enrollment Origin',
          value: 'B2B Tracks',
        });
      },
    },
  ];

  const OPTION_COURSES_OWN_DESKTOP = useMemo(() => {
    let NEW_OPTION_COURSES_OWN_DEFAULT: TMenuOptionCoursesOwn[] = [];
    if (dataCourse.hasQuiz) {
      NEW_OPTION_COURSES_OWN_DEFAULT = [
        ...NEW_OPTION_COURSES_OWN_DEFAULT,
        {
          label: 'LEARN_TEAM_TRACK_OPTIONAL_MENU_START_EVALUATION',
          onClick: () => {
            if (dataCourse.hasQuiz) {
              window.location.href = `/clases/v2/${dataCourse.courseId}/player/examen-final/`;
            }
          },
        },
      ];
    }

    NEW_OPTION_COURSES_OWN_DEFAULT = [
      ...NEW_OPTION_COURSES_OWN_DEFAULT,
      {
        label: 'LEARN_TEAM_TRACK_OPTIONAL_MENU_START_PROJECT',
        onClick: () => {
          window.location.href = `/clases/v2/${dataCourse.courseId}/`;
        },
      },
    ];

    return [...OPTION_COURSES_OWN_DEFAULT, ...NEW_OPTION_COURSES_OWN_DEFAULT];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCourse]);

  const OPTION_COURSES_OWN_RESPONSIVE: TMenuOptionCoursesOwn[] = [
    ...OPTION_COURSES_OWN_DESKTOP,
    {
      label: 'LEARN_TEAM_TRACK_OPTIONAL_MENU_VIEW_TRAILER',
      onClick: () => {
        onOpenTrailer && onOpenTrailer();
        setIsOpen();
      },
    },
    {
      label: 'LEARN_TEAM_TRACK_OPTIONAL_MENU_GO_BACK',
      onClick: setIsOpen,
    },
  ];

  const returnItemDesktop = () => (
    <ul className="tw-font-subtitle3 md:tw-font-body5 md:tw-font-normal sm:tw-text-center tw-text-neutral-light-800 dark:tw-text-neutral-light-100 tw-min-w-16.4 tw-rounded-1.2">
      {OPTION_COURSES_OWN_DESKTOP.map(({ label, onClick }, i) => (
        <li
          key={`option_menu_${i * 1}`}
          className={cn(
            'tw-cursor-pointer tw-p-1.2 hover:tw-bg-neutral-light-200',
            i < OPTION_COURSES_OWN_DESKTOP.length - 1 &&
              'tw-border-b tw-border-neutral-light-400 tw-border-solid',
          )}
          aria-label={label}
          onClick={onClick}
          onKeyDown={onClick}
        >
          <span className="dark:tw-text-neutral-light-800">{t(label)}</span>
        </li>
      ))}
    </ul>
  );

  const returnItemResponsive = () => (
    <ul className="tw-font-subtitle3 md:tw-font-body5 md:tw-font-normal tw-text-neutral-light-800 dark:tw-text-neutral-light-100 tw-min-w-16.4">
      {OPTION_COURSES_OWN_RESPONSIVE.map(({ label, onClick }, i) => (
        <li
          key={`option_menu_${i * 1}`}
          className={cn(
            'tw-cursor-pointer tw-p-2.4 hover:tw-bg-neutral-light-200 dark:hover:tw-bg-neutral-dark-800 tw-flex tw-justify-start xs:tw-justify-center tw-content-center',
            i < OPTION_COURSES_OWN_RESPONSIVE.length - 1 &&
              'tw-border-b tw-border-neutral-light-400 tw-border-solid',
          )}
          aria-label={label}
          onClick={onClick}
          onKeyDown={onClick}
        >
          <span className="dark:tw-text-neutral-light-100">{t(label)}</span>
        </li>
      ))}
    </ul>
  );

  return isMd ? (
    <Popper
      anchorRef={refPopper}
      isVisible={isOpenDrawer}
      onDismiss={setIsOpen}
      position="bottom"
      dismissOnClickOutside
      arrowProps={{ triggerPosition: 'center' }}
      className="dark:tw-bg-neutral-light-100 dark:tw-text-neutral-light-800 tw-overflow-hidden"
    >
      {returnItemDesktop()}
    </Popper>
  ) : (
    <Drawer
      open={isOpenDrawer}
      onDismiss={setIsOpen}
      position="bottom"
      className="tw-flex tw-flex-col tw-flex-no-wrap tw-h-full"
      width={{ xs: '100%', sm: '100%', md: '375px', lg: '375px' }}
      backdropStyles={{
        background: '#292C4766',
        backdropFilter: 'blur(4px)',
      }}
    >
      {returnItemResponsive()}
    </Drawer>
  );
};

export default Option;
