import Lock from '@crehana/compass.icons.fill/Lock';

import useTeamCoursesTranslation from '../../hooks/useTeamCoursesTranslation/useTeamCoursesTranslation';

const CardTrackDisabled = () => {
  const { t } = useTeamCoursesTranslation();
  return (
    <div className="tw-bg-on-hold-100 tw-p-2 tw-rounded-1">
      <div className="tw-bg-on-hold-400 tw-flex tw-h-2.4 tw-items-center tw-justify-center tw-mb-1 tw-rounded-1 tw-w-3.2">
        <Lock className="tw-text-neutral-light-100 " size={16} />
      </div>
      <p className="tw-font-body5 tw-text-on-hold-500">
        {t('LEARN_TEAM_HEADER_DETAIL_DISABLED_DESCRIPTION')}
      </p>
    </div>
  );
};

export default CardTrackDisabled;
