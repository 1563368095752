import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
var _excluded = ["disabled", "onClick"],
  _excluded2 = ["style", "onTransitionEnd", "refKey"];
import { useCallback, useMemo, useRef, useState } from 'react';
import useEffectAfterMount from "./useEffectAfterMount";
import useOpenState from "./useOpenState";
import useUniqueId from "./useUniqueId";
// eslint-disable-next-line @typescript-eslint/no-empty-function
var noop = function noop() {};
function getElementHeight(el) {
  if (!el || !el.current) {
    return 'auto';
  }
  return el.current.scrollHeight;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any

// Helper function for render props. Sets a function to be called, plus any additional functions passed in
var callAll = function callAll() {
  for (var _len = arguments.length, fns = new Array(_len), _key = 0; _key < _len; _key++) {
    fns[_key] = arguments[_key];
  }
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function () {
      for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        args[_key2] = arguments[_key2];
      }
      return fns.forEach(function (fn) {
        return fn && fn.apply(void 0, args);
      });
    }
  );
};
var defaultTransitionStyles = {
  transitionDuration: '500ms',
  transitionTimingFunction: 'cubic-bezier(0.250, 0.460, 0.450, 0.940)'
};
function joinTransitionProperties(string) {
  if (string) {
    var styles = ['height'];
    styles.push.apply(styles, _toConsumableArray(string.split(', ')));
    return styles.join(', ');
  }
  return 'height';
}
function makeTransitionStyles(_ref) {
  var _ref$expandStyles = _ref.expandStyles,
    expandStyles = _ref$expandStyles === void 0 ? defaultTransitionStyles : _ref$expandStyles,
    _ref$collapseStyles = _ref.collapseStyles,
    collapseStyles = _ref$collapseStyles === void 0 ? defaultTransitionStyles : _ref$collapseStyles;
  return {
    expandStyles: _objectSpread({
      transitionProperty: joinTransitionProperties(expandStyles.transitionProperty)
    }, expandStyles),
    collapseStyles: _objectSpread({
      transitionProperty: joinTransitionProperties(collapseStyles.transitionProperty)
    }, collapseStyles)
  };
}
function useCollapse() {
  var initialConfig = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var uniqueId = useUniqueId();
  var el = useRef(null);
  var _useOpenState = useOpenState(initialConfig),
    _useOpenState2 = _slicedToArray(_useOpenState, 2),
    isOpen = _useOpenState2[0],
    setOpen = _useOpenState2[1];
  var collapsedHeight = "".concat(initialConfig.collapsedHeight || 0, "px");
  var collapsedStyles = useMemo(function () {
    return {
      display: collapsedHeight === '0px' ? 'none' : 'block',
      height: collapsedHeight,
      overflow: 'hidden'
    };
  }, [collapsedHeight]);
  var _useMemo = useMemo(function () {
      return makeTransitionStyles(initialConfig);
    }, [initialConfig]),
    expandStyles = _useMemo.expandStyles,
    collapseStyles = _useMemo.collapseStyles;
  var _useState = useState(isOpen ? {} : collapsedStyles),
    _useState2 = _slicedToArray(_useState, 2),
    styles = _useState2[0],
    setStyles = _useState2[1];
  var _useState3 = useState(isOpen),
    _useState4 = _slicedToArray(_useState3, 2),
    mountChildren = _useState4[0],
    setMountChildren = _useState4[1];
  var toggleOpen = useCallback(function () {
    return setOpen(function (oldOpen) {
      return !oldOpen;
    });
  }, []);
  useEffectAfterMount(function () {
    var animationId;
    var secondAnimationId;
    if (isOpen) {
      if (initialConfig.onOpenCallback) {
        initialConfig.onOpenCallback();
      }
      animationId = requestAnimationFrame(function () {
        setMountChildren(true);
        setStyles(function (oldStyles) {
          return _objectSpread(_objectSpread(_objectSpread({}, oldStyles), expandStyles), {}, {
            willChange: 'height',
            display: 'block',
            overflow: 'hidden'
          });
        });
        secondAnimationId = requestAnimationFrame(function () {
          var height = getElementHeight(el);
          setStyles(function (oldStyles) {
            return _objectSpread(_objectSpread({}, oldStyles), {}, {
              willChange: 'unset',
              height: height
            });
          });
        });
      });
    } else {
      if (initialConfig.onCloseCallback) {
        initialConfig.onCloseCallback();
      }
      animationId = requestAnimationFrame(function () {
        var height = getElementHeight(el);
        setStyles(function (oldStyles) {
          return _objectSpread(_objectSpread(_objectSpread({}, oldStyles), collapseStyles), {}, {
            willChange: 'height',
            height: height
          });
        });
        secondAnimationId = requestAnimationFrame(function () {
          setStyles(function (oldStyles) {
            return _objectSpread(_objectSpread({}, oldStyles), {}, {
              willChange: 'unset',
              height: collapsedHeight,
              overflow: 'hidden'
            });
          });
        });
      });
    }
    return function () {
      if (animationId) cancelAnimationFrame(animationId);
      if (secondAnimationId) cancelAnimationFrame(secondAnimationId);
    };
  }, [isOpen]);
  var handleTransitionEnd = function handleTransitionEnd(e) {
    // Sometimes onTransitionEnd is triggered by another transition,
    // such as a nested collapse panel transitioning. But we only
    // want to handle this if this component's element is transitioning
    if (e.target !== el.current) {
      return;
    }

    // The height comparisons below are a final check before completing the transition
    // Sometimes this callback is run even though we've already begun transitioning the other direction
    // The conditions give us the opportunity to bail out, which will prevent the collapsed content from flashing on the screen
    if (isOpen) {
      var height = getElementHeight(el);
      // If the height at the end of the transition matches the height we're animating to,
      // it's safe to clear all style overrides

      if (height === styles.height) {
        setStyles({});
      } else {
        // If the heights don't match, this could be due the height of the content changing mid-transition
        // If that's the case, re-trigger the animation to animate to the new height
        setStyles(function (oldStyles) {
          return _objectSpread(_objectSpread({}, oldStyles), {}, {
            height: height
          });
        });
      }
      // If the height we should be animating to matches the collapsed height,
      // it's safe to apply the collapsed overrides
    } else if (styles.height === collapsedHeight) {
      setMountChildren(false);
      setStyles(collapsedStyles);
    }
  };
  return {
    getToggleProps: function getToggleProps() {
      var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var _props$disabled = props.disabled,
        disabled = _props$disabled === void 0 ? false : _props$disabled,
        _props$onClick = props.onClick,
        onClick = _props$onClick === void 0 ? noop : _props$onClick,
        rest = _objectWithoutProperties(props, _excluded);
      return _objectSpread(_objectSpread({
        role: 'button',
        id: "react-collapsed-toggle-".concat(uniqueId),
        'aria-controls': "react-collapsed-panel-".concat(uniqueId),
        'aria-expanded': isOpen ? 'true' : 'false',
        tabIndex: 0
      }, rest), {}, {
        onClick: disabled ? noop : callAll(onClick, toggleOpen)
      });
    },
    getCollapseProps: function getCollapseProps() {
      var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var _props$style = props.style,
        style = _props$style === void 0 ? {} : _props$style,
        _props$onTransitionEn = props.onTransitionEnd,
        onTransitionEnd = _props$onTransitionEn === void 0 ? noop : _props$onTransitionEn,
        _props$refKey = props.refKey,
        refKey = _props$refKey === void 0 ? 'ref' : _props$refKey,
        rest = _objectWithoutProperties(props, _excluded2);
      return _objectSpread(_objectSpread({
        id: "react-collapsed-panel-".concat(uniqueId),
        'aria-hidden': isOpen ? undefined : 'true'
      }, rest), {}, _defineProperty(_defineProperty(_defineProperty({}, refKey, el), "onTransitionEnd", callAll(handleTransitionEnd, onTransitionEnd)), "style", _objectSpread(_objectSpread(_objectSpread({}, defaultTransitionStyles), style), {}, {
        // combine any additional transition properties with height
        transitionProperty: joinTransitionProperties(style.transitionProperty)
      }, styles)));
    },
    isOpen: isOpen,
    toggleOpen: toggleOpen,
    mountChildren: mountChildren
  };
}
export default useCollapse;