import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useState } from 'react';

// Unique ID implementation borrowed from React UI :)
// https://github.com/reach/reach-ui/blob/6e9dbcf716d5c9a3420e062e5bac1ac4671d01cb/packages/auto-id/src/index.js
var idCounter = 0;
var genId = function genId() {
  idCounter += 1;
  return idCounter;
};
function useUniqueId() {
  var _useState = useState(null),
    _useState2 = _slicedToArray(_useState, 2),
    id = _useState2[0],
    setId = _useState2[1];
  useEffect(function () {
    return setId(genId());
  }, []);
  return id;
}
export default useUniqueId;