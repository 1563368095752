import { B2B_TEAM_COURSES } from '../constants';

const translationsES = {
  [B2B_TEAM_COURSES]: {
    EMPTY_SUBTITLE:
      'Comunícate con tu administrador para que te asigne una ruta.',
    EMPTY_SUBTITLE_B2B_ALL_CATALOG:
      'Descubre nuestro amplio catálogo mientras tu administrador te asigna una.',
    ASSIGNED_TRACK:
      'Aún no tienes nada asignado. Pronto podrás iniciar tu experiencia de aprendizaje',
    ASSIGNED_TRACK_B2B_ALL_CATALOG:
      'Aún no te asignan rutas, pero ya puedes iniciar tu experiencia de aprendizaje',
    EXPIRATION_DATE: 'FECHA DE ENTREGA:',
    STUDENTS: 'Estudiantes',
    END_DATE: 'Fecha final {{finalDate}}',
    TITLE_MINIMUM_REQUIREMENTS:
      'Requisitos para culminar la ruta de aprendizaje.',
    CTA_START_COURSE: 'Empezar Curso',
    CTA_CONTINUE_PATH: 'Continuar ruta',
    REWARDS_TITLE: '¿Qué son mis Rewards?',
    REWARDS_SUBTITLE: 'Conoce más',
    REWARDS_COPY1:
      'Los Rewards son \n bonificaciones de estudio \n para acceder a cursos \n libres.',
    REWARDS_COPY2:
      'Al terminar tu ruta de \n aprendizaje podrás \n escoger tus cursos \n favoritos.',
    REWARDS_COPY3:
      'Tendrás a tu disposición \n todos los cursos \n del catálogo de Crehana.',
    REWARDS_CTA: 'Entendido',
    REWARDS_UNLOKED: 'rewards desbloqueados',
    REWARDS_INFO:
      'Luego de completar tu training, has desbloqueado {{totalCourses}} curso(s) adicional(es) en Crehana a tu elección.',
    ENROLLED_PATH_COURSE_PROGRESS_LABEL: '{{progress}}% clases vistas',
    ENROLLED_PATH_COURSE_PROJECT_LABEL: 'Proyecto final:',
    SKILL_XP_CARD_RESULTS_CTA: 'Ver Resultado',
    SKILL_XP_CARD_PENDING_CTA: 'Tomar Skill XP',
    PROJECT_DIALOG_TITLE:
      'Selecciona un curso del cuál quieres realizar el proyecto',
    PROJECT_DIALOG_DESCRIPTION:
      'Recuerda que para obtener tu certificado de ruta es obligatorio solo realizar 1 proyecto del curso que elijas. **¿Qué pasa si quieres hacerlos todos?** Puedes hacerlo, cada proyecto será evaluado por un mentor y recibirás feedback.',
    PROJECT_DIALOG_DESCRIPTION_MOBILE:
      'Recuerda que para obtener tu diploma de ruta es obligatorio realizar al menos 1 proyecto final a elección.',
    PROJECT_DIALOG_COURSES_TITLE: 'Cursos sin iniciar proyecto',
    PROJECT_DIALOG_DELIVERABLE_TITLE: 'Entregable del curso',
    PROJECT_TYPE_CERTIFIED: 'Certificado',
    PROJECT_TYPE_DRAFT: 'Pendiente',
    PROJECT_TYPE_FINISHED: 'Finalizado',
    PROJECT_TYPE_OBSERVED: 'Con observaciones',
    PROJECT_TYPE_PUBLISHED: 'En revisión',
    PROJECT_TYPE_RESOLVED: 'Pendiente',
    PROJECT_DIALOG_SELECT_COURSE_PLACEHOLDER:
      'Selecciona un curso para ver el entregable aquí.',
    PROJECT_DIALOG_PENDING_PROJECT_SUBTITLE: 'Último proyecto en curso',

    LEARN_ENROLLED_PATH_PANEL_COURSES_TITLE:
      'Elige tu proyecto final y completa la ruta de aprendizaje.',
    LEARN_ENROLLED_PATH_PANEL_COURSES_TITLE__plural:
      'Elige {{count}} cursos para realizar los proyectos finales y completa la ruta.',
    LEARN_ENROLLED_PATH_PANEL_PENDING_PROJECT_TITLE:
      'Continua tu proyecto final y certifícate en la ruta de aprendizaje.',
    ENROLLED_PATH_START_PROJECT_LABEL: 'Inicia proyecto',
    ENROLLED_PATH_CONTINUE_PROJECT_LABEL: 'Continuer proyecto',
    ENROLLED_PATH_START_DOWNLOAD_CERTIFICATE: 'Descargar diploma',
    REWARD_CARD_TRACK_IN_PROGRESS_TITLE:
      '¡Gran trabajo hasta ahora, {{username}}!',
    REWARD_CARD_TRACK_IN_PROGRESS_SUBTITLE:
      'Continúa completando tu ruta de estudio para que puedas escoger cursos libres de tu interés.',
    REWARD_CARD_TRACK_IN_PROGRESS_CTA: 'Conocer más',
    REWARD_CARD_TRACK_NOT_STARTED_TITLE:
      '¡Tienes {{count}} cursos libres a tu elección!',
    REWARD_CARD_TRACK_NOT_STARTED_SUBTITLE:
      'Completa tu ruta de aprendizaje para escoger {{count}} cursos libres de tu interés.',
    REWARD_CARD_TRACK_NOT_STARTED_CTA: 'Conocer más',
    REWARD_CARD_TRACK_COMPLETED_TITLE: '¡Gran trabajo, {{username}}!',
    REWARD_CARD_TRACK_COMPLETED_SUBTITLE:
      'Ahora que completaste tu ruta de estudio podrás acceder a tus cursos favoritos.',
    REWARD_CARD_TRACK_COMPLETED_CTA: 'Elegir mis cursos',
    STATUS_ITEM_IN_PROGRESS: 'En progreso',
    STATUS_ITEM_MANDATORY_COURSES: 'Obligatorios',
    STATUS_ITEM_MANDATORY_PROJECTS: 'Proyecto',
    STATUS_ITEM_MANDATORY_PROJECTS_plural: 'Proyectos',
    WARNING_DAYS_REMAINING: 'Falta {{count}} día',
    WARNING_DAYS_REMAINING_plural: 'Faltan {{count}} días',
    WARNING_DAYS_DUE: 'Retraso de {{count}} día',
    WARNING_DAYS_DUE_plural: 'Retraso {{count}} días',
    COMPLETE_ALL_COURSES_MESSAGE_TITLE: '¿Cómo certificarte?',
    COMPLETE_ALL_COURSES_MESSAGE:
      'Debes completar la visualización al 100% de la cantidad de cursos obligatorios y aprobar la cantidad de los proyectos obligatorios.',
    LEARNING_PATHS_LEFT_SIDE_TITLE: 'Ruta de aprendizaje',
    LEARNING_PATHS_LEFT_SIDE_TITLE_plural: 'Rutas de aprendizaje',
    LEARNING_TEAM_TRACK_CONTENT_FOR_YOU: 'Ver Contenido para ti',
    LEARNING_TEAM_TRACK_CONTENT_TRACK: 'Contenido de la ruta',
    LEARN_TEAM_TRACK_COMPLETE_SKILLPATH: 'Completa el Skill XP',
    LEARN_TEAM_TRACK_X_ASSIGNED_COURSES: '{{count}} Asignado',
    LEARN_TEAM_TRACK_X_ASSIGNED_COURSES_plural: '{{count}} Asignados',
    LEARN_TEAM_TRACK_X_MANDATORY_COURSES: '{{count}} Obligatorio',
    LEARN_TEAM_TRACK_X_MANDATORY_COURSES_plural: '{{count}} Obligatorios',
    LEARN_TEAM_TRACK_X_MANDATORY_PROJECTS: '{{count}} Proyecto',
    LEARN_TEAM_TRACK_X_MANDATORY_PROJECTS_plural: '{{count}} Proyectos',
    LEARN_TEAM_TRACK_COMPLETED: 'Completada',
    LEARN_TEAM_TRACK_REWARD_QUANTITY: 'Ganaste',
    LEARN_TEAM_TRACK_PROJECT_LABEL: 'Proyecto',
    LEARN_TEAM_SKIP: 'Ahora no',
    LEARN_TEAM_EXTERNAL: 'Contenido externo',
    LEARN_TEAM_EXTERNAL_ASK: '¿Deseas marcar el contenido como visto?',
    LEARN_TEAM_TRACK_SUCCESS: '¡Completaste la ruta de aprendizaje!',
    LEARN_TEAM_TRACK_SUCCESS_LABEL: 'Obtuviste',
    LEARN_TEAM_TRACK_TOOLTIP_BLOCK:
      'Debes completar los cursos anteriores para ver estos',
    LEARN_TEAM_TRACK_BLOCK_DESCRIPTION:
      'Para poder iniciar este curso deberás ver previamente los siguientes:',
    LEARN_TEAM_TRACK_MANDATORY_LABEL: 'Curso Obligatorio',
    LEARN_TEAM_TRACK_OPTIONAL_LABEL: 'Curso Opcional',
    LEARN_TEAM_TRACK_OPTIONAL_LABEL_SHORT: 'Opcional',
    LEARN_TEAM_TRACK_OPTIONAL_MENU_START_CURSO: 'Iniciar curso',
    LEARN_TEAM_TRACK_MEDIUM: '¡Vienes muy bien, sigue así!',
    LEARN_TEAM_TRACK_OPTIONAL_MENU_START_EVALUATION: 'Iniciar evaluación',
    LEARN_TEAM_TRACK_OPTIONAL_MENU_START_PROJECT: 'Iniciar proyecto',
    LEARN_TEAM_TRACK_OPTIONAL_MENU_VIEW_TRAILER: 'Ver trailer',
    LEARN_TEAM_TRACK_OPTIONAL_MENU_GO_BACK: 'Volver atrás',
    LEARN_TEAM_TRACK_OPTIONAL_MENU_OPEN_CONTENT: 'Abrir contenido',
    LEARN_TEAM_TRACK_OPTIONAL_MENU_MARK_AS_WATCHED: 'Marcar como visto',
    LEARN_TEAM_TRACK_CUSTOM_LABEL: 'Personalizado',
    LEARN_TEAM_TRACK_SHOW_PROJECT: 'Mostrar proyecto',
    LEARN_TEAM_TRACK_CONTINUE_PROJECT: 'Continuar proyecto',
    LEARN_TEAM_TRACK_CHOOSE_MISSING_PROJECTS: 'Elegir proyectos faltantes',
    LEARN_TEAM_TRACK_START_PROJECT: 'Iniciar proyecto final',
    LEARN_TEAM_TRACK_ACADEMY_TAG: 'Academia',
    LEARN_TEAM_TRACK_COURSE_CARD_ARIA_LABEL_CTA: 'Continuar curso',
    LEARN_TEAM_SEE_LESS: 'Ver menos cursos',
    LEARN_TEAM_MORE: 'Ver más',
    LEARN_TEAM_HELP:
      'Completa tu ruta de aprendizaje y podrás escoger *{{courseCount}} cursos libres Crehana* de tu interés.',
    LEARN_TEAM_SEE_MORE: 'Ver más cursos de la ruta',
    LEARN_TEAM_STATE_INTEGRATION: 'Marcar como visto',
    COURSE_CARD_COURSE_REQUIRED: 'curso{{s}} requerido{{s}}',
    COURSE_CARD_REQUIREMENTS_POPUP_TITLE: 'Cursos requeridos',
    COURSE_CARD_REQUIREMENTS_POPUP_SUBTITLE:
      'Para poder iniciar este curso deberás ver previamente los siguientes:',
    COURSE_CARD_REQUIREMENTS_POPUP_CTA: 'Entendido',
    LEARN_TEAM_HELP_ONE:
      'Los Rewards son bonificaciones de estudio para acceder a cursos libres.',
    LEARN_TEAM_HELP_TWO:
      'Al terminar tu ruta de aprendizaje podrás escoger tus cursos favoritos.',
    LEARN_TEAM_HELP_THREE:
      'Tendrás a tu disposición todos los cursos del catálogo de Crehana',
    LEARN_TEAM_HEADER_OPTION_ALL: 'Todos',
    LEARN_TEAM_HEADER_OPTION_DEFEATED: 'Por vencer',
    LEARN_TEAM_HEADER_OPTION_EXPIRED: 'Vencidas',
    LEARN_TEAM_HEADER_OPTION_DISABLED: 'Deshabilitadas',
    LEARN_TEAM_HEADER_OPTION_COMPLETEDS: 'Completadas',
    LEARN_TEAM_HEADER_SEARCH: 'Buscar por título de ruta',
    LEARN_TEAM_EMPTY_TITLE: 'No tienes rutas asignadas',
    LEARN_TEAM_EMPTY_FILTER_NONE: 'Intenta con otro nombre',
    LEARN_TEAM_HEADER_FILTER: 'FILTROS',
    LEARN_TEAM_HEADER_DETAIL_DEFEATED: 'Vence',
    LEARN_TEAM_HEADER_DETAIL_EXPIRED: 'Venció',
    LEARN_TEAM_HEADER_DETAIL_ASSIGNED: 'Asignado por',
    LEARN_TEAM_HEADER_DETAIL_REWARD: 'Reward disponible',
    LEARN_TEAM_HEADER_DETAIL_REWARD_MOBILE: 'Reward',
    LEARN_TEAM_OPTION: 'Más acciones',
    LEARN_TEAM_TIME: 'Tiempo restante: {{duration}}',
    LEARN_TEAM_TIME_DURATION: 'Duración del curso: {{duration}}',
    LEARN_TEAM_COURSE_REQUIRED_LABEL: 'Visualizar curso',
    LEARN_TEAM_QUIZ_REQUIRED_LABEL: 'Aprobar evaluación',
    LEARN_TEAM_PROJECT_REQUIRED_LABEL: 'Aprobar proyecto',
    LEARN_TEAM_HEADER_DETAIL_DISABLED: 'Ruta deshabilitada',
    LEARN_TEAM_CRITERIA: 'Criterios de certificación',
    LEARN_TEAM_CRITERIA_COURSE_MANDATORY:
      'Total de cursos que deberás completar obligatoriamente para terminar una ruta.',
    LEARN_TEAM_CRITERIA_COMPLETE: 'Completa',
    LEARN_TEAM_CRITERIA_COURSE_SHORT: 'Curso obligatorio',
    LEARN_TEAM_CRITERIA_COURSE_SHORT_plural: 'Cursos obligatorios',
    LEARN_TEAM_CRITERIA_QUIZ_MANDATORY:
      'Cantidad de evaluaciones que faltan aprobara para culminar la ruta',
    LEARN_TEAM_CRITERIA_QUIZ_SHORT_plural: 'evaluaciones',
    LEARN_TEAM_CRITERIA_QUIZ_SHORT: 'evaluación',
    LEARN_TEAM_CRITERIA_APPROVES: 'Aprueba',
    LEARN_TEAM_CRITERIA_PROJECT_MANDATORY:
      'Completa tu ruta y certifícate con {{count}} proyectos de tu elección.',
    LEARN_TEAM_CRITERIA_PROJECT_SHORT: 'Proyecto',
    LEARN_TEAM_CRITERIA_PROJECT_SHORT_plural: 'Proyectos',
    LEARN_TEAM_HEADER_DETAIL_DISABLED_DESCRIPTION:
      'Esta ruta fue deshabilitada por tu administrador.',
    LEARN_TEAM_HEADER_DETAIL_SUCESS: 'RUTA COMPLETADA',
    LABEL_SIDEBAR_ITEM_MY_COURSES_SUBTITLE:
      'Explora tus rutas asignadas para un aprendizaje personalizado.',
    LABEL_SIDEBAR_ITEM_MY_COURSES_SUBTITLE_DESKTOP:
      'Explora tus rutas asignadas para un aprendizaje personalizado. ¡Comienza hoy mismo y crece en tu carrera profesional!',
    LEARN_SKILL_PATH_SUMMARY_COURSE: 'Cursos',
    LEARN_SKILL_PATH_SUMMARY_QUIZ: 'Evaluaciones',
    LEARN_SKILL_PATH_SUMMARY_PROJECTS: 'Proyectos',
    LEARN_CARD_REWARD_TITLE_COMPLETED_NEW:
      '¡Felicidades! Ahora tienes *{{courseCount}} rewards* que podrás canjear por cursos de tu elección.',
    LEARN_CARD_REWARD_TITLE_TAKEN_NEW:
      'Has canjeado todos los rewards disponibles en esta ruta.',
    LEARN_TEXT_DURATION: 'Duración del curso:',
    LEARN_TITLE_REWARDS_CLAIMEDS: 'Rewards canjeados',
    LEARN_SUBTITLE_REWARDS_CLAIMEDS:
      'Recuerda que podrás encontrar tus cursos canjeados en la pestaña de Mis cursos.',
    LEARN_TEAM_HEADER_DETAIL_REWARD_IN_PROGRESS: 'Rewards',
    LEARN_TEAM_HEADER_DETAIL_REWARD_TAKEN: 'Reward canjeados',
    LEARN_TEXT_LINK: 'Link',
    LEARN_TEXT_EXTERNAL_LINK: 'Abrir contenido',
    LEARN_TEXT_COMPLETED_CRITERIAS:
      'Has completado todos los criterios de certificación',
    LEARN_DOWNLOAD_CERTIFICATION: 'Descargar certificado',
    TITLE_ERROR_VIEW_DETAIL_TRACK: 'Ocurrió un problema',
    SUBTITLE_ERROR_VIEW_DETAIL_TRACK:
      'Lo sentimos, ocurrió un error al cargar la página. Actualiza para resolverlo. Si persiste, contacta al servicio al cliente por chat o intenta nuevamente más tarde.',
    RELOAD_PAGE_ERROR: 'Recargar página',
    TAG_REQUIRED: 'Obligatorio',
  },
};

export default translationsES;
