import { useQuery } from '@apollo/client';

import { notEmpty } from '@crehana/utils';

import GET_HOME_USER_COURSES_WITHOUT_TRACK from '../graphql/GetHomeUserCoursesWithoutTrack.graphql';
import type {
  GetHomeUserCoursesWithoutTrack,
  GetHomeUserCoursesWithoutTrackVariables,
} from '../graphql/types/GetHomeUserCoursesWithoutTrack';

const ITEMS_PER_PAGE = 10;

const useGetCoursesWithoutTrack = () => {
  const { data, loading, error } = useQuery<
    GetHomeUserCoursesWithoutTrack,
    GetHomeUserCoursesWithoutTrackVariables
  >(GET_HOME_USER_COURSES_WITHOUT_TRACK, {
    variables: {
      first: ITEMS_PER_PAGE,
      withoutTrackCourses: true,
    },
  });

  const coursesWithoutTrack = (
    data?.me?.enrollmentSet?.edges.map(el => el?.node?.course) || []
  ).filter(notEmpty);

  return { coursesWithoutTrack, loading, error };
};

export default useGetCoursesWithoutTrack;
