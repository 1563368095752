import { useQuery } from '@apollo/client';

import GET_FEATURE_FLAG from '../graphql/LXGetFlagOrganization.v4.b2b.graphql';
import type {
  LXGetFlagOrganization,
  LXGetFlagOrganizationVariables,
} from '../graphql/types/LXGetFlagOrganization';

function useIsNewSidebarEnabled(organizationSlug?: string) {
  const { data, loading, called, error } = useQuery<
    LXGetFlagOrganization,
    LXGetFlagOrganizationVariables
  >(GET_FEATURE_FLAG, {
    variables: { slug: organizationSlug },
    skip: !organizationSlug,
    context: {
      clientName: 'v4.b2b',
    },
  });

  const isSidebarV2Enabled = data?.b2bOrganizationCurrent?.features?.find(
    feature => feature?.slug === 'new-sidebar-v2',
  );

  return {
    isSidebarV2Enabled: !!isSidebarV2Enabled,
    loading,
    called,
    error,
  };
}

export default useIsNewSidebarEnabled;
