import { Suspense } from 'react';

import dynamic from 'next/dynamic';

import AcademiesSkeleton from './components/AcademiesSkeleton';

const LazyAcademies = dynamic(
  () =>
    import(/* webpackChunkName: "learn-dashboard-academies" */ './Academies'),
);

const Academies = () => {
  return (
    <Suspense fallback={<AcademiesSkeleton />}>
      <LazyAcademies />
    </Suspense>
  );
};

export default Academies;
