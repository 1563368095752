import { Fragment, useCallback, useState } from 'react';

import { useMutation } from '@apollo/client';

import { Col, Container, Pagination, Row, Snackbar } from '@crehana/ui';
import { useUITheme } from '@crehana/web';

import { TaskUserStatusEnum } from '@/shared/types/graphql/globals.v4.b2b';

import PhaseTransitionDialog from './components/PhaseTransitionDialog';
import TaskDescriptionDialog from './components/TaskDescriptionDialog';
import TaskItem from './components/TaskItem';
import {
  UpdateOrganizationUserTaskWorkflowMutation,
  UpdateOrganizationUserTaskWorkflowMutationVariables,
} from './graphql/types/UpdateOrganizationUserTaskWorkflowMutation';
import UPDATE_TASK_STATUS from './graphql/UpdateOrganizationUserTaskWorkflowMutation.v4.b2b.graphql';
import useGetHomeUserTask from './hooks/useGetHomeUserTasks';
import useGetOrgLogo from './hooks/useGetOrgLogo';
import useSnackbarMessage from './hooks/useSnackbarMessage';
import useTasksTranslations from './hooks/useTasksTranslations';
import { TasksProps, TaskType } from './types';

const Tasks: React.FC<TasksProps> = ({ slug, userId }) => {
  const { t } = useTasksTranslations();
  const { isDark } = useUITheme();
  const { organizationLogo } = useGetOrgLogo({ slug });
  const { tasks, currentPage, totalPages, setCurrentPage, refetch } =
    useGetHomeUserTask({
      organizationSlug: slug,
      userId,
    });
  const {
    isOpen: isSnackbarOpen,
    message,
    state: snackbarState,
    openSnackbar,
  } = useSnackbarMessage();

  const isPhaseTransitionDialogActive = false;

  const [taskDetail, setTaskDetail] = useState<TaskType | null>(null);
  const [{ phaseName, isPhaseTransitionDialogOpen }, setPhaseTransitionDialog] =
    useState<{
      phaseName: string | null;
      isPhaseTransitionDialogOpen: boolean;
    }>({ phaseName: '', isPhaseTransitionDialogOpen: false });
  const [itemId, setItemId] = useState<number | null>(1);
  const [updateTaskStatus] = useMutation<
    UpdateOrganizationUserTaskWorkflowMutation,
    UpdateOrganizationUserTaskWorkflowMutationVariables
  >(UPDATE_TASK_STATUS, {
    context: { clientName: 'v4.b2b' },
  });

  const onOpen = useCallback(
    (id: number | null) => {
      setItemId(id);
    },
    [setItemId],
  );

  const onOpenTask = useCallback(
    (taskData: TaskType) => {
      setTaskDetail(taskData);
    },
    [setTaskDetail],
  );

  const onUpdateTask = async () => {
    try {
      const response = await updateTaskStatus({
        variables: {
          slug,
          taskUserWorkflowId: taskDetail?.originalId!,
          status: TaskUserStatusEnum.COMPLETED,
        },
      });

      if (response.data?.updateTaskuserworkflowStatus?.success) {
        if (response.data.updateTaskuserworkflowStatus.phaseCompleted) {
          setPhaseTransitionDialog({
            phaseName:
              response?.data?.updateTaskuserworkflowStatus?.nextPhase?.name!,
            isPhaseTransitionDialogOpen:
              response?.data?.updateTaskuserworkflowStatus?.phaseCompleted,
          });
        }
        await refetch();
        setTaskDetail(null);
      } else {
        openSnackbar({
          type: 'alert',
          msg: t('SNACKBAR_ERROR_MSG'),
        });
      }
    } catch (err) {
      openSnackbar({
        type: 'alert',
        msg: t('SNACKBAR_ERROR_MSG'),
      });
    }
  };

  const renderEmptyCase = () => {
    return (
      <section
        className="flex items-center justify-center -mt-24 sm:-mt-56 lg:-mt-64"
        style={{ minHeight: 400 }}
      >
        <div>
          <p className="font-h4 text-center">{t('EMPTY_CASE')}</p>
          <p className="font-body2 text-center mt-16">{t('EMPTY_CASE_TEXT')}</p>
        </div>
      </section>
    );
  };

  return (
    <Fragment>
      <Container>
        {tasks.length > 0 ? (
          <Fragment>
            <p className="font-h4 mb-12">{t('START_INDUCTION')}</p>
            <span className="font-body2 text-base-lighter">
              {t('START_INDUCTION_DESCRIPTION')}
            </span>
            <Row>
              <Col>
                {tasks.map(task => {
                  const isOpen = task.originalId === String(itemId);

                  return (
                    <TaskItem
                      open={isOpen}
                      key={task.originalId}
                      onOpen={onOpen}
                      onOpenTask={onOpenTask}
                      task={task}
                    />
                  );
                })}
              </Col>
            </Row>
            <div className="flex justify-end my-72">
              <Pagination
                isDark={isDark}
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={setCurrentPage}
              />
            </div>
          </Fragment>
        ) : (
          renderEmptyCase()
        )}
      </Container>
      <TaskDescriptionDialog
        onConfirm={onUpdateTask}
        taskData={taskDetail}
        onDismiss={() => setTaskDetail(null)}
        isDark={isDark}
        slug={slug}
      />
      {isPhaseTransitionDialogActive && (
        <PhaseTransitionDialog
          isDark={isDark}
          open={isPhaseTransitionDialogOpen}
          organizationLogo={organizationLogo || ''}
          phaseName={phaseName || ''}
          onDismiss={() => {
            setPhaseTransitionDialog({
              phaseName: null,
              isPhaseTransitionDialogOpen: false,
            });
          }}
        />
      )}
      <Snackbar
        isDark={isDark}
        open={isSnackbarOpen}
        state={snackbarState}
        message={message}
        position="top-right"
        dismissOnDelay={2000}
        dismissOnClickOutside
      />
    </Fragment>
  );
};

export default Tasks;
