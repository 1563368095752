import { FC } from 'react';
import * as React from 'react';

import { useMutation } from '@apollo/client';

import Download from '@crehana/icons/outline/Download';
import { PrimaryButton } from '@crehana/ui';

import GENERATE_ATTACHMENT_URL from '../../../../graphql/GenerateOrganizationInductionAttachmentUrlMutation.v4.b2b.graphql';
import {
  GenerateOrganizationInductionAttachmentUrlMutation,
  GenerateOrganizationInductionAttachmentUrlMutationVariables,
} from '../../../../graphql/types/GenerateOrganizationInductionAttachmentUrlMutation';
import useTasksTranslations from '../../../../hooks/useTasksTranslations';
import { FileType } from '../../../../types';

interface DownloadableAttachment extends FileType {
  isDark?: boolean;
  isLast?: boolean;
}

const DownloadableAttachment: FC<
  React.PropsWithChildren<DownloadableAttachment>
> = ({ name, description, isDark, isLast, id }) => {
  const { t } = useTasksTranslations();
  const [generateAttachmentUrl] = useMutation<
    GenerateOrganizationInductionAttachmentUrlMutation,
    GenerateOrganizationInductionAttachmentUrlMutationVariables
  >(GENERATE_ATTACHMENT_URL, {
    variables: {
      taskId: id,
      filename: name,
    },
    context: { clientName: 'v4.b2b' },
    onCompleted({ generateInductionAttachmentUrl }) {
      if (
        generateInductionAttachmentUrl?.success &&
        generateInductionAttachmentUrl.attachment?.url
      ) {
        window.open(generateInductionAttachmentUrl.attachment?.url);
      }
    },
    onError(e) {
      console.log(e);
    },
  });

  return (
    <div>
      <div
        className={`flex justify-between items-center rounded-5  px-24 py-16 ${
          isLast ? '' : 'mb-16'
        } ${isDark ? 'bg-gray-lighter-dark-mode' : 'bg-gray-lighter'}`}
      >
        <p className="font-body1 w-3/4">{description}</p>
        <PrimaryButton
          isDark={isDark}
          size="s"
          leftIcon={{ component: Download, size: 24 }}
          label={t(
            'TASK_DESCRIPTION_DIALOG_DOWNLOADABLE_ATTACHMENT_BUTTON_LABEL',
          )}
          className="self-end"
          onClick={() => {
            generateAttachmentUrl();
          }}
        />
      </div>
    </div>
  );
};

export default DownloadableAttachment;
