import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useState } from "react";
import canVisibleNps from "../utils/canVisibleNps";

/**
 * It returns a boolean value that indicates if the reminder should be enabled or not
 * @param {ActiveSurveyType} activeSurveyQuery - ActiveSurveyType
 * @returns A boolean value
 */
export var useReminderEnabled = function useReminderEnabled(_ref) {
  var hasAvailableSurveyToAnswer = _ref.hasAvailableSurveyToAnswer,
    skip = _ref.skip,
    surveyToAnswerId = _ref.surveyToAnswerId,
    userName = _ref.userName;
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    isEnable = _useState2[0],
    setIsEnable = _useState2[1];
  useEffect(function () {
    if (!skip && userName) {
      setIsEnable(hasAvailableSurveyToAnswer && surveyToAnswerId && canVisibleNps(userName));
    }
  }, [hasAvailableSurveyToAnswer, surveyToAnswerId, skip, userName]);
  return isEnable;
};