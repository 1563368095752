import { FC } from 'react';
import { Link } from 'react-router-dom';

import { clsx } from 'clsx';

import ArrowIosRight from '@crehana/compass.icons.outline/ArrowIosRight';

import { useCrehanaTranslation } from '@/views/LearnDashboard/hooks/useCrehanaTranslation';

import type {
  BreadcrumbTracksProps,
  TBreadcrumbTracksPropsMobile,
} from './type';

const BreadcrumbDesktop: FC<
  TBreadcrumbTracksPropsMobile & Pick<BreadcrumbTracksProps, 'items'>
> = ({ items, itemClass, goToPath, homeUrl }) => {
  const { t } = useCrehanaTranslation();
  return (
    <>
      <li className="tw-font-body5 breadcrumb-item" key="home">
        <a
          className={itemClass}
          href={homeUrl}
          onClick={ev => goToPath(ev, homeUrl)}
        >
          {t('LABEL_SIDEBAR_ITEM_START')}
        </a>
      </li>
      {items?.map((item, index) => {
        const isLast = index === items.length - 1;

        return [
          <li
            key={(index * 1).toString()}
            aria-hidden
            className={clsx('tw-mx-0.8', itemClass)}
          >
            <ArrowIosRight className="tw-fill-current " size={16} />
          </li>,
          <li
            key={item.label.toLocaleLowerCase().replace(/ /g, '-')}
            aria-current="page"
            aria-label={item.label}
          >
            {isLast && (
              <span
                className={clsx(itemClass, {
                  'tw-text-primary-light-500 dark:tw-text-secondary-dark-500 tw-font-link1':
                    item.isSelect && isLast,
                })}
              >
                {item.label}
              </span>
            )}

            {item.href && !isLast && (
              <Link
                to={item.href}
                className={clsx(itemClass, {
                  'tw-text-primary-light-500 dark:tw-text-secondary-dark-500 tw-font-link1':
                    item.isSelect && isLast,
                })}
              >
                {item.label}
              </Link>
            )}
          </li>,
        ];
      })}
    </>
  );
};

export default BreadcrumbDesktop;
