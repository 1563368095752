import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["data", "refetch"];
import { useCallback } from "react";
import { useQuery } from "@apollo/client";
import { EnrollmentTypeEnum } from "../../../__generated__/graphql-schema";
import parseCourseEnrollmentsQuery from "../../../Graphql/Parsers/parseCourseEnrollmentsQuery";
var COURSE_ENROLLMENTS_QUERY = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "CourseEnrollmentsQuery"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "first"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "Int"
        }
      },
      directives: []
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "after"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      },
      directives: []
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "last"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "Int"
        }
      },
      directives: []
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "before"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      },
      directives: []
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "progressState"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      },
      directives: []
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "lastActions"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "Boolean"
        }
      },
      directives: []
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "assignedCourses"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "Boolean"
        }
      },
      directives: []
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "isHidden"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "Boolean"
        }
      },
      directives: []
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "isActive"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "Boolean"
        }
      },
      directives: []
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "enrollmentType"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "EnrollmentTypeEnum"
        }
      },
      directives: []
    }],
    directives: [],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "me"
        },
        arguments: [],
        directives: [],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            },
            arguments: [],
            directives: []
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "enrollmentSet"
            },
            arguments: [{
              kind: "Argument",
              name: {
                kind: "Name",
                value: "first"
              },
              value: {
                kind: "Variable",
                name: {
                  kind: "Name",
                  value: "first"
                }
              }
            }, {
              kind: "Argument",
              name: {
                kind: "Name",
                value: "after"
              },
              value: {
                kind: "Variable",
                name: {
                  kind: "Name",
                  value: "after"
                }
              }
            }, {
              kind: "Argument",
              name: {
                kind: "Name",
                value: "last"
              },
              value: {
                kind: "Variable",
                name: {
                  kind: "Name",
                  value: "last"
                }
              }
            }, {
              kind: "Argument",
              name: {
                kind: "Name",
                value: "before"
              },
              value: {
                kind: "Variable",
                name: {
                  kind: "Name",
                  value: "before"
                }
              }
            }, {
              kind: "Argument",
              name: {
                kind: "Name",
                value: "progress"
              },
              value: {
                kind: "Variable",
                name: {
                  kind: "Name",
                  value: "progressState"
                }
              }
            }, {
              kind: "Argument",
              name: {
                kind: "Name",
                value: "lastActions"
              },
              value: {
                kind: "Variable",
                name: {
                  kind: "Name",
                  value: "lastActions"
                }
              }
            }, {
              kind: "Argument",
              name: {
                kind: "Name",
                value: "assignedCourses"
              },
              value: {
                kind: "Variable",
                name: {
                  kind: "Name",
                  value: "assignedCourses"
                }
              }
            }, {
              kind: "Argument",
              name: {
                kind: "Name",
                value: "isHidden"
              },
              value: {
                kind: "Variable",
                name: {
                  kind: "Name",
                  value: "isHidden"
                }
              }
            }, {
              kind: "Argument",
              name: {
                kind: "Name",
                value: "isActive"
              },
              value: {
                kind: "Variable",
                name: {
                  kind: "Name",
                  value: "isActive"
                }
              }
            }, {
              kind: "Argument",
              name: {
                kind: "Name",
                value: "enrollmentType"
              },
              value: {
                kind: "Variable",
                name: {
                  kind: "Name",
                  value: "enrollmentType"
                }
              }
            }],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "totalCount"
                },
                arguments: [],
                directives: []
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "edges"
                },
                arguments: [],
                directives: [],
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "node"
                    },
                    arguments: [],
                    directives: [],
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "CourseEnrollmentFragment"
                        },
                        directives: []
                      }, {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "course"
                        },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{
                            kind: "Field",
                            name: {
                              kind: "Name",
                              value: "participationCertificate"
                            },
                            arguments: [],
                            directives: [],
                            selectionSet: {
                              kind: "SelectionSet",
                              selections: [{
                                kind: "Field",
                                name: {
                                  kind: "Name",
                                  value: "certificateUrl"
                                },
                                arguments: [],
                                directives: []
                              }]
                            }
                          }]
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "CourseEnrollmentFragment"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "EnrollmentNode"
      }
    },
    directives: [],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "originalId"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "course"
        },
        arguments: [],
        directives: [],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "CourseEnrollmentCourseFragment"
            },
            directives: []
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "isHidden"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "project"
        },
        arguments: [],
        directives: [],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "CourseEnrollmentProjectFragment"
            },
            directives: []
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "certificate"
        },
        arguments: [],
        directives: [],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "attachmentUrl"
            },
            arguments: [],
            directives: []
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "CourseEnrollmentCourseFragment"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "CourseNode"
      }
    },
    directives: [],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "originalId"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "title"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "promoImage"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "imagePlaceholder"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "duration"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "progress"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "isFree"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "professor"
        },
        arguments: [],
        directives: [],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "originalId"
            },
            arguments: [],
            directives: []
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "fullName"
            },
            arguments: [],
            directives: []
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "courseTypeEnum"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "enrollmentTypeEnum"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "lastVideoLectureUrl"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "lastVideoEvent"
        },
        arguments: [],
        directives: [],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "videoLecture"
            },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "originalId"
                },
                arguments: [],
                directives: []
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "order"
                },
                arguments: [],
                directives: []
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "title"
                },
                arguments: [],
                directives: []
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "module"
                },
                arguments: [],
                directives: [],
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "originalId"
                    },
                    arguments: [],
                    directives: []
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "order"
                    },
                    arguments: [],
                    directives: []
                  }]
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "isOrganizationAssigned"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissionsV2"
        },
        arguments: [],
        directives: [],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "canUploadProject"
            },
            arguments: [],
            directives: []
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "canFeedbackProject"
            },
            arguments: [],
            directives: []
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "catalogSubCategories"
        },
        arguments: [],
        directives: [],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "name"
            },
            arguments: [],
            directives: []
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "originalId"
            },
            arguments: [],
            directives: []
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "studioConfigSet"
        },
        arguments: [],
        directives: [],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "approvalRequirementEnum"
            },
            arguments: [],
            directives: []
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "CourseEnrollmentProjectFragment"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ProjectNode"
      }
    },
    directives: [],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "originalId"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "title"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "projectImageUrl"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "absoluteUrl"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "currentActionState"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "hasCertification"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "updatedAt"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "degreeLearningDetail"
        },
        arguments: [],
        directives: [],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "degreeLearning"
            },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "degreeSyllabus"
                },
                arguments: [],
                directives: [],
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "statusEnum"
                    },
                    arguments: [],
                    directives: []
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }],
  loc: {
    start: 0,
    end: 852,
    source: {
      body: "#import \"../Fragments/CourseEnrollmentFragment.graphql\"\n\nquery CourseEnrollmentsQuery(\n  $first: Int\n  $after: String\n  $last: Int\n  $before: String\n  $progressState: String\n  $lastActions: Boolean\n  $assignedCourses: Boolean\n  $isHidden: Boolean\n  $isActive: Boolean\n  $enrollmentType: EnrollmentTypeEnum\n) {\n  me {\n    id\n    enrollmentSet(\n      first: $first\n      after: $after\n      last: $last\n      before: $before\n      progress: $progressState\n      lastActions: $lastActions\n      assignedCourses: $assignedCourses\n      isHidden: $isHidden\n      isActive: $isActive\n      enrollmentType: $enrollmentType\n    ) {\n      totalCount\n      edges {\n        node {\n          ...CourseEnrollmentFragment,\n          course {\n            participationCertificate {\n              certificateUrl\n            }\n          }\n        }\n      }\n    }\n  }\n}\n",
      name: "GraphQL request",
      locationOffset: {
        line: 1,
        column: 1
      }
    }
  }
};
import { ENROLLMENTS_FETCHED_BY_STUDENT_TYPE } from "../constants";
export var getCourseEnrollmentsVariables = function getCourseEnrollmentsVariables(_ref) {
  var student = _ref.student,
    progressState = _ref.progressState;
  return {
    first: ENROLLMENTS_FETCHED_BY_STUDENT_TYPE[student],
    lastActions: true,
    isHidden: false,
    // Include expired temporal access enrollments for every student except b2b and degree
    isActive: student === 'degree' || student === 'b2b',
    enrollmentType: student === 'degree' ? EnrollmentTypeEnum.ENROLLMENT_DEGREE : undefined,
    progressState: progressState
  };
};
export var useCourseEnrollments = function useCourseEnrollments(_ref2) {
  var _data$me, _data$me$enrollmentSe;
  var student = _ref2.student,
    progressState = _ref2.progressState,
    skip = _ref2.skip,
    _ref2$first = _ref2.first,
    first = _ref2$first === void 0 ? ENROLLMENTS_FETCHED_BY_STUDENT_TYPE[student] : _ref2$first;
  var _useQuery = useQuery(COURSE_ENROLLMENTS_QUERY, {
      variables: _objectSpread(_objectSpread({}, getCourseEnrollmentsVariables({
        student: student,
        progressState: progressState
      })), {}, {
        first: first
      }),
      skip: skip || student === 'register',
      notifyOnNetworkStatusChange: true
    }),
    data = _useQuery.data,
    refetch = _useQuery.refetch,
    courseEnrollmentsQuery = _objectWithoutProperties(_useQuery, _excluded);
  var courseEnrollments = parseCourseEnrollmentsQuery(data);
  var totalCount = (data === null || data === void 0 ? void 0 : (_data$me = data.me) === null || _data$me === void 0 ? void 0 : (_data$me$enrollmentSe = _data$me.enrollmentSet) === null || _data$me$enrollmentSe === void 0 ? void 0 : _data$me$enrollmentSe.totalCount) || 0;
  var onRefetch = useCallback(function (refetchVariables) {
    return refetch(refetchVariables);
  }, [refetch]);
  return _objectSpread({
    totalCount: totalCount,
    courseEnrollments: courseEnrollments,
    onRefetch: onRefetch,
    refetch: refetch
  }, courseEnrollmentsQuery);
};
export default useCourseEnrollments;