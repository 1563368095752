import { FC, Fragment } from 'react';
import * as React from 'react';

import { css } from 'styled-components';

import { formatDate } from '@crehana/date';
import { Avatar, StateTag } from '@crehana/ui';

import useTasksTranslations from '../../hooks/useTasksTranslations';
import { STATUS } from './constants';
import type { TaskItemProps } from './types';

const TaskItem: FC<React.PropsWithChildren<TaskItemProps>> = ({
  onOpen,
  onOpenTask,
  task,
  open,
}) => {
  const { t, languageCode } = useTasksTranslations();
  const date = new Date(task.deadline);
  const formattedEndDate = date
    ? formatDate(date, {
        outputFormat: 'DD/MM/YYYY',
        languageCode,
      })
    : null;

  return (
    <section
      onKeyDown={() => {
        onOpen(!open ? Number(task.originalId) : null);
        onOpenTask(task);
      }}
      onClick={() => {
        onOpen(!open ? Number(task.originalId) : null);
        onOpenTask(task);
      }}
      className="flex justify-between sm:items-center border-b border-solid border-gray-light py-24"
    >
      <div
        className="flex flex-col"
        css={css`
          width: 250px;
          ${props => props.theme.breakpoints.up('sm')} {
            width: 380px;
          }
        `}
      >
        <p className="font-subtitle1 text-left mb-16 cursor-pointer">
          {task.title}
        </p>
        <StateTag
          label={task.phaseName || ''}
          type="green"
          className="w-80 text-center"
        />
      </div>
      <div className="flex w-360 justify-end flex-col sm:flex-row">
        <div className="flex">
          <Fragment>
            {task.createdBy?.userProfilePictureLink && (
              <Avatar
                alt="avatar"
                bgColor="gray-lighter"
                className="hidden sm:block"
                size={32}
                src={task.createdBy?.userProfilePictureLink}
              />
            )}
            {formattedEndDate && (
              <div className="flex flex-grow flex-col items-end sm:items-start mb-16 sm:mb-0 sm:ml-12 sm:mr-24">
                {task.createdBy?.userFullname && (
                  <p className="font-subtitle2 mb-12 hidden sm:block">
                    {task.createdBy?.userFullname}
                  </p>
                )}
                <span className="font-body3">
                  Finalizar: {formattedEndDate}
                </span>
              </div>
            )}
          </Fragment>
        </div>
        {task.status && (
          <div className="sm:w-160 flex justify-end">
            <StateTag
              label={t(STATUS[task.status].label)}
              type={STATUS[task.status].type}
              leftIcon={{ component: STATUS[task.status].leftIcon }}
            />
          </div>
        )}
      </div>
    </section>
  );
};

export default TaskItem;
