import type { TPageTranslations } from '@crehana/i18n';

import { LEARN_DASHBOARD_PAGE_KEY } from '@/views/LearnDashboard/constants';

import type { TKeys } from './types';

const pt: TPageTranslations<TKeys, typeof LEARN_DASHBOARD_PAGE_KEY> = {
  [LEARN_DASHBOARD_PAGE_KEY]: {
    // Titles
    UD_ENROLLMENTS_TITLE: 'My courses',
    UD_ENROLLMENTS_COURSES: 'All my courses',
    UD_ENROLLMENTS_TRACKS: 'My specializations',
    UD_ENROLLMENTS_CAREERS: 'My careers',
    UD_ENROLLMENTS_BUNDLES: 'My certificates',
    UD_ENROLLMENTS_DEGREES: 'MicroDegree',
    UD_ENROLLMENTS_ACADEMIES: 'Academies',
    UD_ENROLLMENTS_NEW_TAG: 'New',
    UD_ENROLLMENTS_BOOTCAMPS: 'Bootcamp',
    UD_CLASSNOTES_TITLE: 'My notes',
    UD_PROJECTS_TITLE: 'My projects',
    UD_DIPLOMAS_TITLE: 'My diplomas',
    UD_STUDY_PLAN_TITLE: 'My study plan',
    // Onboarding
    UD_ONBOARDING_WELCOME_TITLE: 'Welcome to your new student dashboard!',
    UD_ONBOARDING_WELCOME_DESC:
      'We will show you the improvements we have for you.',
    UD_ONBOARDING_MOBILE_MENU_TITLE: 'New menu bar',
    UD_ONBOARDING_MOBILE_MENU_DESC:
      'We have implemented a new menu bar to improve your navigation between the sections My Courses, My Notes, My Projects, My Certificates, and My Study Plan.',
    UD_ONBOARDING_ENROLLMENTS_TITLE: 'My courses',
    UD_ONBOARDING_ENROLLMENTS_DESC:
      'Here, you will not only find all the enrolled courses but also the progress in your specializations.',
    UD_ONBOARDING_CLASSNOTES_TITLE: 'My notes',
    UD_ONBOARDING_CLASSNOTES_DESC:
      'All the notes you take within your courses can be viewed here.',
    UD_ONBOARDING_PROJECTS_TITLE: 'My projects',
    UD_ONBOARDING_PROJECTS_DESC:
      'In this section, find all the projects you are working on. You can also view the review status of each one, including those that have already been certified.',
    UD_ONBOARDING_DIPLOMAS_TITLE: 'My certificates',
    UD_ONBOARDING_DIPLOMAS_DESC:
      'Find your Crehana certificates here once your final project has been approved.',
    UD_ONBOARDING_STUDY_PLAN_TITLE: 'My study plan',
    UD_ONBOARDING_STUDY_PLAN_DESC:
      'Did you know that successful people have habits? In this section, we will help you achieve your goals.',
    UD_ONBOARDING_NIGHT_SWITCH_TITLE: 'Join the dark side',
    UD_ONBOARDING_NIGHT_SWITCH_DESC:
      'Customize your dashboard in dark mode by activating this switch.',
    UD_ONBOARDING_CONFIG_TITLE: 'Account Settings',
    UD_ONBOARDING_CONFIG_DESC:
      'Have direct access to the configuration of your account as a whole in this link.',
    UD_ONBOARDING_FAQ_TITLE: 'Answering your questions',
    UD_ONBOARDING_FAQ_DESC:
      'If you still have questions about how the platform works, we will be happy to help you through the Crehana Help Center.',
    UD_ONBOARDING_SEARCH_TITLE: 'What are you looking for in your dashboard?',
    UD_ONBOARDING_SEARCH_DESC:
      'Perform a more precise search for what you need in your dashboard in this section.',
    // Filters & FAQ
    UD_CONFIGURATION: 'Configuration',
    UD_SEARCH_HINT: 'Search for your courses, projects...',
    UD_SEARCH_PROJECT_SUBTITLE: '{{course}} Project',
    UD_SEARCH_ERROR:
      'There was an error retrieving your dashboard information.',
    UD_SEARCH_NO_RESULTS: 'No results found for your search',
    UD_GO_TO_COURSES: 'View all my courses',
    UD_FILTER_LABEL: 'Filter by',
    UD_FAQ_COURSE: "Can't find my course",
    UD_FAQ_PROJECT_UPLOAD: 'Uploading projects',
    UD_FAQ_START_COURSES: 'How do I start my courses?',
    UD_FAQ_DIPLOMA: 'How do I obtain my certificate?',
    UD_FAQ_ONBOARDING: 'How does my dashboard work?',
    UD_FAQ_GENERAL: 'Still have questions? Contact our',
    UD_FAQ_GENERAL_LINK: 'Crehana Help Center',
    // Enrollments components and actions
    UD_TRACK_TITLE: '{{title}} Specialization',
    UD_DURATION: 'Duration: {{duration}}',
    UD_DOWNLOAD_SYLLABUS: 'Download course syllabus',
    UD_DOWNLOAD_DIPLOMA: 'Download your diploma',
    UD_START_COURSE: 'Start the course',
    UD_CONTINUE_COURSE: 'Continue the course',
    UD_REWATCH_COURSE: 'Watch again',
    UD_CREATE_PROJECT: 'Start project',
    UD_EDIT_PROJECT: 'Edit project',
    UD_GO_TO_PROJECT: 'View project',
    UD_MODULE_LIST_PROGRESS: '{{completed}}/{{total}} modules completed',
    UD_COURSE_LIST_PROGRESS: '{{completed}}/{{total}} courses completed',
    UD_PROJECT_LIST_PROGRESS: '{{completed}}/{{total}} certified projects',
    UD_SAVED_COURSES_TITLE: 'My saved',
    UD_SAVED_COURSES_TOUR_TITLE: 'Save for later',
    UD_SAVED_COURSES_TITLE_DESC:
      "Now you can save courses. Don't lose sight of your learning!",
    // FAQ'S
    UD_UPLOAD_PROJECTS: 'How do I upload my projects?',
    UD_START_COURSES: 'How do I start the courses assigned to me?',
    UD_CHAT_WITH_MENTORS: 'How do I chat with mentors?',
    UD_UPLOAD_PROJECT: 'How do I upload my project?',
    UD_CANT_FIND_COURSES: "Can't find my courses",
    UD_UPLOAD_PROJECTS_TO_PLATFORM:
      'How do I upload my project to the platform?',
    UD_PROJECT_DESCRIPTION: 'Where can I find the description of my project?',
    UDE_ACADEMIES_ERROR: 'There was an error retrieving your academies',
    REWARD_BANNER_TEXT:
      'Earn while you learn! Discover how to earn points, badges, and rewards.',
    REWARD_BANNER_CAT: 'Learn more',
    REWARDS_TITLE: 'Do you want to add the Rewards feature?',
    REWARDS_SUBTITLE: 'Learn more',
    REWARDS_COPY1:
      'By completing learning paths, \n students can choose free courses.',
    REWARDS_COPY2:
      'They will have access to the entire \n Crehana course catalog.',
    REWARDS_COPY3: 'Encourage students to \n continue learning.',
    REWARDS_ACCEPT: 'Yes, I want to',
    REWARDS_CANCEL: 'Not now',
    MAIN_SECTION_TITLE_WELCOME_BACK: 'Good to see you!',
    MAIN_SECTION_TITLE_GREETING: 'Hi, **{{name}}**.',
    MAIN_SECTION_TITLE_WELCOME: 'Welcome to Crehana!',
    LABEL_SIDEBAR_ITEM_COMEBACK: 'Go back to my products',
    LABEL_SIDEBAR_ITEM_COMEBACK_SHORT: 'Go back',
    LABEL_SIDEBAR_ITEM_TITLE: 'E-Learning',
    LABEL_SIDEBAR_ITEM_START: 'Home',
    LABEL_SIDEBAR_ITEM_KNOWLEDGE_HUB: 'Catalog',
    LABEL_SIDEBAR_ITEM_CONTENT_FOR_YOU: 'More content',
    LABEL_SIDEBAR_ITEM_MY_COURSES_SUBTITLE:
      'Explore your assigned routes for personalized learning.',
    LABEL_SIDEBAR_ITEM_MY_COURSES: 'My courses',
    LABEL_SIDEBAR_ITEM_MY_COURSES_SEPARATOR: 'LEARNING AND PROGRESS',
    LABEL_SIDEBAR_ITEM_MY_COURSES_BREADCRUMB: 'Courses',
    LABEL_SIDEBAR_ITEM_MY_COURSES_TITLE: 'My courses',
    LABEL_SIDEBAR_ITEM_MY_COURSES_ENROLLED_MOBILE: 'Courses',
    LABEL_SIDEBAR_ITEM_MY_COURSES_SAVED: 'Saved courses',
    LABEL_SIDEBAR_ITEM_MY_COURSES_TRACKS: 'Learning Paths',
    LABEL_SIDEBAR_ITEM_MY_COURSES_TRACKS_DETAIL: 'Learning paths of',
    LABEL_SIDEBAR_ITEM_MY_COURSES_LIVE_SESSIONS: 'Live Sessions',
    LABEL_SIDEBAR_ITEM_MY_COURSES_NOTES: 'Notes',
    LABEL_SIDEBAR_ITEM_MY_COURSES_CERTIFICATIONS: 'Certifications',
    LABEL_SIDEBAR_ITEM_MY_PROJECTS: 'Projects',
    LABEL_SIDEBAR_ITEM_MY_STUDY_PLAN: 'Study plan',
    LABEL_SIDEBAR_ITEM_SKILL_XP: 'Skill XP',
    LABEL_SIDEBAR_ITEM_SKILL_XP_EVOLUTION: 'Skill XP Evolution',
    LABEL_SIDEBAR_ITEM_SKILL_XP_EVOLUTION_PENDING: 'Pending tests',
    LABEL_SIDEBAR_ITEM_SKILL_XP_EVOLUTION_SOLVED: 'Solved tests',
    LABEL_SIDEBAR_ITEM_SKILL_XP_SEPARATOR: 'ASSESSMENT AND ACHIEVEMENTS',
    LABEL_SIDEBAR_ITEM_MENTORS: 'Mentorships',
    LABEL_SIDEBAR_ITEM_BLOG: 'Blog',
    LABEL_SIDEBAR_ITEM_LIVES: 'Lives',
    LABEL_SIDEBAR_ITEM_TOOLS: 'Tools',
    LABEL_SIDEBAR_ITEM_PLAYROOM_ACHIEVEMENTS: 'Achievements',
    LABEL_SIDEBAR_ITEM_PLAYROOM_REWARDS: 'Rewards',
    LABEL_SIDEBAR_ITEM_PLAYROOM_TAB: 'Gamification',
    SHOW_MORE: 'Show more',
    B2B_CONTINUE_WITH_LAST_ACTION_TITLE: 'Continue where you left off',
    GO_TO_MY_COURSES: 'Go to my courses',
    B2B_START_SECTION_MANDATORY_COURSES: 'Mandatory courses',
    B2B_START_SECTION_MANDATORY_PROJECTS: 'Mandatory projects',
    B2B_START_SECTION_GOTO_ROUTES: 'Go to learning paths',
    B2B_START_SECTION_GOTO_ROUTES_MOBILE: 'Go to my paths',
    B2B_START_SECTION_YOUR_LEARNING_PATH: 'Your learning paths',
    CONTENT_FOR_YOU_SECTION_BREADCRUMB: 'Content for you',
    CONTENT_FOR_YOU_SECTION_DESCRIPTION:
      'Discover new courses, routes, projects, blog articles, and more, according to your interests.',
    HITS_SECTION_MOBILE_TITLE: 'Crehana Hits',
    HITS_SECTION_TITLE: 'Crehana Hits, learn from your favorite celebrities',
    BLOG_SECTION_TITLE: 'Latest blog articles',

    DOWNLOAD_FILTER: 'Certification type',
    DOWNLOAD_FILTER_ALL: 'All ({{count}})',
    DOWNLOAD_FILTER_DIPLOMA: 'Certificates ({{count}})',
    DOWNLOAD_FILTER_PROOF: 'Proofs ({{count}})',
    DOWNLOAD_DIPLOMA: 'Certificate',
    DOWNLOAD_PROOF: 'PROOF',
    DOWNLOAD_DESCRIPTION_GENERAL:
      'In total, you have obtained {{diplomasCount}} certificates and {{certificateCount}} participation certificates',

    SKILLXP_EVOLUTION_REMINDER_PENDINGS_TITLE:
      'You have {{count}} skill test to solve',
    SKILLXP_EVOLUTION_REMINDER_PENDINGS_SUBTITLE:
      'Your administrator is waiting for you to solve your tests to continue assigning your learning paths.',
    SKILLXP_EVOLUTION_REMINDER_PENDINGS_DISMISS: 'Skip skill test',
    SKILLXP_EVOLUTION_REMINDER_PENDINGS_CONFIRM: 'Start SkillXP',

    START_LEARNING_PATH: 'Start your learning',
    START_LEARNING_UNASSIGNED_TITLE: "Don't create your curriculum yet",
    START_LEARNING_UNASSIGNED_SUBTITLE:
      'Organize your week to achieve your learning goals',
    START_LEARNING_UNASSIGNED_CTA: 'Create curriculum',
    START_LEARNING_EDIT: 'Edit',
    START_LEARNING_LABEL_COMPLETED: '**You did it!** Keep it up!',
    START_LEARNING_LABEL_SUCCESS: 'The final effort!',
    START_LEARNING_LABEL_WARNING: "Don't stop!",
    START_LEARNING_EMPTY_ASSIGNED:
      'All that remains is for the courses to be assigned to you!',
    START_LEARNING_EMPTY_MESSAGE:
      'When your administrator does this, you will be able to see your progress here',
    START_LEARNING_HEADER: 'Your progress',
    START_LEARNING_EMPTY_TITLE: "It's time to start!",
    START_LEARNING_CAN: 'You can do it!',
    START_LEARNING_MESSAGE_OBJECTIVE: 'to complete the weekly objective',
    START_LEARNING_GOTO_PROJECT: 'Go to my projects',
    START_LEARNING_COURSE_TITLE: '{{count}} Course to be completed',
    START_LEARNING_COURSE_TITLE_plural: '{{count}} Courses to be completed',
    START_LEARNING_ROUTE_TITLE: '{{count}} Route to be completed',
    START_LEARNING_ROUTE_TITLE_plural: '{{count}} Routes to be completed',
    START_LEARNING_PROJECT_TITLE: '{{count}} Project to be completed',
    START_LEARNING_PROJECT_TITLE_plural: '{{count}} Projects to be completed',
    START_LEARNING_COURSE_COMPLETED:
      'You have completed {{count}} of {{countTarget}} courses',
    START_LEARNING_ROUTE_COMPLETED:
      'You have completed {{count}} of {{countTarget}} Routes',
    START_LEARNING_PROJECT_COMPLETED:
      'You have completed {{count}} of {{countTarget}} Projects',
    START_LEARNING_TRACK: 'Your pending courses to be developed',
    START_LEARNING_SHOW_CONTENT: 'More content of your interest',
    START_LEARNING_SHOW_CATALOG: 'See the catalog',
    START_LEARNING_ONLYPANEL_MESSAGE_TITLE:
      'Dive into new learning experiences!',
    START_LEARNING_ONLYPANEL_MESSAGE_SUBTITLE:
      'Here you will discover the content recently viewed when your first course is assigned to you',
    START_LAST_PLAY: 'Last playback',
    START_LEARNING_READY_COURSE: 'Start course',
    START_LEARNING_SHOW_MORE: 'More information',
    UD_DURATION_LEFT: 'Time remaining: {{duration}}',
    LABEL_SIDEBAR_BACKOUT: 'Go back',

    LIVE_SESSION_SUBTITLE:
      'Here you can see all the sessions that have been assigned to you',
  },
};

export default pt;
