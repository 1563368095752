export var LAST_ACTIVITY_AMPLITUDE_SECTION_NAME = 'Learn Last Activity Section';
export var USER_DASHBOARD_MICRODEGREE_URL = '/panel/mis-cursos/microdegree/';
export var USER_DASHBOARD_BOOTCAMP_URL = '/panel/mis-cursos/bootcamp/';
export var PAYG_RECOMMENDED_COURSE_COUNT = 10;
export var ENROLLMENTS_FETCHED_BY_STUDENT_TYPE = {
  degree: 1,
  b2b: 1,
  premium: 1,
  'premium-trial': 10,
  register: 0,
  payg: 10,
  anonymous: 0
};
export var MENTORSHIP_SUPPORT_URL = 'https://ayuda.crehana.com/s/topic/0TO4x000000opXlGAI/proyectos-diplomas-y-mentor%C3%ADa';
export var LAST_ACTIVITY_URLS = {
  homeUserUrl: '/home/',
  homeUserB2BUrl: function homeUserB2BUrl(organizationSlug) {
    return "/org/".concat(organizationSlug, "/panel/");
  },
  studyPlan: '/plan-de-estudios/',
  mentorsLanding: '/mentores/',
  inbox: '/inbox/',
  premiumSupport: 'https://ayuda.crehana.com/s/topic/0TO4x000000opXvGAI/premium',
  b2bSupport: 'https://ayuda.crehana.com/s/topic/0TO4x000000opYPGAY/crehana-para-empresas',
  skillXpUrl: '/skill/b2b/',
  blogB2BUrl: '/blog/empresas/',
  academiesLanding: '/academias/'
};
export var STUDY_PLAN_ACTION = {
  id: 'study-plan',
  imageUrl: 'https://crehana-public-catalog.imgix.net/images/app/gallery/2021/07/06/f341b7b7/fb62b9fa.png',
  label: 'CREATE_MY_STUDY_PLAN_LABEL',
  action: 'CREATE_MY_STUDY_PLAN_TITLE',
  href: LAST_ACTIVITY_URLS.studyPlan
};
export var MENTORS_ACTION = {
  id: 'mentors',
  imageUrl: 'https://crehana-public-catalog.imgix.net/images/app/gallery/2021/07/06/7db1854b/f6fe1f0a.png',
  label: 'MEET_MENTORS_LABEL',
  action: 'MEET_MENTORS_TITLE',
  href: LAST_ACTIVITY_URLS.mentorsLanding
};
export var PREMIUM_ACTIONS = [{
  id: 'start-onboarding',
  imageUrl: 'https://crehana-public-catalog.imgix.net/images/app/gallery/2021/07/06/4ee62c86/b75e580f.png',
  label: 'START_ONBOARDING_LABEL',
  action: 'START_ONBOARDING_TITLE'
}, STUDY_PLAN_ACTION, {
  id: 'edit-interests',
  imageUrl: 'https://crehana-public-catalog.imgix.net/images/app/gallery/2021/07/06/082da2b4/1549f68a.png',
  label: 'EDIT_INTERESTS_LABEL',
  action: 'EDIT_INTERESTS_TITLE'
}, MENTORS_ACTION, {
  id: 'academies',
  imageUrl: 'https://crehana-public-catalog.imgix.net/images/app/gallery/2021/11/23/45da58db/120c4188.png',
  label: 'MEET_ACADEMIES_LABEL',
  action: 'MEET_ACADEMIES_TITLE',
  href: LAST_ACTIVITY_URLS.academiesLanding,
  isNew: true
}, {
  id: 'support',
  imageUrl: 'https://crehana-public-catalog.imgix.net/images/app/gallery/2021/07/06/20516f7e/e7fea749.png',
  label: 'SUPPORT_LABEL',
  action: 'SUPPORT_TITLE',
  href: LAST_ACTIVITY_URLS.premiumSupport
}];
export var B2B_ACTIONS = [{
  id: 'start-test-skill-xp',
  imageUrl: 'https://crehana-public-catalog.imgix.net/images/app/gallery/2021/09/07/a3505fb3/86949aab.jpg',
  label: 'HU_B2B_START_TEST_SKILL_XP',
  action: 'HU_B2B_START_TEST_SKILL_XP_CTA',
  href: LAST_ACTIVITY_URLS.skillXpUrl
}, {
  id: 'mentors',
  imageUrl: 'https://crehana-public-catalog.imgix.net/images/app/gallery/2021/09/07/828fdadf/904ab433.jpg',
  label: 'HU_B2B_MENTORS',
  action: 'HU_B2B_MENTORS_CTA',
  href: LAST_ACTIVITY_URLS.mentorsLanding
}, {
  id: 'blog-content',
  imageUrl: 'https://crehana-public-catalog.imgix.net/images/app/gallery/2021/09/07/d0260317/c20be465.jpg',
  label: 'HU_B2B_BLOG',
  action: 'HU_B2B_BLOG_CTA',
  href: LAST_ACTIVITY_URLS.blogB2BUrl
}, {
  id: 'support',
  imageUrl: 'https://crehana-public-catalog.imgix.net/images/app/gallery/2021/09/07/59303ffe/58986c20.jpg',
  label: 'HU_B2B_SUPPORT',
  action: 'HU_B2B_SUPPORT_CTA',
  href: LAST_ACTIVITY_URLS.b2bSupport
}];