import type { RouteType } from '@crehana/web';

export const PROJECTS_ROUTE = {
  key: 'LEARN_PROJECTS_TESTS',
  path: '/projects/',
};

export const ENROLLMENTS_DEGREES_ROUTE = {
  key: 'LEARN_DEGREES',
  path: '/microdegree/',
};

export const ENROLLMENTS_COURSES_ROUTE = {
  key: 'LEARN_ENROLLED_COURSES',
  path: '/my-courses/enrolled/',
};

export const ENROLLMENTS_ROUTE = {
  key: 'LEARN_ENROLLEMENTS',
  path: '',
};

export const SKILLXP_ROUTE = {
  key: 'SKILLXP_ROUTE',
  path: '/skill-xp/evolution/',
};

const ROUTES: Omit<RouteType, 'label' | 'icon' | 'activeIcon'>[] = [
  ENROLLMENTS_DEGREES_ROUTE,
  ENROLLMENTS_COURSES_ROUTE,
  ENROLLMENTS_ROUTE,
];

export default ROUTES;
