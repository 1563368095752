export const AMPLITUDE_SCROLL_EVENT_NAME = 'Scroll HomeUser Start';
export const AMPLITUDE_LAST_ACTIVITY_SOURCE = 'Home Last Activity';
export const AMPLITUDE_LAST_ACTIVITY_SOURCE_DETAIL =
  'Button Courses Last Activity';

export const AMPLITUDE_TRACKS_SOURCE = 'Home Tracks Section';
export const AMPLITUDE_TRACKS_SOURCE_DETAIL = 'Continue Track Button';

export const AMPLITUDE_PLAYROOM_REWARD_BANNER_CTA_SOURCE = 'Home User';
export const AMPLITUDE_PLAYROOM_REWARD_BANNER_CTA_SOURCE_DETAIL = 'Home Banner';

export const PATH_TRACK = '/my-courses/tracks/';

export const DEFAULT_IMG_CONTENT =
  'https://crehana-public-catalog.imgix.net/images/app/gallery/2023/12/20/d08e784f/b3b57c7c.jpg';
