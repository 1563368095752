import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import type { MeObject } from '@crehana/auth';
import { useIsPlayRoomActivated } from '@crehana/compass.playroom';

import { BASE_URL_B2C } from './constants';
import { LearnDashboardContext } from './LearnDashboardContext';
import type {
  LearnDashboardContextState,
  RedirectToFn,
  TLearnDashboardContextPlayroomReactiveEvents as TPlayroomReactiveEvents,
} from './types';
import { createSubjectForRedeemCatalog } from './utils';

type LearnDashboardContextProviderProps = Pick<
  LearnDashboardContextState,
  'isDark' | 'organizationSlug' | 'organizationId' | 'organizationTheme'
> & {
  me: MeObject;
};

const LearnDashboardContextProvider: React.FC<
  React.PropsWithChildren<LearnDashboardContextProviderProps>
> = ({
  isDark,
  me,
  organizationSlug,
  organizationId,
  organizationTheme,
  children,
}) => {
  const navigate = useHistory();
  const {
    isActivated: isPlayroomEnabled,
    loading: isLoadingPlayroomActivated,
  } = useIsPlayRoomActivated(organizationSlug);

  const redirectTo: RedirectToFn = useCallback(
    (path, args) => {
      if (args?._blank) {
        window.open(path, '_blank');
      } else if (args?.replace) {
        window.location.href = path;
      } else {
        const [base, search] = path.split('?');
        const _path =
          args?.trailingSlash && !path.endsWith('/') ? `${base}/` : base;

        navigate.push({
          pathname: _path,
          search: search ? `?${search}` : '',
          state: args?.state,
        });
      }
    },
    [navigate],
  );

  // Using useMemo here because the reactive events must be a unique instance
  const reactivePlayroomEventsValue = useMemo<TPlayroomReactiveEvents>(
    createSubjectForRedeemCatalog,
    [],
  );

  const baseUrlB2B = organizationSlug
    ? '/org/' + organizationSlug + '/learn'
    : undefined;

  const isB2BOnlyPanel = Boolean(me.isB2BOnlyPanel);

  const value: Readonly<LearnDashboardContextState> = {
    me,
    isDark,
    baseUrlB2B,
    baseUrlB2C: BASE_URL_B2C,
    organizationSlug,
    organizationId,
    redirectTo,
    isPlayroomEnabled,
    isLoadingPlayroomActivated,
    isB2BOnlyPanel,
    organizationTheme,
    ...reactivePlayroomEventsValue,
  };

  return (
    <LearnDashboardContext.Provider value={value}>
      {children}
    </LearnDashboardContext.Provider>
  );
};

export default LearnDashboardContextProvider;
