import dynamic from 'next/dynamic';

import { MainCategoriesCoursesViewSkeleton } from '@crehana/compass.knowledgehub';

const LazyKnowledgeHubHomeSection = dynamic(
  () => import('./KnowledgeHubHomeSection'),
  {
    loading: () => <MainCategoriesCoursesViewSkeleton />,
  },
);

export default LazyKnowledgeHubHomeSection;
