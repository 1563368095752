import { SingleRouteType } from '@crehana/web';

import { KNOWLEDGE_HUB_SUPPORTED_PATHS } from '../layouts/shared/KnowledgeHubHomeSection';

/**
 * @description Get the knowledge hub route from the pathname.
 *              Currently, the knowledge hub view has multiple paths.
 */
export function getKnowledgeHubRoute(
  pathname: string,
  routes: SingleRouteType[],
): SingleRouteType | undefined {
  const KnowledgeCrehanaPath = KNOWLEDGE_HUB_SUPPORTED_PATHS.find(
    supportedPath => pathname.match(supportedPath.path),
  );

  if (KnowledgeCrehanaPath) {
    const knowledgeHubRoute: SingleRouteType | undefined = routes.find(
      r => r.key === 'LEARN_KNOWLEDGE_HUB',
    );

    if (knowledgeHubRoute) {
      // Update the path of the route with the current pathname to match the route using react-router-dom.
      knowledgeHubRoute.path = pathname;

      return knowledgeHubRoute;
    }
  }
}
