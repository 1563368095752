import { useState } from 'react';

import { createUrlWithSourceQueryParams } from '@crehana/analytics';
import { DialogInformative } from '@crehana/compass.ui';
import { addQueryParamToUrl, date } from '@crehana/utils';

import useTeamCoursesTranslation from '../../../hooks/useTeamCoursesTranslation';
import { TMachineContextCourseTrailerType } from '../../../types';
import CourseCover from './CourseCover';

type ModalShowTrailerProps = {
  isOpen: boolean;
  onDismiss: () => void;
  title: string;
  courseId: number;
  coverImage: string;
  sizeImage: string;
  duration: number;
  professor: string;
  lastVideoLectureURL: string;
  trailerSources: TMachineContextCourseTrailerType;
};

const ModalShowTrailer: React.FC<ModalShowTrailerProps> = ({
  isOpen,
  onDismiss,
  trailerSources,
  title,
  courseId,
  coverImage,
  duration,
  professor,
  lastVideoLectureURL,
  sizeImage,
}) => {
  const { t } = useTeamCoursesTranslation();
  const [playTrailer, setPlayTrailer] = useState(false);

  const onPlayTrailer = () => {
    setPlayTrailer(true);
  };

  const onGoToInitCourse = () => {
    window.location.href = addQueryParamToUrl({
      url: createUrlWithSourceQueryParams({
        url: lastVideoLectureURL || '',
        source: 'Learn Dashboard',
        sourceDetail: 'B2B Tracks',
      }),
      key: 'Enrollment Origin',
      value: 'B2B Tracks',
    });
  };

  return (
    <DialogInformative
      size="s"
      open={isOpen}
      onDismiss={onDismiss}
      className="tw-p-0 dark:tw-shadow-none"
    >
      <>
        <div className="tw-w-full tw-h-[317px] tw-min-h-[317px] tw-bg-primary-light-400">
          <CourseCover
            title={title}
            hovered={playTrailer}
            courseId={courseId}
            coverImage={coverImage}
            trailerSources={trailerSources.sources}
            isCrehanaCourse={true}
            sizeImage={sizeImage}
            onClickImageTrailer={onPlayTrailer}
            onCloseModal={onDismiss}
          />
        </div>
        <div className="tw-px-2.4 tw-pt-2.4 dark:tw-bg-neutral-dark-800">
          <DialogInformative.Title>{title}</DialogInformative.Title>
          <DialogInformative.Description>
            {`${professor} - ${date.toMinutes(duration)}`}
          </DialogInformative.Description>
        </div>
        <DialogInformative.Actions className="tw-bg-neutral-light-400 dark:tw-bg-neutral-dark-600 tw-justify-center tw-px-2.4 tw-pb-2.4">
          <button
            className="tw-btn-primary-s"
            onClick={onGoToInitCourse}
            type="button"
          >
            {t('LEARN_TEAM_TRACK_OPTIONAL_MENU_START_CURSO')}
          </button>
        </DialogInformative.Actions>
      </>
    </DialogInformative>
  );
};

export default ModalShowTrailer;
