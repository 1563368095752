import {
  GetListCoursesClaimedByRewardQuery,
  GetListCoursesClaimedByRewardQuery_content_organization_team_user_team_reward_UserTeamRewardNode_courses as ListCourse,
} from '../graphql/types/GetListCoursesClaimedByRewardQuery';

export const getParseListCoursesRewards = (
  dataList: GetListCoursesClaimedByRewardQuery | undefined,
) => {
  if (
    dataList &&
    dataList?.content_organization_team?.user_team_reward?.__typename ===
      'UserTeamRewardNode'
  ) {
    return dataList.content_organization_team?.user_team_reward
      ?.courses as ListCourse[];
  } else {
    return [] as ListCourse[];
  }
};
