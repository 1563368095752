import { useMutation } from '@apollo/client';

import OPEN_ENROLLMENT_INTEGRATION from '../../graphql/PutOpenEnrollmentIntegrationMutation.v4.b2b.graphql';
import {
  PutOpenEnrollmentIntegrationMutation,
  PutOpenEnrollmentIntegrationMutationVariables,
} from '../../graphql/types/PutOpenEnrollmentIntegrationMutation';

export const useExternalCoursesOpen = () => {
  const result = useMutation<
    PutOpenEnrollmentIntegrationMutation,
    PutOpenEnrollmentIntegrationMutationVariables
  >(OPEN_ENROLLMENT_INTEGRATION, { context: { clientName: 'v4.b2b' } });

  return result;
};

export default useExternalCoursesOpen;
