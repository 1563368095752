import {
  DialogRequest,
  LazyImage,
  Loader,
  ModalIcon,
} from '@crehana/compass.ui';
import { date } from '@crehana/utils';

import useGetListRewardsClaimeds from '../../../hooks/useGetListRewardsClaimeds';
import useTeamCoursesTranslation from '../../../hooks/useTeamCoursesTranslation/useTeamCoursesTranslation';

type ModalListRewardsClaimedProps = {
  isOpen: boolean;
  onDismiss: () => void;
  currentTeamId: number | null;
  organizationId: number | null;
};

const ModalListRewardsClaimed: React.FC<ModalListRewardsClaimedProps> = ({
  isOpen,
  onDismiss,
  currentTeamId,
  organizationId,
}) => {
  const { t } = useTeamCoursesTranslation();
  const { listCourses, loading } = useGetListRewardsClaimeds({
    organizationID: organizationId,
    teamID: currentTeamId,
  });

  return (
    <DialogRequest
      open={isOpen}
      onDismiss={onDismiss}
      size="m"
      className="dark:tw-bg-elevation-dark-00 dark:tw-shadow-none"
    >
      <>
        <DialogRequest.Title>
          <ModalIcon size="s" type="reward" />
          <h2 className="dark:tw-text-neutral-light-100">
            {t('LEARN_TITLE_REWARDS_CLAIMEDS')}
          </h2>
        </DialogRequest.Title>
        <DialogRequest.Body>
          <div className="tw-w-full">
            <p className="tw-font-subtitle5 tw-mb-2 dark:tw-text-neutral-light-100">
              {t('LEARN_SUBTITLE_REWARDS_CLAIMEDS')}
            </p>
            <div className="tw-flex tw-flex-col tw-gap-y-1.2">
              {loading && (
                <div className="tw-w-full tw-h-4.8 tw-flex tw-justify-center tw-items-center">
                  <Loader size="m" />
                </div>
              )}
              {!loading &&
                listCourses.map((course, index) => {
                  return (
                    <div
                      key={course.title}
                      className="tw-flex tw-border-0.8 tw-border-solid tw-border-neutral-light-300 dark:tw-border-neutral-dark-400 tw-bg-neutral-light-100 dark:tw-bg-elevation-dark-04 tw-rounded-1.2 tw-items-center tw-p-1.2"
                    >
                      <div className="tw-border-[3px] tw-border-solid tw-rounded-full tw-border-neutral-light-300 dark:tw-border-neutral-dark-400 tw-text-neutral-light-700 tw-w-[26px] tw-h-[26px] tw-font-caption tw-flex tw-justify-center tw-items-center tw-mr-0.8">
                        {index + 1}
                      </div>
                      <LazyImage
                        className="tw-w-[92px] tw-h-[52px] tw-mr-1.2"
                        imgClassName="tw-object-cover tw-rounded-[5px]"
                        alt={course.title}
                        src={course.image || ''}
                      />
                      <div className="tw-flex tw-flex-col tw-gap-0.4 tw-justify-start">
                        <h3 className="tw-font-subtitle5 dark:tw-text-neutral-light-100">
                          {course.title}
                        </h3>
                        <p className="tw-font-body5 tw-text-neutral-light-700 tw-font-normal dark:tw-text-neutral-dark-400">
                          {t('LEARN_TEXT_DURATION')}{' '}
                          {date.toMinutes(course.duration)}
                        </p>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </DialogRequest.Body>
        <DialogRequest.Actions className="tw-justify-end tw-bg-neutral-light-400 dark:tw-bg-neutral-dark-700">
          <button
            className="tw-btn-primary-s"
            onClick={onDismiss}
            type="button"
          >
            {t('REWARDS_CTA')}
          </button>
        </DialogRequest.Actions>
      </>
    </DialogRequest>
  );
};

export default ModalListRewardsClaimed;
