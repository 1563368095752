import { Dispatch, SetStateAction, useEffect } from 'react';

import { PAYG_NOTIFICATIONS, PREMIUM_NOTIFICATIONS } from '../constants';
import { PAYGNotificationKey, PremiumNotificationKey } from '../types';

export type CurrentNotification<NotificationKeys> = {
  index: number;
  key: NotificationKeys;
  isActiveOrBeingEvaluated: boolean;
};

function useValidateAndUpdateCurrentNotification<
  NotificationKeys extends PremiumNotificationKey | PAYGNotificationKey,
>({
  currentNotification,
  setCurrentNotification,
  validationsByNotificationKey,
  notificationsType,
}: {
  currentNotification?: CurrentNotification<NotificationKeys>;
  setCurrentNotification: Dispatch<
    SetStateAction<CurrentNotification<NotificationKeys> | undefined>
  >;
  validationsByNotificationKey: Record<NotificationKeys, boolean>;
  notificationsType: 'premium' | 'payg';
}) {
  const NOTIFICATION_PRIORITY_LIST = (
    notificationsType === 'premium' ? PREMIUM_NOTIFICATIONS : PAYG_NOTIFICATIONS
  ) as NotificationKeys[];

  useEffect(() => {
    if (currentNotification) {
      const currentNotificationValidation =
        validationsByNotificationKey[currentNotification.key];

      // Update current notification validation in case it is not being evaluated anymore
      if (
        currentNotificationValidation !==
        currentNotification.isActiveOrBeingEvaluated
      ) {
        setCurrentNotification(prevState =>
          prevState
            ? {
                ...prevState,
                isActiveOrBeingEvaluated: currentNotificationValidation,
              }
            : prevState,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationsByNotificationKey]);

  useEffect(() => {
    if (!currentNotification) {
      // If there is no current notification,
      // assign the first one from the priority list
      const firstNotificationKey = NOTIFICATION_PRIORITY_LIST[0];

      if (firstNotificationKey) {
        setCurrentNotification({
          index: 0,
          key: firstNotificationKey,
          isActiveOrBeingEvaluated:
            validationsByNotificationKey[firstNotificationKey],
        });
      }
    } else if (!currentNotification.isActiveOrBeingEvaluated) {
      // If the current notification is not active or being evaluated,
      // assign the next notification from the priority list as the new current
      const nextNotificationIndex = currentNotification.index + 1;
      const nextNotificationKey =
        NOTIFICATION_PRIORITY_LIST[nextNotificationIndex];

      if (nextNotificationKey) {
        setCurrentNotification({
          index: nextNotificationIndex,
          key: nextNotificationKey,
          isActiveOrBeingEvaluated:
            validationsByNotificationKey[nextNotificationKey],
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentNotification?.index,
    currentNotification?.isActiveOrBeingEvaluated,
  ]);
}

export default useValidateAndUpdateCurrentNotification;
