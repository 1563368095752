import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import * as Apollo from '@apollo/client';
var defaultOptions = {};
export var GetMainCategoriesAndContentQueryDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "GetMainCategoriesAndContentQuery"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "organizationId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "after"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "String"
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "before"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "String"
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "first"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "Int"
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "last"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "Int"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "knowledge_hub"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "knowledge_hub_catalog"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "organization_id"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "organizationId"
                }
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "base_category"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "slug"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "title"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "total_courses"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "image_url"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "category_type"
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "contents"
                },
                "arguments": [{
                  "kind": "Argument",
                  "name": {
                    "kind": "Name",
                    "value": "before"
                  },
                  "value": {
                    "kind": "Variable",
                    "name": {
                      "kind": "Name",
                      "value": "before"
                    }
                  }
                }, {
                  "kind": "Argument",
                  "name": {
                    "kind": "Name",
                    "value": "after"
                  },
                  "value": {
                    "kind": "Variable",
                    "name": {
                      "kind": "Name",
                      "value": "after"
                    }
                  }
                }, {
                  "kind": "Argument",
                  "name": {
                    "kind": "Name",
                    "value": "first"
                  },
                  "value": {
                    "kind": "Variable",
                    "name": {
                      "kind": "Name",
                      "value": "first"
                    }
                  }
                }, {
                  "kind": "Argument",
                  "name": {
                    "kind": "Name",
                    "value": "last"
                  },
                  "value": {
                    "kind": "Variable",
                    "name": {
                      "kind": "Name",
                      "value": "last"
                    }
                  }
                }],
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "total_count"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "page_info"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "start_cursor"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "has_next_page"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "has_previous_page"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "end_cursor"
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "edges"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "node"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "course"
                            },
                            "selectionSet": {
                              "kind": "SelectionSet",
                              "selections": [{
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "id"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "title"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "duration"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "is_enrolled"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "course_type"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "image"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "course_level"
                                },
                                "selectionSet": {
                                  "kind": "SelectionSet",
                                  "selections": [{
                                    "kind": "InlineFragment",
                                    "typeCondition": {
                                      "kind": "NamedType",
                                      "name": {
                                        "kind": "Name",
                                        "value": "Level"
                                      }
                                    },
                                    "selectionSet": {
                                      "kind": "SelectionSet",
                                      "selections": [{
                                        "kind": "Field",
                                        "name": {
                                          "kind": "Name",
                                          "value": "id"
                                        }
                                      }, {
                                        "kind": "Field",
                                        "name": {
                                          "kind": "Name",
                                          "value": "name"
                                        }
                                      }, {
                                        "kind": "Field",
                                        "name": {
                                          "kind": "Name",
                                          "value": "slug"
                                        }
                                      }]
                                    }
                                  }]
                                }
                              }]
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "track"
                            },
                            "selectionSet": {
                              "kind": "SelectionSet",
                              "selections": [{
                                "kind": "FragmentSpread",
                                "name": {
                                  "kind": "Name",
                                  "value": "KnowledgeHubAdminContentTrack"
                                }
                              }]
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "document"
                            },
                            "selectionSet": {
                              "kind": "SelectionSet",
                              "selections": [{
                                "kind": "FragmentSpread",
                                "name": {
                                  "kind": "Name",
                                  "value": "TKnowledgeHubAdminDocumentFragment"
                                }
                              }]
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "original_id"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "title"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "is_crehana_content"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "slug"
                            }
                          }]
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "KnowledgeHubAdminContentTrack"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "KnowledgeHubOrganizationTeamNode"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "description"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "cover_image_url"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "cover_image_id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "updated_at"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "organization_team_courses"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "course_id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "content_duration"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TKnowledgeHubAdminDocumentFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "KnowledgeHubDocumentNode"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "size"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "file_id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "is_active"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "description"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "extension"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "updated_at"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "created_at"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "document_file"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "file"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "absolute_url"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "download_url"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};

/**
 * __useGetMainCategoriesAndContentQueryQuery__
 *
 * To run a query within a React component, call `useGetMainCategoriesAndContentQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMainCategoriesAndContentQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMainCategoriesAndContentQueryQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useGetMainCategoriesAndContentQueryQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);
  return Apollo.useQuery(GetMainCategoriesAndContentQueryDocument, options);
}
export function useGetMainCategoriesAndContentQueryLazyQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);
  return Apollo.useLazyQuery(GetMainCategoriesAndContentQueryDocument, options);
}