import { useFeatureFlag } from '@crehana/feature-flags/react';

import useTeamTracksFromApiv4 from './useTeamTracksFromApiv4';
import useTeamTracksFromApiv5 from './useTeamTracksFromApiv5';

const useGetTeamTracksMigration = ({
  organization_slug,
  organization_id,
}: {
  organization_slug: string;
  organization_id: number;
}) => {
  const { flagValue: isEnableTrackMigrationRedesign, loading: loadingFF } =
    useFeatureFlag('CONTENT_TRACK_REDESIGN_COLLABORATOR');

  const resultAPiv5 = useTeamTracksFromApiv5({
    organization_id,
    organization_slug,
    skipQuery: Boolean(!loadingFF && !isEnableTrackMigrationRedesign),
  });

  const resultApiv4 = useTeamTracksFromApiv4({
    organizationSlug: organization_slug,
    skipQuery: Boolean(!loadingFF && isEnableTrackMigrationRedesign),
  });

  const { teams, loading, totalCount, error } =
    !loadingFF && isEnableTrackMigrationRedesign ? resultAPiv5 : resultApiv4;

  return {
    teams,
    totalCountTeams: totalCount,
    loading,
    error,
  };
};

export default useGetTeamTracksMigration;
