import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import { useCallback, useEffect, useRef } from "react";
import { trackPageView } from "@crehana/analytics";
import { useMe } from "@crehana/auth";
var usePageView = function usePageView(_ref) {
  var type = _ref.type,
    id = _ref.id,
    page = _ref.page,
    pageData = _ref.pageData,
    _ref$asynchronous = _ref.asynchronous,
    asynchronous = _ref$asynchronous === void 0 ? false : _ref$asynchronous;
  var _useMe = useMe(),
    me = _useMe.me,
    loading = _useMe.loading,
    error = _useMe.error;
  var tracked = useRef(false);
  var sendPageView = useCallback(function (args) {
    var shouldSendPageView = (!tracked.current || asynchronous) && !loading && !error;
    if (shouldSendPageView) {
      trackPageView(_objectSpread({
        type: type,
        id: id,
        page: page,
        user: me,
        pageData: pageData
      }, args));
      tracked.current = true;
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [asynchronous, me, loading, error]);
  useEffect(function () {
    if (!asynchronous) {
      sendPageView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me, loading, error]);
  return {
    sendPageView: sendPageView
  };
};
export default usePageView;