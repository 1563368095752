import type { TItem } from '@crehana/compass.ui';

import useTranslations from './useTranslations';

type optionsCourseToDevelopProps = {
  routesLength: number;
  courseLength: number;
  sessionsLength: number;
  sessionFFActive: boolean;
};

type customType = TItem & {
  count: number;
};

enum TValuesSelectedEnum {
  ROUTES = 'routes',
  COURSES = 'course',
  LIVE_SESSIONS = 'live-sessions',
}

const useOptionsCourseToDevelop = ({
  routesLength,
  courseLength,
  sessionsLength,
  sessionFFActive,
}: optionsCourseToDevelopProps) => {
  const { t } = useTranslations();
  const optionsToSelect: customType[] = [];

  if (routesLength > 0) {
    optionsToSelect.push({
      label: t('ROUTES_NAME'),
      value: TValuesSelectedEnum.ROUTES,
      count: routesLength,
    });
  }
  if (courseLength > 0) {
    optionsToSelect.push({
      label: t('COURSE_NAME'),
      value: TValuesSelectedEnum.COURSES,
      count: courseLength,
    });
  }
  if (sessionsLength > 0 && sessionFFActive) {
    optionsToSelect.push({
      label: t('LIVE_SESSIONS_NAME'),
      value: TValuesSelectedEnum.LIVE_SESSIONS,
      count: sessionsLength,
    });
  }

  return { optionsToSelect };
};

export default useOptionsCourseToDevelop;
