import { FC } from 'react';

import { clsx } from 'clsx';

import { ProgressBar } from '@crehana/compass.ui';
import { boldFormatter } from '@crehana/i18n';
import { useMediaQuery } from '@crehana/react-hooks';

import { useCrehanaTranslation } from '@/views/LearnDashboard/hooks';
import { LearnDashboardLocaleKeys } from '@/views/LearnDashboard/types';

import { COLOR_STATE } from '../../CoursesThatDontBelongToTracks/constants';
import { TProgressState } from '../types';

const ProgressState: FC<TProgressState> = ({
  state,
  minProgress = 0,
  minTarget = 0,
  progress,
}) => {
  const { matches: isXs } = useMediaQuery({ only: 'xs' });
  const { t } = useCrehanaTranslation();

  const targetMin: string = `${minProgress}/${minTarget} m`;
  const actualProgress: number =
    minTarget - minProgress >= 0 ? minTarget - minProgress : 0;

  if (state === 'empty')
    return isXs ? (
      <>
        <p
          className={clsx(
            'tw-font-subtitle5 tw-mb-4.8 tw-mt-1.6 dark:tw-text-neutral-dark-700',
            COLOR_STATE[state].text,
          )}
        >
          {t('START_LEARNING_EMPTY_TITLE')}
        </p>
        <div className="tw-relative tw-mb-3.2">
          <ProgressBar
            detailBackground={{
              background: 'tw-bg-neutral-light-100',
              progressBar: 'tw-bg-neutral-light-700',
            }}
            progress={progress}
            size="lg"
            className="tw-rounded-2.4 tw-h-2 tw-relative [&>span]:tw-rounded-0"
          />
          <span className="tw-absolute tw-font-body4 tw-right-1 tw-text-neutral-light-800 tw-top-0 tw-whitespace-pre">
            {targetMin}
          </span>
        </div>
        <p
          className={clsx(
            'tw-flex tw-font-caption tw-items-center dark:tw-text-neutral-dark-700 tw-mb-0.4',
            COLOR_STATE[state].text,
          )}
        >
          <span className="tw-font-main-title md:tw-font-h3 tw-leading-3 tw-mr-0.4">
            {actualProgress}
          </span>{' '}
          min
        </p>
        <p className="tw-font-body4 tw-text-neutral-light-800 tw-mb-1.6">
          {t('START_LEARNING_MESSAGE_OBJECTIVE')}
        </p>
      </>
    ) : (
      <div className="tw-bg-neutral-light-300 tw-px-2 tw-py-1.6 tw-rounded-2">
        <p
          className={clsx(
            'tw-font-subtitle5 tw-mb-1.6',
            COLOR_STATE[state].text,
          )}
        >
          {t('START_LEARNING_EMPTY_TITLE')}
        </p>
        <div className="tw-mb-1.2 tw-relative">
          <ProgressBar
            detailBackground={{
              background: 'tw-bg-neutral-light-100',
              progressBar: 'tw-bg-neutral-light-700',
            }}
            progress={progress}
            size="lg"
            className="tw-rounded-2.4 tw-h-2 tw-relative [&>span]:tw-rounded-0"
          />
          <span className="tw-absolute tw-font-body4 tw-right-1 tw-text-neutral-light-800 tw-top-0 tw-whitespace-pre">
            {targetMin}
          </span>
        </div>
        <div className="tw-flex tw-pt-1.2 md:tw-pl-2.4 tw-w-full">
          <p
            className={clsx(
              'tw-flex tw-flex-col tw-font-caption tw-items-center tw-justify-end tw-mr-1.2',
              COLOR_STATE[state].text,
            )}
          >
            <span className="tw-font-main-title md:tw-font-h3 tw-leading-3">
              {actualProgress}
            </span>{' '}
            min
          </p>

          <p className="tw-font-body4 tw-text-neutral-light-800">
            {t('START_LEARNING_MESSAGE_OBJECTIVE')}
          </p>
        </div>
      </div>
    );
  return (
    <>
      {/* Section Mobile */}
      <div className="md:tw-hidden tw-mt-1.6">
        <p
          className={clsx(
            'tw-font-subtitle5 tw-mb-1.6',
            COLOR_STATE[state]?.text,
            { 'tw-font-normal': state === 'completed' },
          )}
        >
          {t(COLOR_STATE[state]?.label as LearnDashboardLocaleKeys, {
            formatter: boldFormatter,
          })}
        </p>
        <div className="tw-my-3.2 tw-relative">
          <ProgressBar
            detailBackground={{
              background: 'tw-bg-neutral-light-100',
              progressBar: COLOR_STATE[state]?.backgroundProgress,
            }}
            progress={progress}
            size="lg"
            className="tw-rounded-2.4 tw-h-2 tw-relative [&>span]:tw-rounded-0"
          />
          <span className="tw-absolute tw-font-body5 tw-right-1 tw-text-neutral-light-800 tw-top-[10%] tw-whitespace-pre">
            {targetMin}
          </span>
        </div>
        <div className="tw-flex tw-flex-col tw-pt-1.2 md:tw-pl-2.4 tw-w-full">
          <p
            className={clsx(
              'tw-flex tw-font-caption tw-items-center tw-mr-1.2',
              COLOR_STATE[state]?.text,
            )}
          >
            <span className="tw-font-main-title md:tw-font-h3 tw-leading-3 tw-mr-0.4">
              {actualProgress}
            </span>{' '}
            min
          </p>

          <p className="tw-font-body4 tw-text-neutral-light-800 tw-mt-1.2">
            {t('START_LEARNING_MESSAGE_OBJECTIVE')}
          </p>
        </div>
      </div>
      <div
        className={clsx(
          'tw-rounded-2 tw-px-2 tw-py-1.6 tw-hidden md:tw-block',
          COLOR_STATE[state].background,
        )}
      >
        <p
          className={clsx(
            'tw-font-subtitle5 tw-mb-1.6',
            COLOR_STATE[state]?.text,
            { 'tw-font-normal': state === 'completed' },
          )}
        >
          {t(COLOR_STATE[state]?.label as LearnDashboardLocaleKeys, {
            formatter: boldFormatter,
          })}
        </p>
        <div className="tw-mb-1.2 tw-relative">
          <ProgressBar
            detailBackground={{
              background: 'tw-bg-neutral-light-100',
              progressBar: COLOR_STATE[state]?.backgroundProgress,
            }}
            progress={progress}
            size="lg"
            className="tw-rounded-2.4 tw-h-2 tw-relative [&>span]:tw-rounded-0"
          />
          <span className="tw-absolute tw-font-body5 tw-right-1 tw-text-neutral-light-800 tw-top-[10%] tw-whitespace-pre">
            {targetMin}
          </span>
        </div>
        <div className="tw-flex tw-pt-1.2 tw-w-full md:tw-pl-2.4">
          <p
            className={clsx(
              'tw-flex tw-flex-col tw-font-caption tw-items-center tw-mr-1.2',
              COLOR_STATE[state]?.text,
            )}
          >
            <span className="tw-font-main-title md:tw-font-h3 tw-mr-0.4">
              {actualProgress}
            </span>{' '}
            min
          </p>

          <p className="tw-font-body4 tw-text-neutral-light-800">
            {t('START_LEARNING_MESSAGE_OBJECTIVE')}
          </p>
        </div>
      </div>
    </>
  );
};

export default ProgressState;
