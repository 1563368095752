import { FC } from 'react';

import cn from 'clsx';

import QuestionMarkCircle from '@crehana/compass.icons.fill/QuestionMarkCircle';
import { ProgressBar, TooltipWrapper } from '@crehana/compass.ui';
import { useMediaQuery } from '@crehana/react-hooks';

import type { TCertificacionCriteria } from '../../types';

const CertificacionCriteria: FC<TCertificacionCriteria> = ({
  description,
  numberCertifications,
  state,
  typeCertification,
  isBorder,
  className,
}) => {
  const { matches: isMd } = useMediaQuery({ only: 'md' });
  return (
    <div
      className={cn(
        'tw-grid tw-w-full tw-grid-cols-1 tw-grid-flow-row',
        isBorder &&
          'tw-pb-1.2 tw-border-b tw-border-neutral-light-300 tw-border-solid',
        className,
      )}
    >
      <div
        className={cn(
          'tw-flex md:tw-flex-1 tw-font-body5 tw-items-center tw-text-neutral-light-800 dark:tw-text-neutral-light-100 tw-mb-0.8',
        )}
      >
        <span>{state}</span>
        <span className="tw-rounded-0.4 tw-mx-0.4 tw-font-link1 dark:tw-text-primary-dark-600 tw-text-primary-light-600 tw-px-0.4 dark:tw-bg-primary-dark-100 tw-bg-primary-light-100">
          {numberCertifications.expected}
        </span>
        <span className="tw-mr-0.4 tw-line-clamp-1">{typeCertification}</span>

        <TooltipWrapper
          message={description}
          sectionClassName="!tw-w-[40rem]"
          containerClassName="md:tw-flex"
        >
          <QuestionMarkCircle
            className="tw-text-neutral-light-700 tw-mr-auto"
            size={16}
          />
        </TooltipWrapper>
      </div>
      <p
        className={cn(
          'tw-font-body5 md:tw-hidden tw-text-neutral-light-700 dark:tw-text-neutral-dark-500 tw-mb-0.8',
        )}
      >
        {description}
      </p>

      <div
        className={cn(
          'md:tw-w-full md:tw-pl-0.4 lg:tw-pl-0 tw-flex tw-text-neutral-light-800 dark:tw-text-neutral-light-100 tw-font-body5 tw-items-center lg:tw-max-w-full lg:tw-mt-0.8',
        )}
      >
        <ProgressBar
          size={isMd ? 'm' : 'lg'}
          progress={Math.floor(
            (numberCertifications.completed / numberCertifications.expected) *
              100,
          )}
          className="tw-rounded-0.8 tw-mr-1.6 tw-p-0.4 md:tw-p-0 md:tw-mr-0.8 lg:tw-p-0.4"
          detailBackground={{
            background: 'tw-bg-neutral-light-400 dark:tw-bg-neutral-dark-700',
            progressBar:
              'tw-rounded-0.8 tw-bg-primary-light-400 dark:tw-bg-primary-light-300',
          }}
        />
        {`${numberCertifications.completed}/${numberCertifications.expected}`}
      </div>
    </div>
  );
};

export default CertificacionCriteria;
