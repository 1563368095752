import styled, { css } from 'styled-components';

import {
  elevationDark,
  neutralDark,
  neutralLight,
  primaryLight,
  secondaryLight,
} from '@crehana/tailwindcss/colors';

export const CrossSidebarWrapper = styled.div<{
  isDark?: boolean;
  isSidebarV2Enabled?: boolean;
}>(
  ({ isDark, isSidebarV2Enabled }) => css`
    .cross-sidebar {
      ${isSidebarV2Enabled &&
      `
      & > div[class^="Sidebar"] {
        height: calc(100vh - 72px);
        background-color ${isDark ? elevationDark['01'] : neutralLight['100']};
        & > a{
          padding: 0px;
          height: auto;
          max-height: 100%;
          .wp-come-back:hover{
            .icon-come-back{
              path{
                fill: ${isDark ? secondaryLight['300'] : primaryLight['500']};
              }
            }
            p{
              color: ${isDark ? secondaryLight['300'] : primaryLight['500']};
            }
          }

        }
        & > div {
          & > button {
            & > div {
              box-shadow: none;
              &:hover {
                background-color: ${
                  isDark ? elevationDark['02'] : neutralLight['200']
                };
                svg {
                  color: ${
                    isDark ? secondaryLight['300'] : primaryLight['500']
                  };
                  fill: ${isDark ? secondaryLight['300'] : primaryLight['500']};
                }
                p {
                  color: ${
                    isDark ? secondaryLight['300'] : primaryLight['500']
                  };
                }
              }
              &:active {
                background-color: ${
                  isDark ? neutralDark['700'] : neutralDark['200']
                };

              }
              &:first-of-type.shadow-tooltip {
                background-color: ${
                  isDark ? elevationDark['02'] : neutralLight['200']
                };
                svg {
                  fill: ${isDark ? secondaryLight['300'] : primaryLight['500']};
                }
                p {
                  color: ${neutralDark['700']};
                }
              }
            }

          }
          & > div {
            & > button {
              & > div {
                box-shadow: none;
                &:hover {
                  background-color: ${
                    isDark ? elevationDark['02'] : neutralLight['200']
                  };
                  svg {
                    color: ${
                      isDark ? secondaryLight['300'] : primaryLight['500']
                    };
                    fill: ${
                      isDark ? secondaryLight['300'] : primaryLight['500']
                    };
                  }
                  p {
                    color: ${
                      isDark ? secondaryLight['300'] : primaryLight['500']
                    };
                  }
                }
                &:active {
                  background-color: ${
                    isDark ? neutralDark['700'] : neutralDark['200']
                  };

                }
                &:first-of-type.shadow-tooltip {
                  background-color: ${
                    isDark ? neutralDark['700'] : neutralDark['200']
                  };
                  svg {
                    fill: ${
                      isDark ? secondaryLight['300'] : primaryLight['500']
                    };
                  }
                  p {
                    color: ${neutralDark['700']};
                  }
                }
              }

            }
          }
        }
      }
    `}
    }
    .cross-sidebar {
      ${isSidebarV2Enabled &&
      `
      & > div[class^="Sidebar"] {
        & > a{
          & > button {
            position: absolute;
            top: 75px;
            svg {
              display: none;
            }
            &::before {
              content: '';
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 20px;
              height: 20px;
              background-image: url('data:image/svg+xml,%3Csvg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cmask id="mask0_4117_382984" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="3" y="5" width="13" height="10"%3E%3Cpath fill-rule="evenodd" clip-rule="evenodd" d="M14.9999 5C15.4601 5 15.8332 5.3731 15.8332 5.83333V14.1667C15.8332 14.6269 15.4601 15 14.9999 15C14.5396 15 14.1665 14.6269 14.1665 14.1667V5.83333C14.1665 5.3731 14.5396 5 14.9999 5ZM8.58915 6.07741C8.91459 6.40285 8.91459 6.93048 8.58916 7.25592L6.67842 9.16667H11.6665C12.1267 9.16667 12.4998 9.53976 12.4998 10C12.4998 10.4602 12.1267 10.8333 11.6665 10.8333H6.67843L8.58915 12.7441C8.91459 13.0695 8.91459 13.5971 8.58915 13.9226C8.26372 14.248 7.73608 14.248 7.41064 13.9226L4.07733 10.5893C3.75189 10.2638 3.75189 9.73618 4.07733 9.41075L7.41064 6.07741C7.73608 5.75197 8.26371 5.75197 8.58915 6.07741Z" fill="%23181B32"%3E%3C/path%3E%3C/mask%3E%3Cg mask="url(%23mask0_4117_382984)"%3E%3Crect width="20" height="20" fill="${
                isDark ? '%23FFFFFF' : '%230F1019'
              }"%3E%3C/rect%3E%3C/g%3E%3C/svg%3E');
              background-repeat: no-repeat;
              background-size: cover;
            }
          }
        }
      }
    `}
    }
  `,
);
