import Rocket from '@crehana/icons/pictograms/Rocket';
import Winner2 from '@crehana/icons/pictograms/Winner2';
import StarO from '@crehana/icons/StarO';
import type { ImgxParams } from '@crehana/ui';

export const B2B_TEAM_COURSES = 'b2b-team-courses';

export const TEAMS_FIRST = 16;

export const COURSE_CARD_IMAGE_PARAMS: Readonly<ImgxParams> = {
  auto: 'format',
  h: 104,
  dpr: 2,
  crop: 'faces',
};

/* A constant that is used to limit the number of courses that are displayed. */
export const MAX_COURSES_TO_DISPLAY = 10;

export const DEFAULT_IMG_SKILLPATH =
  'https://crehana-public-catalog.imgix.net/images/app/gallery/2023/07/10/f27cd406/bfd00a2b.png';

export const IMG_EMPTY_LIGHT =
  'https://crehana-public-catalog.imgix.net/images/app/gallery/2023/11/07/effaad17/4f64c29a.png';
export const IMG_EMPTY_DARK =
  'https://crehana-public-catalog.imgix.net/images/app/gallery/2023/11/07/363074ce/9eb4c2b4.png';

export const IMG_COURSE_CARD_PORTAIT =
  'https://s3.amazonaws.com/public-catalog.crehana.com/images/app/gallery/2023/12/20/d08e784f/b3b57c7c.jpg';

export const TRACK_HELP = [
  {
    Icon: StarO,
    text: 'LEARN_TEAM_HELP_ONE',
  },
  {
    Icon: Winner2,
    text: 'LEARN_TEAM_HELP_TWO',
  },
  {
    Icon: Rocket,
    text: 'LEARN_TEAM_HELP_THREE',
  },
];

export const IMAGE_ERROR_VIEW_DARK =
  'https://crehana-vercel-images-prd.imgix.net/public/lx-mf/fdc1698a-8dfd-46eb-ba70-e5dd7e339040-darkmode_error_image.png?auto=format&fit=clip';

export const IMAGE_ERROR_VIEW_LIGHT =
  'https://crehana-vercel-images-prd.imgix.net/public/lx-mf/2df87a5d-0e2b-4fe1-b2b7-5ee3cb03ac6e-error_image.png?auto=format&fit=clip';

export const baseUrl = (organizationSlug: string) =>
  `/org/${organizationSlug}/learn/`;
