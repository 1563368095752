import { useMemo, useState } from 'react';

import SurveyReminder from '@crehana/web/components/SurveyReminder';

import { LEARN_DASHBOARD_PAGE_NAME } from '../../../../constants';
import {
  useNPSSurveyValidation,
  useValidateAndUpdateCurrentNotification,
} from '../../hooks';
import { CurrentNotification } from '../../hooks/useValidateAndUpdateCurrentNotification';
import { PremiumNotificationKey } from '../../types';

const PremiumNotification = () => {
  const [currentNotification, setCurrentNotification] =
    useState<CurrentNotification<PremiumNotificationKey>>();

  const npsSurveyValidation = useNPSSurveyValidation({
    currentNotificationIndex: currentNotification?.index,
    notificationType: 'premium',
  });

  const validationsByNotificationKey: Record<PremiumNotificationKey, boolean> =
    useMemo(
      () => ({
        'nps-reminder': npsSurveyValidation.isActiveOrBeingEvaluated,
      }),
      [npsSurveyValidation.isActiveOrBeingEvaluated],
    );

  useValidateAndUpdateCurrentNotification({
    currentNotification,
    setCurrentNotification,
    validationsByNotificationKey,
    notificationsType: 'premium',
  });

  if (currentNotification?.isActiveOrBeingEvaluated) {
    switch (currentNotification.key) {
      case 'nps-reminder':
        return npsSurveyValidation.isEnabledToShow ? (
          <SurveyReminder
            surveyId={npsSurveyValidation.surveyId}
            amplitudePageName={LEARN_DASHBOARD_PAGE_NAME}
          />
        ) : null;
      default:
        return null;
    }
  }
  return null;
};

export default PremiumNotification;
