import { useQuery } from '@apollo/client';

import { UserTeamSearchFilterEnum } from '@/shared/types/graphql/globals.v5';

import GET_USER_TRACKS_APIV5 from '../../graphql/GetListTracksQuery.v5.graphql';
import {
  GetListTracksQuery,
  GetListTracksQueryVariables,
} from '../../graphql/types/GetListTracksQuery';
import useParsedTeamCoursesFromApiv5 from './utils/useParsedTeamCoursesFromApiv5';

const TEAMS_FIRST = 10;

const useTeamTracksFromApiv5 = ({
  filter,
  organization_id,
  organization_slug,
  skipQuery,
}: {
  filter?: UserTeamSearchFilterEnum;
  organization_id: number;
  organization_slug: string;
  skipQuery: boolean;
}) => {
  const { data, loading, error } = useQuery<
    GetListTracksQuery,
    GetListTracksQueryVariables
  >(GET_USER_TRACKS_APIV5, {
    skip: skipQuery,
    variables: {
      first: TEAMS_FIRST,
      organization_id,
      organization_slug,
      filter_by: filter,
    },
    context: { clientName: 'v5' },
    fetchPolicy: 'network-only',
  });

  const { teams } = useParsedTeamCoursesFromApiv5(
    data?.content_organization_team?.user_organization_teams?.edges || [],
  );

  return {
    teams,
    totalCount:
      data?.content_organization_team?.user_organization_teams?.total_count ||
      0,
    loading,
    error,
  };
};

export default useTeamTracksFromApiv5;
