import { useQuery } from '@apollo/client';

import { usePagination } from '@crehana/react-hooks';
import { notEmpty } from '@crehana/utils';

import GET_HOME_ORGANIZATION_USER_TASKS from '../graphql/GetHomeOrganizationUserTasks.v4.b2b.graphql';
import {
  GetHomeOrganizationUserTasks,
  GetHomeOrganizationUserTasksVariables,
} from '../graphql/types/GetHomeOrganizationUserTasks';

const ITEMS_PER_FETCH = 5;

const useGetHomeUserTask = ({
  organizationSlug,
  userId,
}: {
  organizationSlug: string | undefined;
  userId: string | undefined;
}) => {
  const { data, loading, error, refetch } = useQuery<
    GetHomeOrganizationUserTasks,
    GetHomeOrganizationUserTasksVariables
  >(GET_HOME_ORGANIZATION_USER_TASKS, {
    variables: {
      organizationSlug: organizationSlug || '',
      userId: userId || '',
      first: ITEMS_PER_FETCH,
    },
    context: { clientName: 'v4.b2b' },
    fetchPolicy: 'no-cache',
    skip: !organizationSlug,
  });

  const currentTotalCount = data?.inductionUserTasks.totalCount || 0;

  const { currentPage, onPageChange, totalPages } = usePagination(
    ITEMS_PER_FETCH,
    {
      refetch,
      totalElements: currentTotalCount,
      scrollTopOnPageChange: true,
    },
  );

  const tasks =
    data?.inductionUserTasks.edges.filter(notEmpty).map(el => el.node) || [];

  const coursesIds =
    data?.inductionUserTasks.edges.map(courses => ({
      ids: courses.node.courses?.filter(notEmpty) || [],
    })) || [];

  const totalCount = data?.inductionUserTasks?.totalCount || 0;

  return {
    tasks,
    coursesIds,
    loading,
    error,
    currentPage,
    totalCount,
    totalPages,
    setCurrentPage: onPageChange,
    refetch,
  };
};

export default useGetHomeUserTask;
