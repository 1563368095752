import { Skeleton } from '@crehana/compass.ui';

import type { LoadingCardsProps } from '../../types';

const SkeletonLearnHeaderTrack: React.FC<
  Pick<LoadingCardsProps, 'containerType'>
> = () => (
  <div className="tw-px-0 tw-py-2">
    <Skeleton animated className="tw-h-2.4 tw-w-20 tw-mb-0.8 tw-rounded-0.8" />
    <Skeleton animated className="tw-h-1.6 tw-w-[70%] tw-mb-2 tw-rounded-0.8" />
    <Skeleton animated className="tw-h-4 tw-w-[35%] tw-rounded-0.8" />
  </div>
);

export default SkeletonLearnHeaderTrack;
