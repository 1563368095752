import { useMutation } from '@apollo/client';

import type {
  UpdateBasicUserInfo,
  UpdateBasicUserInfoVariables,
} from './graphql/types/UpdateBasicUserInfo';
import UPDATE__BASIC_USER_INFO from './graphql/UpdateBasicUserInfoMutation.graphql';

/**
 * @deprecated Replace with "import useUpdateBasicUserInfo from 'Jsx/Shared/Graphql/Hooks/useUpdateBasicUserInfo'"
 */
export const useUpdateBasicUserInfo = () => {
  const [updateBasicUserInfo] = useMutation<
    UpdateBasicUserInfo,
    UpdateBasicUserInfoVariables
  >(UPDATE__BASIC_USER_INFO);

  const updateUserInfo = (userData: UpdateBasicUserInfoVariables) => {
    return updateBasicUserInfo({
      variables: userData,
    });
  };

  return { updateUserInfo };
};
