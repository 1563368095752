import { Skeleton } from '@crehana/compass.ui';

const SkeletonLearnCreteria = () => (
  <div className="tw-bg-neutral-light-100 dark:tw-bg-elevation-dark-04 tw-px-1.6 tw-py-2 tw-rounded-1 tw-mt-1.2">
    <Skeleton animated className="tw-h-2.4 tw-w-12 tw-mb-2 tw-rounded-0.8" />
    {Array.from(Array(2)).map((_, i) => (
      <Skeleton
        key={`skeletonCriteria_${i * 1}`}
        animated
        className="tw-h-1.6 tw-w-full tw-mb-2 tw-rounded-0.8"
      />
    ))}
    <Skeleton animated className="tw-h-1.6 tw-w-full tw-mb-2 tw-rounded-0.8" />
  </div>
);

export default SkeletonLearnCreteria;
