import useTeamCoursesTranslation from '../../hooks/useTeamCoursesTranslation';
import { TCoursesTrack } from '../../types';
import CourseCardPreRequirements from '../CardCourses/components/CourseCardPreRequirements';
import LayoutCardModal from '../Rewards/LayoutCardModal';

type ModalPreRequirementsProps = {
  isOpen: boolean;
  onClose: () => void;
  listCoursesDependencies: TCoursesTrack[];
};

const ModalPreRequirements: React.FC<ModalPreRequirementsProps> = ({
  isOpen,
  onClose,
  listCoursesDependencies,
}) => {
  const { t } = useTeamCoursesTranslation();

  return (
    <LayoutCardModal
      onDismiss={onClose}
      isOpen={isOpen}
      title={t('COURSE_CARD_REQUIREMENTS_POPUP_TITLE')}
    >
      <div className="md:tw-px-2 md:tw-pb-3.2 tw-mx-2.4 tw-pt-1.2 tw-pb-2.4 tw-flex tw-flex-col">
        <p className="tw-font-body4 tw-mb-2 tw-text-center tw-text-neutral-light-800 dark:tw-text-neutral-light-100">
          {t('LEARN_TEAM_TRACK_BLOCK_DESCRIPTION')}
        </p>
        {listCoursesDependencies.map((item, index) => (
          <div
            key={item.courseId}
            className="tw-bg-neutral-light-300 dark:tw-bg-neutral-dark-400 tw-rounded-1.2 tw-p-0.8 tw-mb-1.2 "
          >
            <CourseCardPreRequirements
              position={index + 1}
              course={item}
              visibleOrder
            />
          </div>
        ))}
      </div>
    </LayoutCardModal>
  );
};

export default ModalPreRequirements;
