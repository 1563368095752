import { useContext } from 'react';

import { LearnDashboardContext } from './LearnDashboardContext';

const useLearnDashboardContext = () => {
  const context = useContext(LearnDashboardContext);

  if (context === undefined) {
    throw new Error(
      'useLearnDashboardContext must be used within a LearnDashboardProvider',
    );
  }

  return context;
};

export default useLearnDashboardContext;
