import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["addResourceBundle"];
import { useTranslation } from '@crehana/i18n';
import { LIVE_SESSIONS_TRANSLATION_KEY } from "../constants";
import locales from "../i18n";
export var useLiveSessionsTranslation = function useLiveSessionsTranslation() {
  var _useTranslation = useTranslation(LIVE_SESSIONS_TRANSLATION_KEY),
    addResourceBundle = _useTranslation.addResourceBundle,
    translationProps = _objectWithoutProperties(_useTranslation, _excluded);
  addResourceBundle(locales);
  return translationProps;
};