import { useQuery } from '@apollo/client';

import { notEmpty } from '@crehana/utils';

import GET_HOME_USER_CLASSROOMS from '../graphql/GetHomeOrganizationInductionClassrooms.v4.b2b.graphql';
import {
  GetHomeOrganizationInductionClassrooms,
  GetHomeOrganizationInductionClassroomsVariables,
} from '../graphql/types/GetHomeOrganizationInductionClassrooms';

const useGetHomeUserClassrooms = ({
  slug,
  ids,
  open,
}: {
  slug: string;
  ids: string[];
  open: boolean;
}) => {
  const { data, loading, error } = useQuery<
    GetHomeOrganizationInductionClassrooms,
    GetHomeOrganizationInductionClassroomsVariables
  >(GET_HOME_USER_CLASSROOMS, {
    context: { clientName: 'v4.b2b' },
    variables: {
      organizationSlug: slug,
      ids,
    },
    fetchPolicy: 'network-only',
    skip: !open || !slug || ids.length === 0,
  });

  const courses = data?.courses.filter(notEmpty) || [];

  return { courses, loading, error };
};

export default useGetHomeUserClassrooms;
