import { formatDate, toDate } from '@crehana/date';
import { LANGUAGES } from '@crehana/ts-types';

export function parseTrackExpirationDate(
  expirationDate: string | Date | undefined,
  languageCode: LANGUAGES = LANGUAGES.ES,
): Date | undefined {
  if (!expirationDate) {
    return;
  }

  const expirationDateStr =
    typeof expirationDate === 'string'
      ? expirationDate
      : expirationDate.toISOString();

  return toDate(expirationDateStr, {
    inputFormat: 'YYYY-MM-DD',
    languageCode,
  });
}

export function humanizeExpirationDate(
  expirationDateStr: string | Date | undefined | null,
  args?: { languageCode: LANGUAGES; outputFormat?: string },
) {
  if (!expirationDateStr) {
    return;
  }

  const { languageCode = LANGUAGES.ES, outputFormat = 'DD MMM' } = args || {};
  const date = parseTrackExpirationDate(expirationDateStr, languageCode);

  if (date) {
    return formatDate(date, {
      outputFormat,
      languageCode,
    });
  }
}
