import { useTranslation } from '@crehana/i18n';
import type { TSetTranslationKeys } from '@crehana/ts-types';

import { INTERN_TRANSLATION_KEY } from '../constants';
import localesEn from '../i18n/localeEn';
import localesEs from '../i18n/localeEs';
import localesPt from '../i18n/localePt';

type TranslationKeys = TSetTranslationKeys<typeof localesEs>;

const useTranslations = () => {
  const { addResourceBundle, t, countryCode, languageCode } =
    useTranslation<TranslationKeys>(INTERN_TRANSLATION_KEY);

  addResourceBundle({
    es: localesEs,
    pt: localesPt,
    en: localesEn,
  });

  return { t, countryCode, languageCode };
};

export default useTranslations;
