import { useCallback, useMemo, useState } from 'react';

import { createUrlWithSourceQueryParams } from '@crehana/analytics';
import { MainCategoryCard } from '@crehana/compass.knowledgehub/components';
import { useGetCatalog } from '@crehana/compass.knowledgehub/hooks';
import { Dropdown, Tab, Tabs, type TItem } from '@crehana/compass.ui';
import { cn } from '@crehana/compass.ui/v2';
import { useMediaQuery } from '@crehana/react-hooks';
import { Col, Container, Row } from '@crehana/ui';

import { useLearnDashboardContext } from '@/views/LearnDashboard/context';

import { PATH_TRACK } from '../../constants';
import Slider from '../Slider';
import useOptionsCourseToDevelop from './hooks/useOptionsCourseToDevelop';
import useTranslations from './hooks/useTranslations';
import type {
  TCoursesThatDontBelongToTracksKeys,
  TTrackCourseHandler,
} from './types';
import useCardHandler from './utils/useCardHandler';

enum TValuesSelectedEnum {
  ROUTES = 'routes',
  COURSES = 'course',
  LIVE_SESSIONS = 'live-sessions',
}

const TrackCourseHandler: React.FC<
  React.PropsWithChildren<TTrackCourseHandler>
> = ({
  containerType,
  children,
  courses,
  teams,
  liveSessionData,
  playroomCourseCoins,
  playroomTrackCoins,
  knowledgeHubActivated,
  isFeatureFlagActive,
  totalCountTeams,
}) => {
  const { t } = useTranslations();
  const { matches: isLg } = useMediaQuery('lg');
  const { matches: isMd } = useMediaQuery('md');

  const {
    baseUrlB2B = '',
    redirectTo,
    organizationSlug,
    organizationId,
    isB2BOnlyPanel,
  } = useLearnDashboardContext();

  const { countCourseCatalog } = useGetCatalog({
    organizationId: organizationId ? String(organizationId) : '',
    first: 1,
    skip: !knowledgeHubActivated,
  });

  const { optionsToSelect } = useOptionsCourseToDevelop({
    courseLength: courses.length,
    routesLength: totalCountTeams || teams.length,
    sessionFFActive: isFeatureFlagActive,
    sessionsLength: liveSessionData.length,
  });

  const [optionSelected, setSelectedOption] = useState<
    TItem | undefined | null
  >(optionsToSelect[0]);

  const onValueTab = () => {
    const selectedIndex = optionsToSelect.findIndex(
      item => item.value === optionSelected?.value,
    );
    return selectedIndex;
  };

  const groupSizeContent = useMemo(() => {
    if (isLg) return knowledgeHubActivated ? 3 : 4;
    if (isMd) return knowledgeHubActivated ? 2 : 3;
    return 2;
  }, [isLg, isMd, knowledgeHubActivated]);

  const { generateCardButtons, parseItems, redirectToCatalog } = useCardHandler(
    playroomCourseCoins,
    playroomTrackCoins,
  );

  const onTabChange = (value: number) => {
    setSelectedOption(optionsToSelect[value]);
  };

  const onChangeSelectedDropdown = (selectedItem: TItem | null) => {
    if (selectedItem) {
      setSelectedOption(selectedItem);
    }
  };

  const redirectToTracks = useCallback(() => {
    const url = createUrlWithSourceQueryParams({
      url: baseUrlB2B + PATH_TRACK,
      source: 'Home Tracks Section',
      sourceDetail: 'Text Link Tracks',
    });

    redirectTo(url);
  }, [baseUrlB2B, redirectTo]);

  const redirectToCourses = useCallback(() => {
    const url = createUrlWithSourceQueryParams({
      url: `/org/${organizationSlug}/learn/my-courses/enrolled/`,
      source: 'Home Assigned Courses',
      sourceDetail: 'Text Link Assigned Courses',
    });

    redirectTo(url);
  }, [organizationSlug, redirectTo]);

  const redirectToLiveSessions = useCallback(() => {
    const url = createUrlWithSourceQueryParams({
      url: `/org/${organizationSlug}/learn/my-courses/live-sessions/`,
      source: 'Home Live Sessions',
      sourceDetail: 'Text Link Live Sessions',
    });

    redirectTo(url);
  }, [organizationSlug, redirectTo]);

  const returnContent = useCallback(() => {
    //TODO: Unify names: Routes, Teams, Tracks
    if (optionSelected?.value === TValuesSelectedEnum.ROUTES) {
      return generateCardButtons(
        parseItems({ itemsParsedTeams: teams }),
        t('SHOW_MORE_TRACK'),
        redirectToTracks,
      );
    }

    if (optionSelected?.value === TValuesSelectedEnum.COURSES) {
      return generateCardButtons(
        parseItems({ itemsParsedCourse: courses }),
        t('SHOW_MORE_COURSE'),
        redirectToCourses,
      );
    }

    if (optionSelected?.value === TValuesSelectedEnum.LIVE_SESSIONS) {
      return generateCardButtons(
        parseItems({ itemsParsedLiveSessions: liveSessionData }),
        t('SHOW_MORE_LIVE_SESSIONS'),
        redirectToLiveSessions,
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    generateCardButtons,
    parseItems,
    optionSelected?.value,
    teams,
    courses,
    liveSessionData,
  ]);

  const SelectContent = () => (
    <>
      <Tabs
        onTabChange={onTabChange}
        value={onValueTab()}
        className="dark:tw-bg-transparent tw-hidden md:tw-inline-flex"
      >
        {optionsToSelect.map(item => (
          <Tab
            key={item.value}
            label={t(item.label as TCoursesThatDontBelongToTracksKeys)}
            count={item.count}
            withoutUnderline
            className="tw-py-0.8"
            styleSelectAround
          />
        ))}
      </Tabs>
      <Dropdown
        id="dropdown_item"
        items={optionsToSelect}
        selectedItem={optionSelected}
        onSelect={({ selectedItem }) => onChangeSelectedDropdown(selectedItem)}
        size={'s'}
        className="tw-w-full md:tw-hidden tw-pr-4"
      />
    </>
  );

  if (
    courses.length === 0 &&
    teams.length === 0 &&
    liveSessionData.length === 0
  )
    return null;

  return (
    <>
      {children}
      <Container type={containerType} className="tw-mb-5.6 tw-mt-1.6">
        <Row className="md:tw-w-full">
          <Col xs={12} md={isB2BOnlyPanel || !knowledgeHubActivated ? 12 : 8}>
            <section
              className={cn(
                'md:tw-overflow-hidden md:tw-p-2.4 tw-w-screen md:tw-w-full md:tw-bg-neutral-light-100 dark:tw-bg-transparent tw-rounded-2',
              )}
            >
              <section>
                <div className="md:tw-flex tw-text-neutral-light-800 dark:tw-text-neutral-light-100 tw-mb-2">
                  <p className="tw-font-subtitle4">
                    {t('START_LEARNING_COURSE')}
                  </p>
                </div>
                <SelectContent />
              </section>

              <Slider
                groupSize={groupSizeContent}
                className="tw-mt-1.2 md:tw-overflow-hidden tw-gap-1.6"
                isPagination={false}
              >
                {returnContent()}
              </Slider>
            </section>
          </Col>
          <Col
            md={4}
            className={cn({
              'tw-hidden': isB2BOnlyPanel || !knowledgeHubActivated,
            })}
          >
            <MainCategoryCard
              title={t('START_LEARNING_MORE_CONTENT')}
              coursesCount={countCourseCatalog}
              ctaText={t('VIEW_CATALOG')}
              onCTAClick={redirectToCatalog}
              className="tw-hidden md:tw-flex tw-bg-neutral-dark-300 tw-w-24 sm:tw-w-24 md:tw-w-full tw-h-full tw-p-2.4"
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default TrackCourseHandler;
