import { FC } from 'react';

import cn from 'clsx';

import Checkmark from '@crehana/compass.icons.outline/Checkmark';
import Flag2 from '@crehana/compass.icons.outline/Flag2';
import { useUITheme } from '@crehana/compass.ui';
import { colors } from '@crehana/tailwindcss';
import { ProgressRing } from '@crehana/ui';

import { TLayoutLearnTeam } from '../../types';

const RingOrder: FC<
  Pick<
    TLayoutLearnTeam,
    'porcentage' | 'isSuccess' | 'order' | 'isCompletedCourse'
  > & {
    className?: string;
  }
> = ({ porcentage, isSuccess, order, className, isCompletedCourse }) => {
  const { isDark } = useUITheme();
  const userAgent = window.navigator.userAgent;
  const isFirefox = userAgent?.toLowerCase()?.indexOf('firefox') > -1;

  const getContentColorRingOrder = () => {
    if (isSuccess) {
      return colors['success'][100];
    }

    if (isCompletedCourse) {
      return isDark
        ? colors['secondary-light'][300]
        : colors['primary-light'][300];
    }

    return isDark
      ? colors['elevation-dark']['04']
      : colors['neutral-light'][100];
  };

  const getTrackColorRingOrder = () => {
    if (isSuccess) {
      return colors['success'][100];
    }

    if (isCompletedCourse) {
      return isDark
        ? colors['secondary-light'][300]
        : colors['primary-light'][300];
    }

    return isDark ? colors['neutral-dark'][400] : colors['neutral-light'][400];
  };

  const getBarColorRingOrder = () => {
    if (isSuccess) {
      return colors['success'][100];
    }

    if (isCompletedCourse) {
      return isDark
        ? colors['secondary-light'][300]
        : colors['primary-light'][300];
    }

    return isDark
      ? colors['secondary-light'][300]
      : colors['primary-light'][300];
  };

  const getContentRing = () => {
    if (isSuccess) {
      return (
        <g transform="translate(6, 4)">
          <Flag2 size={16} className="tw-text-success-500" />
        </g>
      );
    }

    if (isCompletedCourse) {
      return (
        <g
          className="tw-bg-primary-light-300 tw-w-1.6 tw-h-1.6 tw-rounded-full tw-z-2"
          transform="translate(5, 6)"
        >
          <Checkmark className="tw-text-neutral-light-100" size={16} />
        </g>
      );
    }

    return (
      <text
        alignmentBaseline="central"
        className="tw-font-link1 tw-fill-neutral-light-700 dark:tw-fill-neutral-light-100"
        fontSize={11}
        textAnchor="middle"
        x={13}
        y={isFirefox ? 18 : 13}
      >
        {order}
      </text>
    );
  };

  return (
    <ProgressRing
      progress={porcentage}
      size={27}
      strokeWidth={4}
      centerColor={getContentColorRingOrder()}
      className={cn(
        'tw-absolute tw-inset-0 tw-z-1 tw-top-0 tw-left-[-11px] sm:tw-top-[21px] sm:tw-left-[-13px]',
        className,
      )}
      trackColor={getTrackColorRingOrder()}
      barColor={getBarColorRingOrder()}
    >
      {getContentRing()}
    </ProgressRing>
  );
};

export default RingOrder;
