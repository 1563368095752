import { useState } from 'react';

import { useMe } from '@crehana/auth';
import { Col, Row } from '@crehana/ui';

import { Layout } from '../../components/CertificationCriteria/Layout';
import useCoursesDependencies from '../../hooks/useCoursesDependencies';
import { TDetailReward, TDetailRewards, TDetailTrackBody } from '../../types';
import CourseWrapper from '../CardCourses/CourseWrapper';
import CardTrackCompleted from '../CardTrackCompleted';
import ModalListRewardsClaimed from '../Rewards/ModalListRewardsClaimed';
import ModalRewardInProgress from '../Rewards/ModalRewardInProgress';
import RewardCardNew from '../Rewards/RewardCardNew';
import SkillPathCoursesPanelNew from './components/SkillPathCoursesPanelNew';

type LearnTrackCoursesProps = {
  detailTrack: TDetailTrackBody;
  detailReward: TDetailReward;
};

const LearnTrackCourses: React.FC<
  React.PropsWithChildren<LearnTrackCoursesProps>
> = ({ detailTrack, detailReward }) => {
  const { me } = useMe();
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isOpenModalListRewards, setIsOpenModalListRewards] =
    useState<boolean>(false);
  const rewardDetail: TDetailRewards = {
    numberCourses: detailReward.numberCourses,
    originalId: detailReward.rewardId,
    status: detailReward.status,
  };

  const sizeHalf =
    detailTrack.listCourses.length === 3
      ? 2
      : Math.floor(detailTrack.listCourses.length / 2);

  const { dependencies, loading: loadingDependencies } = useCoursesDependencies(
    detailTrack.teamId,
  );

  return (
    <div className="tw-w-full">
      <Row>
        <Col xs={12} md={4} lg={4} className="tw-mb-1.2 tw-mx-2 sm:tw-mx-0">
          <Layout
            summaryCourse={{
              progress: detailTrack.inProgressCourses,
              required: detailTrack.mandatoryCoursesCount,
            }}
            summaryProject={{
              progress: detailTrack.inProgressMandatoryProjectsCount,
              required: detailTrack.mandatoryProjectsCount,
            }}
            summaryQuiz={{
              progress: detailTrack.inProgressQuizzesCount,
              required: detailTrack.mandatoryQuizzesCount,
            }}
            teamDisabled={detailTrack.trackDisabled}
            isCompletedTrack={detailTrack.isCompletedTrack}
            trackProjects={detailTrack.trackProjects}
            dataCertificate={detailTrack.dataCertificate}
            hasCertificate={detailTrack.isCertificateGenerated}
          />
        </Col>
        <Col xs={12} md={8} lg={8}>
          <SkillPathCoursesPanelNew
            courseCount={detailTrack.listCourses.length}
            className="tw-mb-1.2"
          >
            <ul data-testid="skill-path-courses">
              {detailTrack.listCourses.map((course, index) => {
                return (
                  <CourseWrapper
                    key={Number(course.courseId)}
                    dependencies={dependencies}
                    courseData={course}
                    listCourses={detailTrack.listCourses}
                    loadingDependencies={loadingDependencies}
                    order={index + 1}
                    sizeHalf={sizeHalf}
                    isTrackDisabled={detailTrack.trackDisabled}
                  />
                );
              })}
              {detailTrack.isCompletedTrack && detailTrack.dataCertificate && (
                <CardTrackCompleted
                  order={detailTrack.listCourses.length}
                  trackName={detailTrack.nameTrack}
                  portraitCertificate={detailTrack.dataCertificate?.image || ''}
                  rewardQuantity={detailTrack?.coinPlayroomNumber}
                />
              )}
            </ul>
            {detailTrack.shouldRenderRewardCard && (
              <RewardCardNew
                onClickStatusEnable={() => setIsOpenModal(true)}
                onClickStatusTaken={() => setIsOpenModalListRewards(true)}
                detailReward={rewardDetail}
              />
            )}
          </SkillPathCoursesPanelNew>
        </Col>
      </Row>
      <ModalRewardInProgress
        isOpen={isOpenModal}
        onDismiss={() => setIsOpenModal(false)}
      />
      <ModalListRewardsClaimed
        currentTeamId={detailTrack.teamId}
        organizationId={Number(me?.organization?.originalId)}
        isOpen={isOpenModalListRewards}
        onDismiss={() => setIsOpenModalListRewards(false)}
      />
    </div>
  );
};

export default LearnTrackCourses;
