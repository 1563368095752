export var groupUpcomingSessionsByDate = function groupUpcomingSessionsByDate(sessions, sessionIdToHide) {
  var sortedAndFilteredSessions = sessions.reduce(function (result, currentSession) {
    if (currentSession.id === sessionIdToHide) return result;
    var existingGroup = result.find(function (group) {
      return group.date.toDateString() === currentSession.startDate.toDateString();
    });
    if (existingGroup) {
      existingGroup.sessions.push(currentSession);
    } else {
      result.push({
        date: currentSession.startDate,
        sessions: [currentSession]
      });
    }
    return result;
  }, []);
  sortedAndFilteredSessions.forEach(function (group) {
    return group.sessions.sort(function (a, b) {
      return a.startDate.getTime() - b.startDate.getTime();
    });
  });
  return {
    upcomingSessions: sortedAndFilteredSessions.sort(function (a, b) {
      return a.date.getTime() - b.date.getTime();
    }),
    count: sessions.length
  };
};