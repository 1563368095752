import Calendar5 from '@crehana/icons/Calendar5';
import Calendar5Filled from '@crehana/icons/Calendar5Filled';
import Diploma from '@crehana/icons/Diploma';
import PeopleFilled from '@crehana/icons/fill/People';
import HeartFilled from '@crehana/icons/Heart';
import Home from '@crehana/icons/Home';
import Archive from '@crehana/icons/outline/Archive';
import Heart from '@crehana/icons/outline/Heart';
import People from '@crehana/icons/outline/People';
import Pencil from '@crehana/icons/Pencil';
import PencilOutline from '@crehana/icons/PencilOutline';
import { Container } from '@crehana/ui';
import {
  NewDashboardEmptyView,
  NewDashboardErrorView,
  type RouteType,
} from '@crehana/web';

import Academies from '../layouts/premium/Academies';
import ClassNotesSection from '../layouts/shared/ClassNotesSection';
import CoursesSection from '../layouts/shared/CoursesSection';
import DiplomasSection from '../layouts/shared/DiplomasSection';
import MentorsSection from '../layouts/shared/MentorsSection';
import ProjectsSection from '../layouts/shared/ProjectsSection';
import SavedCoursesSection from '../layouts/shared/SavedCoursesSection';
import StudyPlanSection from '../layouts/shared/StudyPlanSection';

const CustomContainer: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => <Container type="dashboard">{children}</Container>;

const base = '/learn';

export type CustomRouteType = RouteType & {
  canAccessByRole?: {
    isPremium?: boolean;
    isB2B?: boolean;
    isDegree?: boolean;
  };
  hiddenAccessByRegion?: {
    isBrazil?: boolean;
  };
};

export const premiumRoutes: CustomRouteType[] = [
  {
    key: 'LEARN_HOME_START',
    label: 'Inicio',
    path: '/learn/',
    exact: true,
    component: () => (
      <CustomContainer>
        <h2>Inicio</h2>
      </CustomContainer>
    ),
    icon: Home,
    activeIcon: Home,
    tourSelector: 'home-start',
  },
  {
    key: 'LEARN_FOR_YOU',
    label: 'Contenido para ti',
    path: base + '/foryou/',
    exact: true,
    component: () => (
      <CustomContainer>
        <h2>Contenido para ti</h2>
      </CustomContainer>
    ),
    icon: Heart,
    activeIcon: HeartFilled,
    tourSelector: 'home-start',
  },
  {
    key: 'LEARN_ENROLLMENTS',
    icon: PencilOutline,
    activeIcon: Pencil,
    tourSelector: 'enrollments-tab',
    label: 'Mis Cursos',
    decorations: {
      subTitle: 'APRENDIZAJE Y PROGRESO',
    },
    nested: [
      {
        key: 'LEARN_ENROLLED_COURSES',
        path: base + '/my-courses/enrolled/',
        exact: false,
        component: CoursesSection,
        icon: PencilOutline,
        activeIcon: Pencil,
        tourSelector: 'enrolled-courses-tab',
        label: 'LABEL_SIDEBAR_ITEM_MY_COURSES_ENROLLED',
      },
      {
        key: 'LEARN_SAVED_COURSES',
        path: base + '/my-courses/saved',
        exact: false,
        component: SavedCoursesSection,
        icon: PencilOutline,
        activeIcon: Pencil,
        tourSelector: 'saved-courses-tab',
        label: 'LABEL_SIDEBAR_ITEM_MY_COURSES_SAVED',
      },
      {
        key: 'LEARN_NOTES',
        path: base + '/my-courses/notes/',
        exact: false,
        component: ClassNotesSection,
        icon: PencilOutline,
        activeIcon: Pencil,
        tourSelector: 'classnotes-tab',
        label: 'LABEL_SIDEBAR_ITEM_MY_COURSES_NOTES',
      },
    ],
  },
  {
    label: 'Academias',
    key: 'LEARN_DIPLOMAS',
    path: base + '/academies/',
    exact: true,
    component: Academies,
    icon: Diploma,
    activeIcon: Diploma,
    tourSelector: 'diplomas-tab',
  },
  {
    label: 'LABEL_SIDEBAR_ITEM_MY_STUDY_PLAN',
    contentTitle: 'LABEL_SIDEBAR_ITEM_MY_STUDY_PLAN',
    key: 'LEARN_STUDY_PLAN',
    icon: Calendar5,
    activeIcon: Calendar5Filled,
    exact: true,
    path: base + '/studyplan/',
    component: StudyPlanSection,
    tourSelector: 'study-plan-tab',
  },
  {
    label: 'LABEL_SIDEBAR_ITEM_MENTORS',
    contentTitle: 'LABEL_SIDEBAR_ITEM_MENTORS',
    key: 'LEARN_MENTORS',
    icon: People,
    activeIcon: PeopleFilled,
    exact: true,
    path: base + '/mentorship/',
    component: MentorsSection,
    tourSelector: 'mentors-tab',
  },
  {
    label: 'LABEL_SIDEBAR_ITEM_MY_PROJECTS',
    contentTitle: 'LABEL_SIDEBAR_ITEM_MY_PROJECTS',
    key: 'LEARN_PROJECTS_TESTS',
    path: base + '/projects/',
    exact: true,
    component: ProjectsSection,
    icon: Archive,
    activeIcon: Archive,
    tourSelector: 'projects-tab',
  },
  {
    label: 'LABEL_SIDEBAR_ITEM_MY_COURSES_CERTIFICATIONS',
    contentTitle: 'LABEL_SIDEBAR_ITEM_MY_COURSES_CERTIFICATIONS',
    key: 'LEARN_CERTIFICATES',
    path: base + '/certificates/',
    exact: true,
    component: DiplomasSection,
    icon: Archive,
    activeIcon: Archive,
    tourSelector: 'diplomas-tab',
  },
  {
    label: 'Empty view',
    key: 'LEARN_EMPTY_VIEW',
    path: base + '/empty-view/',
    exact: true,
    component: () => (
      <NewDashboardEmptyView
        title="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute"
        button={{
          label: 'Empty label button',
          onClick: () => console.log('empty'),
          'data-testid': 'study-plan-empty-view-button',
        }}
        supportsDarkMode
        data-testid="study-plan-empty-view"
      />
    ),
    icon: Calendar5,
    activeIcon: Calendar5Filled,
    tourSelector: 'study-plan-tab',
  },
  {
    label: 'Error view',
    key: 'LEARN_ERROR_VIEW',
    path: base + '/error-view/',
    exact: true,
    component: () => (
      <NewDashboardErrorView
        error="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
        onRetry={() => {
          console.log('on retry');
        }}
        supportsDarkMode
        data-testid="study-plan-error-view"
      />
    ),
    icon: Calendar5,
    activeIcon: Calendar5Filled,
    tourSelector: 'study-plan-tab',
  },
  {
    key: 'LEARN_HELP_CENTER',
    icon: PencilOutline,
    activeIcon: Pencil,
    tourSelector: 'enrollments-tab',
    label: 'Centro de ayuda',
    decorations: {
      lineTop: true,
    },
    nested: [
      {
        key: 'LEARN_HELP_CENTER_1',
        path: base + '/help-1/',
        exact: false,
        component: () => (
          <CustomContainer>
            <h2>Ayuda 1</h2>
          </CustomContainer>
        ),
        icon: PencilOutline,
        activeIcon: Pencil,
        tourSelector: 'saved-courses-tab',
        label: 'Help 1',
        openInNewTab: true,
      },
      {
        key: 'LEARN_HELP_CENTER_2',
        path: base + '/help-2/',
        exact: false,
        component: () => (
          <CustomContainer>
            <h2>Ayuda 2</h2>
          </CustomContainer>
        ),
        icon: PencilOutline,
        activeIcon: Pencil,
        tourSelector: 'temp-1-tab',
        label: 'Help 2',
        openInNewTab: true,
      },
    ],
  },
];
