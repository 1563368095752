import { FC } from 'react';

import {
  CourseCard,
  MainCategoryCard,
} from '@crehana/compass.knowledgehub/components';
import { useGetCatalog } from '@crehana/compass.knowledgehub/hooks';
import { Container } from '@crehana/ui';

import { TrackSectionScroll } from '@/views/LearnDashboard/components';
import { useLearnDashboardContext } from '@/views/LearnDashboard/context';
import { useCrehanaTranslation } from '@/views/LearnDashboard/hooks';

import { AMPLITUDE_SCROLL_EVENT_NAME, DEFAULT_IMG_CONTENT } from '../constants';
import useCardHandler from '../sections/CoursesThatDontBelongToTracks/utils/useCardHandler';
import Slider from '../sections/Slider';

type CatalogLayoutProps = {
  showMoreContent: boolean;
};

const CatalogLayout: FC<CatalogLayoutProps> = ({ showMoreContent }) => {
  const { t } = useCrehanaTranslation();
  const { redirectToCatalog } = useCardHandler();
  const { organizationId } = useLearnDashboardContext();
  const { coursesCatalog, countCourseCatalog } = useGetCatalog({
    organizationId: String(organizationId),
    first: 10,
    skip: typeof organizationId !== 'number',
  });

  const groupSize = showMoreContent ? 3 : 4;

  if (!coursesCatalog) return null;

  return (
    <Container type="dashboard" className="tw-my-3.2">
      <TrackSectionScroll
        amplitudeEventName={AMPLITUDE_SCROLL_EVENT_NAME}
        amplitudeSource="Knowledge Hub Start Section"
      />

      <section className="tw-w-screen md:tw-w-full">
        <Slider groupSize={groupSize} className="tw-gap-1" isPagination={false}>
          {showMoreContent && (
            <MainCategoryCard
              title={t('START_LEARNING_SHOW_CONTENT')}
              coursesCount={countCourseCatalog ?? 0}
              ctaText={t('START_LEARNING_SHOW_CATALOG')}
              onCTAClick={redirectToCatalog}
              className="tw-bg-neutral-dark-300 tw-w-[26rem] md:tw-h-[44.4rem] tw-h-[35.2rem]"
            />
          )}

          {coursesCatalog.map((node, index) => (
            <div
              key={`slides_card${index * 1}`}
              className="tw-rounded-2 tw-object-cover tw-w-[26rem] md:tw-w-full md:tw-max-w-28 md:tw-h-[44.4rem] lg:tw-max-w-full tw-h-[35.2rem] "
            >
              <CourseCard
                title={node.title ?? ''}
                className="tw-h-full tw-w-full"
                duration={node.course?.duration ?? 0}
                courseId={node.original_id}
                coverImage={node.course?.image ?? DEFAULT_IMG_CONTENT}
                isCrehanaCourse={Boolean(node.is_crehana_content)}
                sizeImage="md:tw-h-[31.2rem]"
              />
            </div>
          ))}
        </Slider>
      </section>
    </Container>
  );
};

export default CatalogLayout;
