function getFaviconTypeByImageURL(imageURL: string) {
  if (imageURL.endsWith('.png')) {
    return 'image/png';
  }

  if (imageURL.endsWith('.ico')) {
    return 'image/x-icon';
  }

  if (imageURL.endsWith('.svg')) {
    return 'image/svg+xml';
  }

  return 'image/jpeg';
}

export function changeFavicon(faviconSrc: string) {
  const faviconType = getFaviconTypeByImageURL(faviconSrc);
  const linkFavicon = document.getElementById('lnkfavicon');

  if (linkFavicon) {
    linkFavicon.setAttribute('href', faviconSrc);
    linkFavicon.setAttribute('type', faviconType);
  }

  const favicon = document.getElementById('favicon');
  if (favicon) {
    favicon.setAttribute('href', faviconSrc);
    favicon.setAttribute('type', faviconType);
    favicon.setAttribute('sizes', '32x32');
  }
}
