import { useEffect } from 'react';

import {
  getCommonAmplitudeProperties,
  getSourceEventParams,
  useAmplitude,
} from '@crehana/analytics';
import { useMe } from '@crehana/auth';

import { StudyPlan, type StudyPlanProps } from '@/shared/components';
import Breadcrumb from '@/views/LearnDashboard/components/Breadcrumb';
import SectionTitle from '@/views/LearnDashboard/components/SectionTitle';
import { useLearnDashboardContext } from '@/views/LearnDashboard/context';

import { LEARN_DASHBOARD_PAGE_KEY } from '../../../constants';
import { useCrehanaTranslation } from '../../../hooks/useCrehanaTranslation';

const StudyPlanSection: React.FC<React.PropsWithChildren<StudyPlanProps>> = ({
  className,
}) => {
  const { logEvent } = useAmplitude();
  const { me } = useMe();
  const { t } = useCrehanaTranslation();
  const { isLoadingPlayroomActivated, isPlayroomEnabled } =
    useLearnDashboardContext();

  useEffect(() => {
    if (!isLoadingPlayroomActivated) {
      logEvent({
        event: 'Render Home Study Plan',
        data: {
          ...getSourceEventParams(),
          ...getCommonAmplitudeProperties(me!, isPlayroomEnabled),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingPlayroomActivated, isPlayroomEnabled]);

  return (
    <>
      <Breadcrumb items={[{ label: t('LABEL_SIDEBAR_ITEM_MY_STUDY_PLAN') }]} />
      <SectionTitle
        title={t('LABEL_SIDEBAR_ITEM_MY_STUDY_PLAN')}
        noMargin
        className="my-20"
      />
      <StudyPlan source={LEARN_DASHBOARD_PAGE_KEY} className={className} />
    </>
  );
};

export default StudyPlanSection;
