import { isPast, toDate } from '@crehana/date';
import { useTranslation } from '@crehana/i18n';
import { LANGUAGES } from '@crehana/ts-types';
import type { UserTeamItem } from '@crehana/web';

import { GetListTracksQuery_content_organization_team_user_organization_teams_edges } from '../../../graphql/types/GetListTracksQuery';
import { formatAcademyImage } from '../../../layouts/b2b/StartSection/utils/utils';
import { orderByDateProperty } from './orderByDateProperty';

export type UserTeamItemParsed = UserTeamItem & {
  parsedDate?: string;
  parsedDueDate?: Date | string;
  isExpired?: boolean;
  endDate?: string;
};

const useParsedTeamCoursesFromApiv5 = (
  teams: GetListTracksQuery_content_organization_team_user_organization_teams_edges[],
) => {
  const { languageCode } = useTranslation();

  const parsedItems: UserTeamItemParsed[] = teams.map(team => {
    let parsedDate: Date | undefined;
    let isExpired = false;
    const nodeTeam = team.node;
    if (nodeTeam.expiration_date) {
      parsedDate = toDate(nodeTeam.expiration_date, {
        languageCode: languageCode || LANGUAGES.ES,
        inputFormat: 'YYYY-MM-DD',
      });
      isExpired = !!parsedDate && isPast(parsedDate);
    }

    if (nodeTeam?.organization_team?.academy?.logo) {
      nodeTeam.organization_team.academy.logo = formatAcademyImage(
        nodeTeam?.organization_team?.academy?.logo,
      );
    }

    const parsedTeam: UserTeamItemParsed = {
      teamId: nodeTeam.team_id,
      team: {
        name: nodeTeam.organization_team.name,
        description: nodeTeam.organization_team.description,
        hasIntegrations: false,
        isHidden: nodeTeam.organization_team.is_hidden,
        reward: {
          originalId: Number(nodeTeam.organization_team.reward?.id),
          numberCourses: Number(
            nodeTeam.organization_team.reward?.number_courses,
          ),
        },
        userReward: {
          completedCourses: Number(nodeTeam.user_reward?.completed_courses),
          originalId: Number(nodeTeam.user_reward?.id),
          completedProject: Number(nodeTeam.user_reward?.completed_project),
          status: Number(nodeTeam.user_reward?.status) || null,
          courseProgress: Number(nodeTeam.user_reward?.course_progress),
          projectProgress: Number(nodeTeam.user_reward?.project_progress),
          totalCourses: Number(nodeTeam.user_reward?.total_courses),
          totalProject: Number(nodeTeam.user_reward?.total_project),
        },
        endDate: nodeTeam.organization_team.end_date,
        skill: null,
        userSkill: null,
        academy: {
          logo: nodeTeam.organization_team.academy?.logo || null,
          name: nodeTeam.academy_name || '',
          originalId: nodeTeam.team_id,
        },
        teamImageV2: {
          url: nodeTeam?.organization_team?.team_image
            ? nodeTeam?.organization_team?.team_image?.url
            : '',
          placeholder:
            (nodeTeam?.organization_team?.team_image &&
              nodeTeam?.organization_team?.team_image?.placeholder) ||
            '',
        },
        teamImage: nodeTeam?.organization_team?.team_image?.url || '',
        track: {
          iconSource: '',
          name: nodeTeam.team_name,
        },
      },
      teamName: nodeTeam.team_name,
      originalId: nodeTeam.team_id,
      academyName: nodeTeam.academy_name,
      assignedCourses:
        nodeTeam.team_courses.map(course => {
          return {
            courseId: Number(course.course_id),
            isRequired: course?.is_required,
            isRequiredProject: course?.is_required_project,
            __typename: 'UserTeamCourseDetailNode',
          };
        }) || [],
      coursesSummary: {
        completedQuizCourses: 0,
        createdProjects: 0,
        inProgressCourses: 0,
        isCompleted: false,
        requiredCourses: 0,
        requiredProjects: 0,
        requiredQuizCourses: 0,
        totalCourses: 0,
      },
      expirationDate: nodeTeam.expiration_date,
      isActive: nodeTeam.is_active,
      parsedDate: parsedDate?.toISOString() || undefined,
      isExpired,
      endDate: parsedDate?.toISOString() || undefined,
      parsedDueDate: parsedDate,
    };

    return parsedTeam;
  });

  const orderedByDate = orderByDateProperty<UserTeamItemParsed>(
    parsedItems,
    'parsedDueDate',
  );

  return { teams: orderedByDate };
};

export default useParsedTeamCoursesFromApiv5;
