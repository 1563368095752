import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
var _excluded = ["data", "loading", "called", "error"];
import { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { transformData } from "@crehana/utils";
var MY_DIPLOMAS_QUERY = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "MyDiplomasQuery"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "username"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      },
      directives: []
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "first"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "Int"
        }
      },
      directives: []
    }],
    directives: [],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "certificates"
        },
        arguments: [{
          kind: "Argument",
          name: {
            kind: "Name",
            value: "username"
          },
          value: {
            kind: "Variable",
            name: {
              kind: "Name",
              value: "username"
            }
          }
        }, {
          kind: "Argument",
          name: {
            kind: "Name",
            value: "first"
          },
          value: {
            kind: "Variable",
            name: {
              kind: "Name",
              value: "first"
            }
          }
        }],
        directives: [],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "edges"
            },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "node"
                },
                arguments: [],
                directives: [],
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MyDiplomaFragment"
                    },
                    directives: []
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MyDiplomaFragment"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "CertificateNode"
      }
    },
    directives: [],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "originalId"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "code"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "attachmentUrl"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "imageThumbnailUrl"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "createdDate"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "type"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "enrollment"
        },
        arguments: [],
        directives: [],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "course"
            },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "originalId"
                },
                arguments: [],
                directives: []
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "title"
                },
                arguments: [],
                directives: []
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "promoImage"
                },
                arguments: [],
                directives: []
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "dashboardUrl"
                },
                arguments: [],
                directives: []
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "enrollmentBundle"
        },
        arguments: [],
        directives: [],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "bundle"
            },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "originalId"
                },
                arguments: [],
                directives: []
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "title"
                },
                arguments: [],
                directives: []
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "promoImage"
                },
                arguments: [],
                directives: []
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "enrollmentCareer"
        },
        arguments: [],
        directives: [],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "career"
            },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "originalId"
                },
                arguments: [],
                directives: []
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "title"
                },
                arguments: [],
                directives: []
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "promoImage"
                },
                arguments: [],
                directives: []
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "enrollmentAcademySkillpath"
        },
        arguments: [],
        directives: [],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "skillPath"
            },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "name"
                },
                arguments: [],
                directives: []
              }]
            }
          }]
        }
      }]
    }
  }],
  loc: {
    start: 0,
    end: 233,
    source: {
      body: "#import \"../Fragments/MyDiplomaFragment.graphql\"\n\nquery MyDiplomasQuery($username: String, $first: Int) {\n  certificates(username: $username, first: $first) {\n    edges {\n      node {\n        ...MyDiplomaFragment\n      }\n    }\n  }\n}\n",
      name: "GraphQL request",
      locationOffset: {
        line: 1,
        column: 1
      }
    }
  }
}; // import { DIPLOMAS_MOCK } from '@/Graphql/Queries/__mocks__/MyDiplomasQuery';
var useMyDiplomas = function useMyDiplomas(variables) {
  var _useLazyQuery = useLazyQuery(MY_DIPLOMAS_QUERY, {
      notifyOnNetworkStatusChange: true
    }),
    _useLazyQuery2 = _slicedToArray(_useLazyQuery, 2),
    myDiplomasLazyQuery = _useLazyQuery2[0],
    _useLazyQuery2$ = _useLazyQuery2[1],
    data = _useLazyQuery2$.data,
    loading = _useLazyQuery2$.loading,
    called = _useLazyQuery2$.called,
    error = _useLazyQuery2$.error,
    myDiplomasQuery = _objectWithoutProperties(_useLazyQuery2$, _excluded);
  useEffect(function () {
    if (variables !== null && variables !== void 0 && variables.username) {
      myDiplomasLazyQuery({
        variables: _objectSpread({
          username: variables.username
        }, variables)
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variables === null || variables === void 0 ? void 0 : variables.username]);
  var diplomas = transformData(data === null || data === void 0 ? void 0 : data.certificates).map(function (diploma) {
    return _objectSpread(_objectSpread({}, diploma), {}, {
      diplomaUrl: diploma.code ? "/diplomas/".concat(diploma.code) : diploma.attachmentUrl
    });
  });
  return _objectSpread({
    loading: !called || loading,
    error: error,
    diplomas: diplomas
  }, myDiplomasQuery);
};
export default useMyDiplomas;