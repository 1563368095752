import BookOpen from '@crehana/compass.icons.outline/BookOpen';

import useTeamCoursesTranslation from '../../../hooks/useTeamCoursesTranslation';
import LayoutCardModal from '../../Rewards/LayoutCardModal';

type ModalConfirmSeenProps = {
  isOpenModal: boolean;
  onCloseModal: () => void;
  onConfirmSeen: () => Promise<void>;
};

const ModalConfirmSeen: React.FC<ModalConfirmSeenProps> = ({
  isOpenModal,
  onCloseModal,
  onConfirmSeen,
}) => {
  const { t } = useTeamCoursesTranslation();

  return (
    <LayoutCardModal
      onDismiss={onCloseModal}
      isOpen={isOpenModal}
      contentAction={
        <div className="sm:tw-flex sm:tw-flex-row sm:tw-w-full tw-justify-end">
          <button
            className="sm:tw-mr-0.8 sm:tw-mt-0 sm:tw-w-auto tw-btn-inverse-s tw-inline tw-mt-1.2 tw-w-full sm:tw-btn-tertiary-s sm:tw-border-0 hover:tw-text-neutral-light-700 hover:tw-bg-transparent"
            type="button"
            onClick={onCloseModal}
          >
            {t('LEARN_TEAM_SKIP')}
          </button>
          <button
            className="sm:tw-w-auto tw-btn-primary-s tw-inline tw-w-full "
            type="button"
            onClick={onConfirmSeen}
          >
            {t('LEARN_TEAM_STATE_INTEGRATION')}
          </button>
        </div>
      }
    >
      <div className="tw-flex tw-flex-col tw-mb-2.4 tw-mx-2.4 tw-text-center">
        <div className="tw-mb-1.6 tw-mx-auto tw-p-0.8 tw-w-4.4 tw-h-4.4 tw-rounded-full tw-shadow-[0_0_0_5.6px] tw-shadow-success-100 tw-bg-success-300">
          <BookOpen size={28} className="tw-text-success-500" />
        </div>
        <h5 className="tw-font-h5 tw-font-semibold tw-text-neutral-light-800 dark:tw-text-neutral-light-100">
          {t('LEARN_TEAM_EXTERNAL')}
        </h5>
        <p className="tw-mt-1.2 tw-font-body5 tw-text-neutral-light-800 dark:tw-text-neutral-light-100">
          {t('LEARN_TEAM_EXTERNAL_ASK')}
        </p>
      </div>
    </LayoutCardModal>
  );
};

export default ModalConfirmSeen;
