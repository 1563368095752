import { useEffect } from 'react';

import {
  getCommonAmplitudeProperties,
  getSourceEventParams,
  useAmplitude,
} from '@crehana/analytics';
import { useMe } from '@crehana/auth';

import { Projects, type ProjectsProps } from '@/shared/components';
import Breadcrumb from '@/views/LearnDashboard/components/Breadcrumb/Breadcrumb';
import SectionTitle from '@/views/LearnDashboard/components/SectionTitle';
import { useLearnDashboardContext } from '@/views/LearnDashboard/context';

import { LEARN_DASHBOARD_PAGE_NAME } from '../../../constants';
import { useCrehanaTranslation } from '../../../hooks/useCrehanaTranslation';
import {
  ENROLLMENTS_COURSES_ROUTE,
  ENROLLMENTS_DEGREES_ROUTE,
} from '../../../routes/common.routes';

const ProjectsSection: React.FC<
  React.PropsWithChildren<ProjectsProps>
> = () => {
  const { me } = useMe();
  const { logEvent } = useAmplitude();
  const { t } = useCrehanaTranslation();
  const { isPlayroomEnabled, isLoadingPlayroomActivated } =
    useLearnDashboardContext();

  const isB2B = !!me?.isB2B;
  const organizationSlug = me?.organization?.slug;

  useEffect(() => {
    if (!isLoadingPlayroomActivated) {
      logEvent({
        event: 'Render Home Projects',
        data: {
          ...getSourceEventParams(),
          ...getCommonAmplitudeProperties(me!, isPlayroomEnabled),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingPlayroomActivated, isPlayroomEnabled]);

  return (
    <>
      <Breadcrumb
        items={[{ label: t('LABEL_SIDEBAR_ITEM_MY_PROJECTS') }]}
        className="my-20"
      />

      <SectionTitle
        title={t('LABEL_SIDEBAR_ITEM_MY_PROJECTS')}
        noMargin
        className="mb-20"
      />

      <Projects
        amplitudePageName={LEARN_DASHBOARD_PAGE_NAME}
        enrollmentsCoursesRoutePath={ENROLLMENTS_COURSES_ROUTE.path}
        enrollmentsDegreesRoutePath={ENROLLMENTS_DEGREES_ROUTE.path}
        enrollmentsRoute={isB2B ? `/org/${organizationSlug}/learn` : '/learn'}
      />
    </>
  );
};

export default ProjectsSection;
