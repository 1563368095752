import { FC } from 'react';

import Lock from '@crehana/compass.icons.fill/Lock';
import Star from '@crehana/compass.icons.fill/Star';
import { PlayroomRewardIndicatorWithRive } from '@crehana/compass.playroom';
import { DateTag, TrackTag } from '@crehana/compass.ui';
import { useMediaQuery } from '@crehana/react-hooks';

import useTeamCoursesTranslation from '../../hooks/useTeamCoursesTranslation/useTeamCoursesTranslation';
import { THeaderDetail } from '../../types';
import { humanizeExpirationDate } from '../../utils';

const DetailDesktop: FC<THeaderDetail> = ({
  trackName,
  isTrackReward,
  assigned,
  coinPlayroomNumber,
  description,
  date,
  typeTrack = 'active',
  teamExpired,
}) => {
  const { t, languageCode } = useTeamCoursesTranslation();
  const { matches: isMd } = useMediaQuery('md');

  const shouldRenderRewardIndicator =
    coinPlayroomNumber !== undefined && coinPlayroomNumber > 0;

  return (
    <div
      className="tw-bg-neutral-light-100 tw-rounded-1 tw-p-2.4 tw-mb-1.6 dark:tw-bg-elevation-dark-04"
      data-testid="detailDesktopTrack"
    >
      <section>
        <div className="tw-flex tw-mb-0.8 tw-w-full">
          <div className="tw-flex tw-flex-col tw-w-full">
            <div className="tw-items-center md:tw-justify-between tw-justify-end tw-hidden md:tw-flex">
              {isMd && (
                <TrackTag
                  label={trackName}
                  className="tw-w-fit"
                  disabled={typeTrack === 'disabled'}
                />
              )}
              {typeTrack === 'disabled' && (
                <TrackTag
                  label={t('LEARN_TEAM_HEADER_DETAIL_DISABLED')}
                  trackDisabled
                  icon={Lock}
                  className="tw-h-fit tw-w-fit"
                />
              )}
            </div>
            <div className="md:tw-flex md:tw-items-center tw-flex tw-items-start tw-justify-between tw-mt-1.2">
              <div>
                <p className="tw-font-subtitle4  tw-text-neutral-light-800 dark:tw-text-neutral-light-100 tw-mb-0.8 md:tw-mb-0">
                  {t('LEARNING_PATHS_LEFT_SIDE_TITLE')} - {trackName}
                </p>
                {assigned && (
                  <span className="tw-font-body5 tw-text-neutral-light-700 dark:tw-text-neutral-dark-500 md:tw-ml-0.8">
                    {t('LEARN_TEAM_HEADER_DETAIL_ASSIGNED')}: {assigned}
                  </span>
                )}
              </div>
              {typeTrack === 'disabled' && (
                <TrackTag
                  label={t('LEARN_TEAM_HEADER_DETAIL_DISABLED')}
                  trackDisabled
                  icon={Lock}
                  className={'tw-h-fit tw-w-fit tw-block md:tw-hidden'}
                />
              )}
            </div>
          </div>
          {date && typeTrack === 'active' && (
            <div className="tw-flex-shrink-0 tw-ml-1.2 tw-relative">
              <DateTag
                label={t(
                  teamExpired
                    ? 'LEARN_TEAM_HEADER_DETAIL_EXPIRED'
                    : 'LEARN_TEAM_HEADER_DETAIL_DEFEATED',
                )}
                date={
                  humanizeExpirationDate(date, {
                    languageCode,
                  }) || ''
                }
                isExpired={teamExpired}
              />
            </div>
          )}

          {typeTrack === 'success' && (
            <TrackTag
              label={t('LEARN_TEAM_HEADER_DETAIL_SUCESS')}
              className="tw-w-fit tw-h-fit"
              trackCompleted
            />
          )}
        </div>
        <div>
          {description && typeTrack !== 'success' && (
            <p className="tw-font-body5 tw-text-neutral-light-800 dark:tw-text-neutral-dark-300">
              {description}
            </p>
          )}
          <div className="tw-flex tw-mt-0.8">
            {isTrackReward && typeTrack !== 'disabled' && (
              <TrackTag
                label={t('LEARN_TEAM_HEADER_DETAIL_REWARD')}
                icon={Star}
                className="tw-mr-1.2"
                type="info"
              />
            )}
            {shouldRenderRewardIndicator && typeTrack !== 'disabled' && (
              <PlayroomRewardIndicatorWithRive coins={coinPlayroomNumber} />
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default DetailDesktop;
