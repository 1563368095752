import { FC } from 'react';
import * as React from 'react';

import { IconProps } from '@crehana/icons/types';
import { colors } from '@crehana/ui';

const ArrowIcon: FC<React.PropsWithChildren<IconProps>> = ({
  className,
  color: colorProp = colors.baseMain,
  ignoreColor,
}) => {
  const color = !ignoreColor ? colorProp : undefined;

  return (
    <svg
      className={className}
      width={51}
      height={44}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.559.048H21.672l15.43 21.851-15.43 21.851h13.887l15.43-21.85L35.558.047z"
        fill={color}
      />
      <path
        d="M13.957.048H.07L15.5 21.899.07 43.75h13.887l15.43-21.85L13.957.047z"
        fill={color}
      />
    </svg>
  );
};

export default ArrowIcon;
