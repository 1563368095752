import { useMemo } from 'react';

import { LazyImage } from '@crehana/compass.ui';
import { formatDuration } from '@crehana/date';
import { useMediaQuery } from '@crehana/react-hooks';

import {
  COURSE_CARD_IMAGE_PARAMS,
  DEFAULT_IMG_SKILLPATH,
} from '../../../constants';
import useTeamCoursesTranslation from '../../../hooks/useTeamCoursesTranslation';
import { TCoursesTrack, TInformation } from '../../../types';
import RingOrder from '../../RingOrder';
import Information from './Information';
import InformationTablet from './InformationTablet';

export type CourseCardRevampProps = {
  course: TCoursesTrack;
  position: number;
  visibleOrder?: boolean;
};

const CourseCardPreRequirements: React.FC<CourseCardRevampProps> = ({
  course,
  position,
  visibleOrder,
}) => {
  const { t } = useTeamCoursesTranslation();
  const { matches: isXs } = useMediaQuery({ only: 'xs' });

  const durationCourse = useMemo(() => {
    if (course.duration && course.progress) {
      return t('LEARN_TEAM_TIME', {
        interpolation: {
          duration: formatDuration(
            course.duration - course.duration * (course.progress / 100),
          ),
        },
      });
    }
    if (course.duration)
      return t('LEARN_TEAM_TIME_DURATION', {
        interpolation: {
          duration: formatDuration(course.duration),
        },
      });

    if (course.duration && isXs) return formatDuration(course.duration);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course, isXs]);

  const renderInformation = () => {
    const paramsInformation: TInformation = {
      title: course.courseName,
      duration: durationCourse,
      teacherName: course.profesorFullName,
      isRequired: false,
    };

    return isXs ? (
      <Information
        {...paramsInformation}
        isVisibleTeacherName={Boolean(course.progress && course.progress > 0)}
      />
    ) : (
      <InformationTablet {...paramsInformation} />
    );
  };

  return (
    <div
      className="sm:dark:tw-bg-transparent dark:tw-bg-elevation-dark-04 tw-bg-neutral-light-100 tw-flex tw-flex-col sm:tw-flex-row tw-p-1.2 tw-relative tw-rounded-0.8 sm:tw-shadow-none dark:tw-shadow-none sm:tw-p-0.8 tw-transition-all hover:tw-shadow-01 hover:tw-z-2"
      role="listitem"
    >
      <div className="tw-flex-1 tw-w-full tw-text-left tw-flex tw-flex-col sm:tw-flex-row">
        {visibleOrder && (
          <div className="tw-h-2.4 tw-mr-0.8 tw-my-auto tw-w-2.4 tw-hidden sm:tw-block">
            <RingOrder
              order={position}
              porcentage={course.progress || 0}
              className="tw-relative !tw-inset-0"
            />
          </div>
        )}
        {/* seccion de imagen */}
        <div className="tw-relative tw-flex tw-flex-col tw-w-full tw-overflow-hidden tw-rounded-0.8 tw-h-8 tw-mb-0.8 sm:tw-mb-0 sm:tw-mr-1.2 sm:tw-w-[9.2rem] sm:tw-h-[5.2rem]">
          <LazyImage
            src={course.courseImage || DEFAULT_IMG_SKILLPATH}
            imgxParams={COURSE_CARD_IMAGE_PARAMS}
            alt={course.courseName || ''}
            isTransparent
            imgClassName="object-cover"
            className="tw-w-full tw-h-full"
          />
        </div>
        {renderInformation()}
      </div>
    </div>
  );
};

export default CourseCardPreRequirements;
