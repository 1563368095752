import { FC, useState } from 'react';

import { clsx } from 'clsx';

import ArrowIosDown from '@crehana/compass.icons.outline/ArrowIosDown';
import ArrowIosUp from '@crehana/compass.icons.outline/ArrowIosUp';
import { Collapse, TTypeProject } from '@crehana/compass.ui';
import { cn } from '@crehana/compass.ui/v2';

import { StatusProjectEnum } from '@/shared/types/graphql/globals.v5';

import useTeamCoursesTranslation from '../../hooks/useTeamCoursesTranslation/useTeamCoursesTranslation';
import {
  customType,
  type TCertificacionCriteria,
  type TLayout,
} from '../../types';
import { LayoutCardProject } from '../CardProject/LayoutCardProject';
import CardTrackDisabled from '../CardTrackDisabled';
import CardDownloadCertificate from './CardDownloadCertificate';
import CertificacionCriteria from './CertificacionCriteria';

export const Layout: FC<TLayout> = ({
  summaryCourse,
  summaryProject,
  summaryQuiz,
  teamDisabled,
  dataCertificate,
  isCompletedTrack,
  trackProjects,
  hasCertificate,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const { t } = useTeamCoursesTranslation();

  const shouldRenderCardDownloadCertificate =
    isCompletedTrack && hasCertificate && dataCertificate;

  const toogleTitleProject = (
    type?: StatusProjectEnum | null,
  ): string | undefined => {
    if (type) return t(`PROJECT_TYPE_${type}`);
  };

  const toogleTypeProject = (
    type?: StatusProjectEnum | null,
  ): TTypeProject | undefined => {
    if (type) {
      return customType[type];
    }
  };

  const handleClickCollapse = () => {
    setIsOpen(!isOpen);
  };

  const _itemsCertificationCriteria = (): TCertificacionCriteria[] => {
    const _currentItems: TCertificacionCriteria[] = [
      {
        description: t('LEARN_TEAM_CRITERIA_COURSE_MANDATORY'),
        numberCertifications: {
          completed: summaryCourse.progress,
          expected: summaryCourse.required,
        },
        state: t('LEARN_TEAM_CRITERIA_COMPLETE'),
        typeCertification: t(
          summaryCourse.required > 1
            ? 'LEARN_TEAM_CRITERIA_COURSE_SHORT_plural'
            : 'LEARN_TEAM_CRITERIA_COURSE_SHORT',
        ),
      },
    ];
    _currentItems.push({
      description: t('LEARN_TEAM_CRITERIA_QUIZ_MANDATORY'),
      numberCertifications: {
        completed: summaryQuiz.progress,
        expected: summaryQuiz.required,
      },
      state: t('LEARN_TEAM_CRITERIA_APPROVES'),
      typeCertification: t(
        summaryQuiz.required > 1
          ? 'LEARN_TEAM_CRITERIA_QUIZ_SHORT_plural'
          : 'LEARN_TEAM_CRITERIA_QUIZ_SHORT',
      ),
    });

    _currentItems.push({
      description: t('LEARN_TEAM_CRITERIA_PROJECT_MANDATORY', {
        interpolation: {
          count: summaryProject.required,
        },
      }),
      numberCertifications: {
        completed: summaryProject.progress,
        expected: summaryProject.required,
      },
      state: t('LEARN_TEAM_CRITERIA_APPROVES'),
      typeCertification: t(
        summaryProject.required > 1
          ? 'LEARN_TEAM_CRITERIA_PROJECT_SHORT_plural'
          : 'LEARN_TEAM_CRITERIA_PROJECT_SHORT',
      ),
    });

    return _currentItems.filter(
      items => items.numberCertifications.expected !== 0,
    );
  };

  const redirectProject = (url?: string | null) => {
    if (url) window.open(url, '_blank');
  };

  const selectIconArrow = () =>
    isOpen ? (
      <ArrowIosUp size={20} className="tw-text-current" />
    ) : (
      <ArrowIosDown size={20} className="tw-text-current" />
    );

  return (
    <>
      {!teamDisabled ? (
        <div
          className={cn(
            'tw-bg-neutral-light-100 tw-p-2 tw-rounded-1 dark:tw-bg-elevation-dark-04',
            isOpen ? 'tw-h-fit' : 'tw-h-6.4',
          )}
        >
          <div className=" tw-flex tw-items-center tw-justify-between">
            <p className="tw-font-subtitle5 tw-text-neutral-light-800 dark:tw-text-neutral-light-100">
              {t('LEARN_TEAM_CRITERIA')}
            </p>
            <button
              className="tw-text-neutral-light-700 dark:tw-text-neutral-dark-400"
              onClick={handleClickCollapse}
              type="button"
            >
              {selectIconArrow()}
            </button>
          </div>
          <div
            className={clsx(
              isOpen ? 'tw-pt-1 tw-mt-1.2' : 'tw-pt-0 tw-mt-0 tw-opacity-0',
            )}
          >
            <Collapse
              open={isOpen}
              animated
              className="!tw-h-full !tw-overflow-visible"
            >
              {_itemsCertificationCriteria().map((item, i) => (
                <CertificacionCriteria
                  key={`certification_criteria_${i * 1}`}
                  {...item}
                  isBorder={_itemsCertificationCriteria().length - 1 !== i}
                  className="tw-mb-1.2"
                />
              ))}
              {summaryProject.required > 0 && trackProjects && (
                <LayoutCardProject
                  key={trackProjects?.originalId}
                  type={toogleTypeProject(trackProjects?.currentActionState)}
                  typeTitle={toogleTitleProject(
                    trackProjects?.currentActionState,
                  )}
                  title={trackProjects?.title || ''}
                  logo={trackProjects?.projectImageThumbnailUrl || ''}
                  handleClick={() =>
                    redirectProject(trackProjects?.url.relativeUrl)
                  }
                />
              )}
              {shouldRenderCardDownloadCertificate &&
                dataCertificate?.attachmentUrl && (
                  <CardDownloadCertificate
                    urlAttachmenteCertificate={dataCertificate?.attachmentUrl}
                  />
                )}
            </Collapse>
          </div>
        </div>
      ) : (
        <CardTrackDisabled />
      )}
    </>
  );
};
