import { MainCategoriesContentSkeleton } from '@crehana/compass.knowledgehub/pages';
import { Skeleton } from '@crehana/compass.ui';
import { Container } from '@crehana/ui';

const SkeletonStartSection = () => {
  return (
    <section className="w-full h-full">
      <Container type="dashboard" className="tw-my-3.2">
        <div className="tw-grid tw-gap-1.6 tw-w-full tw-grid-cols-1 md:tw-grid-cols-[75rem,1fr] ">
          <div className="tw-p-2.4 dark:tw-bg-elevation-dark-04 tw-bg-neutral-light-100 tw-rounded-2 tw-h-[44.4rem] tw-flex tw-flex-col tw-justify-end">
            <div className="tw-mb-1.6">
              <Skeleton
                animated
                className="tw-w-[80%] md:tw-w-[43.2rem] tw-mb-0.8 tw-h-2.4 tw-bg-neutral-light-400 dark:tw-bg-neutral-dark-400 tw-rounded-1"
              />
              <Skeleton
                animated
                className="tw-w-[80%] md:tw-w-[33.7rem] tw-mb-0.8 tw-h-2.4 tw-bg-neutral-light-400 dark:tw-bg-neutral-dark-400 tw-rounded-1"
              />
              <Skeleton
                animated
                className="tw-w-[12.6rem] tw-h-1.6 tw-bg-neutral-light-300 dark:tw-bg-neutral-dark-300 tw-rounded-1"
              />
            </div>
            <Skeleton
              animated
              className="tw-w-[10.7rem] tw-h-4.4 tw-bg-neutral-light-600 tw-rounded-1"
            />
          </div>
          <div className="tw-p-2.4 dark:tw-bg-elevation-dark-04 tw-bg-neutral-light-100 tw-rounded-2 tw-h-[44.4rem] tw-flex tw-flex-col tw-justify-end">
            <Skeleton
              animated
              className="tw-w-12.4 tw-h-2 tw-bg-neutral-light-400 dark:tw-bg-neutral-dark-400 tw-rounded-1.2 tw-mb-2"
            />
            <Skeleton
              animated
              className="tw-w-full tw-h-[15.2rem] tw-bg-neutral-light-400 dark:tw-bg-neutral-dark-400 tw-rounded-2 tw-mb-2.4"
            />
            {Array.from({ length: 3 }).map((_, index: number) => (
              <div key={`skeleton_${index * 1}`} className="tw-mb-1.2 tw-flex">
                <div className="tw-rounded-full tw-w-3.6 tw-h-3.6 tw-border-0.2 tw-border-solid tw-border-neutral-light-500" />
                <div className="tw-ml-1.2">
                  <Skeleton
                    animated
                    className="tw-w-[17.6rem] tw-h-2 tw-bg-neutral-light-400 dark:tw-bg-neutral-dark-400 tw-rounded-2 tw-mb-0.8"
                  />
                  <Skeleton
                    animated
                    className="tw-w-12.4 tw-h-1.6 tw-bg-neutral-light-300 dark:tw-bg-neutral-dark-300 tw-rounded-2"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        <MainCategoriesContentSkeleton className="tw-mt-3.2" />
      </Container>
    </section>
  );
};

export default SkeletonStartSection;
