import type { Dependency, TCoursesTrack } from '../types';

type TGetBlockingDependenciesProps = {
  dependencies: Dependency[];
  resource: TCoursesTrack;
  integrationResource: TCoursesTrack[];
};

function getCourseDependencies(courseId: number, dependencies: Dependency[]) {
  return (
    dependencies.find(
      courseDependency => courseDependency.courseId === courseId,
    )?.dependsOn ?? []
  );
}

export default function getBlockingDependencies({
  dependencies,
  resource: course,
  integrationResource: selectedContent,
}: TGetBlockingDependenciesProps): TCoursesTrack[] {
  const courseDependencies = getCourseDependencies(
    Number(course.courseId),
    dependencies,
  );

  return selectedContent
    .filter(content => courseDependencies.includes(Number(content.courseId)))
    .map(fDep => fDep as TCoursesTrack);
}
