import { LEARN_DASHBOARD_PAGE_KEY } from '@/views/LearnDashboard/constants';

const es = {
  [LEARN_DASHBOARD_PAGE_KEY]: {
    // Titles
    UD_ENROLLMENTS_TITLE: 'Mis cursos',
    UD_ENROLLMENTS_COURSES: 'Todos mis cursos',
    UD_ENROLLMENTS_TRACKS: 'Mis especializaciones',
    UD_ENROLLMENTS_CAREERS: 'Mis carreras',
    UD_ENROLLMENTS_BUNDLES: 'Mis certificaciones',
    UD_ENROLLMENTS_DEGREES: 'MicroDegree',
    UD_ENROLLMENTS_ACADEMIES: 'Academias',
    UD_ENROLLMENTS_NEW_TAG: 'Nuevo',
    UD_ENROLLMENTS_BOOTCAMPS: 'Bootcamp',
    UD_CLASSNOTES_TITLE: 'Mis apuntes',
    UD_PROJECTS_TITLE: 'Mis proyectos',
    UD_DIPLOMAS_TITLE: 'Mis diplomas',
    UD_STUDY_PLAN_TITLE: 'Mi plan de estudios',
    // Onboarding
    UD_ONBOARDING_WELCOME_TITLE:
      '¡Te damos la bienvenida a tu nuevo panel de estudiante!',
    UD_ONBOARDING_WELCOME_DESC:
      'Te contamos las nuevas mejoras que tenemos para ti.',
    UD_ONBOARDING_MOBILE_MENU_TITLE: 'Nueva barra de menú',
    UD_ONBOARDING_MOBILE_MENU_DESC:
      'Implementamos una nueva barra de menú para que tengas una mejor navegación entre las secciones de Mis cursos, Mis apuntes, Mis proyectos, Mis diplomas y Mi plan de estudios.',
    UD_ONBOARDING_ENROLLMENTS_TITLE: 'Mis cursos',
    UD_ONBOARDING_ENROLLMENTS_DESC:
      'Aquí no solo encontrarás todos los cursos inscritos, sino tambien el progreso de tus especializaciones.',
    UD_ONBOARDING_CLASSNOTES_TITLE: 'Mis apuntes',
    UD_ONBOARDING_CLASSNOTES_DESC:
      'Todos los apuntes que tomes dentro de todos tus cursos los podrás revisar aquí.',
    UD_ONBOARDING_PROJECTS_TITLE: 'Mis proyectos',
    UD_ONBOARDING_PROJECTS_DESC:
      'Encuentra en esta sección todos los proyectos que estés trabajando. Tambien podrás visualizar el estado de revisión de cada uno, incluso los que ya están certificados.',
    UD_ONBOARDING_DIPLOMAS_TITLE: 'Mis diplomas',
    UD_ONBOARDING_DIPLOMAS_DESC:
      'Ubica aquí tus diplomas Crehana, una vez que ya haya sido aprobado tu proyecto final.',
    UD_ONBOARDING_STUDY_PLAN_TITLE: 'Mi plan de estudios',
    UD_ONBOARDING_STUDY_PLAN_DESC:
      '¿Sabías que las personas más exitosas generan hábitos? En esta sección te ayudamos a cumplir tus metas.',
    UD_ONBOARDING_NIGHT_SWITCH_TITLE: 'Únete al lado oscuro',
    UD_ONBOARDING_NIGHT_SWITCH_DESC:
      'Personaliza tu panel al modo nocturo activando este switch.',
    UD_ONBOARDING_CONFIG_TITLE: 'Ajustes dentro de tu cuenta',
    UD_ONBOARDING_CONFIG_DESC:
      'Ten un acceso más directo a toda la configuración de tu cuenta en este enlace.',
    UD_ONBOARDING_FAQ_TITLE: 'Resolviendo tus preguntas',
    UD_ONBOARDING_FAQ_DESC:
      'Si aún tienes dudas sobre como funciona la plataforma, con mucho gusto te podemos ayudar dentro del Centro de Ayuda Crehana.',
    UD_ONBOARDING_SEARCH_TITLE: '¿Qué buscas en tu panel?',
    UD_ONBOARDING_SEARCH_DESC:
      'Ten una búsqueda más acertada de lo que necesitas de tu panel en esta sección.',
    // Filters & FAQ
    UD_FILTER_LABEL: 'Filtrar por',
    UD_SEARCH_HINT: 'Busca tus cursos, proyectos...',
    UD_SEARCH_PROJECT_SUBTITLE: 'Proyecto de {{course}}',
    UD_SEARCH_ERROR: 'Ocurrió un error al obtener la información de tu panel.',
    UD_SEARCH_NO_RESULTS: 'No encontramos resultados para tu búsqueda',
    UD_GO_TO_COURSES: 'Ver todos mis cursos',
    UD_CONFIGURATION: 'Configuración',
    UD_FAQ_COURSE: 'No encuentro mi curso',
    UD_FAQ_PROJECT_UPLOAD: 'Subida de proyectos',
    UD_FAQ_START_COURSES: '¿Cómo empiezo mis cursos?',
    UD_FAQ_DIPLOMA: '¿Cómo obtengo mi diploma?',
    UD_FAQ_ONBOARDING: '¿Cómo funciona mi panel?',
    UD_FAQ_GENERAL: '¿Aún con dudas? Entra a nuestro',
    UD_FAQ_GENERAL_LINK: 'Centro de Ayuda Crehana',
    // Enrollments components and actions
    UD_TRACK_TITLE: 'Especialización en {{title}}',
    UD_DURATION: 'Duración: {{duration}}',
    UD_DURATION_LEFT: 'Tiempo restante: {{duration}}',
    UD_DOWNLOAD_SYLLABUS: 'Descarga el sílabo',
    UD_DOWNLOAD_DIPLOMA: 'Descarga tu diploma',
    UD_START_COURSE: 'Empezar curso',
    UD_CONTINUE_COURSE: 'Continuar curso',
    UD_REWATCH_COURSE: 'Ver nuevamente',
    UD_CREATE_PROJECT: 'Iniciar proyecto',
    UD_EDIT_PROJECT: 'Editar proyecto',
    UD_GO_TO_PROJECT: 'Ver proyecto',
    UD_MODULE_LIST_PROGRESS: '{{completed}}/{{total}} módulos completados',
    UD_COURSE_LIST_PROGRESS: '{{completed}}/{{total}} cursos completados',
    UD_PROJECT_LIST_PROGRESS: '{{completed}}/{{total}} proyectos certificados',
    UD_SAVED_COURSES_TITLE: 'Mis guardados',
    UD_SAVED_COURSES_TOUR_TITLE: 'Guarda para ver después',
    UD_SAVED_COURSES_TITLE_DESC:
      'Ahora puedes guardar cursos. ¡No pierdas de vista tu aprendizaje!',
    // FAQ'S
    UD_UPLOAD_PROJECTS: '¿Cómo subo mis proyectos?',
    UD_START_COURSES: '¿Cómo empiezo mis cursos asignados?',
    UD_CHAT_WITH_MENTORS: '¿Cómo converso con los mentores?',
    UD_UPLOAD_PROJECT: '¿Cómo subo mi proyecto?',
    UD_CANT_FIND_COURSES: 'No encuentro mis cursos',
    UD_UPLOAD_PROJECTS_TO_PLATFORM: '¿Cómo subo mi proyecto a la plataforma?',
    UD_PROJECT_DESCRIPTION: '¿Dónde encuentro la descripción de mi proyecto?',
    UDE_ACADEMIES_ERROR: 'Ocurrió un error al obtener tus academias',
    REWARD_BANNER_TEXT:
      '¡Gana mientras aprendes!, descubre cómo obtener puntos, insignias y recompensas.',
    REWARD_BANNER_CAT: 'Conoce más',
    REWARDS_TITLE: '¿Quieres añadir la función de Rewards?',
    REWARDS_SUBTITLE: 'Conoce más',
    REWARDS_COPY1:
      'Al terminar las rutas de \n aprendizaje los estudiantes \n pueden elegir cursos libres.',
    REWARDS_COPY2:
      'Tendrán acceso a todo el \n catologo de cursos de \n Crehana.',
    REWARDS_COPY3: 'Motiva a los estudiantes \n para que sigan \n aprendiendo.',
    REWARDS_ACCEPT: 'Sí, quiero',
    REWARDS_CANCEL: 'Ahora no',
    MAIN_SECTION_TITLE_WELCOME_BACK: '¡Qué bueno verte!',
    MAIN_SECTION_TITLE_GREETING: 'Hola, **{{name}}**.',
    MAIN_SECTION_TITLE_WELCOME: '¡Te damos la bienvenida a Crehana!',

    LABEL_SIDEBAR_ITEM_COMEBACK: 'Volver a mis productos',
    LABEL_SIDEBAR_ITEM_COMEBACK_SHORT: 'Volver',
    LABEL_SIDEBAR_ITEM_TITLE: 'E-Learning',
    LABEL_SIDEBAR_ITEM_START: 'Inicio',
    LABEL_SIDEBAR_ITEM_KNOWLEDGE_HUB: 'Catálogo',
    LABEL_SIDEBAR_ITEM_CONTENT_FOR_YOU: 'Más contenido',
    LABEL_SIDEBAR_ITEM_MY_COURSES: 'Mis cursos',
    LABEL_SIDEBAR_ITEM_MY_COURSES_SEPARATOR: 'APRENDIZAJE Y PROGRESO',
    LABEL_SIDEBAR_ITEM_MY_COURSES_BREADCRUMB: 'Cursos',
    LABEL_SIDEBAR_ITEM_MY_COURSES_TITLE: 'Mis cursos',
    LABEL_SIDEBAR_ITEM_MY_COURSES_ENROLLED_MOBILE: 'Cursos',
    LABEL_SIDEBAR_ITEM_MY_COURSES_SAVED: 'Cursos guardados',
    LABEL_SIDEBAR_ITEM_MY_COURSES_TRACKS: 'Rutas de aprendizaje',
    LABEL_SIDEBAR_ITEM_MY_COURSES_TRACKS_DETAIL: 'Rutas de aprendizaje de',
    LABEL_SIDEBAR_BACKOUT: 'Regresar',
    LABEL_SIDEBAR_ITEM_MY_COURSES_SUBTITLE:
      'Explora tus rutas asignadas para un aprendizaje personalizado.',
    LABEL_SIDEBAR_ITEM_MY_COURSES_LIVE_SESSIONS: 'Sesiones en vivo',
    LABEL_SIDEBAR_ITEM_MY_COURSES_NOTES: 'Apuntes',
    LABEL_SIDEBAR_ITEM_MY_COURSES_CERTIFICATIONS: 'Diplomas',
    LABEL_SIDEBAR_ITEM_MY_PROJECTS: 'Proyectos',
    LABEL_SIDEBAR_ITEM_MY_STUDY_PLAN: 'Plan de estudios',
    LABEL_SIDEBAR_ITEM_SKILL_XP: 'Skill XP',
    LABEL_SIDEBAR_ITEM_SKILL_XP_EVOLUTION: 'Skill XP Evolution',
    LABEL_SIDEBAR_ITEM_SKILL_XP_EVOLUTION_PENDING: 'Tests pendientes',
    LABEL_SIDEBAR_ITEM_SKILL_XP_EVOLUTION_SOLVED: 'Tests resueltos',
    LABEL_SIDEBAR_ITEM_SKILL_XP_SEPARATOR: 'EVALUACIÓN Y LOGROS',
    LABEL_SIDEBAR_ITEM_MENTORS: 'Mentorías',
    LABEL_SIDEBAR_ITEM_BLOG: 'Blog',
    LABEL_SIDEBAR_ITEM_LIVES: 'Lives',
    LABEL_SIDEBAR_ITEM_TOOLS: 'Herramientas',
    LABEL_SIDEBAR_ITEM_PLAYROOM_ACHIEVEMENTS: 'Logros',
    LABEL_SIDEBAR_ITEM_PLAYROOM_REWARDS: 'Recompensas',
    LABEL_SIDEBAR_ITEM_PLAYROOM_TAB: 'Gamificación',
    SHOW_MORE: 'Ver más',
    B2B_CONTINUE_WITH_LAST_ACTION_TITLE: 'Continúa donde te quedaste',
    GO_TO_MY_COURSES: 'Ir a mis cursos',
    B2B_START_SECTION_MANDATORY_COURSES: 'Cursos Obligatorios',
    B2B_START_SECTION_MANDATORY_PROJECTS: 'Proyectos obligatorios',
    B2B_START_SECTION_GOTO_ROUTES: 'Ir a rutas de aprendizaje',
    B2B_START_SECTION_GOTO_ROUTES_MOBILE: 'Ir a mis rutas',
    B2B_START_SECTION_YOUR_LEARNING_PATH: 'Tus rutas de aprendizaje',
    CONTENT_FOR_YOU_SECTION_BREADCRUMB: 'Contenido para ti',
    CONTENT_FOR_YOU_SECTION_DESCRIPTION:
      'Descubre cursos nuevos, rutas, proyectos, artículos de blog y más según tus intereses.',
    HITS_SECTION_MOBILE_TITLE: 'Crehana Hits',
    HITS_SECTION_TITLE: 'Crehana Hits, aprende con tus celebridades favoritas',
    BLOG_SECTION_TITLE: 'Últimos artículos del blog',

    DOWNLOAD_FILTER: 'Tipo de certificación',
    DOWNLOAD_FILTER_ALL: 'Todos ({{count}})',
    DOWNLOAD_FILTER_DIPLOMA: 'Certificados ({{count}})',
    DOWNLOAD_FILTER_PROOF: 'Constancias ({{count}})',
    DOWNLOAD_DIPLOMA: 'Certificado',
    DOWNLOAD_PROOF: 'CONSTANCIA',
    DOWNLOAD_DESCRIPTION_GENERAL:
      'En total lograste {{diplomasCount}} certificados y {{certificateCount}} constancias de participación',

    SKILLXP_EVOLUTION_REMINDER_PENDINGS_TITLE:
      'Tienes {{count}} Skill XP Test por resolver',
    SKILLXP_EVOLUTION_REMINDER_PENDINGS_SUBTITLE:
      'Tu administrador está esperando que resuelvas tus test para continuar con la asignación de tus rutas de aprendizaje.',
    SKILLXP_EVOLUTION_REMINDER_PENDINGS_DISMISS: 'Omitir Skill XP',
    SKILLXP_EVOLUTION_REMINDER_PENDINGS_CONFIRM: 'Iniciar Skill XP',

    START_LEARNING_PATH: 'Inicia tu aprendizaje',
    START_LEARNING_UNASSIGNED_TITLE: 'Aún no creas tu plan de estudios',
    START_LEARNING_UNASSIGNED_SUBTITLE:
      'Organiza tu semana para alcanzar tus objetivos de aprendizaje.',
    START_LEARNING_UNASSIGNED_CTA: 'Crear plan de estudios',
    START_LEARNING_EDIT: 'Editar',
    START_LEARNING_EMPTY_ASSIGNED: '¡Solo queda que te asignen cursos!',
    START_LEARNING_EMPTY_MESSAGE:
      'Cuando tu administrador lo realice, podrás visualizar tu progreso aquí.',
    START_LEARNING_HEADER: 'Tu progreso',
    START_LEARNING_EMPTY_TITLE: '¡Es hora de comenzar!',
    START_LEARNING_CAN: '¡Tu puedes!',
    START_LEARNING_LABEL_COMPLETED: '**¡Lo lograste!** ¡Sigue así!',
    START_LEARNING_LABEL_SUCCESS: '¡El último esfuerzo!',
    START_LEARNING_LABEL_WARNING: '¡No te detengas!',
    START_LEARNING_MESSAGE_OBJECTIVE: 'para completar el objetivo semanal',
    START_LEARNING_GOTO_PROJECT: 'Ir a mis proyectos',
    START_LEARNING_COURSE_TITLE: '{{count}} Curso por completar',
    START_LEARNING_COURSE_TITLE_plural: '{{count}} Cursos por completar',
    START_LEARNING_COURSE_COMPLETED:
      'Completaste {{count}} de {{countTarget}} cursos',
    START_LEARNING_ROUTE_TITLE: '{{count}} ruta por completar',
    START_LEARNING_ROUTE_TITLE_plural: '{{count}} Rutas por completar',
    START_LEARNING_PROJECT_TITLE: '{{count}} Proyecto por completar',
    START_LEARNING_PROJECT_TITLE_plural: '{{count}} Proyectos por completar',
    START_LEARNING_ROUTE_COMPLETED:
      'Completaste {{count}} de {{countTarget}} Rutas',
    START_LEARNING_PROJECT_COMPLETED:
      'Completaste {{count}} de {{countTarget}} Proyectos',
    START_LEARNING_TRACK: 'Tus cursos pendientes a desarrollar',
    START_LEARNING_SHOW_CONTENT: 'Más contenido de tu interés',
    START_LEARNING_SHOW_CATALOG: 'Ver catálogo',
    START_LEARNING_ONLYPANEL_MESSAGE_TITLE:
      '¡Sumérgete en nuevas experiencias de aprendizaje!',
    START_LEARNING_ONLYPANEL_MESSAGE_SUBTITLE:
      'Aquí descubrirás contenido recientemente visto cuando te asignen tu primer curso.',
    START_LAST_PLAY: 'Última reproducción',
    START_LEARNING_READY_COURSE: 'Iniciar curso',
    START_LEARNING_SHOW_MORE: 'Más info.',

    LIVE_SESSION_SUBTITLE:
      'Aquí podrás ver todas las sesiones que te han sido asignadas',
  },
};

export default es;
