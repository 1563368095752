import { INTERN_TRANSLATION_KEY } from '../constants';
import type { TCoursesThatDontBelongToTracksPortugueseTranslations } from '../types';

const PT: TCoursesThatDontBelongToTracksPortugueseTranslations = {
  [INTERN_TRANSLATION_KEY]: {
    NEW_TAG: 'Novo',
    SEE_ALL_LINK: 'Ver tudo',
    SEE_ALL_LINK_SESSIONS: 'Ver tudo',
    COURSES_WITHOUT_TRACKS_TITLE: 'Cursos atribuídos para você',
    CTA_UNDERSTOOD_COURSE: 'Entendi',
    EMPTY_TITLE: 'Você ainda não tem cursos atribuídos',
    EMPTY_SUBTITLE:
      'Peça ao administrador da sua conta para atribuir um para você começar seu aprendizado',
    START_LEARNING_COURSE: 'Seus cursos pendentes a serem desenvolvidos',
    START_LEARNING_TRACKS: 'Seus rotas pendentes a serem desenvolvidos',
    ROUTES_NAME: 'Trilhas de aprendizagem',
    COURSE_NAME: 'Cursos',
    LIVE_SESSIONS_NAME: 'Próximas sessões',
    START_LEARNING_MORE_CONTENT: 'Mais conteúdo de seu interesse',
    VIEW_CATALOG: 'Ver catálogo',
    SHOW_MORE_TRACK: 'Acesse todos os seus caminhos de aprendizado',
    SHOW_MORE_COURSE: 'Acesse todos os seus cursos atribuídos',
    SHOW_MORE_LIVE_SESSIONS: 'Acesse todas as suas próximas sessões',
    EXPIRE_DATE: 'Expira {{date}}',
  },
};

export default PT;
