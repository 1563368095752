import { PAYG_NOTIFICATIONS, PREMIUM_NOTIFICATIONS } from '../constants';
import { PAYGNotificationKey, PremiumNotificationKey } from '../types';

export const isCurrentPremiumNotificationPreviousToKey = ({
  currentNotificationIndex,
  notificationKey,
}: {
  currentNotificationIndex?: number;
  notificationKey: PremiumNotificationKey;
}) =>
  typeof currentNotificationIndex === 'number'
    ? currentNotificationIndex <
      (PREMIUM_NOTIFICATIONS.findIndex(
        (key: string) => key === notificationKey,
      ) || 0)
    : true;

export const isCurrentPAYGNotificationPreviousToKey = ({
  currentNotificationIndex,
  notificationKey,
}: {
  currentNotificationIndex?: number;
  notificationKey: PAYGNotificationKey;
}) =>
  typeof currentNotificationIndex === 'number'
    ? currentNotificationIndex <
      (PAYG_NOTIFICATIONS.findIndex((key: string) => key === notificationKey) ||
        0)
    : true;
