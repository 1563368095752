import { FC, useEffect, useState } from 'react';

import { TooltipWrapper } from '@crehana/compass.ui';

import { IMG_COURSE_CARD_PORTAIT } from '../../constants';
import useTeamCoursesTranslation from '../../hooks/useTeamCoursesTranslation';
import type { Dependency, TCoursesTrack, TLayoutLearnTeam } from '../../types';
import getBlockingDependencies from '../../utils/getBlockingDependencies';
import { trailerTypeToMimeType } from '../../utils/playerUtils';
import LayoutLearnTrack from '../LayoutLearnTrack';
import ModalPreRequirements from '../ModalPreRequirements';
import CourseCardIntegrationRevamp from './components/CourseCardIntegrationRevamp';
import CourseCardRevamp from './components/CourseCardRevamp';
import ModalShowTrailer from './components/ModalShowTrailer';

export type TCourseWrapperProps = {
  order: number;
  sizeHalf: number;
  dependencies: Dependency[];
  courseData: TCoursesTrack;
  listCourses: TCoursesTrack[];
  loadingDependencies: boolean;
  isTrackDisabled: boolean;
};

const CourseWrapper: FC<TCourseWrapperProps> = ({
  dependencies,
  courseData,
  listCourses,
  loadingDependencies,
  order,
  sizeHalf,
  isTrackDisabled,
}) => {
  const { t } = useTeamCoursesTranslation();
  const [isModalBlock, setisModalBlock] = useState<boolean>(false);
  const [isOpenModalTrailer, setIsOpenModalTrailer] = useState(false);
  const [blockingDependencies, setBlockingDependencies] = useState<
    TCoursesTrack[]
  >([]);
  const paramLayout: TLayoutLearnTeam = {
    order: order,
    isMediumT: order === sizeHalf,
    isMedium: order === sizeHalf,
    isSuccess: false,
    isCompletedCourse: courseData?.progress === 100,
    isFirst: order === 1,
    isLast: order === listCourses.length,
    porcentage: courseData.progress,
  };

  const isCourseTypeOwn = courseData.typeCourse === 'course';

  const handleOpenOrCloseTrailerOrRedirectModal = () => {
    setIsOpenModalTrailer(prev => !prev);
  };

  useEffect(() => {
    if (!loadingDependencies)
      setBlockingDependencies(
        getBlockingDependencies({
          dependencies,
          integrationResource: listCourses,
          resource: courseData,
        }),
      );
  }, [dependencies, listCourses, loadingDependencies, courseData]);

  const returnContent = () => {
    if (isCourseTypeOwn) {
      const blockingDependencies = getBlockingDependencies({
        dependencies,
        integrationResource: listCourses,
        resource: courseData,
      });

      if (blockingDependencies.length > 0) {
        return (
          <TooltipWrapper
            message={t('LEARN_TEAM_TRACK_TOOLTIP_BLOCK')}
            position="top"
          >
            <CourseCardRevamp
              course={courseData}
              blockingDependencies={blockingDependencies}
              hiddeOptions
              onVisibleModalBlocking={() => setisModalBlock(true)}
              onVisibleModalTrailer={handleOpenOrCloseTrailerOrRedirectModal}
              isCourseTypeOwn={isCourseTypeOwn}
            />
          </TooltipWrapper>
        );
      }
      return (
        <CourseCardRevamp
          course={courseData}
          blockingDependencies={blockingDependencies}
          hiddeOptions={isTrackDisabled}
          onVisibleModalBlocking={() => setisModalBlock(true)}
          onVisibleModalTrailer={handleOpenOrCloseTrailerOrRedirectModal}
          isCourseTypeOwn={isCourseTypeOwn}
        />
      );
    }
    return <CourseCardIntegrationRevamp courseData={courseData} />;
  };

  return (
    <>
      <LayoutLearnTrack {...paramLayout}>{returnContent()}</LayoutLearnTrack>
      {/* Modal de Pre Requisitos */}
      {isModalBlock && (
        <ModalPreRequirements
          isOpen={isModalBlock}
          onClose={() => setisModalBlock(false)}
          listCoursesDependencies={blockingDependencies}
        />
      )}
      {/* Modal de Trailer curso */}
      {isOpenModalTrailer && (
        <ModalShowTrailer
          isOpen={isOpenModalTrailer}
          onDismiss={handleOpenOrCloseTrailerOrRedirectModal}
          title={courseData.courseName}
          courseId={courseData.courseId}
          coverImage={courseData.courseImage || IMG_COURSE_CARD_PORTAIT}
          duration={courseData.duration || 0}
          professor={courseData.profesorFullName || ''}
          lastVideoLectureURL={courseData.lastVideoLectureUrl || ''}
          trailerSources={{
            sources:
              courseData.trailerSource.map(video => {
                return {
                  src: video.videoUrl,
                  type: trailerTypeToMimeType(video.videoSourceType),
                };
              }) || [],
          }}
          sizeImage="tw-h-[31.7rem]"
        />
      )}
    </>
  );
};

export default CourseWrapper;
