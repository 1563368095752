import { useCallback, useMemo, useRef, useState } from 'react';

import Eye from '@crehana/compass.icons.fill/Eye';
import Lock from '@crehana/compass.icons.fill/Lock';
import Plus from '@crehana/compass.icons.outline/Plus';
import { LazyImage } from '@crehana/compass.ui';
import { formatDuration } from '@crehana/date';
import { useMediaQuery } from '@crehana/react-hooks';

import {
  COURSE_CARD_IMAGE_PARAMS,
  DEFAULT_IMG_SKILLPATH,
} from '../../../constants';
import useTeamCoursesTranslation from '../../../hooks/useTeamCoursesTranslation';
import { TCoursesTrack, TInformation } from '../../../types';
import Information from './Information';
import InformationTablet from './InformationTablet';
import Option from './Option';

export type CourseCardRevampProps = {
  course: TCoursesTrack;
  blockingDependencies: TCoursesTrack[];
  hiddeOptions: boolean;
  onVisibleModalBlocking: () => void;
  onVisibleModalTrailer: () => void;
  isCourseTypeOwn: boolean;
};

const CourseCardRevamp: React.FC<CourseCardRevampProps> = ({
  course,
  blockingDependencies = [],
  hiddeOptions,
  onVisibleModalBlocking,
  onVisibleModalTrailer,
  isCourseTypeOwn,
}) => {
  const { t } = useTeamCoursesTranslation();
  const { matches: isXs } = useMediaQuery({ only: 'xs' });
  const optionRef = useRef<HTMLButtonElement>(null);
  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);

  const durationCourse = useMemo(() => {
    if (course.duration && course.progress) {
      return t('LEARN_TEAM_TIME', {
        interpolation: {
          duration: formatDuration(
            course.duration - course.duration * (course.progress / 100),
          ),
        },
      });
    }
    if (course.duration)
      return t('LEARN_TEAM_TIME_DURATION', {
        interpolation: {
          duration: formatDuration(course.duration),
        },
      });

    if (course.duration && isXs) return formatDuration(course.duration);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course, isXs]);

  // rutas desahabilitada o cuando tenga dependencias por pre-requisitos
  const hasBlockingDependencies = blockingDependencies.length > 0;
  const shouldRenderTeamOptionButton: boolean =
    !hasBlockingDependencies && !hiddeOptions;

  const handleActionBlocking = useCallback(() => {
    if (onVisibleModalBlocking && hasBlockingDependencies) {
      onVisibleModalBlocking();
    }
  }, [hasBlockingDependencies, onVisibleModalBlocking]);

  const renderInformation = () => {
    const paramsInformation: TInformation = {
      title: course.courseName,
      duration: durationCourse,
      teacherName: course.profesorFullName,
      isRequired: Boolean(course.isRequired),
    };

    return isXs ? (
      <Information
        {...paramsInformation}
        isVisibleTeacherName={Boolean(course.progress && course.progress > 0)}
      />
    ) : (
      <InformationTablet {...paramsInformation} />
    );
  };

  // Main render
  return (
    <div
      className="sm:dark:tw-bg-transparent dark:tw-bg-elevation-dark-04 tw-bg-neutral-light-100 tw-flex tw-flex-col sm:tw-flex-row tw-p-1.2 tw-relative tw-rounded-0.8 sm:tw-shadow-none dark:tw-shadow-none sm:tw-p-0.8 tw-transition-all tw-cursor-pointer hover:tw-shadow-01 hover:tw-z-2"
      role="listitem"
    >
      <div
        className="tw-flex-1 tw-w-full tw-text-left tw-flex tw-flex-col sm:tw-flex-row"
        onClick={handleActionBlocking}
        onKeyDown={handleActionBlocking}
      >
        {/* seccion de imagen */}
        <div className="tw-relative tw-flex tw-flex-col tw-w-full tw-overflow-hidden tw-rounded-0.8 tw-h-8 tw-mb-0.8 sm:tw-mb-0 sm:tw-mr-1.2 sm:tw-w-[9.2rem] sm:tw-h-[5.2rem]">
          {isCourseTypeOwn && !hiddeOptions && (
            <button
              type="button"
              className="tw-flex tw-bg-[rgba(0,0,0,0.6)] hover:tw-opacity-100 tw-opacity-0 tw-absolute tw-w-full tw-h-full tw-inset-0 tw-items-center tw-justify-center tw-rounded-0.4 tw-z-7"
              onClick={onVisibleModalTrailer}
            >
              <Eye className="tw-text-neutral-light-100" size={24} />
            </button>
          )}
          <LazyImage
            src={course.courseImage || DEFAULT_IMG_SKILLPATH}
            placeholder={DEFAULT_IMG_SKILLPATH}
            imgxParams={COURSE_CARD_IMAGE_PARAMS}
            alt={course.courseName || ''}
            isTransparent
            imgClassName="object-cover"
            className="tw-w-full tw-h-full"
          />

          {hasBlockingDependencies && (
            <div className="tw-bg-[#000000] tw-opacity-60 tw-absolute tw-w-full tw-h-full tw-inset-0 tw-flex tw-items-center tw-justify-center tw-rounded-0.4 tw-z-7">
              <Lock className="tw-text-neutral-light-100" size={24} />
            </div>
          )}
        </div>
        {renderInformation()}

        {shouldRenderTeamOptionButton && (
          <button
            type="button"
            className="sm:tw-h-fit dark:tw-text-neutral-light-100 dark:tw-bg-neutral-dark-700 dark:tw-border-none tw-flex tw-items-center tw-justify-center tw-text-neutral-light-800 tw-font-link1 tw-px-1.2 tw-py-0.8 tw-rounded-1 tw-border tw-border-solid tw-border-neutral-light-500"
            onClick={() => setIsOpenDrawer(true)}
            ref={optionRef}
          >
            <Plus size={16} className="tw-mr-0.8 sm:tw-mr-0 tw-fill-current" />
            <span className="tw-block sm:tw-hidden">
              {t('LEARN_TEAM_OPTION')}
            </span>
          </button>
        )}
      </div>
      <div className="tw-relative tw-my-auto">
        <Option
          isOpen={isOpenDrawer}
          onOpenTrailer={onVisibleModalTrailer}
          setIsOpen={() => setIsOpenDrawer(false)}
          refPopper={optionRef}
          dataCourse={course}
        />
      </div>
    </div>
  );
};

export default CourseCardRevamp;
