import getGreaterDate from "./getGreaterDate";
import toDate from "./toDate";

/**
 * @category Day helpers
 * @summary Get the number of milliseconds, seconds, minutes, houts or days between the given dates.
 *
 * @returns Returns the difference between the two dates in different time units (rounded up to 2 decimals)
 *
 * @example
 * What is the difference between
 * 2020-12-21T22:00:00+00:00 and 2020-12-22T05:00:00+00:00
 *
 * const result = differenceInCalendarDays('2020-12-21T22:00:00+00:00', '2020-12-22T05:00:00+00:00')
 * => result is { milliseconds: 25200000, seconds: 25200, minutes: 420, hours: 7, days: 0.29 }
 *
 * @param date1Param - Date object or date as string
 * @param date2Param - Date object or date as string
 */
var differenceBetweenDates = function differenceBetweenDates(date1Param, date2Param, options) {
  var languageCode = options.languageCode;
  var date1 = typeof date1Param === 'string' ? toDate(date1Param, {
    languageCode: languageCode
  }) : date1Param;
  var date2 = typeof date2Param === 'string' ? toDate(date2Param, {
    languageCode: languageCode
  }) : date2Param;
  var endDate = getGreaterDate(date1, date2, {
    languageCode: languageCode
  });
  var startDate = date1 === endDate ? date2 : date1;
  if (!endDate || !startDate) {
    return {
      milliseconds: 0,
      seconds: 0,
      minutes: 0,
      hours: 0,
      days: 0
    };
  }
  var milliseconds = endDate.getTime() - startDate.getTime();
  if (milliseconds <= 0) {
    return {
      milliseconds: 0,
      seconds: 0,
      minutes: 0,
      hours: 0,
      days: 0
    };
  }
  var seconds = milliseconds / 1000;
  var minutes = seconds / 60;
  var hours = minutes / 60;
  var days = hours / 24;
  return {
    milliseconds: Math.round(milliseconds * 100) / 100,
    seconds: Math.round(seconds * 100) / 100,
    minutes: Math.round(minutes * 100) / 100,
    hours: Math.round(hours * 100) / 100,
    days: Math.round(days * 100) / 100
  };
};
export default differenceBetweenDates;