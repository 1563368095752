import { useEffect, type FC } from 'react';

import {
  getCommonAmplitudeProperties,
  getSourceEventParams,
  useAmplitude,
} from '@crehana/analytics';
import { useMe } from '@crehana/auth';

import { SavedCourses, type SavedCoursesProps } from '@/shared/components';
import SectionTitle from '@/views/LearnDashboard/components/SectionTitle';
import { useLearnDashboardContext } from '@/views/LearnDashboard/context';

import { LEARN_DASHBOARD_PAGE_KEY } from '../../../constants';
import { useCrehanaTranslation } from '../../../hooks/useCrehanaTranslation';

const SavedCoursesSection: FC<
  React.PropsWithChildren<SavedCoursesProps>
> = props => {
  const { logEvent } = useAmplitude();
  const { me } = useMe();
  const { t } = useCrehanaTranslation();
  const { isLoadingPlayroomActivated, isPlayroomEnabled } =
    useLearnDashboardContext();

  useEffect(() => {
    if (!isLoadingPlayroomActivated) {
      logEvent({
        event: 'Render Home Courses Saved',
        data: {
          ...getSourceEventParams(),
          ...getCommonAmplitudeProperties(me!, isPlayroomEnabled),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingPlayroomActivated, isPlayroomEnabled]);

  return (
    <>
      <SectionTitle title={t('LABEL_SIDEBAR_ITEM_MY_COURSES_SAVED')} />
      <SavedCourses {...props} source={LEARN_DASHBOARD_PAGE_KEY} user={me!} />
    </>
  );
};

export default SavedCoursesSection;
