import { Col, Row } from '@crehana/ui';

import SkeletonLearnCreteria from './SkeletonLearnCreteria';
import SkeletonLearnHeaderTrack from './SkeletonLearnHeaderTrack';
import SkeletonLearnTeam from './SkeletonLearnTeam';

const SkeletonLearnContentNew = () => {
  return (
    <section
      className="tw-px-3.2 tw-py-2.4"
      data-testid="skeleton-sm-learn-team-tracks"
    >
      <SkeletonLearnHeaderTrack />
      <Row>
        <Col xs={12} sm={3}>
          <SkeletonLearnCreteria />
        </Col>
        <Col xs={12} sm={9}>
          <SkeletonLearnTeam />
        </Col>
      </Row>
    </section>
  );
};

export default SkeletonLearnContentNew;
