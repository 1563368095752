import { differenceInCalendarDays } from '@crehana/date';
import { useMediaQuery } from '@crehana/react-hooks';
import { Container } from '@crehana/ui';

import BreadcrumbTracks from '../../components/BreadcrumbTracks';
import DetailLearnTrackHeader from '../../components/DetailLearnTrackHeader';
import ErrorViewDetail from '../../components/ErrorViewDetail';
import LearnTrackCourses from '../../components/LearnTrackCourses';
import SkeletonLearnContentNew from '../../components/SkeletonLearnTeamTracks/SkeletonLearnContentNew';
import useGetDetailTrack from '../../hooks/useGetDetailTrack';
import useTeamCoursesTranslation from '../../hooks/useTeamCoursesTranslation/useTeamCoursesTranslation';
import {
  STATUS,
  TDetailReward,
  TDetailTrackBody,
  THeaderDetail,
} from '../../types';
import { parseTrackExpirationDate } from '../../utils';
import useToggleType from '../../utils/getTypeStateTrack';

const DetailLearnTracks = () => {
  const { data, loading, error } = useGetDetailTrack();

  const { matches: isXs } = useMediaQuery({ only: 'xs' });
  const { languageCode } = useTeamCoursesTranslation();

  const parsedDate = parseTrackExpirationDate(data?.trackEndDate, languageCode);

  let overdueDays = 0;
  if (parsedDate) {
    overdueDays =
      parsedDate && differenceInCalendarDays(parsedDate, new Date()) + 1;
  }

  const detailTrackHeader: THeaderDetail = {
    trackName: data?.trackName || '',
    isTrackReward: Boolean(
      data?.trackReward.rewardId &&
        data?.trackReward.userRewardId &&
        data?.trackReward.status !== STATUS.REWARD_STATUS_INACTIVE,
    ),
    description: data?.trackDescription,
    assigned: '',
    coinPlayroomNumber: data?.trackPlayroomCoin,
    date: data?.trackEndDate,
    typeTrack: useToggleType(data),
    teamExpired: Boolean(overdueDays !== undefined && overdueDays < 0),
  };

  const detailTrackBody: TDetailTrackBody = {
    teamId: data?.trackId || 0,
    nameTrack: data?.trackName || '',
    mandatoryProjectsCount: data?.trackSummary.mandatoryProjectsCount || 0,
    mandatoryCoursesCount: data?.trackSummary.mandatoryCoursesCount || 0,
    mandatoryQuizzesCount: data?.trackSummary.mandatoryQuizzesCount || 0,
    shouldRenderRewardCard: Boolean(
      data?.trackReward.rewardId && data?.trackReward.userRewardId,
    ),
    trackDisabled: data?.trackDisabled || false,
    isCompletedTrack: data?.isCompletedTrack || false,
    inProgressMandatoryProjectsCount:
      data?.trackSummary.inProgressMandatoryProjectsCount || 0,
    inProgressCourses: data?.trackSummary.inProgressCourses || 0,
    inProgressQuizzesCount: data?.trackSummary.inProgressQuizzesCount || 0,
    dataCertificate: data?.dataCertificate || null,
    trackProjects: data?.lastProject || null,
    listCourses: data?.listCourses || [],
    coinPlayroomNumber: data?.trackPlayroomCoin,
    isCertificateGenerated: data?.hasCertificate || false,
    trackVersion: data?.trackVersion || null,
  };

  const detailRewardBody: TDetailReward = {
    status: data?.trackReward.status || STATUS.REWARD_STATUS_INACTIVE,
    numberCourses: data?.trackReward.numberCourses || 0,
    rewardId: data?.trackReward?.userRewardId || 0,
  };

  const renderCoursesContent = () => {
    if (loading) return <SkeletonLearnContentNew />;

    if (!loading && !data) return <ErrorViewDetail />;

    if (!loading && error) return <ErrorViewDetail />;

    return (
      <div className="mb-112 tw-flex tw-flex-col tw-h-full">
        <div className="tw-mt-2 tw-pb-1.6">
          <DetailLearnTrackHeader detailHeaderTrack={detailTrackHeader} />
        </div>
        <div className="tw-rounded-t-1 tw-h-full">
          {isXs ? (
            <section className="tw-h-full">
              <LearnTrackCourses
                detailTrack={detailTrackBody}
                detailReward={detailRewardBody}
              />
            </section>
          ) : (
            <Container type="dashboard" className="tw-h-full">
              <LearnTrackCourses
                detailTrack={detailTrackBody}
                detailReward={detailRewardBody}
              />
            </Container>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <BreadcrumbTracks
        className="tw-pt-2.4 tw-pb-1.2"
        isOnDetailTrack
        detailTrack={{
          teamId: detailTrackBody.teamId,
          trackName: detailTrackHeader.trackName,
        }}
      />
      <section
        data-testid="b2b-activity-team-courses"
        id="b2b-activity-team-courses"
        className="tw-h-full"
      >
        {renderCoursesContent()}
      </section>
    </>
  );
};

export default DetailLearnTracks;
