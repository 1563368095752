import { useEffect, useRef } from 'react';

import { useAmplitude } from '@crehana/analytics';
import { useIntersectionObserver } from '@crehana/react-hooks';

type TTrackSectionScrollProps = {
  amplitudeEventName: string;
  amplitudeSource: string;
  amplitudeSourceDetail?: string;
};

const TrackSectionScroll: React.FC<
  React.PropsWithChildren<TTrackSectionScrollProps>
> = ({ amplitudeEventName, amplitudeSource, amplitudeSourceDetail }) => {
  const divRef = useRef(null);
  const observer = useIntersectionObserver(divRef, {
    threshold: 1,
    freezeOnceVisible: true,
  });
  const { logEvent } = useAmplitude();

  useEffect(() => {
    if (observer?.isIntersecting && amplitudeEventName) {
      logEvent({
        event: amplitudeEventName,
        data: {
          Source: amplitudeSource,
          'Source Detail': amplitudeSourceDetail,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [observer?.isIntersecting]);

  return <div ref={divRef} style={{ height: 1 }} />;
};

export default TrackSectionScroll;
