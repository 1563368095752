import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
export function flattenRoutes(routes) {
  // @ts-expect-error The type of `routes` is `RouteType[]` but the type of `routes[i]` is `SingleRouteType`
  return routes.reduce(function (acc, route) {
    if (route.nested) {
      return [].concat(_toConsumableArray(acc), _toConsumableArray(route.nested));
    }
    return [].concat(_toConsumableArray(acc), [route]);
  }, []);
}
export function flattenRoutesWithComponent(routes) {
  var routesFlat = flattenRoutes(routes);
  return routesFlat.filter(function (route) {
    return route.component;
  });
}