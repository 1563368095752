import { memo, useCallback, useMemo, type FC, type MouseEvent } from 'react';

import cn from 'clsx';

import { useMediaQuery } from '@crehana/react-hooks';
import { Container } from '@crehana/ui';
import { useStudentUrls } from '@crehana/web';

import { useLearnDashboardContext } from '@/views/LearnDashboard/context';
import { useCrehanaTranslation } from '@/views/LearnDashboard/hooks';
import { PATH_TRACK } from '@/views/LearnDashboard/layouts/b2b/StartSection/constants';

import { BreadcrumbItem } from '../../../Breadcrumb/type';
import BreadcrumbDesktop from './BreadcrumbDesktop';
import BreadcrumbMobile from './BreadcrumbMobile';
import type {
  BreadcrumbTracksProps,
  TBreadcrumbTracksPropsMobile,
} from './type';

const BreadcrumbTracks: FC<BreadcrumbTracksProps> = ({
  className = 'tw-pt-2',
  fullWidth = false,
  containerType = 'dashboard',
  isOnDetailTrack,
  detailTrack,
}) => {
  const { t } = useCrehanaTranslation();
  const { baseUrlB2B, redirectTo } = useLearnDashboardContext();
  const { homeUrl } = useStudentUrls();
  const { matches: isMd } = useMediaQuery('md');
  const { matches: isSm } = useMediaQuery({ until: 'md' });

  const commonItemLinkClassnames =
    'tw-font-body5 tw-text-neutral-light-800 dark:tw-text-neutral-light-100 tw-flex tw-items-center';
  const urlListTracks = `${baseUrlB2B as string}${PATH_TRACK}`;
  const urlDetailTrack = `${baseUrlB2B as string}${PATH_TRACK}/${
    detailTrack?.teamId
  }/`;

  const breadcrumbName = useMemo(() => {
    let defaultBreadcrum: BreadcrumbItem[] = [];
    if (!isSm) {
      defaultBreadcrum = [
        {
          label: t('LABEL_SIDEBAR_ITEM_MY_COURSES_TRACKS'),
          href: urlListTracks,
          isSelect: true,
        },
      ];
    }

    if (isOnDetailTrack && detailTrack) {
      defaultBreadcrum.push({
        label: isSm ? t('LABEL_SIDEBAR_BACKOUT') : detailTrack?.trackName,
        isSelect: true,
        href: urlDetailTrack,
      });
    }
    return defaultBreadcrum;
  }, [isSm, t, isOnDetailTrack, detailTrack, urlDetailTrack, urlListTracks]);

  const goToPath = useCallback(
    (event: MouseEvent<HTMLAnchorElement>, path: string) => {
      event.preventDefault();
      event.stopPropagation();
      redirectTo(path);
    },
    [redirectTo],
  );

  const currentProps: TBreadcrumbTracksPropsMobile &
    Pick<BreadcrumbTracksProps, 'items'> = {
    goToPath,
    homeUrl: !isMd && isOnDetailTrack ? urlListTracks : homeUrl,
    itemClass: commonItemLinkClassnames,
    items: breadcrumbName,
  };

  return (
    <div className={cn(className, 'tw-w-full')}>
      <Container type={containerType} full={fullWidth}>
        <nav aria-label="breadcrumb">
          <ol className="tw-flex tw-items-center">
            {isMd ? (
              <BreadcrumbDesktop {...currentProps} />
            ) : (
              <BreadcrumbMobile {...currentProps} />
            )}
          </ol>
        </nav>
      </Container>
    </div>
  );
};

export default memo(BreadcrumbTracks);
