import dynamic from 'next/dynamic';

import SkillXPEvolutionSkeleton from '../../components/SkillXPEvolutionSkeleton';

const LazyPendingSkills = dynamic(() => import('./PendingSkills'), {
  ssr: false,
  loading: () => <SkillXPEvolutionSkeleton />,
});

export default LazyPendingSkills;
