export var DEFAULT_STUDY_PLAN_WEEK_PROGRESS = [{
  day: 'Monday',
  seconds: 0,
  date: ''
}, {
  day: 'Tuesday',
  seconds: 0,
  date: ''
}, {
  day: 'Wednesday',
  seconds: 0,
  date: ''
}, {
  day: 'Thursday',
  seconds: 0,
  date: ''
}, {
  day: 'Friday',
  seconds: 0,
  date: ''
}, {
  day: 'Saturday',
  seconds: 0,
  date: ''
}, {
  day: 'Sunday',
  seconds: 0,
  date: ''
}];