import Divider from './Divider';

type InformationGeneral = {
  title: string;
  fullNameTeacher: string;
  language: string;
};

const InformationGeneral: React.FC<InformationGeneral> = ({
  title,
  fullNameTeacher,
  language,
}) => {
  return (
    <div className="tw-w-full tw-text-neutral-light-800 dark:tw-text-neutral-light-100">
      <div className="tw-flex tw-items-center tw-justify-between">
        <span className="tw-line-clamp-1 tw-block tw-mb-0.4 tw-font-subtitle5">
          {title}
        </span>
      </div>

      <div className="tw-flex tw-items-center tw-font-body5 dark:tw-text-neutral-dark-400 tw-text-neutral-light-700 tw-mb-0.8">
        {fullNameTeacher ? (
          <span className="tw-block tw-line-clamp-1">{fullNameTeacher}</span>
        ) : null}
        <Divider />
        {language && <span>{language?.toUpperCase()}</span>}
      </div>
    </div>
  );
};

export default InformationGeneral;
