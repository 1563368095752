import { FC, PropsWithChildren } from 'react';

import cn from 'clsx';

import Rocket from '@crehana/icons/outline/Rocket';
import { colors } from '@crehana/tailwindcss';

import useTeamCoursesTranslation from '../../hooks/useTeamCoursesTranslation/useTeamCoursesTranslation';
import type { TLayoutLearnTeam } from '../../types';
import RingOrder from '../RingOrder';

const LayoutLearnTrack: FC<PropsWithChildren<TLayoutLearnTeam>> = ({
  children,
  order,
  isMediumT,
  isMedium,
  isSuccess,
  isFirst,
  isLast,
  porcentage = 0,
  isCompletedCourse,
}) => {
  const { t } = useTeamCoursesTranslation();
  const radiusB =
    "before:content-[''] before:tw-absolute before:tw-w-[18px] sm:before:tw-h-5.6 before:tw-h-[204px] before:tw-bottom-[27px] before:tw-left-[18px] before:tw-rounded-bl-1.2 before:tw-border-solid before:tw-border-b-0.2 before:tw-border-neutral-light-400 before:tw-border-l-0.2";
  const radiusT =
    "after:content-[''] after:tw-absolute after:tw-w-[18px] after:tw-h-5.6 after:tw-top-[27px] after:tw-left-[18px] after:tw-rounded-tl-1.2 after:tw-border-solid after:tw-border-t-0.2 after:tw-border-neutral-light-400 after:tw-border-l-0.2";

  const shouldShowTheLine =
    "after:content-[''] after:tw-absolute sm:after:tw-left-[-1px] after:tw-left-[2px] after:tw-top-2.4 after:tw-w-0.2 after:tw-h-full after:tw-bg-neutral-light-400";
  const shouldShowTheLineFromTop =
    "after:content-[''] after:tw-absolute sm:after:tw-left-[-1px] after:tw-left-[2px] after:tw-top-[-175px] sm:after:tw-top-[-24px] after:tw-w-0.2 after:tw-h-full after:tw-bg-neutral-light-400";
  const hideLineOfCourses = 'after:tw-hidden';

  return (
    <>
      <div
        className={cn(
          'tw-ml-2 tw-pl-2 sm:tw-pl-1.6 tw-pb-1.2 sm:tw-pb-0 tw-relative',
          !isSuccess && shouldShowTheLine,
          isSuccess && shouldShowTheLineFromTop,
          !isFirst && isMediumT && hideLineOfCourses,
          isLast && !isSuccess && hideLineOfCourses,
        )}
      >
        <RingOrder
          order={order}
          porcentage={porcentage}
          isSuccess={isSuccess}
          isCompletedCourse={isCompletedCourse}
        />

        <div className="tw-w-full">{children}</div>
      </div>
      {isMedium && !isFirst && (
        <>
          <div
            className={cn(
              'tw-h-5.6 tw-w-full tw-relative tw-flex tw-items-center tw-pl-3.6',
              radiusT,
              radiusB,
            )}
          >
            <div className="tw-bg-neutral-light-100 dark:tw-bg-elevation-dark-04 tw-flex tw-w-full tw-top-[-15%] tw-left-2.4 tw-items-center">
              <span className="tw-mr-0.8 tw-rounded-full tw-bg-success-100 tw-w-[26px] tw-h-[26px] tw-flex tw-items-center tw-justify-center">
                <Rocket size={16} color={colors['success'][500]} />
              </span>
              <p className="tw-text-success-500 tw-font-subtitle5 dark:tw-text-neutral-light-100">
                {t('LEARN_TEAM_TRACK_MEDIUM')}
              </p>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default LayoutLearnTrack;
