import { FC } from 'react';
import * as React from 'react';

import { css } from 'styled-components';

import {
  colors,
  Dialog,
  DialogContent,
  LazyImage,
  PrimaryButton,
  StateTag,
} from '@crehana/ui';

import useTasksTranslations from '../../hooks/useTasksTranslations';
import ArrowIcon from './assets/svg/ArrowIcon';
import { PhaseTransitionDialogProps } from './types';

const PhaseTransitionDialog: FC<
  React.PropsWithChildren<PhaseTransitionDialogProps>
> = ({ open, isDark, phaseName, organizationLogo, onDismiss }) => {
  const { t } = useTasksTranslations();
  const { primaryLight, secondaryLight } = colors;

  return (
    <Dialog
      open={open}
      onDismiss={onDismiss}
      isDark={isDark}
      hideCloseBtn
      ignoreEscape
    >
      <DialogContent className="whitespace-pre-line relative" isDark={isDark}>
        <div
          className="absolute"
          css={css`
            min-height: 100px;
            min-width: 160px;
          `}
        >
          <LazyImage
            alt="org-logo"
            src={organizationLogo}
            className="h-52 -ml-16"
            imgClassName="object-contain"
            isDark={isDark}
          />
        </div>
        <section className="flex flex-col justify-center items-center mt-80">
          <div className="flex items-center mb-40">
            <ArrowIcon color={isDark ? secondaryLight : primaryLight} />
            {phaseName && <StateTag className="ml-20" label={phaseName} />}
          </div>
          <h3 className="font-h3 text-center">
            {`${t('PHASE_TRANSITION_DIALOG_CONGRATULATIONS')} ${
              phaseName &&
              `${t('PHASE_TRANSITION_DIALOG_CONGRATULATIONS_PHASE', {
                interpolation: { phaseName },
              })}`
            }`}
          </h3>
          <p className="font-body3 text-center mt-16 mb-40">
            {t('PHASE_TRANSITION_DIALOG_DESCRIPTION')}
          </p>
          <PrimaryButton
            css={css`
              width: 138px;
            `}
            label={t('PHASE_TRANSITION_BUTTON_LABEL')}
            size="m"
            onClick={onDismiss}
          />
        </section>
      </DialogContent>
    </Dialog>
  );
};

export default PhaseTransitionDialog;
