import type { TMachineContextCurrentPageType } from '@crehana/compass.knowledgehub';

export const KNOWLEDGE_HUB_SUPPORTED_PATHS: Array<{
  path: RegExp;
  page: TMachineContextCurrentPageType;
  getPathSlug?: (pathname: string) => string;
}> = [
  {
    // Path example: /learn/content/crehana/
    path: /(\/learn\/content\/crehana\/$)/i,
    page: 'PAGE_CATEGORIES',
  },
  {
    // Path example: /learn/content/fotografia-de-bodas/
    path: /\/learn\/content\/([\S]+)\//i,
    page: 'PAGE_ORGANIZATION_CATALOG',
    getPathSlug: function (pathname: string) {
      const match = this.path.exec(pathname);

      return match ? decodeURIComponent(match[1]) : '';
    },
  },
];
