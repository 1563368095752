import { useQuery } from '@apollo/client';

import GET_CAN_USE_INDUCTION_ROLE from '../graphql/GetCanUseInduction.graphql';
import { GetCanUseInduction } from '../graphql/types/GetCanUseInduction';

const useGetCanUseInduction = () => {
  const { data } = useQuery<GetCanUseInduction>(GET_CAN_USE_INDUCTION_ROLE, {
    fetchPolicy: 'no-cache',
  });

  const meRole = data?.me?.userOrganization?.role;

  return { meRole };
};

export default useGetCanUseInduction;
