import { FC } from 'react';

import { cn } from '@crehana/compass.ui/v2';

import useTeamCoursesTranslation from '../../../hooks/useTeamCoursesTranslation';
import { TInformation } from '../../../types';
import Divider from './Divider';

const Information: FC<TInformation> = ({
  title,
  teacherName,
  duration,
  labelTrack,
  isVisibleTeacherName,
  isRequired,
}) => {
  const { t } = useTeamCoursesTranslation();

  return (
    <div className="tw-w-full tw-mb-0.8">
      <div className="tw-flex tw-items-center tw-justify-between">
        <p className="tw-line-clamp-2 tw-block tw-font-subtitle5 tw-mb-0.4 tw-text-neutral-light-800 dark:tw-text-neutral-light-100">
          {title}
        </p>
      </div>

      <div className="tw-mb-1 tw-flex tw-items-center tw-w-full tw-text-neutral-light-700 dark:tw-text-neutral-dark-400 tw-font-body5">
        <div
          className={cn('tw-flex tw-flex-row tw-items-center', {
            'tw-hidden': isVisibleTeacherName,
          })}
        >
          <span className="tw-line-clamp-1 tw-block">{teacherName}</span>
          {(duration || isRequired) && <Divider />}
        </div>
        {isRequired && (
          <>
            <span className="tw-flex-shrink-0 tw-text-primary-light-500">
              {t('TAG_REQUIRED')}
            </span>
            {duration && <Divider />}
          </>
        )}
        {duration && <span className="tw-flex-shrink-0">{duration}</span>}
      </div>
      <span className="tw-font-label1  tw-text-neutral-light-600 tw-uppercase">
        {labelTrack}
      </span>
    </div>
  );
};

export default Information;
