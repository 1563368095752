function getCommonAmplitudeProperties(me) {
  var _me$organization;
  var isPlayroomEnabled = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var userType = '';
  if (me) {
    if (me.isB2BAllCatalog) {
      userType = 'B2B All Catalog';
    } else if (me.isB2BOnlyPanel) {
      userType = 'B2B Only Panel';
    } else if (me.isB2B) {
      userType = 'B2B';
    } else if (me.isPremiumTrial) {
      userType = 'Premium Trial';
    } else if (me.isPremium) {
      userType = 'Premium';
    } else if (me.isPAYG) {
      userType = 'PAYG';
    } else if (me.isProfessor) {
      userType = 'Professor';
    }
  }
  if (!userType) {
    userType = 'Unknown';
  }
  var organizationSlug = ((_me$organization = me.organization) === null || _me$organization === void 0 ? void 0 : _me$organization.slug) || 'Unknown';
  return {
    'User Type': userType,
    'Organization Slug': organizationSlug,
    'Playroom Enabled': isPlayroomEnabled ? 'Yes' : 'No'
  };
}
export default getCommonAmplitudeProperties;