import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import { TimeZonesEnum } from '@crehana/ts-types';
import { SessionTimeLineEnum, SessionTypeEnum } from "../graphql/types/globals.v5";
export var parseSessions = function parseSessions(sessionsFromQuery) {
  if (!(sessionsFromQuery !== null && sessionsFromQuery !== void 0 && sessionsFromQuery.length)) return [];
  return sessionsFromQuery.map(function (session) {
    var _session$location, _session$location2, _session$total_guests, _session$cover_image$, _session$cover_image, _session$cover_image$2, _session$timeline;
    var isVirtual = session.type_session === SessionTypeEnum.ONLINE;
    var sessionTypeDefinition = isVirtual ? {
      type: 'virtual',
      link: (_session$location = session.location) !== null && _session$location !== void 0 ? _session$location : ''
    } : {
      type: 'location',
      address: (_session$location2 = session.location) !== null && _session$location2 !== void 0 ? _session$location2 : ''
    };
    return _objectSpread({
      origin: session.id === '0' ? 'course' : 'session',
      id: session.id,
      title: session.session_title,
      startDate: new Date(session.start_date),
      endDate: new Date(session.end_date),
      timeZone: TimeZonesEnum[session.time_zone],
      guests: (_session$total_guests = session.total_guests) !== null && _session$total_guests !== void 0 ? _session$total_guests : 0,
      imageUrl: (_session$cover_image$ = (_session$cover_image = session.cover_image) === null || _session$cover_image === void 0 ? void 0 : (_session$cover_image$2 = _session$cover_image.file) === null || _session$cover_image$2 === void 0 ? void 0 : _session$cover_image$2.absolute_url) !== null && _session$cover_image$ !== void 0 ? _session$cover_image$ : '',
      timeline: (_session$timeline = session.timeline) !== null && _session$timeline !== void 0 ? _session$timeline : SessionTimeLineEnum.PAST
    }, sessionTypeDefinition);
  });
};