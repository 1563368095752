import { createUrlWithSourceQueryParams } from '@crehana/analytics';

import { LEARN_DASHBOARD_PAGE_NAME } from '@/views/LearnDashboard/constants';

export const redirectToEditStudyPlan = () => {
  window.location.href = createUrlWithSourceQueryParams({
    url: '/plan-de-estudios/',
    source: LEARN_DASHBOARD_PAGE_NAME,
    sourceDetail: 'Study Plan Section',
  });
};
