import React from 'react';

import { CrossSidebarWrapper } from './CrossSidebar.styles';
import { CrossSidebarProps } from './types';

const CrossSidebar: React.FC<CrossSidebarProps> = ({
  isDark,
  isSidebarV2Enabled,
  children,
}) => {
  return (
    <CrossSidebarWrapper
      isSidebarV2Enabled={isSidebarV2Enabled}
      isDark={isDark}
    >
      {children}
    </CrossSidebarWrapper>
  );
};

export default CrossSidebar;
