import { FC } from 'react';

import { PlayroomRewardIndicatorWithRive } from '@crehana/compass.playroom';
import { LazyImage } from '@crehana/compass.ui';

import { COURSE_CARD_IMAGE_PARAMS } from '../../constants';
import useTeamCoursesTranslation from '../../hooks/useTeamCoursesTranslation/useTeamCoursesTranslation';
import { TLayoutLearnTeam } from '../../types';
import LayoutLearnTrack from '../LayoutLearnTrack';

type CardTrackCompletedProps = {
  order: number;
  portraitCertificate: string;
  trackName: string;
  rewardQuantity?: number;
};

const CardTrackCompleted: FC<CardTrackCompletedProps> = ({
  order,
  portraitCertificate,
  trackName,
  rewardQuantity,
}) => {
  const { t } = useTeamCoursesTranslation();
  const shouldRenderRewardIndicator =
    rewardQuantity !== undefined && rewardQuantity > 0;

  const paramLayout: TLayoutLearnTeam = {
    order: order + 1,
    isMediumT: false,
    isSuccess: true,
    isLast: true,
    porcentage: 0,
  };

  return (
    <LayoutLearnTrack {...paramLayout}>
      <div
        className="sm:dark:tw-bg-transparent dark:tw-bg-elevation-dark-04 tw-bg-neutral-light-100 tw-flex tw-flex-col sm:tw-flex-row tw-p-1.2 tw-relative tw-rounded-0.8 sm:tw-p-0.8"
        role={'listitem'}
      >
        <div className="tw-flex-1 tw-w-full tw-text-left tw-flex tw-flex-col sm:tw-flex-row">
          <div className="tw-relative tw-flex tw-flex-col tw-w-full tw-overflow-hidden tw-rounded-0.8 tw-h-8 tw-mb-0.8 sm:tw-mb-0 sm:tw-mr-1.2 sm:tw-w-[9.2rem] sm:tw-h-[5.2rem] tw-border tw-border-solid tw-border-success-500">
            <LazyImage
              src={portraitCertificate || ''}
              imgxParams={COURSE_CARD_IMAGE_PARAMS}
              alt={trackName || ''}
              isTransparent
              imgClassName="tw-object-cover"
              className="tw-w-full tw-h-full"
            />
          </div>
          <div className="tw-flex tw-flex-col tw-w-full tw-mr-[4.8rem]">
            <span className="tw-font-subtitle5 tw-text-success-500 dark:tw-text-neutral-light-100">
              {t('LEARN_TEAM_TRACK_SUCCESS')}
            </span>
            {shouldRenderRewardIndicator && (
              <div className="tw-flex tw-items-center">
                <PlayroomRewardIndicatorWithRive
                  coins={rewardQuantity}
                  className="tw-ml-1.2 sm:tw-w-fit tw-h-2.4 sm:tw-ml-0 sm:tw-mt-0.4"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </LayoutLearnTrack>
  );
};

export default CardTrackCompleted;
