import { clsx } from 'clsx';

import PlayApps from '@crehana/compass.icons.fill/PlayApps';
import CloseSmall from '@crehana/compass.icons.outline/CloseSmall';
import ExternalLink from '@crehana/compass.icons.outline/ExternalLink';
import { LazyImage } from '@crehana/compass.ui';
import { CrehanaPlayerWrapper, type CrehanaPlayerProps } from '@crehana/player';
import { LANGUAGES } from '@crehana/ts-types';

import useTeamCoursesTranslation from '../../../hooks/useTeamCoursesTranslation';
import { TMachineContextCourseTrailerType } from '../../../types';

type TCourseCoverProps = {
  courseId: number;
  hovered?: boolean;
  title: string;
  coverImage: string;
  sizeImage: string;
  trailerSources?: TMachineContextCourseTrailerType['sources'];
  isCrehanaCourse?: boolean;
  onTimeUpdate?: Required<CrehanaPlayerProps>['onTimeUpdate'];
  onClickImageTrailer: () => void;
  onCloseModal: () => void;
};

const COMMON_COVER_CLASSNAME = 'tw-w-full tw-min-h-20 tw-h-[220px]';

const CourseCover: React.FC<TCourseCoverProps> = ({
  courseId,
  title,
  hovered = false,
  isCrehanaCourse,
  coverImage,
  trailerSources = [],
  onTimeUpdate,
  sizeImage,
  onClickImageTrailer,
  onCloseModal,
}) => {
  const { t } = useTeamCoursesTranslation();
  const shouldRenderTrailer =
    hovered && !!isCrehanaCourse && trailerSources.length > 0;

  return shouldRenderTrailer ? (
    <CrehanaPlayerWrapper
      videoId={'video-' + courseId}
      autoplay={true}
      hideLogo={true}
      hiddenNotes={true}
      hideSkipButtons={true}
      hideSpeedOptions={true}
      sources={trailerSources}
      defaultAudioLang={LANGUAGES.ES}
      defaultSubtitleLang={LANGUAGES.ES}
      orientation="portrait"
      className="tw-w-full tw-h-full"
      onTimeUpdate={onTimeUpdate}
    />
  ) : (
    <div className="tw-relative tw-w-full tw-h-full">
      <LazyImage
        alt={title}
        src={coverImage}
        className={clsx(COMMON_COVER_CLASSNAME, sizeImage)}
        imgClassName="tw-object-cover tw-object-top tw-w-full tw-h-full"
        imgxParams={{ fit: 'crop', crop: 'faces', w: 260, h: 220, q: 100 }}
      />
      <button
        type="button"
        onClick={onCloseModal}
        className="tw-bg-neutral-light-800 tw-w-3.2 tw-h-3.2 tw-rounded-full tw-flex tw-justify-center tw-items-center tw-absolute tw-right-1.6 tw-top-1.6 tw-z-7"
      >
        <CloseSmall size={24} className="tw-text-neutral-light-100" />
      </button>
      {trailerSources.length > 0 && (
        <button
          type="button"
          className="tw-w-8 tw-h-8 tw-absolute tw-top-[calc(50%-40px)] tw-left-[calc(50%-40px)] tw-right-0 tw-bottom-0 tw-flex tw-flex-col tw-items-center"
          onClick={onClickImageTrailer}
        >
          <div className="tw-w-5.6 tw-h-5.6 tw-bg-[rgba(255,255,255,0.2)] tw-flex tw-justify-center tw-items-center tw-rounded-full">
            {!isCrehanaCourse ? (
              <ExternalLink size={28} className="tw-text-neutral-light-100" />
            ) : (
              <PlayApps size={28} className="tw-text-neutral-light-100" />
            )}
          </div>
          <p className="tw-font-link2 tw-text-neutral-light-100 tw-mt-0.8">
            {!isCrehanaCourse
              ? t('LEARN_TEXT_LINK')
              : t('LEARN_TEAM_TRACK_OPTIONAL_MENU_VIEW_TRAILER')}
          </p>
        </button>
      )}
    </div>
  );
};

export default CourseCover;
