import { useMemo } from "react";
import { useQuery } from "@apollo/client";
import { date, notEmpty } from "@crehana/utils";
import { StudyPlanTargetEnum } from "../../__generated__/graphql-schema";
var STUDY_PLAN_QUERY = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "StudyPlanQuery"
    },
    variableDefinitions: [],
    directives: [],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "userStudyPlan"
        },
        arguments: [],
        directives: [],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            },
            arguments: [],
            directives: []
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "targetEnum"
            },
            arguments: [],
            directives: []
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "userMetrics"
        },
        arguments: [],
        directives: [],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "progress"
            },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "seconds"
                },
                arguments: [],
                directives: []
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "dates"
                },
                arguments: [],
                directives: [],
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "day"
                    },
                    arguments: [],
                    directives: []
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "date"
                    },
                    arguments: [],
                    directives: []
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "seconds"
                    },
                    arguments: [],
                    directives: []
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }],
  loc: {
    start: 0,
    end: 187,
    source: {
      body: "query StudyPlanQuery {\n  userStudyPlan {\n    id\n    targetEnum\n  }\n  userMetrics {\n    progress {\n      seconds\n      dates {\n        day\n        date\n        seconds\n      }\n    }\n  }\n}\n",
      name: "GraphQL request",
      locationOffset: {
        line: 1,
        column: 1
      }
    }
  }
};
import { DEFAULT_STUDY_PLAN_WEEK_PROGRESS } from "./constants";
export var useStudyPlan = function useStudyPlan(args) {
  var _useQuery = useQuery(STUDY_PLAN_QUERY, {
      skip: args === null || args === void 0 ? void 0 : args.skip
    }),
    loading = _useQuery.loading,
    error = _useQuery.error,
    refetch = _useQuery.refetch,
    data = _useQuery.data;
  var plan = useMemo(function () {
    if (data) {
      var _data$userStudyPlan, _data$userMetrics, _data$userMetrics$pro, _data$userMetrics2, _data$userMetrics2$pr;
      var targetEnum = (_data$userStudyPlan = data.userStudyPlan) === null || _data$userStudyPlan === void 0 ? void 0 : _data$userStudyPlan.targetEnum;
      var progressSeconds = ((_data$userMetrics = data.userMetrics) === null || _data$userMetrics === void 0 ? void 0 : (_data$userMetrics$pro = _data$userMetrics.progress) === null || _data$userMetrics$pro === void 0 ? void 0 : _data$userMetrics$pro.seconds) || 0;
      var weekProgressByDay = ((_data$userMetrics2 = data.userMetrics) === null || _data$userMetrics2 === void 0 ? void 0 : (_data$userMetrics2$pr = _data$userMetrics2.progress) === null || _data$userMetrics2$pr === void 0 ? void 0 : _data$userMetrics2$pr.dates) || [];
      var targetMinutes = 0;
      if (targetEnum) {
        if ([StudyPlanTargetEnum.TARGET_CHILL, StudyPlanTargetEnum.TARGET_REGULAR].includes(targetEnum)) {
          targetMinutes = StudyPlanTargetEnum.TARGET_CHILL === targetEnum ? 30 : 60;
        } else if (targetEnum === StudyPlanTargetEnum.TARGET_INTENSIVE) {
          targetMinutes = 120;
        }
      }
      if (targetMinutes) {
        var _weekProgress$find, _weekProgress$find2;
        var weekProgress = (weekProgressByDay === null || weekProgressByDay === void 0 ? void 0 : weekProgressByDay.length) > 0 ? weekProgressByDay.filter(notEmpty).map(function (item) {
          return {
            day: item.day,
            date: item.date || '',
            seconds: item.seconds || 0
          };
        }) : DEFAULT_STUDY_PLAN_WEEK_PROGRESS;
        var progressMin = Math.ceil(progressSeconds / 60);
        var startDate = (_weekProgress$find = weekProgress.find(function (_ref) {
          var day = _ref.day;
          return day === 'Monday';
        })) === null || _weekProgress$find === void 0 ? void 0 : _weekProgress$find.date;
        var endDate = (_weekProgress$find2 = weekProgress.find(function (_ref2) {
          var day = _ref2.day;
          return day === 'Sunday';
        })) === null || _weekProgress$find2 === void 0 ? void 0 : _weekProgress$find2.date;
        var progressPercent = targetMinutes ? Math.ceil(progressMin / targetMinutes * 100) : 0;

        // Days start on sunday, but we need them to start on monday
        var currentDayIndex = new Date().getDay() - 1;

        // Set sunday as the last day of the week
        if (currentDayIndex < 0) {
          currentDayIndex = 6;
        }
        return {
          currentDayIndex: currentDayIndex,
          startDate: startDate ? date.format(startDate, 'D [de] MMM', 'YYYY/MM/DD') : '',
          endDate: endDate ? date.format(endDate, 'D [de] MMM', 'YYYY/MM/DD') : '',
          target: {
            minutes: targetMinutes,
            seconds: targetMinutes * 60
          },
          progress: {
            minutes: progressMin,
            seconds: progressSeconds,
            percent: progressPercent,
            week: weekProgress
          }
        };
      }
    }
  }, [data]);
  return {
    loading: loading,
    refetch: refetch,
    error: error,
    plan: plan
  };
};