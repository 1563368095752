import { FC, Fragment, useEffect, useState } from 'react';
import * as React from 'react';

import { css } from 'styled-components';

import { formatDate } from '@crehana/date';
import { LANGUAGES } from '@crehana/ts-types';
import {
  Checkbox,
  Col,
  colors,
  Dialog,
  PrimaryButton,
  Row,
  StateTag,
  StringParser,
} from '@crehana/ui';
import { notEmpty } from '@crehana/utils';

import { TaskUserStatusEnum } from '@/shared/types/graphql/globals.v4.b2b';

import useGetHomeUserClassrooms from '../../hooks/useGetHomeUserClassrooms';
import useTasksTranslations from '../../hooks/useTasksTranslations';
import type { TaskType } from '../../types';
import ClassroomCourseCard from './components/ClassroomCourseCard';
import DownloadableAttachment from './components/DownloadableAttachment';
import type { TaskDescriptionDialogProps } from './types';

const setFormattedDate = (data: TaskType | null, languageCode: LANGUAGES) => {
  const date = data?.deadline && new Date(data.deadline);
  const formattedDate = date
    ? formatDate(date, { outputFormat: 'D [de] MMMM [del] YYYY', languageCode })
    : null;

  return formattedDate;
};

const TaskDescriptionDialog: FC<
  React.PropsWithChildren<TaskDescriptionDialogProps>
> = ({ loading, taskData, isDark, slug, onDismiss, onConfirm }) => {
  const { t, languageCode } = useTasksTranslations();
  const [isChecked, setIsChecked] = useState<boolean>(
    taskData?.status === TaskUserStatusEnum.COMPLETED,
  );
  const { white } = colors;
  const { courses } = useGetHomeUserClassrooms({
    slug,
    ids: taskData?.courses?.map(el => el.id).filter(notEmpty) || [],
    open: !!taskData,
  });

  useEffect(() => {
    if (!taskData) {
      setIsChecked(false);
    }
    if (taskData?.status === TaskUserStatusEnum.COMPLETED) {
      setIsChecked(true);
    }
  }, [taskData]);

  return (
    <Dialog open={!!taskData} onDismiss={onDismiss} isDark={isDark} size="l">
      <section className="p-32 sm:p-52">
        <p className="font-h4">{taskData?.title}</p>
        <div className="flex mt-56 mb-32">
          <div className="flex-col w-4/6">
            <p className="font-subtitle1 mb-12">
              {t('TASK_DESCRIPTION_DIALOG_ENDS')}
            </p>
            <span className="font-body1">
              {setFormattedDate(taskData, languageCode)}
            </span>
          </div>
          <div>
            <p className="font-subtitle1 mb-12">
              {t('TASK_DESCRIPTION_DIALOG_PHASE')}
            </p>
            <StateTag
              label={taskData?.phaseName || ''}
              type="green"
              css={css`
                justify-content: center;
                color: ${isDark ? white : ''};
                width: fit-content;
              `}
            />
          </div>
        </div>
        <p className="font-subtitle1 mb-12">
          {t('TASK_DESCRIPTION_DIALOG_DESCRIPTION')}
        </p>
        {taskData?.description && (
          <StringParser plainText={taskData.description} textType="body1" />
        )}
        {taskData?.attachments && taskData.attachments.length > 0 && (
          <Fragment>
            <p className="font-subtitle1 my-24">
              {t('TASK_DESCRIPTION_DIALOG_ATTACHMENTS')}
            </p>
            <section
              className="overflow-y-auto"
              css={css`
                max-height: 180px;
              `}
            >
              {taskData?.attachments.map((el, i) => (
                <DownloadableAttachment
                  key={el.id}
                  name={el.name}
                  description={el.description}
                  id={taskData.taskId || ''}
                  isDark={isDark}
                  isLast={
                    !!taskData.attachments &&
                    i === taskData.attachments.length - 1
                  }
                />
              ))}
            </section>
          </Fragment>
        )}
        {courses.length > 0 && (
          <Fragment>
            <p className="font-subtitle1 my-24">
              {t('TASK_DESCRIPTION_DIALOG_COURSES')}
            </p>
            <Row style={{ maxHeight: 180 }} className="overflow-y-auto">
              {courses.map(course => (
                <Col key={course.originalId} xs={12} sm={6}>
                  <ClassroomCourseCard
                    isDark={isDark}
                    course={course}
                    key={course.originalId}
                  />
                </Col>
              ))}
            </Row>
          </Fragment>
        )}
        <div className="flex flex-col w-full items-center mt-52">
          <Checkbox
            isDark={isDark}
            checked={isChecked}
            onChange={() => {
              if (taskData?.status !== TaskUserStatusEnum.COMPLETED) {
                setIsChecked(!isChecked);
              }
            }}
            label={
              <Fragment>
                <p className="font-subtitle1 uppercase">
                  {t('TASK_DESCRIPTION_DIALOG_MARK_AS_COMPLETED')}
                </p>
              </Fragment>
            }
          />
          <PrimaryButton
            label={
              !isChecked
                ? t('TASK_DESCRIPTION_DIALOG_BUTTON_LABEL_CHECKED')
                : t('TASK_DESCRIPTION_DIALOG_BUTTON_LABEL_CONFIRM')
            }
            isDark={isDark}
            className="mt-24"
            disabled={loading}
            onClick={() =>
              isChecked && taskData?.status !== TaskUserStatusEnum.COMPLETED
                ? onConfirm()
                : onDismiss()
            }
          />
        </div>
      </section>
    </Dialog>
  );
};

export default TaskDescriptionDialog;
