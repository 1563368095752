import { useQuery } from '@apollo/client';

import GET_COURSE_DEPENDENCIES_STATUS_QUERY from '../../graphql/GetCourseDependenciesStatusQuery.v5.graphql';
import {
  GetCourseDependenciesStatusQuery,
  GetCourseDependenciesStatusQueryVariables,
} from '../../graphql/types/GetCourseDependenciesStatusQuery';

const useCoursesDependencies = (organization_team_id: number) => {
  const { data, loading, error } = useQuery<
    GetCourseDependenciesStatusQuery,
    GetCourseDependenciesStatusQueryVariables
  >(GET_COURSE_DEPENDENCIES_STATUS_QUERY, {
    variables: {
      organization_team_id: organization_team_id.toString(),
    },
    context: { clientName: 'v5' },
  });

  return {
    dependencies: parseDependencies(data),
    loading,
    error,
  };
};

function parseDependencies(data?: GetCourseDependenciesStatusQuery) {
  if (!data) return [];

  return data.course_dependencies_status_in_track.map(course => ({
    courseId: course.course_id,
    isBlocked: course.is_blocked,
    dependsOn: course.course_dependency?.map(dep => Number(dep.id)) ?? [],
  }));
}

export default useCoursesDependencies;
