import React from 'react';

import { LazyImage, useUITheme } from '@crehana/compass.ui';

import { IMAGE_ERROR_VIEW_DARK, IMAGE_ERROR_VIEW_LIGHT } from '../../constants';
import useTeamCoursesTranslation from '../../hooks/useTeamCoursesTranslation';

const ErrorViewDetail = () => {
  const { isDark } = useUITheme();
  const { t } = useTeamCoursesTranslation();

  return (
    <div className="tw-w-full tw-h-full tw-flex tw-justify-center tw-items-start">
      <div className="tw-max-w-[508px] tw-mx-auto tw-mt-28.8 tw-flex tw-flex-col tw-justify-center tw-items-center">
        <LazyImage
          src={isDark ? IMAGE_ERROR_VIEW_DARK : IMAGE_ERROR_VIEW_LIGHT}
          alt="error_view_detail_track"
          className="tw-w-[152px] tw-h-[152px]"
          imgxParams={{ w: 152, h: 152 }}
        />
        <p className="tw-font-subtitl5 tw-text-neutral-light-800 dark:tw-text-neutral-light-100 tw-font-semibold tw-text-center tw-mt-2">
          {t('TITLE_ERROR_VIEW_DETAIL_TRACK')}
        </p>
        <p className="tw-font-body5 tw-text-neutral-light-700 dark:tw-text-neutral-dark-300 tw-text-center tw-mt-0.8">
          {t('SUBTITLE_ERROR_VIEW_DETAIL_TRACK')}
        </p>
        <button
          type="button"
          className="tw-btn-primary-xs tw-mt-2"
          onClick={() => {
            window.location.reload();
          }}
        >
          {t('RELOAD_PAGE_ERROR')}
        </button>
      </div>
    </div>
  );
};

export default ErrorViewDetail;
