import { useEffect } from 'react';

import {
  getCommonAmplitudeProperties,
  getSourceEventParams,
  useAmplitude,
} from '@crehana/analytics';
import { useUITheme } from '@crehana/web';
import Mentors from '@crehana/web/HomeUser/Mentors';

import Breadcrumb from '@/views/LearnDashboard/components/Breadcrumb';
import { useLearnDashboardContext } from '@/views/LearnDashboard/context';
import { useCrehanaTranslation } from '@/views/LearnDashboard/hooks/useCrehanaTranslation';

import { LEARN_DASHBOARD_PAGE_NAME } from '../../../constants';

const MentorsSection = () => {
  const { isDark } = useUITheme();
  const { me, isLoadingPlayroomActivated, isPlayroomEnabled } =
    useLearnDashboardContext();
  const { logEvent } = useAmplitude();
  const { t } = useCrehanaTranslation();

  useEffect(() => {
    if (!isLoadingPlayroomActivated) {
      logEvent({
        event: 'Render Home Mentorship',
        data: {
          ...getSourceEventParams(),
          ...getCommonAmplitudeProperties(me, isPlayroomEnabled),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingPlayroomActivated, isPlayroomEnabled]);

  return (
    <div className="w-full h-full">
      <Breadcrumb
        items={[{ label: t('LABEL_SIDEBAR_ITEM_MENTORS') }]}
        className="pt-20"
      />

      <Mentors
        containerType="dashboard"
        isB2B={!!me?.isB2B}
        isDark={isDark}
        me={me}
        amplitudePageName={LEARN_DASHBOARD_PAGE_NAME}
        isFromLearnDashboard
      />
    </div>
  );
};

export default MentorsSection;
