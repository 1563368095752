import dynamic from 'next/dynamic';

import SkillXPEvolutionSkeleton from '../../components/SkillXPEvolutionSkeleton';

const LazySolvedSkills = dynamic(() => import('./SolvedSkills'), {
  ssr: false,
  loading: () => <SkillXPEvolutionSkeleton />,
});

export default LazySolvedSkills;
