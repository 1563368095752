import { FC } from 'react';

import Lock from '@crehana/compass.icons.fill/Lock';
import Star from '@crehana/compass.icons.fill/Star';
import { PlayroomRewardIndicatorWithRive } from '@crehana/compass.playroom';
import { DateTag, TrackTag } from '@crehana/compass.ui';

import useTeamCoursesTranslation from '../../hooks/useTeamCoursesTranslation/useTeamCoursesTranslation';
import { THeaderDetail } from '../../types';
import { humanizeExpirationDate } from '../../utils';

const DetailMobile: FC<THeaderDetail> = ({
  trackName,
  isTrackReward,
  description,
  assigned,
  coinPlayroomNumber,
  date,
  typeTrack = 'active',
  teamExpired,
}) => {
  const { t, languageCode } = useTeamCoursesTranslation();
  const shouldRenderRewardIndicator =
    coinPlayroomNumber !== undefined && coinPlayroomNumber > 0;

  return (
    <div
      className="tw-bg-neutral-light-100   tw-rounded-1 tw-p-1.6 tw-mb-1.2 dark:tw-bg-elevation-dark-04"
      data-testid="detailMobileTrack"
    >
      <div className="tw-flex tw-mb-1.2 tw-justify-between">
        <div className="tw-w-20">
          {typeTrack === 'success' && (
            <TrackTag
              label={t('LEARN_TEAM_HEADER_DETAIL_SUCESS')}
              className="tw-w-fit tw-h-fit tw-mb-1.2"
              trackCompleted
            />
          )}
          <p className="tw-font-subtitle4 tw-text-neutral-light-800 dark:tw-text-neutral-light-100">
            {t('LEARNING_PATHS_LEFT_SIDE_TITLE')} - {trackName}
          </p>
        </div>

        <div className="tw-flex-shrink-0 tw-ml-1.2 tw-relative">
          {date && typeTrack === 'active' && (
            <DateTag
              label={t('LEARN_TEAM_HEADER_DETAIL_DEFEATED')}
              date={
                humanizeExpirationDate(date, {
                  languageCode,
                }) || ''
              }
              isExpired={teamExpired}
            />
          )}
        </div>
      </div>
      <div>
        {assigned && (
          <span className="tw-font-body5 tw-text-neutral-light-700 dark:tw-text-neutral-dark-500">
            {t('LEARN_TEAM_HEADER_DETAIL_ASSIGNED')}: {assigned}
          </span>
        )}

        {description && (
          <p className="tw-font-body5 tw-mt-0.8 tw-text-neutral-light-800 dark:tw-text-neutral-dark-300">
            {description}
          </p>
        )}
        {typeTrack !== 'disabled' && (
          <div className="tw-mt-1.2 tw-flex">
            {isTrackReward && (
              <TrackTag
                label={t('LEARN_TEAM_HEADER_DETAIL_REWARD_MOBILE')}
                icon={Star}
              />
            )}
            {shouldRenderRewardIndicator && (
              <PlayroomRewardIndicatorWithRive
                coins={coinPlayroomNumber}
                className="tw-ml-1.2"
              />
            )}
          </div>
        )}
        {typeTrack === 'disabled' && (
          <TrackTag
            label={t('LEARN_TEAM_HEADER_DETAIL_DISABLED')}
            trackDisabled
            icon={Lock}
            className="tw-mt-1.2 tw-w-fit"
          />
        )}
      </div>
    </div>
  );
};

export default DetailMobile;
