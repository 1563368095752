import { FC } from 'react';

import ArrowIosRight from '@crehana/compass.icons.outline/ArrowIosRight';
import ArrowRight from '@crehana/compass.icons.outline/ArrowRight';
import { useMediaQuery } from '@crehana/react-hooks';
import { colors } from '@crehana/tailwindcss';
import { ProgressRing } from '@crehana/ui';

import { useLearnDashboardContext } from '@/views/LearnDashboard/context';

import type { TAbstract } from '../types';

const Abstract: FC<TAbstract> = ({
  progress,
  icon: Icon,
  course,
  ctaButton,
  onAction,
}) => {
  const { title, subtitle } = course;
  const { matches: isMd } = useMediaQuery({ from: 'md' });
  const { isDark } = useLearnDashboardContext();
  const positionMiddle = isMd ? '30%' : '35%';
  return (
    <div className="md:tw-mb-2 md:tw-min-w-full md:tw-flex-row tw-min-w-[25rem] sm:tw-min-w-[30rem] tw-flex tw-flex-col tw-justify-center md:tw-justify-start md:tw-items-center tw-h-auto tw-bg-neutral-light-100 dark:tw-bg-elevation-dark-04 tw-p-2.4 md:tw-p-0 tw-rounded-2">
      <div className="tw-mx-auto md:tw-ml-0 md:tw-pb-0 tw-text-center tw-pb-2.4 md:tw-mr-1.2">
        <ProgressRing
          progress={progress}
          barColor={
            isDark
              ? colors['secondary-dark']['500']
              : colors['primary-light']['500']
          }
          size={isMd ? 50 : 132}
          strokeWidth={isMd ? 4 : 8}
          centerColor={
            isDark
              ? colors['elevation-dark']['04']
              : colors['neutral-light']['100']
          }
        >
          <svg
            alignmentBaseline="central"
            textAnchor="middle"
            x={positionMiddle}
            y={positionMiddle}
          >
            <Icon
              className="tw-text-primary-light-400 dark:tw-text-secondary-dark-500"
              size={isMd ? 20 : 40}
            />
          </svg>
        </ProgressRing>
      </div>

      <button
        type="button"
        onClick={onAction}
        className="tw-whitespace-normal tw-mt-auto md:tw-mt-0 md:tw-flex md:tw-w-full md:tw-justify-between"
      >
        <div className="dark:tw-text-neutral-light-100 tw-text-neutral-light-800">
          <p className="tw-font-h3 md:tw-font-body4">{title}</p>
          <p
            className="tw-font-body4 md:tw-font-body5 md:tw-text-neutral-light-600 tw-text-left"
            data-testid="abstract_subtitle"
          >
            {subtitle}
          </p>
        </div>
        <span className="tw-text-link-m dark:tw-text-secondary-dark-500 tw-font-subtitle5 tw-mt-1.6 md:tw-mt-0">
          {isMd ? (
            <ArrowIosRight
              size={24}
              className="dark:tw-text-neutral-light-100 tw-text-neutral-light-800"
            />
          ) : (
            <>
              {ctaButton}
              <ArrowRight size={20} className="tw-text-current" />
            </>
          )}
        </span>
      </button>
    </div>
  );
};

export default Abstract;
