import { FC } from 'react';

import { IconProps } from '@crehana/compass.icons.fill/types';
import CheckmarkCircle from '@crehana/compass.icons.outline/CheckmarkCircle';
import Edit from '@crehana/compass.icons.outline/Edit';
import Eye from '@crehana/compass.icons.outline/Eye';
import Info from '@crehana/compass.icons.outline/Info';
import { TTypeProject } from '@crehana/compass.ui';

const useSelectIconType = (type: TTypeProject): FC<IconProps> => {
  const selectIconType = () => {
    if (type === 'pending') return Info;
    if (type === 'underReview') return Eye;
    if (type === 'remarks') return Edit;
    if (type === 'certified') return CheckmarkCircle;
    return Info;
  };

  const IconComponent = selectIconType();

  return IconComponent;
};

export default useSelectIconType;
