const compareDateValues = (dateA: Date, dateB: Date, order: 'asc' | 'desc') => {
  return order === 'asc'
    ? dateA.getTime() - dateB.getTime()
    : dateB.getTime() - dateA.getTime();
};

export function orderByDateProperty<T>(
  array: T[],
  property: keyof T,
  order: 'asc' | 'desc' = 'asc',
): T[] {
  return array.sort((a, b) => {
    const dateA = a[property];
    const dateB = b[property];

    if (typeof dateA === 'string' && typeof dateB === 'string') {
      const valueA = new Date(dateA);
      const valueB = new Date(dateB);
      if (!isNaN(valueA.getTime()) && !isNaN(valueB.getTime())) {
        return compareDateValues(valueA, valueB, order);
      }
    }

    if (dateA instanceof Date && dateB instanceof Date)
      return compareDateValues(dateA, dateB, order);

    if (dateA && !dateB) return -1;

    if (!dateA && dateB) return 1;

    return 0;
  });
}
