import { useEffect, type FC } from 'react';

import {
  getCommonAmplitudeProperties,
  getSourceEventParams,
  useAmplitude,
} from '@crehana/analytics';
import { useMe } from '@crehana/auth';

import { ClassNotes, type MyNotesProps } from '@/shared/components';
import Breadcrumb from '@/views/LearnDashboard/components/Breadcrumb';
import SectionTitle from '@/views/LearnDashboard/components/SectionTitle';
import { useLearnDashboardContext } from '@/views/LearnDashboard/context';

import { useCrehanaTranslation } from '../../../hooks';
import { ENROLLMENTS_COURSES_ROUTE } from '../../../routes/common.routes';

const ClassNotesSection: FC<React.PropsWithChildren<MyNotesProps>> = props => {
  const { logEvent } = useAmplitude();
  const { me } = useMe();
  const { t } = useCrehanaTranslation();
  const { isLoadingPlayroomActivated, isPlayroomEnabled } =
    useLearnDashboardContext();

  const isB2B = !!me?.isB2B;
  const organizationSlug = me?.organization?.slug;

  useEffect(() => {
    if (!isLoadingPlayroomActivated) {
      logEvent({
        event: 'Render Home Notes',
        data: {
          ...getSourceEventParams(),
          ...getCommonAmplitudeProperties(me!, isPlayroomEnabled),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingPlayroomActivated, isPlayroomEnabled]);

  const routeUserB2B = isB2B ? `/org/${organizationSlug}/learn/` : '/learn/';

  return (
    <>
      <Breadcrumb
        items={[{ label: t('LABEL_SIDEBAR_ITEM_MY_COURSES_NOTES') }]}
      />
      <SectionTitle title={t('LABEL_SIDEBAR_ITEM_MY_COURSES_NOTES')} />
      <ClassNotes
        {...props}
        enrollmentsRoutePath={routeUserB2B}
        enrollmentsCoursesRoutePath={ENROLLMENTS_COURSES_ROUTE.path}
      />
    </>
  );
};

export default ClassNotesSection;
