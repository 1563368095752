import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';

import cn from 'clsx';

import {
  getCommonAmplitudeProperties,
  getSourceEventParams,
  useAmplitude,
} from '@crehana/analytics';
import { useMe } from '@crehana/auth';
import { Skeleton } from '@crehana/compass.ui';
import { Container, Dropdown, type CuiOptionType } from '@crehana/ui';
import { useUITheme } from '@crehana/web';
import type { MyDiplomasQueryVariables } from '@crehana/web/Graphql/Queries/types/MyDiplomasQuery';
import useMyDiplomas from '@crehana/web/HomeUser/Diplomas/hooks/useMyDiplomas';

import {
  Diplomas,
  DiplomasProps,
  useParticipationCertificates,
} from '@/shared/components';
import Breadcrumb from '@/views/LearnDashboard/components/Breadcrumb';
import { useLearnDashboardContext } from '@/views/LearnDashboard/context';

import { LEARN_DASHBOARD_PAGE_NAME } from '../../../constants';
import { useCrehanaTranslation } from '../../../hooks/useCrehanaTranslation';
import {
  ENROLLMENTS_DEGREES_ROUTE,
  PROJECTS_ROUTE,
} from '../../../routes/common.routes';
import { DIPLOMA_FILTER } from './constants';
import type { MyDiplomasCustom } from './type';

const DiplomasSection: React.FC<DiplomasProps> = () => {
  const { logEvent } = useAmplitude();
  const { me } = useMe();
  const { t } = useCrehanaTranslation();
  const { isDark } = useUITheme();
  const { isLoadingPlayroomActivated, isPlayroomEnabled } =
    useLearnDashboardContext();

  const [actData, setActData] = useState<MyDiplomasCustom[]>([]);
  const [selectAct, setSelectAct] = useState<CuiOptionType<number>>();
  const [diplomaData, setDiplomaData] = useState<MyDiplomasCustom[]>();
  const [recordsData, setRecordsData] = useState<MyDiplomasCustom[]>();

  const isB2B = !!me?.isB2B;
  const organizationSlug = me?.organization?.slug;

  const {
    loading,
    error,
    refetch: refetchDiplomas,
    diplomas,
  } = useMyDiplomas({
    username: me?.username!,
  });

  const onRefetch = useCallback(
    (refetchVariables?: MyDiplomasQueryVariables) => {
      if (refetchDiplomas) return refetchDiplomas(refetchVariables);
    },
    [refetchDiplomas],
  );

  const {
    certificatesData: constancies,
    loading: loadingRecords,
    refetch,
  } = useParticipationCertificates();

  const isLoading = loading || loadingRecords;

  const OptionCustom: CuiOptionType<number>[] = useMemo(
    () => [
      {
        label: t('DOWNLOAD_FILTER_ALL', {
          interpolation: { count: diplomas.length + constancies.length },
        }),
        value: DIPLOMA_FILTER.ALL,
      },
      {
        label: t('DOWNLOAD_FILTER_DIPLOMA', {
          interpolation: { count: diplomas.length },
        }),
        value: DIPLOMA_FILTER.CERTIFICATIONS,
      },
      {
        label: t('DOWNLOAD_FILTER_PROOF', {
          interpolation: { count: constancies.length },
        }),
        value: DIPLOMA_FILTER.CONSTANCIES,
      },
    ],
    [t, diplomas.length, constancies.length],
  );

  useEffect(() => {
    if (!selectAct && !loading && !loadingRecords)
      setSelectAct(OptionCustom[0]);
  }, [OptionCustom, loading, loadingRecords, selectAct]);

  useEffect(() => {
    if (diplomas) {
      setDiplomaData(
        diplomas.map(element => ({
          ...element,
          type: t('DOWNLOAD_DIPLOMA'),
        })),
      );
    }

    if (constancies) {
      setRecordsData(
        constancies.map(
          certificate =>
            ({
              ...certificate,
              type: t('DOWNLOAD_PROOF'),
            } as unknown as MyDiplomasCustom),
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diplomas.length, constancies.length]);

  useLayoutEffect(() => {
    if (diplomaData && recordsData) {
      if (!selectAct?.value || selectAct?.value === DIPLOMA_FILTER.ALL) {
        setActData(diplomaData.concat(recordsData));
      } else if (selectAct?.value === DIPLOMA_FILTER.CERTIFICATIONS) {
        onRefetch()?.then(() => {
          setActData(diplomaData);
        });
      } else if (selectAct?.value === DIPLOMA_FILTER.CONSTANCIES) {
        refetch().then(() => {
          setActData(recordsData);
        });
      }
    }
  }, [selectAct, diplomaData, recordsData, onRefetch, me?.username, refetch]);

  useEffect(() => {
    if (!isLoadingPlayroomActivated) {
      logEvent({
        event: 'Render Home Certificates',
        data: {
          ...getSourceEventParams(),
          ...getCommonAmplitudeProperties(me!, isPlayroomEnabled),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingPlayroomActivated, isPlayroomEnabled]);

  return (
    <>
      <Breadcrumb
        items={[{ label: t('LABEL_SIDEBAR_ITEM_MY_COURSES_CERTIFICATIONS') }]}
      />
      <Container className="my-24" type="dashboard">
        {isLoading ? (
          <Skeleton className="tw-mb-2 tw-w-[30%] tw-h-2" animated />
        ) : (
          <h3
            className={cn('mb-20 font-body3 ', {
              'text-white': isDark,
              'text-base-main': !isDark,
            })}
          >
            {t('DOWNLOAD_DESCRIPTION_GENERAL', {
              interpolation: {
                diplomasCount: diplomas.length,
                certificateCount: constancies.length,
              },
            })}
          </h3>
        )}

        <Dropdown
          displayPosition="bottom"
          options={OptionCustom}
          hint={t('DOWNLOAD_FILTER')}
          selected={selectAct}
          onSelected={setSelectAct}
          size={4}
          isDark={isDark}
        />
      </Container>

      <Diplomas
        amplitudePageName={LEARN_DASHBOARD_PAGE_NAME}
        enrollmentsRoutePath={
          isB2B ? `/org/${organizationSlug}/learn/` : '/learn/'
        }
        enrollmentsDegreesRoutePath={ENROLLMENTS_DEGREES_ROUTE.path}
        projectRoutePath={PROJECTS_ROUTE.path}
        loading={isLoading}
        refetch={onRefetch}
        error={error}
        actData={actData}
      />
    </>
  );
};

export default DiplomasSection;
