import { FC } from 'react';
import { Link } from 'react-router-dom';

import ArrowIosLeft from '@crehana/compass.icons.outline/ArrowIosLeft';

import { useCrehanaTranslation } from '@/views/LearnDashboard/hooks/useCrehanaTranslation';

import type {
  BreadcrumbTracksItem,
  BreadcrumbTracksProps,
  TBreadcrumbTracksPropsMobile,
} from './type';

const BreadcrumbMobile: FC<
  TBreadcrumbTracksPropsMobile & Pick<BreadcrumbTracksProps, 'items'>
> = ({ homeUrl, itemClass, items }) => {
  const { t } = useCrehanaTranslation();
  const selectItem: BreadcrumbTracksItem | undefined =
    items && items[items.length - 1];

  return (
    <li className="breadcrumb-item tw-font-body5" key="home">
      <Link className={itemClass} to={homeUrl}>
        <div className="tw-w-2 tw-h-2 tw-mr-0.4">
          <ArrowIosLeft size={20} className="tw-fill-current " />
        </div>
        <span>{selectItem?.label ?? t('LABEL_SIDEBAR_ITEM_START')}</span>
      </Link>
    </li>
  );
};

export default BreadcrumbMobile;
