import { FC } from 'react';

import { LazyImage, TrackTag } from '@crehana/compass.ui';

import { TCardProject } from '../../types';
import useSelectIconType from '../../utils/useSelectIconType';
import ContinueButton from './ContinueButton';

const CardProjectTable: FC<TCardProject> = ({
  type,
  typeTitle,
  logo,
  title,
  handleClick,
}) => {
  const IconComponent = useSelectIconType(type || 'underReview');
  return (
    <div className="dark:tw-bg-elevation-dark-02 tw-border hover:tw-border-primary-light-500 hover:dark:tw-border-primary-light-500 tw-border-neutral-light-200 dark:tw-border-elevation-dark-02 tw-border-solid tw-bg-neutral-light-200 tw-cursor-pointer tw-flex tw-gap-0.8 tw-h-full tw-items-center tw-mt-0.8 tw-p-1.2 tw-rounded-0.8 tw-w-full">
      {logo && (
        <div className="tw-w-4 tw-h-4 tw-min-w-4 tw-min-h-4 tw-relative tw-mr-1.2">
          <LazyImage
            src={logo}
            alt="Skill_Path_background"
            isTransparent
            imgClassName="tw-object-cover tw-w-full tw-h-full tw-rounded-0.4 tw-relative"
            className="tw-w-full tw-h-full"
            imgxParams={{ w: 40, h: 40, dpr: 2 }}
          />
        </div>
      )}
      <div className="tw-flex tw-flex-col tw-w-fit">
        {typeTitle && type && (
          <TrackTag
            label={typeTitle}
            className="tw-w-fit tw-h-fit tw-mb-0.4"
            icon={IconComponent}
            type={type}
          />
        )}

        <span className="tw-line-clamp-1 tw-font-body5 tw-text-neutral-light-800 dark:tw-text-neutral-light-100">
          {title}
        </span>
      </div>
      {type !== 'certified' && (
        <ContinueButton
          handleClick={handleClick}
          className="dark:tw-text-secondary-dark-400 tw-flex tw-flex-shrink-0 tw-font-subtitle5 tw-items-center tw-text-primary-light-500 tw-w-fit tw-ml-auto"
        />
      )}
    </div>
  );
};

CardProjectTable.defaultProps = {
  logo: 'https://crehana-public-catalog.imgix.net/images/app/gallery/2023/07/10/f27cd406/bfd00a2b.png',
};

export default CardProjectTable;
