import {
  getG11nAndTranslationsFromServer,
  type TCustomGetServerSideProps,
} from '@crehana/next';

import { LEARN_DASHBOARD_PAGE_KEY } from '../constants';
import locales from '../i18n';
import type { IAppProps } from '../types';

const getServerSideProps: TCustomGetServerSideProps<IAppProps> = async ({
  req,
  query,
}) => {
  const g11nData = await getG11nAndTranslationsFromServer({
    headers: req.headers,
    pageKey: LEARN_DASHBOARD_PAGE_KEY,
    defaultTranslations: locales,
  });

  const organizationSlug = query.organization as string | undefined;

  return {
    props: {
      ...g11nData,
      isAppFeatureEnabled: true,
      isDesktopFeatureEnabled: true,
      organizationSlug,
    },
  };
};

export default getServerSideProps;
