import { FC } from 'react';
import * as React from 'react';

import LaptopStar from '@crehana/icons/LaptopStar';
import Rocket from '@crehana/icons/pictograms/Rocket';
import UserWithFlag from '@crehana/icons/UserWithFlag';
import {
  Col,
  colors,
  Dialog,
  DialogContent,
  InverseButton,
  PrimaryButton,
  Row,
} from '@crehana/ui';

import { useCrehanaTranslation } from '@/views/LearnDashboard/hooks/useCrehanaTranslation';

type RewardsDialogProps = {
  open: boolean;
  onDismiss: () => void;
  onDecline?: () => void;
  onAccept?: () => void;
};

const RewardsDialog: FC<React.PropsWithChildren<RewardsDialogProps>> = ({
  open,
  onDismiss,
  onDecline,
  onAccept,
}) => {
  const { t } = useCrehanaTranslation();

  return (
    <Dialog open={open} onDismiss={onDismiss} data-testid="rewards-dialog">
      <DialogContent>
        <p className="font-h3 text-center mb-40">{t('REWARDS_TITLE')}</p>
        <p className="font-body3 flex justify-center mb-32">
          {t('REWARDS_SUBTITLE')}
        </p>
        <Row>
          <Col lg={4} md={4} xs={12} className="flex flex-col items-center">
            <LaptopStar size={48} secondaryColor={colors.secondaryMain} />
            <p className="font-body4 mt-24 text-center text-gray-dark whitespace-pre-line">
              {t('REWARDS_COPY1')}
            </p>
          </Col>
          <Col lg={4} md={4} xs={12} className="flex flex-col items-center">
            <UserWithFlag size={48} secondaryColor={colors.primaryLight} />
            <p className="font-body4 mt-24 text-center text-gray-dark whitespace-pre-line">
              {t('REWARDS_COPY2')}
            </p>
          </Col>
          <Col lg={4} md={4} xs={12} className="flex flex-col items-center">
            <Rocket size={48} secondaryColor={colors.yellowMain} />
            <p className="font-body4 mt-24 text-center text-gray-dark whitespace-pre-line">
              {t('REWARDS_COPY3')}
            </p>
          </Col>
        </Row>
        <div className="flex justify-center items-center mt-48">
          <InverseButton
            label={t('REWARDS_CANCEL')}
            onClick={onDecline}
            className="mr-12"
            data-cy="decline-rewards-button"
          />
          <PrimaryButton
            label={t('REWARDS_ACCEPT')}
            onClick={onAccept}
            data-cy="accept-rewards-button"
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default RewardsDialog;
