import { boldFormatter } from '@crehana/i18n';
import { Container } from '@crehana/ui';

import { useCrehanaTranslation } from '../hooks/useCrehanaTranslation';

type MainTitleProps = {
  className?: string;
  paginationClassName?: string;
  containerType?: 'default' | 'dashboard';
  username: string;
  hideWelcomeTitle?: boolean;
  hasEnrollments?: boolean;
};

const MainTitle: React.FC<React.PropsWithChildren<MainTitleProps>> = ({
  className,
  paginationClassName,
  containerType,
  username,
  hideWelcomeTitle,
  hasEnrollments,
}) => {
  const { t } = useCrehanaTranslation();

  return (
    <Container className={className} type={containerType}>
      {paginationClassName && (
        <div className=" flex-row flex justify-between">
          <h1 className="font-h3-reg">
            {t('MAIN_SECTION_TITLE_GREETING', {
              formatter: boldFormatter,
              interpolation: { name: username },
            })}
            {!hideWelcomeTitle && (
              <p className="hidden sm:inline">
                {' '}
                {t(
                  hasEnrollments
                    ? 'MAIN_SECTION_TITLE_WELCOME_BACK'
                    : 'MAIN_SECTION_TITLE_WELCOME',
                )}
              </p>
            )}
          </h1>
          <div className={paginationClassName} />
        </div>
      )}
      {!paginationClassName && (
        <h1 className="font-h3-reg">
          {t('MAIN_SECTION_TITLE_GREETING', {
            formatter: boldFormatter,
            interpolation: { name: username },
          })}
          {!hideWelcomeTitle && (
            <p className="hidden sm:inline">
              {' '}
              {t(
                hasEnrollments
                  ? 'MAIN_SECTION_TITLE_WELCOME_BACK'
                  : 'MAIN_SECTION_TITLE_WELCOME',
              )}
            </p>
          )}
        </h1>
      )}
    </Container>
  );
};

export default MainTitle;
