import { memo, useCallback, useEffect } from 'react';

import {
  getCommonAmplitudeProperties,
  getSourceEventParams,
  useAmplitude,
} from '@crehana/analytics';
import {
  PlayroomRewardsSection,
  useAvailableCurrencyByUser,
} from '@crehana/compass.playroom';
import { Container } from '@crehana/ui';

import { Breadcrumb } from '@/views/LearnDashboard/components';
import { useLearnDashboardContext } from '@/views/LearnDashboard/context';
import { useCrehanaTranslation } from '@/views/LearnDashboard/hooks';

const PlayroomRewardsView = () => {
  const { t } = useCrehanaTranslation();
  const {
    me,
    isPlayroomEnabled,
    isLoadingPlayroomActivated,
    organizationSlug,
    emitRedeemCatalogEvent,
  } = useLearnDashboardContext();
  const { crehanaCoinsCurrentUser, currencyCustomName, refetch } =
    useAvailableCurrencyByUser(organizationSlug as string);
  const { logEvent } = useAmplitude();
  const organizationId = String(me?.organization?.originalId);

  const onRedeemSuccessHandler = useCallback(() => {
    // Wait 2 seconds to refetch the data.
    // We need to wait because the API may not have updated the data yet.
    const TIMEOUT = 2_000;
    setTimeout(() => {
      void refetch();
      emitRedeemCatalogEvent();
    }, TIMEOUT);
  }, [emitRedeemCatalogEvent, refetch]);

  useEffect(() => {
    if (!isLoadingPlayroomActivated) {
      logEvent({
        event: 'Render Home Rewards',
        data: {
          ...getSourceEventParams(),
          ...getCommonAmplitudeProperties(me, isPlayroomEnabled),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingPlayroomActivated, isPlayroomEnabled]);

  return (
    <>
      <Breadcrumb
        items={[{ label: t('LABEL_SIDEBAR_ITEM_PLAYROOM_REWARDS') }]}
      />

      <Container type="dashboard" className="compass tw-mt-2">
        <PlayroomRewardsSection
          crehanaCoinsAvailable={crehanaCoinsCurrentUser}
          organizationSlug={organizationSlug as string}
          organizationId={organizationId}
          customCurrencyName={currencyCustomName}
          onRedeemSuccess={onRedeemSuccessHandler}
        />
      </Container>
    </>
  );
};

export default memo(PlayroomRewardsView);
