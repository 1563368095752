const HelperTooltip = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => {
  return (
    <div className="tw-w-[208px] tw-whitespace-normal">
      <p className="tw-font-subtitle5">{title}</p>
      <p className="tw-font-body5 tw-mt-0.4 tw-whitespace-normal">
        {description}
      </p>
    </div>
  );
};

export default HelperTooltip;
