import { useCrehanaTranslation } from '@/views/LearnDashboard/hooks/useCrehanaTranslation';

import { redirectToEditStudyPlan } from '../../../utils/redirectToEditStudyPlan';
import Header from './Header';

const Empty = () => {
  const { t } = useCrehanaTranslation();

  return (
    <div
      className="tw-flex tw-flex-col tw-justify-between tw-mr-2.4 md:tw-mr-0 md:tw-h-[44.4rem] tw-p-2.4 tw-rounded-2 tw-text-neutral-light-800 dark:tw-text-neutral-light-100 tw-bg-neutral-light-100 dark:tw-bg-elevation-dark-04"
      data-testid="plan_empty"
    >
      <Header />
      <div className="tw-mt-12.4 md:tw-mt-14.4">
        <p className="tw-font-h4 md:tw-font-subtitle1 tw-mb-0.8">
          {t('START_LEARNING_UNASSIGNED_TITLE')}
        </p>
        <p className="md:tw-font-body3 tw-font-body4 tw-mb-1.6 ">
          {t('START_LEARNING_UNASSIGNED_SUBTITLE')}
        </p>
        <button
          className="tw-btn-primary-s max-xs:tw-px-1.2"
          type="button"
          onClick={redirectToEditStudyPlan}
        >
          {t('START_LEARNING_UNASSIGNED_CTA')}
        </button>
      </div>
    </div>
  );
};

export default Empty;
