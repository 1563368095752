import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import { useQuery } from "@apollo/client";
import { transformData } from "@crehana/utils";
import { NEW_COURSES_COUNT, NEW_COURSES_ORDER_BY_VALUE } from "../components/NewCoursesSlider/constants";
var NEW_COURSES_QUERY = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "LearnHomeUserNewCoursesSliderQuery"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "first"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "Int"
        }
      },
      directives: []
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "orderBy"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      },
      directives: []
    }],
    directives: [],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "courses"
        },
        arguments: [{
          kind: "Argument",
          name: {
            kind: "Name",
            value: "first"
          },
          value: {
            kind: "Variable",
            name: {
              kind: "Name",
              value: "first"
            }
          }
        }, {
          kind: "Argument",
          name: {
            kind: "Name",
            value: "newOrderBy"
          },
          value: {
            kind: "Variable",
            name: {
              kind: "Name",
              value: "orderBy"
            }
          }
        }],
        directives: [],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "edges"
            },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "node"
                },
                arguments: [],
                directives: [],
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "originalId"
                    },
                    arguments: [],
                    directives: []
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "title"
                    },
                    arguments: [],
                    directives: []
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "shortDescription"
                    },
                    arguments: [],
                    directives: []
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "description"
                    },
                    arguments: [],
                    directives: []
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "url"
                    },
                    arguments: [],
                    directives: []
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "promoImage"
                    },
                    arguments: [],
                    directives: []
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "imagePlaceholder"
                    },
                    arguments: [],
                    directives: []
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "dashboardUrl"
                    },
                    arguments: [],
                    directives: []
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "lastVideoLectureUrl"
                    },
                    arguments: [],
                    directives: []
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "isEnrollment"
                    },
                    arguments: [],
                    directives: []
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "courseTag"
                    },
                    arguments: [],
                    directives: [],
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "textLabel"
                        },
                        arguments: [],
                        directives: []
                      }, {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "textColor"
                        },
                        arguments: [],
                        directives: []
                      }, {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "backgroundColor"
                        },
                        arguments: [],
                        directives: []
                      }]
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "professor"
                    },
                    arguments: [],
                    directives: [],
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "fullName"
                        },
                        arguments: [],
                        directives: []
                      }, {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "profilePictureUrl"
                        },
                        arguments: [],
                        directives: []
                      }, {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "profileUrl"
                        },
                        arguments: [],
                        directives: []
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }],
  loc: {
    start: 0,
    end: 566,
    source: {
      body: "query LearnHomeUserNewCoursesSliderQuery($first: Int, $orderBy: String) {\n  courses(first: $first, newOrderBy: $orderBy) {\n    edges {\n      node {\n        originalId\n        title\n        shortDescription\n        description\n        url\n        promoImage\n        imagePlaceholder\n        dashboardUrl\n        lastVideoLectureUrl\n        isEnrollment\n        courseTag {\n          textLabel\n          textColor\n          backgroundColor\n        }\n        professor {\n          fullName\n          profilePictureUrl\n          profileUrl\n        }\n      }\n    }\n  }\n}\n",
      name: "GraphQL request",
      locationOffset: {
        line: 1,
        column: 1
      }
    }
  }
};
var useNewCoursesQuery = function useNewCoursesQuery(variables) {
  var _useQuery = useQuery(NEW_COURSES_QUERY, {
      variables: _objectSpread({
        first: NEW_COURSES_COUNT,
        orderBy: NEW_COURSES_ORDER_BY_VALUE
      }, variables),
      notifyOnNetworkStatusChange: true
    }),
    data = _useQuery.data,
    refetch = _useQuery.refetch,
    called = _useQuery.called,
    loading = _useQuery.loading,
    error = _useQuery.error;
  return {
    newCourses: transformData(data === null || data === void 0 ? void 0 : data.courses),
    called: called,
    loading: loading,
    error: !!error,
    onRefetch: function onRefetch() {
      refetch();
    }
  };
};
export default useNewCoursesQuery;