/**
 * @category Date format
 *
 * @summary Show duration in seconds as Xm Xs / Xh Xm / Xd Xh
 *
 * @param {number} durationInSeconds - The duration to format in seconds
 *
 * @example
 * console.log(toMinutes(244)); // 4m 4s
 * console.log(toMinutes(4200)); // 1h 10m
 * console.log(toMinutes(90000)); // 1d 1h
 */
var toMinutes = function toMinutes(durationInSeconds) {
  var minutes = Math.floor(durationInSeconds / 60);
  if (minutes < 60) {
    var remainingSeconds = Math.ceil(durationInSeconds - minutes * 60);
    return "".concat(minutes, "m ").concat(remainingSeconds, "s");
  }
  var hours = Math.floor(minutes / 60);
  if (hours < 24) {
    var remainingMinutes = Math.ceil(minutes - hours * 60);
    return "".concat(hours, "h ").concat(remainingMinutes, "m");
  }
  var days = Math.floor(hours / 24);
  var remainingHours = Math.ceil(hours - days * 24);
  return "".concat(days, "d ").concat(remainingHours, "h");
};
export default toMinutes;