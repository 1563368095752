import Star from '@crehana/compass.icons.fill/Star';
import { TrackTag } from '@crehana/compass.ui';

import { useLearnDashboardContext } from '@/views/LearnDashboard/context';

import useTeamCoursesTranslation from '../../../hooks/useTeamCoursesTranslation/useTeamCoursesTranslation';
import { STATUS, TDetailRewards } from '../../../types';

interface RewardCardNewProps {
  detailReward: TDetailRewards;
  onClickStatusEnable: () => void;
  onClickStatusTaken: () => void;
}

const RewardCardNew: React.FC<RewardCardNewProps> = ({
  onClickStatusEnable,
  onClickStatusTaken,
  detailReward,
}) => {
  const { t } = useTeamCoursesTranslation();
  const { organizationSlug } = useLearnDashboardContext();

  const TRACK_IN_PROGRESS = {
    tagText: t('LEARN_TEAM_HEADER_DETAIL_REWARD_IN_PROGRESS'),
    title: t('LEARN_TEAM_HELP', {
      formatter: (text: string) =>
        text.replace(
          /\*(.*?)\*/,
          (_, replaced) =>
            `<span class="tw-font-link1 tw-text-primary-light-500 dark:tw-text-primary-light-300">${
              replaced as string
            }</span>`,
        ),
      interpolation: {
        courseCount: detailReward.numberCourses,
      },
    }),
    button: t('LEARN_TEAM_MORE'),
    onClick: onClickStatusEnable,
  };

  const TRACK_COMPLETED = {
    tagText: t('LEARN_TEAM_HEADER_DETAIL_REWARD'),
    title: t('LEARN_CARD_REWARD_TITLE_COMPLETED_NEW', {
      formatter: (text: string) =>
        text.replace(
          /\*(.*?)\*/,
          (_, replaced) =>
            `<span class="tw-font-link1 tw-text-primary-light-500 dark:tw-text-primary-light-300">${
              replaced as string
            }</span>`,
        ),
      interpolation: {
        courseCount: detailReward.numberCourses,
      },
    }),
    button: t('REWARD_CARD_TRACK_COMPLETED_CTA'),
    onClick: () => {
      window.location.href = `/org/${organizationSlug}/${detailReward.originalId}/courses-rewards/`;
    },
  };

  const TRACK_REWARD_TAKEN = {
    tagText: t('LEARN_TEAM_HEADER_DETAIL_REWARD_TAKEN'),
    title: t('LEARN_CARD_REWARD_TITLE_TAKEN_NEW'),
    button: t('LEARN_TEAM_MORE'),
    onClick: onClickStatusTaken,
  };

  const REWARD_COMMS = {
    [STATUS.REWARD_STATUS_NOT_AVAILABLE]: TRACK_IN_PROGRESS,
    [STATUS.REWARD_STATUS_AVAILABLE]: TRACK_COMPLETED,
    [STATUS.REWARD_STATUS_TAKEN]: TRACK_REWARD_TAKEN,
    [STATUS.REWARD_STATUS_INACTIVE]: null,
  }[detailReward.status];

  if (!REWARD_COMMS) {
    return null;
  }

  return (
    <footer className="sm:tw-flex-row sm:tw-items-center tw-bg-neutral-light-200 tw-flex tw-flex-col tw-justify-between tw-mt-2 tw-p-2 tw-pt-1.2 tw-rounded-0.8 dark:tw-bg-elevation-dark-02">
      <div>
        <TrackTag
          label={REWARD_COMMS.tagText}
          icon={Star}
          className="tw-mb-0.8 tw-w-max"
          type="info"
        />
        <span className="tw-font-body5 tw-text-neutral-light-800  dark:tw-text-neutral-dark-100">
          {REWARD_COMMS.title}
        </span>
      </div>
      <button
        className="tw-btn-inverse-xs tw-flex tw-items-center tw-justify-center tw-mt-1.2"
        type="button"
        onClick={REWARD_COMMS.onClick}
      >
        {REWARD_COMMS.button}
      </button>
    </footer>
  );
};

export default RewardCardNew;
