import { useTranslation } from '@crehana/i18n';

import { B2B_TEAM_COURSES } from '../../constants';
import locales from '../../i18n';
import type { B2bTeamCoursesLocaleKeys } from '../../types';

const useTeamCoursesTranslation = () => {
  const { addResourceBundle, ...translationContextValue } =
    useTranslation<B2bTeamCoursesLocaleKeys>(B2B_TEAM_COURSES);

  addResourceBundle(locales);

  return translationContextValue;
};

export default useTeamCoursesTranslation;
