import { FC } from 'react';

import { css } from 'styled-components';

import { Container, Skeleton } from '@crehana/ui';
import { useUITheme } from '@crehana/web';

const SkillXPEvolutionSkeleton: FC<
  React.PropsWithChildren<{ 'data-testid'?: string }>
> = ({ 'data-testid': dataTestId }) => {
  const { isDark } = useUITheme();

  return (
    <Container type="dashboard" className="pt-32 pb-24 h-full">
      <Skeleton
        data-testid={dataTestId}
        isDark={isDark}
        className="w-full rounded-10"
        css={css`
          height: 252px;
        `}
      />
    </Container>
  );
};

export default SkillXPEvolutionSkeleton;
