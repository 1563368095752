import { useMediaQuery } from '@crehana/react-hooks';

import { TRACK_HELP } from '../../../constants';
import useTeamCoursesTranslation from '../../../hooks/useTeamCoursesTranslation';
import { B2bTeamCoursesLocaleKeys } from '../../../types';
import LayoutCardModal from '../LayoutCardModal';

type ModalRewardInProgressProps = {
  onDismiss: () => void;
  isOpen: boolean;
};

const ModalRewardInProgress: React.FC<ModalRewardInProgressProps> = ({
  isOpen,
  onDismiss,
}) => {
  const { matches: isMd } = useMediaQuery('md');
  const { t } = useTeamCoursesTranslation();

  return (
    <LayoutCardModal
      onDismiss={onDismiss}
      isOpen={isOpen}
      title={t('REWARDS_TITLE')}
    >
      <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-2.4 md:tw-px-2 md:tw-pb-3.2 tw-mx-2.4 tw-pt-1.2 tw-pb-2.4">
        {TRACK_HELP.map(({ Icon, text }, i) => (
          <div key={`cardModalHelp_${i * 1}`}>
            <div className="md:tw-flex-col tw-bg-neutral-light-200 tw-pt-2.4 tw-px-1.2 tw-pb-2 tw-rounded-2 tw-flex tw-items-center">
              <div>
                <Icon
                  size={isMd ? 48 : 24}
                  ignoreColor
                  className="tw-fill-current tw-mb-2.4 sm:tw-mb-0 md:tw-mb-2.4 tw-text-primary-light-500"
                />
              </div>
              <span className="md:tw-text-center tw-font-body5 sm:tw-font-body4 tw-text-neutral-light-800 tw-ml-1.2">
                {t(text as B2bTeamCoursesLocaleKeys)}
              </span>
            </div>
          </div>
        ))}
      </div>
    </LayoutCardModal>
  );
};

export default ModalRewardInProgress;
