import { useState } from 'react';

import { State } from '@crehana/ui';

const useSnackbarMessage = () => {
  const [{ isOpen, message, state }, setSnackbarState] = useState<{
    isOpen: boolean;
    message: string;
    state?: State;
  }>({
    isOpen: false,
    message: '',
    state: 'info',
  });

  const openSnackbar = ({ msg, type }: { msg: string; type?: State }) => {
    setSnackbarState(snackbarState => ({
      ...snackbarState,
      isOpen: true,
      message: msg,
      state: type,
    }));
  };

  return {
    openSnackbar,
    isOpen,
    message,
    state,
  };
};

export default useSnackbarMessage;
