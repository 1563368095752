import { Skeleton } from '@crehana/compass.ui';

const SkeletonLearnTeam = () => (
  <div className="tw-bg-neutral-light-100 dark:tw-bg-elevation-dark-04 tw-px-1.6 tw-py-2 tw-rounded-1 tw-mt-1.2">
    <Skeleton animated className="tw-h-2.4 tw-w-16 tw-mb-2.4 tw-rounded-0.8" />
    {Array.from(Array(4)).map((_, i) => (
      <div
        key={`SkeletonLearnTeam_${i * 1}`}
        className="tw-flex tw-h-full tw-mb-1.6 tw-w-full"
      >
        <div className="tw-flex tw-h-8.8 tw-items-center tw-w-full">
          <Skeleton
            animated
            className="tw-h-3.2 tw-w-3.2 tw-rounded-full tw-mr-1.2"
          />
          <Skeleton
            animated
            className="tw-h-full tw-w-8.8 tw-rounded-0.8 tw-mr-1.2"
          />
          <Skeleton animated className="tw-h-1.6 tw-w-20 tw-rounded-0.8" />
        </div>
        <Skeleton animated className="tw-h-3.2 tw-w-4 tw-rounded-0.8" />
      </div>
    ))}
  </div>
);

export default SkeletonLearnTeam;
