import { FC, useEffect } from 'react';

import { useLearnLastActivityContext } from '@crehana/web';

import { TrackSectionScroll } from '@/views/LearnDashboard/components';

import { AMPLITUDE_SCROLL_EVENT_NAME } from '../constants';
import { LearningPlanNew } from '../sections/LearningPlanNew';
import type { TLastActivity, TLearningPlan } from '../types';
import { chooseProgressState } from '../utils/utils';

const LearningPlanLayout: FC<
  TLearningPlan & Pick<TLastActivity, 'plans' | 'actionMetrics'>
> = ({ plans, actionMetrics, onActionClickLearningPlan, isCourseAssigned }) => {
  const emptyLearning: boolean = Boolean(!plans?.target.minutes);

  const { canStateLearning } = useLearnLastActivityContext();
  useEffect(() => {
    if (canStateLearning && !emptyLearning)
      canStateLearning(chooseProgressState(plans));
  }, [canStateLearning, emptyLearning, plans]);

  return (
    <>
      <TrackSectionScroll
        amplitudeEventName={AMPLITUDE_SCROLL_EVENT_NAME}
        amplitudeSource="Progress Bar"
      />
      <LearningPlanNew
        hasEmpty={{
          empty: emptyLearning,
          emptyWithoutCourse: !isCourseAssigned,
        }}
        summaryCount={{
          course: {
            progress: actionMetrics?.completedCourses.inProgress || 0,
            target: actionMetrics?.completedCourses.total || 0,
            onClick: () => {
              onActionClickLearningPlan('action_completed_courses');
            },
          },
          project: {
            progress: actionMetrics?.completedProjects.inProgress || 0,
            target: actionMetrics?.completedProjects.total || 0,
            onClick: () => {
              onActionClickLearningPlan('action_completed_projects');
            },
          },
          route: {
            progress: actionMetrics?.completedTracks.inProgress || 0,
            target: actionMetrics?.completedTracks.total || 0,
            onClick: () => {
              onActionClickLearningPlan('action_completed_tracks');
            },
          },
        }}
        plans={plans}
      />
    </>
  );
};

export default LearningPlanLayout;
