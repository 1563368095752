import { useQuery } from '@apollo/client';

import GET_KNOWLEDGE_HUB_CONFIG from '../graphql/GetKnowledgeHubConfigQuery.v5.graphql';
import type {
  GetKnowledgeHubConfigQuery,
  GetKnowledgeHubConfigQueryVariables,
} from '../graphql/types/GetKnowledgeHubConfigQuery';

type UseGetKnowledgeHubConfigProps = {
  organizationId: string;
};

const useGetKnowledgeHubConfig = ({
  organizationId,
}: UseGetKnowledgeHubConfigProps) => {
  const { data, loading, refetch } = useQuery<
    GetKnowledgeHubConfigQuery,
    GetKnowledgeHubConfigQueryVariables
  >(GET_KNOWLEDGE_HUB_CONFIG, {
    context: { clientName: 'v5' },
    variables: {
      organizationId,
    },
  });

  const KHConfig = data?.knowledge_hub_config.knowledge_hub_config;

  return {
    knowledgeHubActivated: KHConfig?.has_knowledge_hub,
    loading,
    refetch,
  };
};

export default useGetKnowledgeHubConfig;
