import { DialogInformative } from '@crehana/compass.ui';

import useTeamCoursesTranslation from '../../../hooks/useTeamCoursesTranslation';
import CourseCover from './CourseCover';

type ModalShowExternalContentProps = {
  isOpen: boolean;
  onDismiss: () => void;
  title: string;
  coverImage: string;
  sizeImage: string;
  lenguage: string;
  professor: string;
  onRedirectToExternalContent: (
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
};

const ModalShowExternalContent: React.FC<ModalShowExternalContentProps> = ({
  isOpen,
  onDismiss,
  title,
  coverImage,
  lenguage,
  professor,
  sizeImage,
  onRedirectToExternalContent,
}) => {
  const { t } = useTeamCoursesTranslation();

  return (
    <DialogInformative
      size="s"
      open={isOpen}
      onDismiss={onDismiss}
      className="tw-p-0 dark:tw-shadow-none"
    >
      <>
        <div className="tw-w-full tw-h-[317px] tw-min-h-[317px] tw-bg-primary-light-400">
          <CourseCover
            title={title}
            hovered={false}
            courseId={0}
            coverImage={coverImage}
            trailerSources={[]}
            isCrehanaCourse={false}
            sizeImage={sizeImage}
            onClickImageTrailer={onRedirectToExternalContent}
            onCloseModal={onDismiss}
          />
        </div>
        <div className="tw-px-2.4 tw-pt-2.4 dark:tw-bg-neutral-dark-800">
          <DialogInformative.Title>{title}</DialogInformative.Title>
          <DialogInformative.Description>
            {`${professor} - ${lenguage}`}
          </DialogInformative.Description>
        </div>
        <DialogInformative.Actions className="tw-bg-neutral-light-400 dark:tw-bg-neutral-dark-600 tw-justify-center tw-px-2.4 tw-pb-2.4">
          <button
            className="tw-btn-primary-s"
            onClick={onRedirectToExternalContent}
            type="button"
          >
            {t('LEARN_TEXT_EXTERNAL_LINK')}
          </button>
        </DialogInformative.Actions>
      </>
    </DialogInformative>
  );
};

export default ModalShowExternalContent;
