import { FC } from 'react';

import Congrats from '@crehana/compass.icons.outline/Congrats';
import { Button } from '@crehana/compass.ui/v2';

import useTeamCoursesTranslation from '../../hooks/useTeamCoursesTranslation/useTeamCoursesTranslation';

type CardDownloadCertificateProps = {
  urlAttachmenteCertificate: string;
};

const CardDownloadCertificate: FC<CardDownloadCertificateProps> = ({
  urlAttachmenteCertificate,
}) => {
  const { t } = useTeamCoursesTranslation();

  const onDownloadCertificate = () => {
    window.open(urlAttachmenteCertificate);
  };

  return (
    <div className="tw-bg-neutral-light-200 dark:tw-bg-elevation-dark-02 tw-border tw-border-solid tw-border-neutral-light-200 dark:tw-border-elevation-dark-02 hover:dark:tw-border-primary-light-300 hover:tw-border-primary-light-300 tw-p-1.2 tw-rounded-1 tw-mt-0.8">
      <div className="tw-flex tw-flex-row tw-flex-nowrap tw-items-center tw-mb-0.8">
        <Congrats
          size={24}
          className="tw-text-primary-light-500 tw-w-full tw-max-w-[24px] tw-mr-0.8 dark:tw-text-neutral-light-100"
        />
        <span className="tw-font-body5 tw-text-neutral-light-800 dark:tw-text-neutral-light-100">
          {t('LEARN_TEXT_COMPLETED_CRITERIAS')}
        </span>
      </div>
      <Button
        type="button"
        variant="primary"
        size="xs"
        className="tw-w-full tw-flex tw-justify-center"
        onClick={onDownloadCertificate}
      >
        {t('LEARN_DOWNLOAD_CERTIFICATION')}
      </Button>
    </div>
  );
};

export default CardDownloadCertificate;
