import { memo, useCallback, type FC, type MouseEvent } from 'react';

import cn from 'clsx';

import { useMediaQuery } from '@crehana/react-hooks';
import { Container } from '@crehana/ui';
import { useStudentUrls } from '@crehana/web';

import { useLearnDashboardContext } from '@/views/LearnDashboard/context';

import BreadcrumbDesktop from './BreadcrumbDesktop';
import BreadcrumbMobile from './BreadcrumbMobile';
import type { BreadcrumbProps, TBreadcrumbPropsMobile } from './type';

const Breadcrumb: FC<BreadcrumbProps> = ({
  items = [],
  className = 'pt-20',
  fullWidth = false,
  containerType = 'dashboard',
}) => {
  const { redirectTo } = useLearnDashboardContext();
  const { homeUrl } = useStudentUrls();
  const { matches: isMd } = useMediaQuery('md');

  const commonItemLinkClassnames =
    'tw-font-body5 tw-text-neutral-light-800 dark:tw-text-neutral-light-100 tw-flex tw-items-center';

  const goToPath = useCallback(
    (event: MouseEvent<HTMLAnchorElement>, path: string) => {
      event.preventDefault();
      event.stopPropagation();

      redirectTo(path);
      window.location.reload();
    },
    [redirectTo],
  );

  const currentProps: TBreadcrumbPropsMobile & Pick<BreadcrumbProps, 'items'> =
    {
      goToPath,
      homeUrl,
      itemClass: commonItemLinkClassnames,
      items,
    };

  return (
    <div className={cn(className, 'tw-w-full')}>
      <Container type={containerType} full={fullWidth}>
        <nav aria-label="breadcrumb">
          <ol className="tw-flex tw-items-center">
            {isMd ? (
              <BreadcrumbDesktop {...currentProps} />
            ) : (
              <BreadcrumbMobile {...currentProps} />
            )}
          </ol>
        </nav>
      </Container>
    </div>
  );
};

export default memo(Breadcrumb);
