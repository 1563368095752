import { FC } from 'react';

import {
  useAvailableSurvey,
  useReminderEnabled,
} from '@crehana/web/components/Survey/hooks';
import SurveyReminder from '@crehana/web/components/SurveyReminder';

import { LEARN_DASHBOARD_PAGE_NAME } from '../../constants';
import PAYGNotification from './components/PAYGNotification';
import PremiumNotification from './components/PremiumNotification';
import type { InitialNotificationProps } from './types';

const InitialNotification: FC<
  React.PropsWithChildren<InitialNotificationProps>
> = ({ me }) => {
  const isPremium = me.isPremium;
  const isB2B = me.isB2B;

  const { hasAvailableSurveyToAnswer, loading, surveyToAnswerId } =
    useAvailableSurvey({
      skip: me?.tourOnboardingCompleted === 'incomplete',
    });

  const npsReminderIsEnabledToShow = useReminderEnabled({
    surveyToAnswerId: Boolean(surveyToAnswerId),
    hasAvailableSurveyToAnswer: hasAvailableSurveyToAnswer,
    skip: loading,
    userName: me.username!,
  });

  if (isPremium) {
    return <PremiumNotification />;
  }

  if (!isB2B) {
    return <PAYGNotification />;
  }

  return (
    <>
      {npsReminderIsEnabledToShow && (
        <SurveyReminder
          surveyId={surveyToAnswerId || ''}
          amplitudePageName={LEARN_DASHBOARD_PAGE_NAME}
        />
      )}
    </>
  );
};

export default InitialNotification;
