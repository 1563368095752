import { cn } from '@crehana/compass.ui/v2';
import { Container, ContainerProps } from '@crehana/ui';

interface SectionTitleProps {
  title?: string;
  subtitle?: string;
  containerType?: ContainerProps['type'];
  full?: boolean;
  noMargin?: boolean;
  className?: string;
}

const SectionTitle: React.FC<React.PropsWithChildren<SectionTitleProps>> = ({
  title,
  subtitle,
  containerType = 'dashboard',
  full,
  noMargin = false,
  className,
}) => {
  return (
    <Container
      className={cn(className, {
        'tw-my-2': !noMargin,
      })}
      type={containerType}
      full={full}
    >
      <h1 className="tw-text-2.8 tw-font-pop tw-text-neutral-dark-700 dark:tw-text-neutral-light-100">
        {title}
      </h1>
      {subtitle && (
        <p className="tw-font-body4 tw-mt-0.8 tw-text-neutral-light-700 dark:tw-text-neutral-dark-500">
          {subtitle}
        </p>
      )}
    </Container>
  );
};

export default SectionTitle;
