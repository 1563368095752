import { memo, useEffect } from 'react';

import {
  getCommonAmplitudeProperties,
  getSourceEventParams,
  useAmplitude,
} from '@crehana/analytics';
import { PlayroomAchievementsSection } from '@crehana/compass.playroom';
import { Container } from '@crehana/ui';

import Breadcrumb from '@/views/LearnDashboard/components/Breadcrumb/Breadcrumb';
import { useLearnDashboardContext } from '@/views/LearnDashboard/context';
import { useCrehanaTranslation } from '@/views/LearnDashboard/hooks/useCrehanaTranslation';

const PlayroomAchievementSection = () => {
  const { t } = useCrehanaTranslation();
  const {
    organizationSlug,
    isPlayroomEnabled,
    isLoadingPlayroomActivated,
    me,
  } = useLearnDashboardContext();
  const { logEvent } = useAmplitude();

  const organizationId = me.organization?.originalId?.toString() as string;

  useEffect(() => {
    if (!isLoadingPlayroomActivated) {
      logEvent({
        event: 'Render Home Achievements',
        data: {
          ...getSourceEventParams(),
          ...getCommonAmplitudeProperties(me, isPlayroomEnabled),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingPlayroomActivated, isPlayroomEnabled]);

  return (
    <>
      <Breadcrumb
        items={[{ label: t('LABEL_SIDEBAR_ITEM_PLAYROOM_ACHIEVEMENTS') }]}
      />

      <Container type="dashboard" className="tw-mt-2">
        <PlayroomAchievementsSection
          organizationId={organizationId}
          organizationSlug={organizationSlug as string}
        />{' '}
        {/* TODO: Remove organizationSlug if necessary */}
      </Container>
    </>
  );
};

export default memo(PlayroomAchievementSection);
