import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useState } from 'react';
function useOpenState(_ref) {
  var isOpen = _ref.isOpen,
    defaultOpen = _ref.defaultOpen;
  var _useState = useState(defaultOpen || false),
    _useState2 = _slicedToArray(_useState, 2),
    open = _useState2[0],
    setOpen = _useState2[1];
  var definedOpen = typeof isOpen !== 'undefined' ? isOpen : open;
  return [definedOpen, setOpen];
}
export default useOpenState;