import { INTERN_TRANSLATION_KEY } from '../constants';
import type { TCoursesThatDontBelongToTracksPortugueseTranslations } from '../types';

const en: TCoursesThatDontBelongToTracksPortugueseTranslations = {
  [INTERN_TRANSLATION_KEY]: {
    NEW_TAG: 'New',
    SEE_ALL_LINK: 'See all',
    SEE_ALL_LINK_SESSIONS: 'See all',
    COURSES_WITHOUT_TRACKS_TITLE: 'Courses assigned to you',
    CTA_UNDERSTOOD_COURSE: 'Understood',
    EMPTY_TITLE: "You don't have any assigned courses yet",
    EMPTY_SUBTITLE:
      'Ask your account administrator to assign one for you to start your learning journey',
    START_LEARNING_COURSE: 'Your pending courses to be developed',
    START_LEARNING_TRACKS: 'Your pending tracks to be developed',
    ROUTES_NAME: 'Learning tracks',
    COURSE_NAME: 'Courses',
    LIVE_SESSIONS_NAME: 'Upcoming sessions',
    START_LEARNING_MORE_CONTENT: 'More content of your interest',
    VIEW_CATALOG: 'View catalog',
    SHOW_MORE_TRACK: 'Access all your learning tracks',
    SHOW_MORE_COURSE: 'Access all your assigned courses',
    SHOW_MORE_LIVE_SESSIONS: 'Access all your upcoming sessions',
    EXPIRE_DATE: 'Expires on {{date}}',
  },
};

export default en;
