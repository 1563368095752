import { FC } from 'react';
import * as React from 'react';

import { css } from 'styled-components';

import { Skeleton } from '@crehana/ui';
import { useUITheme } from '@crehana/web';

const SkillXPSkeleton: FC<
  React.PropsWithChildren<{ 'data-testid'?: string }>
> = ({ 'data-testid': dataTestId }) => {
  const { isDark } = useUITheme();

  return (
    <Skeleton
      data-testid={dataTestId}
      className="w-full"
      css={css`
        height: 352px;
        ${({ theme }) => theme.breakpoints.up('sm')} {
          height: 424px;
        }
      `}
      isDark={isDark}
    />
  );
};

export default SkillXPSkeleton;
