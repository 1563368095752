import { FC } from 'react';

import { LazyImage, TrackTag } from '@crehana/compass.ui';

import { TCardProject } from '../../types';
import useSelectIconType from '../../utils/useSelectIconType';
import ContinueButton from './ContinueButton';

const CardProject: FC<TCardProject> = ({
  type,
  typeTitle,
  logo,
  title,
  handleClick,
}) => {
  const IconComponent = useSelectIconType(type || 'underReview');
  return (
    <div className="tw-cursor-pointer tw-mt-0.8 dark:tw-bg-elevation-dark-02 tw-border hover:tw-border-primary-light-500 hover:dark:tw-border-primary-light-500 tw-border-neutral-light-200 dark:tw-border-elevation-dark-02 tw-border-solid tw-bg-neutral-light-200 tw-gap-0.8 tw-h-full tw-p-1.2 tw-rounded-0.8 tw-w-full">
      {typeTitle && type && (
        <TrackTag
          label={typeTitle}
          className="tw-w-fit tw-mb-0.8"
          icon={IconComponent}
          type={type}
        />
      )}

      <div className="tw-flex tw-w-full tw-mb-0.8">
        {logo && (
          <div className="tw-w-4 tw-h-4 tw-min-w-4 tw-min-h-4 tw-relative ">
            <LazyImage
              src={logo}
              alt="Skill_Path_background"
              isTransparent
              imgClassName="tw-object-cover tw-w-full tw-h-full tw-rounded-0.4 tw-relative"
              className="tw-w-full tw-h-full"
              imgxParams={{ w: 40, h: 40, dpr: 2 }}
            />
          </div>
        )}
        <span className="tw-line-clamp-2 tw-ml-1.2 tw-font-body5 tw-text-neutral-light-800 dark:tw-text-neutral-light-100">
          {title}
        </span>
      </div>

      {type !== 'certified' && (
        <ContinueButton
          className="tw-flex tw-font-subtitle5 tw-items-center tw-text-primary-light-500 dark:tw-text-secondary-dark-400"
          handleClick={handleClick}
        />
      )}
    </div>
  );
};

CardProject.defaultProps = {
  logo: 'https://crehana-images.imgix.net/images/courses/promo-images/74e6d101/19d94b1b.jpeg',
};

export default CardProject;
