import { EnumLearningPlan, UserStudyPlanInfo } from '@crehana/web';

export const chooseProgressState = (
  plans: UserStudyPlanInfo | undefined,
): EnumLearningPlan => {
  if (!plans?.progress || plans?.progress.percent <= 0) return 'empty';
  if (plans?.progress.percent < 25) return 'alert';
  if (plans?.progress.percent < 50) return 'warning';
  if (plans?.progress.percent <= 99) return 'success';
  if (plans?.progress.percent === 100) return 'completed';
  return 'completed';
};

export const formatAcademyImage = (imageURL: string): string => {
  if (!imageURL.startsWith('https:')) {
    return `https://s3.amazonaws.com/public-catalog.crehana.com/${imageURL}`;
  }

  return imageURL;
};
