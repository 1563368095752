import { useQuery } from "@apollo/client";
var SURVEY_QUERY = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "SurveyQuery"
    },
    variableDefinitions: [],
    directives: [],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "currentSurvey"
        },
        arguments: [],
        directives: [],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "originalId"
            },
            arguments: [],
            directives: []
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "availability"
            },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "canAnswer"
                },
                arguments: [],
                directives: []
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "canAnswerReason"
                },
                arguments: [],
                directives: []
              }]
            }
          }]
        }
      }]
    }
  }],
  loc: {
    start: 0,
    end: 122,
    source: {
      body: "query SurveyQuery {\n  currentSurvey {\n    originalId\n    availability {\n      canAnswer\n      canAnswerReason\n    }\n  }\n}\n",
      name: "GraphQL request",
      locationOffset: {
        line: 1,
        column: 1
      }
    }
  }
};
export var useAvailableSurvey = function useAvailableSurvey(options) {
  var _data$currentSurvey2;
  var _useQuery = useQuery(SURVEY_QUERY, {
      skip: options === null || options === void 0 ? void 0 : options.skip
    }),
    loading = _useQuery.loading,
    error = _useQuery.error,
    data = _useQuery.data;
  var verifySurveyAvailability = function verifySurveyAvailability() {
    var _data$currentSurvey;
    if (data !== null && data !== void 0 && (_data$currentSurvey = data.currentSurvey) !== null && _data$currentSurvey !== void 0 && _data$currentSurvey.availability) {
      var canAnswer = data.currentSurvey.availability.canAnswer;
      return !!canAnswer;
    }
    return false;
  };
  return {
    loading: loading,
    error: !!error,
    hasAvailableSurveyToAnswer: verifySurveyAvailability(),
    surveyToAnswerId: (data === null || data === void 0 ? void 0 : (_data$currentSurvey2 = data.currentSurvey) === null || _data$currentSurvey2 === void 0 ? void 0 : _data$currentSurvey2.originalId) || null
  };
};