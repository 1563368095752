import { Fragment } from 'react';

import { LazyImage } from '@crehana/ui';

import type { OrganizationInfo } from '@/views/LearnDashboard/hooks/useOrganizationTheme/types';

type TOrganizationLogoProps = {
  isDark?: boolean;
  isB2B?: boolean;
  organizationSlug?: string;
  organizationInfo?: OrganizationInfo;
};

const OrganizationLogo: React.FC<TOrganizationLogoProps> = ({
  isDark,
  organizationSlug,
  isB2B,
  organizationInfo,
}) => {
  if (!isB2B || !organizationInfo?.configuration) {
    return <Fragment />;
  }

  const currentUrlImage =
    (isDark
      ? organizationInfo.configuration.darkModeLogo
      : organizationInfo.logo) || organizationInfo.logo;

  if (!currentUrlImage) {
    return <Fragment />;
  }

  return (
    <LazyImage
      src={currentUrlImage}
      imgClassName="object-contain"
      alt={organizationSlug || ''}
      imgxParams={{ h: 40 }}
      className="h-32 sm:h-40 w-full mx-24 sm:mx-36"
      isTransparent
      isDark={isDark}
    />
  );
};

export default OrganizationLogo;
