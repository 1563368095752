import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';

import { useRouter } from 'next/router';

import { createUrlWithSourceQueryParams } from '@crehana/analytics';
import {
  AuthCentralizedProvider,
  useCentralizedProviderData,
  useMe,
} from '@crehana/auth';
import {
  CrossPlatformNavigation,
  TLearningRouteKey,
} from '@crehana/compass.layouts';
import {
  NOTIFICATIONS_FETCH,
  useGetNotification,
} from '@crehana/compass.notification';
import { useAvailableCurrencyByUser } from '@crehana/compass.playroom';
import { cn, PageLoader } from '@crehana/compass.ui/v2';
import { useFeatureFlag } from '@crehana/feature-flags/react';
import { useTranslation } from '@crehana/i18n';
import { useMediaQuery } from '@crehana/react-hooks';
import {
  flattenRoutes,
  flattenRoutesWithComponent,
  MenuItem,
  NewDashboardMenu,
  NewDashboardRoot,
  NewRouteHeader,
  NewRouteWrapper,
  usePageView,
  useUITheme,
  type ExternalRouteType,
  type SingleRouteType,
} from '@crehana/web';

import { useParamsUrlFromApps } from '@/shared/hooks';
import { LEARN_DASHBOARD_PAGE_NAME } from '@/views/LearnDashboard/constants';
import { useLearnDashboardContext } from '@/views/LearnDashboard/context';
import {
  useCrehanaTranslation,
  useLearnDashboardRoutes,
} from '@/views/LearnDashboard/hooks';

import {
  CrossSidebar,
  InitialNotification,
  OrganizationIsoType,
  OrganizationLogo,
} from '../components';
import NewMainMenu from '../components/NewMainMenu';
import useHigherEducationHomeUserUI from '../hooks/useHigherEducationHomeUserUI';
import type { OrganizationInfo } from '../hooks/useOrganizationTheme/types';
import RevampDetailTrack from '../layouts/b2b/RevampDetailTrack';
import type { TCustomAlternativeMainMenu, TCustomMainMenu } from '../types';
import { getKnowledgeHubRoute } from '../utils/getKnowledgeHubRoute';

const Wrapper: React.FC<React.PropsWithChildren<{ className?: string }>> = ({
  children,
  className,
}) => (
  <div
    className={cn(
      'tw-overflow-auto sm:tw-overflow-hidden tw-transition-colors tw-duration-300 tw-h-[calc(100vh-73px)] tw-scroll-smooth',
      className,
    )}
  >
    {children}
  </div>
);

type LearnDashboardProps = {
  isPlayroomEnabled: boolean;
  isSidebarV2Enabled: boolean;
  organizationInfo?: OrganizationInfo;
  isLoadingPlayroomActivated: boolean;
};

type TLearnDashboardCrossProps = {
  mainMenuProps: TCustomAlternativeMainMenu;
};

const LearnDashboardCross: React.FC<TLearnDashboardCrossProps> = ({
  mainMenuProps,
}) => {
  const reactRouterHistory = useHistory();
  const { isDark } = useUITheme();
  const [mobileSidebarOpen, setMobileSidebarOpen] = useState(false);
  const { me, organizationSlug } = useLearnDashboardContext();
  const { asPath, push } = useRouter();
  const { loading, me: meCentralized } = useCentralizedProviderData();

  const userOrganizationId = me?.organization?.originalId || undefined;
  const isB2B = !!me?.isB2B;

  const { routes } = useLearnDashboardRoutes({
    me,
    organizationId: userOrganizationId as number,
    organizationSlug,
    isSidebarV2Enabled: true,
  });
  const redirectPath = isB2B ? `/org/${organizationSlug}/learn/` : '/learn/';

  const flattenRoutesComponent = flattenRoutesWithComponent(routes);

  const urlCodeContent = flattenRoutesComponent.find(
    item => item.code === ('LEARN_KNOWLEDGE_HUB' as TLearningRouteKey),
  );

  const handleRoute = (path: string) => {
    if (!organizationSlug) return;
    const url = path.replace('[slug]', organizationSlug);
    push(url);
  };

  const alternativeMainMenuProps: TCustomAlternativeMainMenu = {
    ...mainMenuProps,
    layoutConfig: {
      ...mainMenuProps.layoutConfig,
      renderCatalogAtEnd: true,
      hideMentorDashboardLink: true,
      hideTeacherDashboardLink: true,
      hideB2BAdminDashboardLink: true,
      customSizeLogo: 16,
      embeddedInBreakpoints: ['sm', 'md', 'lg', 'xl'],
      leftLogoWrapperClassNames: 'hidden sm:flex',
      navType: 'dashboard',
      newSidebarEnabled: true,
      hasProductsSwitch: true,
    },
    logoType: 'b2b',
    embeddedIn: 'new-dashboard',
    theme: isDark ? 'dark' : 'light',
    currentUrl: asPath,
    organizationSlug: organizationSlug!,
    customThemes: {
      light: {
        nav: {
          height: { xs: 56, sm: 62, md: 62, lg: 62 },
        },
      },
      dark: {
        nav: {
          height: { xs: 56, sm: 62, md: 62, lg: 62 },
        },
      },
    },
    hasIntegration: true,
    centralizeOrganizationId: meCentralized?.organization.id,
    onNavigateUrl: handleRoute,
    isProduction: process.env.APP_ENV === 'production',
    onLeftMenuClick: () => {
      setMobileSidebarOpen(true);
    },
  };

  const learningRoutes = flattenRoutesComponent.map(route => route.url);

  const navigationCrossUrl = useCallback(
    (url: string) => {
      if (urlCodeContent && url.includes(urlCodeContent?.url)) {
        reactRouterHistory.push(
          createUrlWithSourceQueryParams({
            url,
            source: 'Side Bar',
          }),
        );
        return;
      }

      if (learningRoutes.includes(url)) {
        // This is necessary to handle the React Router DOM used at this page
        reactRouterHistory.push(url);
        return;
      }
      push(url);
    },
    [learningRoutes, push, reactRouterHistory, urlCodeContent],
  );

  if (!organizationSlug) return null;

  if (loading) {
    return <PageLoader size="s" />;
  }

  return (
    <div className="tw-w-full">
      <NewMainMenu alternativeMainMenuProps={alternativeMainMenuProps} />
      <Wrapper
        className={cn(
          'tw-relative tw-w-full sm:tw-flex',
          isDark
            ? 'bg-base-dark text-white'
            : 'tw-bg-neutral-light-200 text-base-main',
        )}
      >
        <CrossPlatformNavigation
          email={me?.email || ''}
          organizationSlug={organizationSlug}
          sidebarType="collaborator"
          handleUrl={navigationCrossUrl}
          mobileSidebarOpen={mobileSidebarOpen}
          organizationId={userOrganizationId!}
          hideMobileSidebar={() => {
            setMobileSidebarOpen(false);
          }}
          productPlatform="learning"
        />
        <NewRouteWrapper className="cross-sidebar-route">
          <Switch>
            {flattenRoutesComponent.map(route => (
              <Route key={route.key} path={route.url} exact={route.exact}>
                <route.component />
              </Route>
            ))}
            <Route
              key={`DETAIL_TRACKS`}
              path={`${redirectPath}my-courses/tracks/:id/`}
              exact={true}
            >
              <RevampDetailTrack />
            </Route>
            <Route>
              <Redirect to={redirectPath} />
            </Route>
          </Switch>
        </NewRouteWrapper>
        <InitialNotification me={me} isDark={isDark} />
      </Wrapper>
    </div>
  );
};

const LearnDashboard: React.FC<LearnDashboardProps> = ({
  isPlayroomEnabled,
  organizationInfo,
  isSidebarV2Enabled,
  isLoadingPlayroomActivated,
}) => {
  const { pathname } = useLocation();
  const { languageCode } = useTranslation();
  const { isDark, toggleTheme } = useUITheme();
  const { countryCode } = useCrehanaTranslation();
  const { me, organizationSlug, redirectTo, subscribeRedeemCatalogEvent } =
    useLearnDashboardContext();
  const { flagValue: showNotification } = useFeatureFlag(
    'user_notification_web',
  );
  const userOrganizationId = me?.organization?.originalId || undefined;

  const { notificationData, error, hasMore, loading, onFetchMore, totalCount } =
    useGetNotification({
      first: NOTIFICATIONS_FETCH,
      isSkip: !showNotification,
    });

  const { talentUser } = useMe();
  const { hasTalentProduct, loading: loadingCentralizedUser } = talentUser;
  const { matches: isLg } = useMediaQuery('lg');
  const { routes, defaultRoute, isLoading, isKnowledgeHubEnabled } =
    useLearnDashboardRoutes({
      me,
      organizationId: userOrganizationId as number,
      organizationSlug,
      isSidebarV2Enabled,
    });
  const { sendPageView } = usePageView({
    type: 'page',
    asynchronous: true,
    pageData: {
      data: {
        name: LEARN_DASHBOARD_PAGE_NAME,
      },
    },
  });
  const { push } = useRouter();
  const { isFromApps } = useParamsUrlFromApps();
  const isB2B = !!me?.isB2B;
  const isOrganizationDemo = me.organization?.organizationType === 3;
  const redirectPath = isB2B ? `/org/${organizationSlug}/learn/` : '/learn/';

  const flatRoutes = flattenRoutes(routes);
  const flattenRoutesComponent = flattenRoutesWithComponent(routes);

  const currentSingleRoute = useMemo<SingleRouteType>(() => {
    if (pathname === '/') {
      return defaultRoute;
    }

    const foundRoute = flatRoutes.find(r => r.path === pathname);

    if (foundRoute) {
      return foundRoute;
    }

    return getKnowledgeHubRoute(pathname, flatRoutes) ?? defaultRoute;
  }, [defaultRoute, flatRoutes, pathname]);
  const {
    refetch: refetchCrehanaCoins,
    currencyCustomName,
    crehanaCoinsCurrentUser,
    loading: isLoadingCurrencyCoins,
  } = useAvailableCurrencyByUser(organizationSlug as string);
  const showMarketplaceUIFF = useHigherEducationHomeUserUI();
  const isMarketplaceUserHEStudent =
    me?.organization?.myRole === 'high-education-member' && showMarketplaceUIFF;
  const isBantrab = me.organization?.slug === 'bantrab-dev';
  const hideCatalogDropdown: boolean = isBantrab || isKnowledgeHubEnabled;

  /** @desc Hide the 'Cursos' button in the navbar when the user is in the Playroom and is in a tablet screen */
  const hideRightCatalog: boolean =
    !isOrganizationDemo &&
    ((isPlayroomEnabled && !isLg) || isBantrab || isKnowledgeHubEnabled);

  const handleRoute = (path: string) => {
    if (!organizationSlug) return;
    const url = path.replace('[slug]', organizationSlug);
    push(url);
  };

  const mainMenuProps: TCustomMainMenu = {
    layoutConfig: {
      hideNightSwitch: false,
      hideSavedCoursesLink: true,
      hideLoginButton: false,
      hideRegisterButton: true,
      hideShoppingCart: true,
      hideB2BDashboardButtonLink: true,
      hideDiplomasLink: true,
      hideMentorDashboardLink: false,
      hideResourcesTools: true,
      hideResourcesDropdown: true,
      hideCourseAgenda: true,
      hideUserDashboardLink: true,
      hideCategories: false,
      hideNewCourses: false,
      hideProjectsLink: true,
      hideSwitchToOldHomeUserLink: false,
      hideLeftLogo: true,
      hideRightCatalog,
      hideCrehanaCoinsButton: !isPlayroomEnabled || isMarketplaceUserHEStudent,
      hideNotifications: !showNotification || isMarketplaceUserHEStudent,
      hideInboxLink: isMarketplaceUserHEStudent,
      hasProductsSwitch: !loadingCentralizedUser && hasTalentProduct,
      // TODO: Replace this hardcoded booleans with FFs
      hideSearch: isBantrab || isKnowledgeHubEnabled,
      hideCatalogDropdown,
      hideRightCatalogKnowledgeHubSearch: !isKnowledgeHubEnabled,
      hasDashboardsDropdown: true,
    },
    countryPrefix: countryCode || '',
    amplitudePageName: LEARN_DASHBOARD_PAGE_NAME,
    customThemes: {
      dark: {
        nav: {
          backgroundColorClassName: 'bg-base-lighter-dark-mode',
        },
      },
    },
    playroom: {
      currencyName: currencyCustomName,
      // Negative value to hide the Navbar button
      currencyCoins: isLoadingCurrencyCoins ? -1 : crehanaCoinsCurrentUser,
      onGoToAchievementsSection: () => {
        const url = `/org/${
          organizationSlug as string
        }/learn/playroom/achievements/`;
        redirectTo(url);
      },
    },

    notification: {
      notificationData: notificationData,
      error: error,
      hasMore: hasMore,
      loading: loading,
      onFetchMore,
      totalCount: totalCount,
      totalUnRead: notificationData.totalUnRead,
    },
    onToggleUserTheme: toggleTheme,
    isProduction: process.env.APP_ENV === 'production',
    onNavigateUrl: handleRoute,
  };

  const selectPageOrOpenLink = useCallback(
    (route: SingleRouteType | ExternalRouteType) => {
      redirectTo(
        createUrlWithSourceQueryParams({
          url: route.path || '',
          source: 'Side Bar',
        }),
        { _blank: !!route.openInNewTab },
      );
    },
    [redirectTo],
  );

  /**
   * Refetch Crehana Coins when the user redeem an element of catalog
   */
  useLayoutEffect(() => {
    const subscriber = subscribeRedeemCatalogEvent(refetchCrehanaCoins);

    // Unsubscribe when the component is unmounted to avoid ghost subscriptions
    return () => subscriber.unsubscribe();
  }, [refetchCrehanaCoins, subscribeRedeemCatalogEvent]);

  useEffect(() => {
    if (pathname) {
      sendPageView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  if (isLoading || isLoadingPlayroomActivated || loadingCentralizedUser) {
    return <PageLoader size="s" languageCode={languageCode} />;
  }

  if (!organizationSlug) return null;

  const shouldCrossSidebar = hasTalentProduct && isSidebarV2Enabled;

  return (
    <CrossSidebar isSidebarV2Enabled={shouldCrossSidebar} isDark={isDark}>
      <NewDashboardRoot
        user={me}
        isDark={isDark}
        supportsDarkMode
        className="cross-sidebar"
        toggleTheme={toggleTheme}
        initialRoute={currentSingleRoute}
        source={LEARN_DASHBOARD_PAGE_NAME}
        onChangeRoute={route => {
          selectPageOrOpenLink(route);
        }}
      >
        {shouldCrossSidebar ? (
          <AuthCentralizedProvider organizationSlug={organizationSlug}>
            <LearnDashboardCross mainMenuProps={mainMenuProps} />
          </AuthCentralizedProvider>
        ) : (
          <>
            <NewDashboardMenu
              organizationSlug={organizationSlug || ''}
              organizationLogo={{
                expanded: (
                  <OrganizationLogo
                    organizationSlug={organizationSlug}
                    organizationInfo={organizationInfo}
                    isDark={isDark}
                    isB2B={isB2B}
                  />
                ),
                collapsed: (
                  <OrganizationIsoType
                    isDark={isDark}
                    organizationInfo={organizationInfo}
                    organizationSlug={organizationSlug}
                    isB2B={isB2B}
                  />
                ),
              }}
              renderLinks={{
                itemLinks: () =>
                  routes.map(route => (
                    <MenuItem key={route.key} route={route} isDark={isDark} />
                  )),
              }}
            />
            <NewRouteWrapper>
              {!isFromApps && <NewRouteHeader mainMenuProps={mainMenuProps} />}
              <Switch>
                {flattenRoutesComponent.map(route => (
                  <Route key={route.key} path={route.path} exact={route.exact}>
                    <route.component />
                  </Route>
                ))}
                <Route
                  key={`DETAIL_TRACKS`}
                  path={`${redirectPath}my-courses/tracks/:id/`}
                  exact={true}
                >
                  <RevampDetailTrack />
                </Route>
                <Route>
                  <Redirect to={redirectPath} />
                </Route>
              </Switch>
            </NewRouteWrapper>
            <InitialNotification me={me} isDark={isDark} />
          </>
        )}
      </NewDashboardRoot>
    </CrossSidebar>
  );
};

export default LearnDashboard;
