/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum B2BEnrollmentTypeEnum {
  ENROLLMENT_B2B = "ENROLLMENT_B2B",
  ENROLLMENT_DEGREE = "ENROLLMENT_DEGREE",
  ENROLLMENT_FREE = "ENROLLMENT_FREE",
  ENROLLMENT_FREEMIUM = "ENROLLMENT_FREEMIUM",
  ENROLLMENT_FREE_MEMBERSHIP = "ENROLLMENT_FREE_MEMBERSHIP",
  ENROLLMENT_GIFTCARD = "ENROLLMENT_GIFTCARD",
  ENROLLMENT_MEMBERSHIP = "ENROLLMENT_MEMBERSHIP",
  ENROLLMENT_MENTOR = "ENROLLMENT_MENTOR",
  ENROLLMENT_MIGRATE = "ENROLLMENT_MIGRATE",
  ENROLLMENT_PAID = "ENROLLMENT_PAID",
  ENROLLMENT_PROFESSOR = "ENROLLMENT_PROFESSOR",
  ENROLLMENT_STAFF = "ENROLLMENT_STAFF",
  ENROLLMENT_TEMPORAL_ACCESS = "ENROLLMENT_TEMPORAL_ACCESS",
}

export enum TaskUserStatusEnum {
  ACTIVE = "ACTIVE",
  COMPLETED = "COMPLETED",
  DELAYED = "DELAYED",
  DELETED = "DELETED",
  INACTIVE = "INACTIVE",
  PENDING = "PENDING",
}

export enum TeamSearchFilterEnum {
  TEAM_SEARCH_FILTER_ALL = "TEAM_SEARCH_FILTER_ALL",
  TEAM_SEARCH_FILTER_COMPLETED = "TEAM_SEARCH_FILTER_COMPLETED",
  TEAM_SEARCH_FILTER_CURRENT = "TEAM_SEARCH_FILTER_CURRENT",
  TEAM_SEARCH_FILTER_DISABLED = "TEAM_SEARCH_FILTER_DISABLED",
  TEAM_SEARCH_FILTER_EXPIRED = "TEAM_SEARCH_FILTER_EXPIRED",
}

export interface OpenEnrollmentIntegrationInput {
  userId: number;
  integrationItemId: number;
  integrationObjectId: string;
  organizationSlug: string;
  clientMutationId?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
