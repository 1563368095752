import { useMemo } from 'react';
import { useGetMainCategoriesAndContentQueryQuery } from "../state-machines/KnowledgeHub/graphql/queries/GetMainCategoriesAndContentQuery.v5.generated";
var useGetCatalog = function useGetCatalog(_ref) {
  var organizationId = _ref.organizationId,
    first = _ref.first,
    _ref$skip = _ref.skip,
    skip = _ref$skip === void 0 ? false : _ref$skip;
  var shouldSkip = first === 0 || !organizationId || skip;
  var _useGetMainCategories = useGetMainCategoriesAndContentQueryQuery({
      variables: {
        first: first,
        organizationId: organizationId
      },
      context: {
        clientName: 'v5'
      },
      skip: shouldSkip
    }),
    data = _useGetMainCategories.data,
    loading = _useGetMainCategories.loading,
    error = _useGetMainCategories.error;
  var _useMemo = useMemo(function () {
      if (data !== null && data !== void 0 && data.knowledge_hub.knowledge_hub_catalog && data.knowledge_hub.knowledge_hub_catalog.length > 0) {
        var _coursesCatalog = data.knowledge_hub.knowledge_hub_catalog[0].contents.edges.map(function (el) {
          return el.node;
        }) || [];
        var _countCourseCatalog = data.knowledge_hub.knowledge_hub_catalog[0].base_category.total_courses || 0;
        return {
          coursesCatalog: _coursesCatalog,
          countCourseCatalog: _countCourseCatalog
        };
      }
      return {
        coursesCatalog: [],
        countCourseCatalog: 0
      };
    }, [data === null || data === void 0 ? void 0 : data.knowledge_hub]),
    coursesCatalog = _useMemo.coursesCatalog,
    countCourseCatalog = _useMemo.countCourseCatalog;
  return {
    coursesCatalog: coursesCatalog,
    loading: loading,
    error: error,
    countCourseCatalog: countCourseCatalog
  };
};
export default useGetCatalog;