import { FC } from 'react';

import { clsx } from 'clsx';

import { useCrehanaTranslation } from '@/views/LearnDashboard/hooks';

const Header: FC<{ className?: string }> = ({ className }) => {
  const { t } = useCrehanaTranslation();
  return (
    <span
      className={clsx(
        'tw-font-subtitle4 tw-items-center md:tw-flex',
        className,
      )}
    >
      {t('START_LEARNING_HEADER')}
    </span>
  );
};

export default Header;
