import { FC } from 'react';

import StartSectionNew from './StartSectionNew';
import type { StartSectionProps } from './types';
import { withProviders } from './withProviders';

const LazyStartSection: FC<StartSectionProps> = props => {
  return <StartSectionNew {...props} />;
};

export default withProviders(LazyStartSection);
