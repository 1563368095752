import { useCallback } from 'react';

import {
  AmplitudeProps,
  createUrlWithSourceQueryParams,
} from '@crehana/analytics';
import { StudyPlanActionName, useStudentUrls } from '@crehana/web';

const useActionsURL = () => {
  const { myCoursesUrl, myProjectsUrl, myTracksUrl, contentForYou } =
    useStudentUrls();

  const getActionClickLearningPlan = useCallback(
    (
      actionName: StudyPlanActionName,
      amplitude?: Partial<AmplitudeProps>,
    ): string => {
      let urlWithSource = '';

      switch (actionName) {
        case 'action_additional_courses':
          urlWithSource = createUrlWithSourceQueryParams({
            url: myCoursesUrl,
            source: 'Home Progress Bar',
            sourceDetail: 'Required Courses Progress Bar',
            ...amplitude,
          });

          break;
        case 'action_completed_courses':
          urlWithSource = createUrlWithSourceQueryParams({
            url: myCoursesUrl,
            source: 'Home Progress Bar',
            sourceDetail: 'Required Courses Progress Bar',
            ...amplitude,
          });

          break;
        case 'action_completed_projects':
          urlWithSource = createUrlWithSourceQueryParams({
            url: myProjectsUrl,
            source: 'Home Progress Bar',
            sourceDetail: 'Approved Projects Progress Bar',
            ...amplitude,
          });
          break;
        case 'action_completed_tracks':
          urlWithSource = createUrlWithSourceQueryParams({
            url: myTracksUrl,
            source: 'Home Progress Bar',
            sourceDetail: 'Completed Tracks Progress Bar',
            ...amplitude,
          });
          break;
        default:
          break;
      }

      return urlWithSource;
    },
    [myCoursesUrl, myProjectsUrl, myTracksUrl],
  );

  return {
    contentForYou,
    getActionClickLearningPlan,
  };
};

export default useActionsURL;
