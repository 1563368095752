import { EnumLearningPlan } from '@crehana/web';

export const VIRTUAL_SESSION_PLACEHOLDER_IMAGE =
  'https://crehana-vercel-images-prd.imgix.net/public/b2b-admin-mf/a2117e3c-45a0-4018-bdff-1c769ea65698-placeholder_sesion-virtual-1.png?auto=format&fit=clip';
export const LOCATION_SESSION_PLACEHOLDER_IMAGE =
  'https://crehana-vercel-images-prd.imgix.net/public/b2b-admin-mf/91952206-9519-41a0-a181-36c19b75cd73-placeholder_sesion-presencial-1.png?auto=format&fit=clip';

export const INTERN_TRANSLATION_KEY =
  'LX_B2B_COURSES_THAT_DONT_BELONG_TO_TRACKS';
export const SLIDES_NAMES = [
  'ROUTES_NAME',
  'COURSE_NAME',
  'LIVE_SESSIONS_NAME',
];

export const ITEMS_CUSTOM = 10;

export const COLOR_STATE: Record<
  EnumLearningPlan,
  {
    text: string;
    backgroundProgress: string;
    background: string;
    label: string;
  }
> = {
  alert: {
    text: 'tw-text-alert-600',
    backgroundProgress: 'tw-bg-alert-400',
    background: 'tw-bg-alert-100',
    label: 'START_LEARNING_CAN',
  },
  warning: {
    text: 'tw-text-on-hold-600',
    backgroundProgress: 'tw-bg-on-hold-400',
    background: 'tw-bg-on-hold-100',
    label: 'START_LEARNING_LABEL_WARNING',
  },
  success: {
    text: 'tw-text-warning-600',
    backgroundProgress: 'tw-bg-warning-400',
    background: 'tw-bg-warning-100',
    label: 'START_LEARNING_LABEL_SUCCESS',
  },
  completed: {
    text: 'tw-text-success-500',
    backgroundProgress: 'tw-bg-success-400',
    background: 'tw-bg-success-100',
    label: 'START_LEARNING_LABEL_COMPLETED',
  },
  empty: {
    text: 'tw-text-neutral-light-700',
    backgroundProgress: 'tw-bg-neutral-light-100',
    background: 'tw-bg-neutral-light-300',
    label: 'START_LEARNING_EMPTY_TITLE',
  },
};
