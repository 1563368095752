import toDate from "./toDate";

/**
 * @category Date comparison
 *
 * @summary Compares 2 dates and returns the greater one.
 *
 * @param {Date | string} date1 You can provide a date object or a date as string (STANDARD_DATE_FORMAT)
 * @param {Date | string} date2 You can provide a date object or a date as string (STANDARD_DATE_FORMAT)
 * @param {Object} options
 * @param {LANGUAGES} options.languageCode - You need to provide the current language code from @crehana/i18n
 */
var getGreaterDate = function getGreaterDate(date1, date2, options) {
  var languageCode = options.languageCode;
  var firstDate = typeof date1 === 'string' ? toDate(date1, {
    languageCode: languageCode
  }) : date1;
  var secondDate = typeof date2 === 'string' ? toDate(date2, {
    languageCode: languageCode
  }) : date2;
  var params = {
    firstDate: date1,
    secondDate: date2
  };
  if (!firstDate && !secondDate) {
    console.warn('getGreaterDate: The provided dates are invalid', params);
  }
  if (!firstDate) {
    console.warn('getGreaterDate: The first date provided is invalid', params);
    return secondDate;
  }
  if (!secondDate) {
    console.warn('getGreaterDate: The second date provided is invalid', params);
    return firstDate;
  }
  return firstDate.getTime() > secondDate.getTime() ? firstDate : secondDate;
};
export default getGreaterDate;