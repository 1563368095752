import { formatTimeZone } from '@crehana/date';
export var formatDateAndDuration = function formatDateAndDuration(startDate, endDate, timeZone, languageCode) {
  return "".concat(startDate.toLocaleDateString(languageCode, {
    timeZone: timeZone,
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  }), " | ").concat(startDate.toLocaleTimeString(languageCode, {
    timeZone: timeZone,
    hour: '2-digit',
    minute: '2-digit',
    hourCycle: 'h12'
  }), " - ").concat(endDate.toLocaleTimeString(languageCode, {
    timeZone: timeZone,
    hour: '2-digit',
    minute: '2-digit',
    hourCycle: 'h12'
  }), " ").concat(formatTimeZone(timeZone, languageCode));
};
export var formatHour = function formatHour(startDate, endDate, languageCode, timeZone, isAdmin) {
  if (isAdmin) {
    return "".concat(startDate.toLocaleTimeString(languageCode, {
      timeZone: timeZone,
      hour: '2-digit',
      minute: '2-digit',
      hourCycle: 'h12'
    }), " - ").concat(endDate.toLocaleTimeString(languageCode, {
      timeZone: timeZone,
      hour: '2-digit',
      minute: '2-digit',
      hourCycle: 'h12'
    }));
  }
  return "".concat(startDate.toLocaleTimeString(languageCode, {
    hour: '2-digit',
    minute: '2-digit',
    hourCycle: 'h12'
  }), " - ").concat(endDate.toLocaleTimeString(languageCode, {
    hour: '2-digit',
    minute: '2-digit',
    hourCycle: 'h12'
  }));
};
export var formatTimeZoneSession = function formatTimeZoneSession(languageCode, timeZone) {
  return "".concat(formatTimeZone(timeZone, languageCode));
};