import { useMe, useUserTracks } from '@crehana/web';

import useParsedTeamCourses from './utils/useParsedTeamCourses';

const useTeamTracksFromApiv4 = ({
  organizationSlug,
  skipQuery,
}: {
  organizationSlug: string;
  skipQuery: boolean;
}) => {
  const { me } = useMe();
  const { data, loading, error, totalCount } = useUserTracks({
    userId: Number(me?.originalId),
    organizationSlug,
    skip: skipQuery,
  });
  const { teams } = useParsedTeamCourses(data);

  return { teams, loading, error, totalCount };
};

export default useTeamTracksFromApiv4;
