import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { LANGUAGES } from '@crehana/ts-types';
var getGTM = function getGTM(timeZone, locale) {
  var _Date$toLocaleTimeStr = new Date().toLocaleTimeString(locale, {
      timeZone: timeZone,
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'longOffset'
    }).split(' '),
    _Date$toLocaleTimeStr2 = _slicedToArray(_Date$toLocaleTimeStr, 2),
    _ = _Date$toLocaleTimeStr2[0],
    gtm = _Date$toLocaleTimeStr2[1];
  return gtm;
};
var getCity = function getCity(timeZone, locale) {
  var city = new Date().toLocaleTimeString(locale, {
    timeZone: timeZone,
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZoneName: 'shortGeneric'
  }).substring(9).replace(/AM |PM |hora de |Horário | Time/g, '');
  return city;
};

/**
 * @category Time Zone format
 *
 * @summary Format a Time Zone to match: (GMT-03:00 Buenos Aires)
 *
 * @param {TimeZonesEnum} timeZone - time zone to be formatted
 * @param {LANGUAGES} languageCode - language to use
 */
export function formatTimeZone(timeZone) {
  var languageCode = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : LANGUAGES.ES;
  var gtm = getGTM(timeZone, languageCode).replace(/\)|\(/g, '');
  var city = getCity(timeZone, languageCode);
  var label = "(".concat(gtm, " ").concat(city, ")");
  return label;
}