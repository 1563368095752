import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
var LEARNING_PLAN_USER_METRICS = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "LearningPlanUserMetricsQuery"
    },
    variableDefinitions: [],
    directives: [],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "organizationTeamsUserMetrics"
        },
        arguments: [],
        directives: [],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "totalAssignedTracks"
            },
            arguments: [],
            directives: []
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "totalCompletedAssignedTracks"
            },
            arguments: [],
            directives: []
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "totalAssignedMandatoryProjects"
            },
            arguments: [],
            directives: []
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "totalCompletedAssignedMandatoryProjects"
            },
            arguments: [],
            directives: []
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "totalAdditionalCourses"
            },
            arguments: [],
            directives: []
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "totalCompletedAdditionalCourses"
            },
            arguments: [],
            directives: []
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "totalAssignedMandatoryCourses"
            },
            arguments: [],
            directives: []
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "totalCompletedAssignedMandatoryCourses"
            },
            arguments: [],
            directives: []
          }]
        }
      }]
    }
  }],
  loc: {
    start: 0,
    end: 419,
    source: {
      body: "query LearningPlanUserMetricsQuery {\n  organizationTeamsUserMetrics {\n    # Tracks\n    totalAssignedTracks\n    totalCompletedAssignedTracks\n    # Projects\n    totalAssignedMandatoryProjects\n    totalCompletedAssignedMandatoryProjects\n    # Additional Courses\n    totalAdditionalCourses\n    totalCompletedAdditionalCourses\n    # Courses\n    totalAssignedMandatoryCourses\n    totalCompletedAssignedMandatoryCourses\n  }\n}\n",
      name: "GraphQL request",
      locationOffset: {
        line: 1,
        column: 1
      }
    }
  }
};
var useCurrentUserMetrics = function useCurrentUserMetrics() {
  var _useQuery = useQuery(LEARNING_PLAN_USER_METRICS, {
      context: {
        clientName: 'v4.b2b'
      }
    }),
    dataB2B = _useQuery.data,
    loading = _useQuery.loading,
    error = _useQuery.error;
  var _useState = useState({
      additionalCourses: {
        completed: 0,
        total: 0
      },
      tracksCompleted: {
        completed: 0,
        total: 0
      },
      projectsCompleted: {
        completed: 0,
        total: 0
      },
      coursesCompleted: {
        completed: 0,
        total: 0
      }
    }),
    _useState2 = _slicedToArray(_useState, 2),
    initialResponse = _useState2[0],
    setInitialResponse = _useState2[1];
  var loadingData = loading;
  useEffect(function () {
    if (dataB2B) {
      var _dataB2B$organization, _dataB2B$organization2, _dataB2B$organization3, _dataB2B$organization4, _dataB2B$organization5, _dataB2B$organization6, _dataB2B$organization7, _dataB2B$organization8;
      setInitialResponse(_objectSpread(_objectSpread({}, initialResponse), {}, {
        additionalCourses: {
          completed: ((_dataB2B$organization = dataB2B.organizationTeamsUserMetrics) === null || _dataB2B$organization === void 0 ? void 0 : _dataB2B$organization.totalCompletedAdditionalCourses) || 0,
          total: ((_dataB2B$organization2 = dataB2B.organizationTeamsUserMetrics) === null || _dataB2B$organization2 === void 0 ? void 0 : _dataB2B$organization2.totalAdditionalCourses) || 0
        },
        projectsCompleted: {
          completed: ((_dataB2B$organization3 = dataB2B.organizationTeamsUserMetrics) === null || _dataB2B$organization3 === void 0 ? void 0 : _dataB2B$organization3.totalCompletedAssignedMandatoryProjects) || 0,
          total: ((_dataB2B$organization4 = dataB2B.organizationTeamsUserMetrics) === null || _dataB2B$organization4 === void 0 ? void 0 : _dataB2B$organization4.totalAssignedMandatoryProjects) || 0
        },
        tracksCompleted: {
          completed: ((_dataB2B$organization5 = dataB2B.organizationTeamsUserMetrics) === null || _dataB2B$organization5 === void 0 ? void 0 : _dataB2B$organization5.totalCompletedAssignedTracks) || 0,
          total: ((_dataB2B$organization6 = dataB2B.organizationTeamsUserMetrics) === null || _dataB2B$organization6 === void 0 ? void 0 : _dataB2B$organization6.totalAssignedTracks) || 0
        },
        coursesCompleted: {
          completed: ((_dataB2B$organization7 = dataB2B.organizationTeamsUserMetrics) === null || _dataB2B$organization7 === void 0 ? void 0 : _dataB2B$organization7.totalCompletedAssignedMandatoryCourses) || 0,
          total: ((_dataB2B$organization8 = dataB2B.organizationTeamsUserMetrics) === null || _dataB2B$organization8 === void 0 ? void 0 : _dataB2B$organization8.totalAssignedMandatoryCourses) || 0
        }
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataB2B]);
  return _objectSpread(_objectSpread({}, initialResponse), {}, {
    loading: loadingData,
    error: error
  });
};
export default useCurrentUserMetrics;