import { useCallback, useRef, useState } from 'react';

import ExternalLink from '@crehana/compass.icons.outline/ExternalLink';
import Plus from '@crehana/compass.icons.outline/Plus';
import { LazyImage } from '@crehana/compass.ui';
import { getImgixUrl } from '@crehana/utils';

import { useLearnDashboardContext } from '@/views/LearnDashboard/context';

import { COURSE_CARD_IMAGE_PARAMS } from '../../../constants';
import useExternalCoursesOpen from '../../../hooks/useExternalCoursesOpen';
import useTeamCoursesTranslation from '../../../hooks/useTeamCoursesTranslation';
import { TCoursesTrack } from '../../../types';
import InformationGeneral from './InformationGeneral';
import ModalConfirmSeen from './ModalConfirmSeen';
import ModalShowExternalContent from './ModalShowExternalContent';
import OptionsIntegration from './OptionsIntegration';

export type CourseCardIntegrationRevampProps = {
  courseData: TCoursesTrack;
};

const CourseCardIntegrationRevamp: React.FC<
  CourseCardIntegrationRevampProps
> = ({ courseData }) => {
  const {
    courseName,
    profesorFullName,
    courseImage,
    iconExternal,
    language,
    url,
    integrationItemId,
    integrationObjectId,
    wasOpened,
  } = courseData;
  const { t } = useTeamCoursesTranslation();
  const { me } = useLearnDashboardContext();

  const optionRef = useRef<HTMLButtonElement>(null);
  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
  const [isOpenVerification, setisOpenVerification] = useState<boolean>(false);
  const [
    isOpenModalRedirectExternalContent,
    setIsOpenModalRedirectExternalContent,
  ] = useState(false);

  const [openExternalCourseMutation] = useExternalCoursesOpen();

  const isContentExternalTypeVideo = integrationObjectId && integrationItemId;

  const _hasOpenExternal = useCallback(async () => {
    if (me?.originalId && isContentExternalTypeVideo) {
      const { data } = await openExternalCourseMutation({
        variables: {
          input: {
            userId: me?.originalId || 0,
            integrationItemId: Number(integrationItemId),
            organizationSlug: me?.organization?.slug || '',
            integrationObjectId: integrationObjectId?.toString(),
          },
        },
      });
      return data;
    }
  }, [
    me,
    integrationItemId,
    integrationObjectId,
    isContentExternalTypeVideo,
    openExternalCourseMutation,
  ]);

  const onVisibleModalTrailer = () => {
    setIsOpenModalRedirectExternalContent(prev => !prev);
  };

  const handleRedirect = (
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event?.preventDefault();
    const tabOrigen = window.name;
    if (!wasOpened && isContentExternalTypeVideo) {
      setisOpenVerification(true);
    }
    window.open(url, tabOrigen || '_blank');
    onVisibleModalTrailer();
  };

  const handleOpenVerificationDirectly = () => {
    if (!wasOpened && isContentExternalTypeVideo) {
      setisOpenVerification(true);
      setIsOpenDrawer(false);
    }
  };

  const handleConfirmSeen = async () => {
    await _hasOpenExternal();
    setisOpenVerification(false);
  };

  return (
    <>
      <div
        className="sm:dark:tw-bg-transparent dark:tw-bg-elevation-dark-04 tw-bg-neutral-light-100 tw-flex tw-flex-col sm:tw-flex-row tw-p-1.2 tw-relative tw-rounded-0.8 tw-shadow-01 hover:tw-shadow-04 hover:dark:tw-shadow-none sm:tw-shadow-none sm:tw-p-0.8 tw-transition-all"
        role="listitem"
      >
        <div className="tw-flex-1 tw-w-full tw-text-left tw-flex tw-flex-col sm:tw-flex-row">
          <div className="tw-flex tw-flex-col tw-w-full tw-mb-0.8 tw-mr-1.2 tw-overflow-hidden tw-rounded-0.4 sm:tw-w-9.6 sm:tw-mb-0 tw-relative">
            <button
              type="button"
              className="tw-flex tw-bg-[rgba(0,0,0,0.6)] hover:tw-opacity-100 tw-opacity-0 tw-absolute tw-w-full tw-h-full tw-inset-0 tw-items-center tw-justify-center tw-rounded-0.4 tw-z-7"
              onClick={onVisibleModalTrailer}
            >
              <ExternalLink className="tw-text-neutral-light-100" size={24} />
            </button>
            {/* Rendering the backgrounImage of the course. */}
            <div className="tw-relative tw-h-10.4 sm:tw-h-6.4">
              <LazyImage
                src={getImgixUrl(courseImage || '')}
                placeholder={courseImage}
                imgxParams={COURSE_CARD_IMAGE_PARAMS}
                alt={courseName}
                isTransparent
                imgClassName="tw-object-cover"
                className="tw-w-full tw-h-full"
              />
            </div>
            {/* Rendering the icon external resource of the integration */}
            {iconExternal && (
              <div className="tw-flex tw-absolute tw-bg-neutral-light-100 tw-h-1.6 tw-items-center tw-justify-center tw-ml-1.2 tw-rounded-0.4 tw-w-1.6 tw-mt-1.2 tw-overflow-hidden sm:tw-h-1.6 sm:tw-w-1.6 sm:tw-ml-0.4 sm:tw-mt-0.4">
                <LazyImage
                  src={getImgixUrl(iconExternal || '')}
                  placeholder={iconExternal}
                  imgxParams={{
                    fit: 'fill',
                  }}
                  alt={courseName}
                  isTransparent
                  imgClassName="tw-object-cover"
                  className="tw-w-full tw-h-full"
                />
              </div>
            )}
          </div>
          <InformationGeneral
            title={courseName || ''}
            fullNameTeacher={profesorFullName || ''}
            language={language || ''}
          />
        </div>
        <button
          type="button"
          className="tw-ml-1.2 sm:tw-h-fit dark:tw-text-neutral-light-100 dark:tw-bg-neutral-dark-700 dark:tw-border-none tw-flex tw-items-center tw-justify-center tw-text-neutral-light-800 tw-font-link1 tw-px-1.2 tw-py-0.8 tw-rounded-1 tw-border tw-border-solid tw-border-neutral-light-500"
          onClick={() => setIsOpenDrawer(true)}
          ref={optionRef}
        >
          <Plus size={16} className="tw-mr-0.8 sm:tw-mr-0 tw-current" />
          <span className="tw-block sm:tw-hidden">
            {t('LEARN_TEAM_OPTION')}
          </span>
        </button>
        <div className="tw-relative">
          <OptionsIntegration
            isOpen={isOpenDrawer}
            setIsOpen={() => setIsOpenDrawer(false)}
            refPopper={optionRef}
            isContentTypeVideo={Boolean(isContentExternalTypeVideo)}
            onOpenContentExternal={handleRedirect}
            onCheckSeen={handleOpenVerificationDirectly}
          />
        </div>
      </div>
      <ModalConfirmSeen
        isOpenModal={isOpenVerification}
        onCloseModal={() => setisOpenVerification(false)}
        onConfirmSeen={handleConfirmSeen}
      />
      {isOpenModalRedirectExternalContent && (
        <ModalShowExternalContent
          isOpen={isOpenModalRedirectExternalContent}
          onDismiss={onVisibleModalTrailer}
          title={courseName || ''}
          coverImage={courseImage || ''}
          lenguage={language || ''}
          professor={profesorFullName || ''}
          sizeImage={'tw-h-[31.7rem]'}
          onRedirectToExternalContent={handleRedirect}
        />
      )}
    </>
  );
};

export default CourseCardIntegrationRevamp;
