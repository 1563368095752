import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import useMe from "./useMe";
import usePureStudentUrls from "./usePureStudentUrls";
export var useStudentUrls = function useStudentUrls(params) {
  var _useMe = useMe(),
    me = _useMe.me;
  var values = usePureStudentUrls(_objectSpread(_objectSpread({}, params), {}, {
    me: me
  }));
  return _objectSpread({}, values);
};
export default useStudentUrls;