import { isPast, toDate } from '@crehana/date';
import { useTranslation } from '@crehana/i18n';
import { LANGUAGES } from '@crehana/ts-types';
import type { UserTeamItem } from '@crehana/web';

import { orderByDateProperty } from './orderByDateProperty';

export type UserTeamItemParsed = UserTeamItem & {
  parsedDate?: string;
  parsedDueDate?: Date | string;
  isExpired?: boolean;
  endDate?: string;
};

const useParsedTeamCourses = (teams: UserTeamItem[]) => {
  const { languageCode } = useTranslation();

  const parsedItems: UserTeamItemParsed[] = teams.map(team => {
    let parsedDate: Date | undefined;
    let isExpired = false;

    if (team.expirationDate) {
      parsedDate = toDate(team.expirationDate, {
        languageCode: languageCode || LANGUAGES.ES,
        inputFormat: 'YYYY-MM-DD',
      });
      isExpired = !!parsedDate && isPast(parsedDate);
    }

    const parsedTeam: UserTeamItemParsed = {
      ...team,
      isExpired,
      endDate: parsedDate?.toISOString() || undefined,
      parsedDueDate: parsedDate,
    };

    return parsedTeam;
  });

  const orderedByDate = orderByDateProperty<UserTeamItemParsed>(
    parsedItems,
    'parsedDueDate',
  );

  const pendingTeams = orderedByDate.filter(
    team =>
      !!team.coursesSummary && (team.coursesSummary.requiredProjects || 0) > 0,
  );

  return { teams: orderedByDate, pendingTeams };
};

export default useParsedTeamCourses;
