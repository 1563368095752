import { useMemo } from "react";
import useCurrentUserMetrics from "./useCurrentUserMetrics";
import { useLearningPlanTranslation } from "./useLearningPlanTranslation";
export var useActionMetrics = function useActionMetrics(_ref) {
  var onActionClick = _ref.onActionClick;
  var _useLearningPlanTrans = useLearningPlanTranslation(),
    t = _useLearningPlanTrans.t;
  var _useCurrentUserMetric = useCurrentUserMetrics(),
    coursesCompleted = _useCurrentUserMetric.coursesCompleted,
    tracksCompleted = _useCurrentUserMetric.tracksCompleted,
    projectsCompleted = _useCurrentUserMetric.projectsCompleted,
    additionalCourses = _useCurrentUserMetric.additionalCourses,
    loading = _useCurrentUserMetric.loading;
  var actionMetricProps = useMemo(function () {
    var _actionMetricProps = {};
    _actionMetricProps.completedAdditionalCourses = {
      total: additionalCourses.total,
      inProgress: additionalCourses.completed,
      description: t('LEARNING_PLAN_COMPLETED_ADDITIONAL_COURSES'),
      tooltipLabel: t('LEARNING_PLAN_COMPLETED_ADDITIONAL_COURSES_TOOLTIP'),
      onClick: function onClick() {
        return onActionClick('action_additional_courses');
      }
    };
    _actionMetricProps.completedTracks = {
      total: tracksCompleted.total,
      inProgress: tracksCompleted.completed,
      description: t('LEARNING_PLAN_COMPLETED_TRACKS'),
      tooltipLabel: t('LEARNING_PLAN_COMPLETED_TRACKS_TOOLTIP'),
      onClick: function onClick() {
        return onActionClick('action_completed_tracks');
      }
    };
    _actionMetricProps.completedProjects = {
      total: projectsCompleted.total,
      inProgress: projectsCompleted.completed,
      description: t('LEARNING_PLAN_COMPLETED_PROJECTS'),
      tooltipLabel: t('LEARNING_PLAN_COMPLETED_PROJECTS_TOOLTIP'),
      onClick: function onClick() {
        return onActionClick('action_completed_projects');
      }
    };
    _actionMetricProps.completedCourses = {
      total: coursesCompleted.total,
      inProgress: coursesCompleted.completed,
      description: t('LEARNING_PLAN_COMPLETED_COURSES'),
      tooltipLabel: t('LEARNING_PLAN_COMPLETED_COURSES_TOOLTIP'),
      onClick: function onClick() {
        return onActionClick('action_completed_courses');
      }
    };
    return _actionMetricProps;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, onActionClick]);
  return {
    actionMetricProps: actionMetricProps,
    loading: loading
  };
};